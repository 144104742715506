import { STRIPE_SECRET_KEY } from '../env'
import axios from '../_client'
const stripeAxiosInstaceV1 = axios.create({
  headers: {
    'Authorization': `Bearer ${STRIPE_SECRET_KEY}`
  }
})
export const StripeAPI = {
    getToken(data) {
        let cardData = `card[number]=${data.card_number}&card[exp_month]=${data.card_month}&card[exp_year]=${data.card_year}&card[cvc]=${data.card_cvc}`
        return stripeAxiosInstaceV1({
          method: 'post',
          url: `https://api.stripe.com/v1/tokens`,
          data: cardData
        })
      },

}