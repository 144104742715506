<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-form ref="form" @submit.prevent="subscribe">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <span>{{vg_business.name}}</span>
                        <v-spacer />
                        <v-btn fab dark x-small @click.prevent="closeLoyalty">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <blockquote style="border-left: solid thick #000;" class="pl-5">
                            <div class="title">{{name}}</div>
                            <div class="title">{{email}}</div>
                        </blockquote>
                    </v-card-text>
                    <v-card-text>
                        <div class="text-center">It looks like you aren't participating in {{vg_programName}}, which means you are missing out on some awesome deals &amp; perks!</div>
                    </v-card-text>
                    <v-card-text>
                        <div class="text-center">Don't worry, you can sign up now!</div>
                    </v-card-text>
                    <v-card-text>
                        <v-checkbox v-model="agree">
                            <template v-slot:label>
                                <div>I agree to the <u @click.stop.prevent="terms()">Terms &amp; Conditions</u> and <u @click.stop.prevent="privacy()">Privacy Policy</u> for <b>{{vg_business.name}}</b> loyalty program.</div>
                            </template>
                        </v-checkbox>
                    </v-card-text>
                    <v-card-actions class="mt-5">
                        <v-btn type="submit" block class="default-btn primary" dark>Join</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn @click.prevent="skip" block outlined class="primary--text">That's not me</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>
<script>
import {UserAPI} from '@/clients/user'
import {mapGetters, mapMutations} from 'vuex'
import { StringHelper } from '@/helpers/stringHelper'
export default {
    data(){
        return {
            business: '',
            name: '',
            email: '',
            agree: false,
        }
    },
    mounted(){
        if(this.vg_loginUser.user && this.vg_loginUser.user.id){
            this.business = this.vg_location.name
            this.name = StringHelper.getUserNameOrPhone(this.vg_loginUser)
            this.email = StringHelper.getMyEmailOrPhone('email')
        }
    },
    watch: {
        vg_loyaltyStatus(value){
            if(value && this.vg_loginUser.user && this.vg_loginUser.user.id){
                this.business = this.vg_location.name
                this.name = StringHelper.getUserNameOrPhone(this.vg_loginUser)
                this.email = StringHelper.getMyEmailOrPhone('email')
            }
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_loyaltyStatus
            },
            set(val){
                this.vm_loyaltyStatus(val)
            }
        },
        ...mapGetters({
            vg_businessId: 'location/business_id',
            vg_loyaltyStatus: 'loyalty/status',
            vg_location: 'location/location',
            vg_loginUser: 'login/user',
            vg_business: 'location/business',
            vg_programName: 'loyalty/programName'
        })
    },
    methods: {
        closeLoyalty(){
            this.vm_loyaltyStatus(false)
        },
        async subscribe(){
            try{
                if(this.agree){
                    this.spinner(true)
                    let data = {
                        business_id: this.vg_businessId,
                        user_id: this.vg_loginUser.user.id
                    }
                    await UserAPI.registerLoyalty(data)
                    this.snackbar({open: true, text: 'Loyalty added successfully.', color: 'success'})
                    this.spinner(false)
                    this.closeLoyalty()
                    await this.checkLoyalty()
                }else{
                    this.spinner(false)
                    this.snackbar({open: true, text: 'You must agree to join', color: 'error'})
                }
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
            }
        },
        skip(){
            this.closeLoyalty()
        },
        terms(){
            this.vm_termsConditionStatus(true)
        },
        privacy(){
            this.vm_privacyPolicyStatus(true)
        },
        async checkLoyalty(){
            try{
                let {data} = await UserAPI.checkLoyalty()
                if(data.points && data.points>0){
                    this.vm_earnedPoints(data.points)
                    this.vm_loyaltyEarningStatus(true)
                    this.vm_loyaltyEarningType('signup')
                }
            }catch(err){
                this.redeem = {}
            }
        },
        ...mapMutations({
            vm_loyaltyStatus: 'loyalty/SET_STATUS',
            vm_termsConditionStatus: 'terms/SET_STATUS',
            vm_privacyPolicyStatus: 'privacy/SET_STATUS',
            vm_earnedPoints: 'loyaltyEarning/SET_POINTS',
            vm_loyaltyEarningStatus: 'loyaltyEarning/SET_STATUS',
            vm_loyaltyEarningType: 'loyaltyEarning/SET_TYPE'
        })
    }
}
</script>
                    