<template>
    <v-container style="background-color:#f9f9f9;">
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Select Items to pay</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <div style=" background-color: #f9f9f9; overflow:auto; margin-bottom:120px;">
            <v-card class="mx-2 my-2 py-3 saw-tooth" elevation="0">
                <v-card-text class="pb-0">
                    <template v-for="(userItem, userindex) in getServerCart()">
                        <div :key="'cat-'+userindex" class="title text-center black--text pb-2">{{getUserNameOrPhone(userindex, vg_server_cart.cart_users)}}</div>
                        <template v-for="(cartItem, index) in userItem">
                            <template v-if="vg_orderType=='dinein'">
                                <!-- Dine in -->
                                <item :cartItem="cartItem" v-if="cartItem.sent_to_kitchen" :key="'list-'+userindex+index"/>
                                <template v-else >
                                    <div v-if="index===0" :key="'null-'+index" class="ml-2">Empty Bill</div>
                                </template>
                            </template>

                            <!-- Pickup -->
                            <template v-else>
                                <item :cartItem="cartItem" :key="'list-'+userindex+index"/>
                            </template>
                        </template>
                        <!-- Subtotal -->
                        <v-list class="py-0" :key="'sub-'+userindex">
                            <v-divider class="my-3" :key="'sub-d-'+userindex" />
                        </v-list>
                    </template>
                    <!-- Subtotal, tax and total -->
                    <v-list class="py-0">
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Subtotal</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{calculateSubtotal()}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Tax</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{calculateTax()}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0 title">Total</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 title" style="color:black;">${{(parseFloat(subtotal)+parseFloat(tax)).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options">
                </v-card-text>
            
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn class="left-btn" dark @click.prevent="back()">Back</v-btn>
                        <v-btn small fab class="center-btn">
                            <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="next" class="right-btn" :disabled="cart_items.length===0" dark >Continue</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </v-container>
</template>

<script>
import item from './item'
import { StringHelper } from '@/helpers/stringHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {PartialPaymentHelper} from '@/helpers/PartialPaymentHelper'
import { mapGetters, mapMutations} from 'vuex'
export default {
    components: {
        item
    },
    data(){
        return {
            subtotal: 0,
            tax: 0
        }
    },
    computed: {
        cart_items: {
            get(){
                return this.vg_selectedCartItems
            },
            set(val){
                console.log(val)
                this.vm_selectedCartItems(val)
            }
        },
        ...mapGetters({
            vg_orderType: 'cart/orderType',
            vg_cartId: 'cart/cart_id',
            vg_server_cart: 'cart/serverCart',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_selectedCartItems: 'cart/selectedCartItems',
            vg_selectedPaymentType:'payment/selectedPaymentType'
        })
    },
    methods: {
        back(){
            this.vm_selectedCartItems([])
            this.vm_splitBillPopup(true)
            this.lockCheckout()
            // this.vm_selectedPaymentType('AllByOne')
            this.$router.push({name: 'close-bill'})
        },
        next(){
            this.lockCheckout()
            this.$router.push({name: 'close-bill'})
        },
        getServerCart(){
            // for dine in
            if(this.vg_orderType=='dinein'){
                return this.sortServerCart(this.vg_server_cart, false)
            }else{
                return this.sortServerCart(this.vg_server_cart, true)
            }
        },
        // isPending true for send_to_kitchen=false
        sortServerCart(serverCart, isPending){
            return ServerCart.sortCartItem(serverCart, isPending)
        },
        getUserNameOrPhone(index, users){
            let selectedUserObj = users.filter((user)=>{
                return user.user_id===index
            })
            if(selectedUserObj && selectedUserObj[0])
                return StringHelper.capitalize(selectedUserObj[0].name)
        },
        calculatePrice(item){
            return ServerCart.calculatePrice(item)
        },
        userItemSubtotal(userItems){
            return ServerCart.calculateUserItemsTotal(userItems)
        },
        calculateSubtotal(){
            this.subtotal = PartialPaymentHelper.calculateSubTotal()
            return this.subtotal
        },
        calculateTax(){
            this.tax = PartialPaymentHelper.calculateTax()
            return this.tax
        },
        lockCheckout(){
            // locked checkout for dine in
            if(this.vg_orderType=='dinein' && this.vg_server_cart && this.vg_server_cart.paid==0){
                let self = this
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'locked_checkout_page',
                        msg: `checkout page locked for cart ${self.vg_server_cart.external_id}`,
                    }
                });
            }
        },
        ...mapMutations({
            vm_splitBillPopup: 'cart/SET_SPLIT_BILL_POPUP',
            vm_selectedCartItems: 'cart/SET_SELECTED_CART_ITEMS',
            vm_selectedPaymentType: 'payment/SET_SELECTED_PAYMENT_TYPE'
        })
    }
}
</script>