import gql from 'graphql-tag'
export const GET_ITEM = gql`
  query item($id: ID!) {
    item(id: $id) {
      id
      name
      image
      slug
      description
      price
      nutrition_summary
      order
      sku
      current_inventory
      enable_inventory_countdown
      tags {
        id
        name
        image
        type
      }
      modifiers {
        id
        name
        sku
        base_price
        included
        order
        min
        max
        status
        modifier_items {
          id
          name
          sku
          order
          price
          min
          max
          status
        }
      }
    }
  }
`
