import { APP_URL, APP_API_LOYALTY_TOKEN } from '../env'
import axios from '../_client'
import store from '../../store'

const userAxiosInstaceV1 = axios.create({
  baseURL: `${APP_URL}/api/v1`,
  headers: {
    'x-api-token': APP_API_LOYALTY_TOKEN
  }
})
userAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const LoyaltyTransactionAPI = {
  loyaltyTransactions(page){
    let vg_businessId = store.getters['location/business_id']
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'get',
      url: `/business/${vg_businessId}/user_loyalty_transactions?page=${page}&limit=50`,
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  }
}