<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Save Address?</span>
                    <v-spacer />
                    <v-btn fab dark x-small  @click.prevent="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-2">
                    <div>Save it to your profile and never worry about inputing it again! Change anytime</div>

                    <v-btn class="px-5 my-5 primary" block @click.prevent="saveAddress">Save Address</v-btn>
                    <v-btn class="px-5 mb-5" block text @click.prevent="cancel">Cancel</v-btn>
                </v-card-text>
                <v-card-text>
                    <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                    <span class="float-left pr-1 primary--text">POWERED BY :</span>
                    <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {UserAPI} from '@/clients/user'
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {

        }
    },
    watch:{
        vg_saveAddressPop(val){
            if(val){
                this.getAddress()
            }
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_saveAddressPop
            },
            set(val){
                this.vm_saveAddressPop(val)
            }
        },
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_address : 'delivery/address',
            vg_saveAddressPop: 'delivery/saveAddressPop'
        })
    },
    methods: {
        async getAddress(){
            try{
                this.spinner(true)
                let {data} = await UserAPI.getAddress()
                console.log(data)
                this.spinner(false)
            }catch(err){
                this.spinner(false)
            }
        },
        async saveAddress(){
            try{
                if(this.vg_loginUser.user && this.vg_loginUser.user){
                    this.spinner(true)
                    await UserAPI.addAddress(this.vg_address)
                    this.snackbar({open: true, text: 'Delivery address saved successfully', color: 'success'});
                    this.vm_saveAddressPop(false)
                    this.vm_savePhonePop(true)
                    this.spinner(false)
                }else{
                    this.vm_loginStatus(true)
                }
            }catch(err){
                this.spinner(false)
            }
        },
        cancel(){
            this.vm_saveAddressPop(false)
        },
        close(){
            this.vm_saveAddressPop(false)
        },
        ...mapMutations({
            vm_loginStatus: 'login/SET_STATUS',
            vm_saveAddressPop: 'delivery/SET_SAVE_ADDRESS',
            vm_savePhonePop: 'delivery/SET_SAVE_PHONE_POP',
        })
    }
}
</script>

