<template>
    <v-container>
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Receipt</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-form ref="form" v-model="valid" @submit.prevent="getReceipt">
            <div>
                <v-card class="mx-5" elevation="0">
                    <v-card-title class="mb-0 pb-0">
                        <div class="mx-auto text-center" style="opacity:1;">
                            <v-icon large color="#219653">mdi-check-circle-outline</v-icon>
                        </div>
                    </v-card-title>
                    <v-card-title class="mt-0 pt-0">
                        <div class="mx-auto text-center" style="opacity:1; color: #219653;">Payment Successful</div>
                    </v-card-title>
                    <v-card-text class="pb-0">
                        <div v-if="isPayer()!==true" class="mx-auto text-center" style="opacity:1; color: black; word-break:normal;">This bill was paid by {{payerName()}} </div>
                        <div v-else class="mx-auto text-center" style="opacity:1; color: black;">Thank you!</div>
                    </v-card-text>
                    <v-card-text>
                        <div class="text-center font-weight-bold mt-2 mb-4" v-if="vg_loginUser.user && vg_callServerTableId">Enter your email to get an electronic receipt or ask the server for a printed receipt.</div>
                        <div class="text-center font-weight-bold mt-2 mb-4" v-else>Enter your email to get an electronic receipt.</div>
                        <v-text-field v-model="form.email" :rules="rules.emailRules" outlined placeholder="Email"  />
                        <div class="signupOffer" v-if="!caslOpt">
                            <v-checkbox class="mt-0" hide-details v-model="form.signup" label="I want to receive new offers &amp; discounts!" ></v-checkbox>
                        </div>
                        <div v-if="vg_loginUser.user && vg_callServerTableId && !vg_callServerValue"  class="text-center font-weight-bold my-3">OR</div>
                        <div v-if="vg_loginUser.user && vg_callServerTableId && !vg_callServerValue" class="text-center my-2"><u @click.prevent="callServer" style="cursor:pointer;">Ask the server</u> to get a print receipt</div>
                    </v-card-text>
                </v-card>
                <version />
            </div>
            <v-footer class="footer" fixed color="white">
                <v-card class="flex" elevation="0">
                    <v-card-text class="other-options" v-if="option">
                    </v-card-text>
                
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn @click.prevent="back" class="default-btn primary" dark>Back</v-btn>
                        <!-- <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                            <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn> -->
                        <v-spacer />
                        <v-btn type="submit" class="default-btn primary" dark>Get Receipt</v-btn>
                    </div>
                </v-card-actions>
                </v-card>
            </v-footer>
        </v-form>
    </v-container>
</template>

<script>
import { StringHelper } from '@/helpers/stringHelper'
import {CartAPI} from '@/clients/cart'
import {UserAPI} from '@/clients/user'
import {OrderAPI} from '@/clients/order'
import {mapGetters, mapMutations} from 'vuex'
import Version from '@/components/custom-components/version'
export default {
    components: {
        Version
    },
    data(){
        return {
            loading: false,
            option: false,
            valid: false,
            caslOpt: true,
            form: {
                email: "",
                signup: false
            },
            rules: {
                emailRules: [
                    v => !!v || 'Email required',
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
                ]
            }
        }
    },
    async mounted(){
        await this.checkCaslOpt()
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        ...mapGetters({
            vg_tableId: 'cart/tableId',
            vg_location: 'location/location',
            vg_notification: 'cartUser/notification',
            vg_businessName: 'location/businessName',
            vg_payer: 'payment/payer',
            vg_loginUser: 'login/user',
            vg_orderId: 'payment/orderId',
            vg_orderCartId: 'payment/orderCartId',
            vg_businessId: 'location/business_id',
            vg_callServerValue: 'menu/callServerValue',
            vg_callServerTableId: 'menu/callServerTableId'
        })
    },
    methods: {
        isPayer(){
            return this.$router.currentRoute.query.payer
        },
        payerName(){
            return this.vg_payer
        },
        back(){
            if(this.vg_orderId){
                this.$router.push({name: 'review'})
            }else{
                this.$router.push({name: 'cart'})
            }
        },
        review(){
            this.$router.push({name: 'review'})
        },
        moreOption(){
            this.option = !this.option
        },
        async checkCaslOpt(){
            try{
                this.spinner(true)
                let {data} = await UserAPI.getCasl()
                this.caslOpt = data.casl_opt_in
                // this.caslOpt = response
                this.spinner(false)
            }catch(err){
                this.spinner(false)
            }
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId,
                    type: 'request_receipt'
                }
                let response = await CartAPI.callServer(this.vg_callServerTableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        async getReceipt(){
            if(this.$refs.form.validate()){
                try{
                    this.spinner(true)
                    // casl
                    if(this.form.signup){
                        await this.caslOptIn()
                    }

                    // email receipt
                    if(this.vg_orderId){
                        await this.getAllReceipt()
                    }else{
                        await this.getMyReceipt()
                    }
                }catch(err){
                    this.spinner(false)
                    console.log(err)
                }
            }
        },
        async caslOptIn(){
            try{
                let caslData = {
                    casl_opt_in: true,
                    email: this.form.email
                }
                UserAPI.caslEmail(caslData)
            }catch(err){
                console.log(err)
            }
        },
        async getAllReceipt(){
            try{
                // send email
                let userInfo = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    email: this.form.email
                }
                if(this.vg_orderId){
                    await OrderAPI.emailReceipt(this.vg_orderId, userInfo);

                    this.$refs.form.reset()
                    this.snackbar({open: true, text: 'Receipt has been sent successfully', color: 'success'});
                    this.spinner(false)
                    this.review()
                }
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        async getMyReceipt(){
            try{
                // send email
                let userInfo = {
                    user_id: this.vg_loginUser.user.id,
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    email: this.form.email
                }
                if(this.vg_orderCartId){
                    await CartAPI.emailReceipt(this.vg_orderCartId, userInfo);

                    this.$refs.form.reset()
                    this.snackbar({open: true, text: 'Receipt has been sent successfully', color: 'success'});
                    this.spinner(false)
                }
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        ...mapMutations({
            vm_notification: 'cartUser/SET_NOTIFICATION'
        }),
    }
}
</script>

<style>
.signupOffer .v-label{
    font-size: 15px;
}
.signupOffer div a{
    color: #666;
}
</style>