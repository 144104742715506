<template>
    <v-container>
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>{{(vg_orderType=='dinein')?'Manage Table':'Manage Order'}}</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <loading-block v-if="loading" class="pt-9" />
        <div class="mx-2">
            <div class="pb-2 font-weight-bold">Table: {{vg_tableId}}</div>
            <v-divider />
            <div class="pt-5 font-weight-bold">Pending</div>
            <v-list class="py-1" v-if="requestingCartUser(vg_cartUsers)">
                <template v-if="isUserJoined()">
                    <template v-for="(cartUser, index) in vg_cartUsers">
                        <v-list-item style="border-radius: 5px;" class="px-0" v-if="cartUser.status==='requested_to_join'" :key="index">
                            <v-list-item-icon class="ma-0">
                                <v-btn elevation="0" class="ma-2" fab small dark color="orange darken-3">{{getIcon(cartUser.name)}}</v-btn>
                            </v-list-item-icon>
                            <v-list-item-content class="py-0">
                                <v-list-item-title>
                                    {{capitalizeFirstLetter(cartUser.name)}}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon class="mt-1 mb-0">
                                <v-chip @click.prevent="allowUser(cartUser)" dark class="ma-2 green darken-1"> 
                                    <span>Accept</span>
                                </v-chip>
                                <v-btn @click.prevent="disAllowUser(cartUser)" class="mt-3 mr-2" width="20" height="20" dark fab elevation="0">
                                    <v-icon x-small>mdi-close</v-icon>
                                </v-btn>
                            </v-list-item-icon>
                        </v-list-item>
                    </template>
                </template>
                <template v-else>
                    <v-list-item-content class="py-0 text-center">
                        {{joinedCartUserJoined(vg_cartUsers)}} can accept you onto the table to get ordering!
                    </v-list-item-content>
                </template>
            </v-list>
            <div v-else>No request found.</div>

            <div class="mt-3 font-weight-bold">Guests</div>
            <v-list class="py-1" v-if="joinedCartUser(vg_cartUsers)">
                <template v-for="(cartUser, index) in vg_cartUsers">
                    <v-list-item style="border-radius: 5px;" class=" px-0" v-if="cartUser.status==='joined'" :key="index">
                        <v-list-item-icon class="ma-0">
                            <v-btn elevation="0" class="ma-2" fab small dark color="orange darken-3">{{getIcon(cartUser.name)}}</v-btn>
                        </v-list-item-icon>
                        <v-list-item-content class="py-0">
                            <v-list-item-title>
                                {{capitalizeFirstLetter(cartUser.name)}}
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action v-if="isAllowedToDelete() && !isSelf(cartUser)">
                            <v-list-item-icon class="ma-0">
                                <v-btn @click.prevent="removeUser(cartUser)" icon><v-icon>mdi-delete</v-icon></v-btn>
                            </v-list-item-icon>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
            <div v-else>None joined yet.</div>
        </div>
        <version />

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                <!-- <v-btn class="mb-2" block color="primary" outlined>Account</v-btn> -->
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="(!vg_tableId || !vg_loginUser.user || vg_orderType!=='dinein' || !!vg_callServerValue || userNotAcceptedToTable())">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-icon v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="inviteFriend" class="right-btn" dark :disabled="isSplitEvenlyPaid()">Invite</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import {CartUserAPI} from '@/clients/cart-user'
import {StringHelper} from '@/helpers/stringHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {CartAPI} from '@/clients/cart'
import {CartUser} from '@/helpers/cartUserHelper'
import Version from '@/components/custom-components/version'
export default {
    components: {
        Version
    },
    data(){
        return {
            loading: false,
            option: false
        }
    },
    async mounted(){
        this.loading = true
        // turn off notification - remove bell only if "item in cart is false"
        if(!this.vg_itemInCart){
            this.vm_notification(false)
        }
        
        await this.va_cartUsers(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
        this.loading  =false
    },
    watch: {
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_itemInCart(val){
            if(val){
                this.$router.push({name: 'cart'})
            }
        },
        vg_selectedPaymentType(val){
            if(val==='selectItems'){
                this.$router.push({name: 'select-item-to-pay'})
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_location: 'location/location',
            vg_server_cart: 'cart/serverCart',
            vg_tableId: 'cart/tableId',
            vg_cartId: 'cart/cart_id',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_cartUsers: 'cartUser/cartUsers',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_payed: 'payment/payed',
            vg_businessId: 'location/business_id',
            vg_loginUser: 'login/user',
            vg_orderType: 'cart/orderType',
            vg_payer: 'payment/payer',
            vg_selectedPaymentType: 'payment/selectedPaymentType',
            vg_callServerValue: 'menu/callServerValue'
        })
    },
    methods: {
        goToMenu(){
            // reload when table transfer
            let url = StringHelper.menuUrlGenerator(
                this.$router.currentRoute.params.businessName, 
                this.$router.currentRoute.params.locationName, 
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        back(){
            this.goToMenu()
        },
        moreOption(){
            this.option = !this.option
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        requestingCartUser(cartUsers){
            if(!cartUsers) return false

            return cartUsers.some((cartUser) => {
                return cartUser.status==='requested_to_join'
            })
        },
        joinedCartUser(cartUsers){
            if(!cartUsers) return false

            return cartUsers.some((cartUser) => {
                return cartUser.status==='joined'
            })
        },
        joinedCartUserJoined(cartUsers){
            if(!cartUsers) return false

            let userList = []
            cartUsers.forEach((cartUser) => {
                if(cartUser.status==='joined'){
                    userList.push(cartUser.name)
                }
            })
            return userList.join(', ')
        },
        async allowUser(cartUser){
            try {
                // let self = this
                this.spinner(true)
                let data = {
                    cart_id: this.vg_joinedCardId?this.vg_joinedCardId:this.vg_cartId,
                    user_id: cartUser.user_id,
                    name: cartUser.name,
                    status: "joined",
                    business_id: this.vg_businessId,
                    added_by_user_id: this.vg_loginUser.user.id,
                    added_by_user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser)
                }
                await CartUserAPI.respondToJoinCart(cartUser.id, data)
                this.spinner(false)
            }catch(err){
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
                this.spinner(false)
            }
        },
        async disAllowUser(cartUser){
            try {
                let self = this
                this.spinner(true)
                await CartUserAPI.removeUserRequestCart(cartUser.id)

                // broadcast to user_joined
                this.$pnPublish({
                    channel: self.vg_joinedCardId?self.vg_joinedCardId:self.vg_cartId,
                    message: {
                        event: 'user_declined'
                    }
                });

                this.snackbar({open: true, text: `${cartUser.name} removed from the request`, color: 'success'})
                this.spinner(false)
            }catch(err){
                this.spinner(false)
            }
        },
        async removeUser(cartUser){
            if(ServerCart.doUserHaveItemInCart(this.vg_server_cart, cartUser)){
                this.vm_cannotRemoveUserPop(true)
            }else{
                this.vm_cartUser(cartUser)
                this.vm_removeUserConfirmPop(true)
            }
        },
        isUserJoined(){
            if(this.vg_loginUser.user && this.vg_loginUser.user.id){
                let userId = this.vg_loginUser.user.id
                let result = this.vg_cartUsers.filter(cartUser => {
                    return cartUser.user_id==userId
                })
                if(result.length>0 && result[0].status==='joined'){
                    return true
                }else{
                    return false
                }
            }
        },
        isSplitEvenlyPaid(){
            if(this.vg_server_cart){
                return (this.vg_server_cart.paid>0 && this.vg_selectedPaymentType=='splitEvenly')
            }
        },
        inviteFriend(){
            this.$router.push({name: 'invite-friend'})
        },
        getIcon(name){
            return StringHelper.getIconName(name)
        },
        capitalizeFirstLetter(name){
            return StringHelper.capitalizeFirstLetter(name)
        },
        userNotAcceptedToTable(){
            return !CartUser.isUserActive(this.vg_loginUser, this.vg_cartUsers)
        },
        isAllowedToDelete(){
            return CartUser.isAllowedToDeleteUser(this.vg_loginUser, this.vg_cartUsers, this.vg_server_cart)
        },
        isSelf(cartUser){
            return CartUser.isSelf(this.vg_loginUser, cartUser)
        },
        ...mapMutations({
            vm_resetCartUser: 'cartUser/RESET',
            vm_cartUser: 'cartUser/SET_CART_USER',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_removeUserConfirmPop: 'cartUser/SET_REMOVE_USER_CONFIRM_POP',
            vm_cannotRemoveUserPop: 'cartUser/SET_CANNOT_REMOVE_USER_POP'
        }),
        ...mapActions({
            va_cartUsers: 'cartUser/cartUsers'
        })
    }

}
</script>