export default {
    namespaced: true,
    state: {
        popup: false,
        saveAddressPop: false,
        address: {},
        savePhonePop: false,
        phone: null,
        deliveryAt: 'now',
        deliveryDate: null,
        deliveryTime: null,
        deliveryFee: null,
        minDeliveryFee: null,
        note: '',
        changeDeliveryPop: false,
        geoLocation: {
            lat: '',
            lng: ''
        }
    },
    getters: {
        popup: state => state.popup,
        saveAddressPop: state => state.saveAddressPop,
        address: state => state.address,
        savePhonePop: state => state.savePhonePop,
        phone: state => state.phone,
        deliveryAt: state => state.deliveryAt,
        deliveryDate: state => state.deliveryDate,
        deliveryTime: state => state.deliveryTime,
        deliveryFee: state => state.deliveryFee,
        minDeliveryFee: state => state.minDeliveryFee,
        note: state => state.note,
        changeDeliveryPop: state => state.changeDeliveryPop,
        geoLocation: state => state.geoLocation
    },
    mutations: {
        SET_POPUP(state, data){
            state.popup = data
        },
        SET_SAVE_ADDRESS(state, data){
            state.saveAddressPop = data
        },
        SET_ADDRESS(state, data){
            state.address = data
        },
        SET_SAVE_PHONE_POP(state, data){
            state.savePhonePop = data
        },
        SET_PHONE(state, data){
            state.phone = data
        },
        SET_DELIVERY_AT(state, data){
            state.deliveryAt = data
        },
        SET_DELIVERY_DATE(state, data){
            state.deliveryDate = data
        },
        SET_DELIVERY_TIME(state, data){
            state.deliveryTime = data
        },
        SET_DELIVERY_FEE(state, data){
            state.deliveryFee = data
        },
        SET_MIN_DELIVERY_FEE(state, data){
            state.minDeliveryFee = data
        },
        SET_NOTE(state, data){
            state.note = data
        },
        SET_CHANGE_DELIVERY_POP(state, data){
            state.changeDeliveryPop = data
        },
        SET_GEO_LOCATION(state, data){
            state.geoLocation = data
        },
        RESET(state){
            state.popup= false,
            state.saveAddressPop= false,
            state.address= {},
            state.savePhonePop= false,
            state.phone= null,
            state.deliveryAt= 'now',
            state.deliveryDate= null,
            state.deliveryTime= null,
            state.deliveryFee = null,
            state.minDeliveryFee = null,
            state.note= '',
            state.changeDeliveryPop= false,
            state.geoLocation= {
                lat: '',
                lng: ''
            }
        }
    }
}