<template>
    <div :style="image?'background: url('+image+') center center; width:100%; height:100%; color:#fff; padding:10px;':'background: #333 center center; width:100%; height:100%; color:#fff; padding:10px;'">
        <v-row style="font-family:roboto;">
            <v-col class="col-7">
                <v-row>
                    <v-col cols="12">
                        <v-img v-if="logo" class="float-left mr-2" :src="logo" contain width="100" height="100" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <div style="margin-bottom:50px;">
            <v-row>
                <v-col md="6" cols="12" style="margin-top:20px;">
                    <div style="font-size:38px; font-family:roboto; text-align:center; max-width:400px; margin:auto;">Welcome to {{name}}</div>
                    <div class="pt-5" style="font-size:28px; font-family:roboto; text-align:center; max-width:500px; margin:auto;">{{description}}</div>
                    <div v-if="is_delivery" class="pt-5" style="margin:auto; width:280px;"><v-btn block large class="font-weight-bold primary" :disabled="(!!vg_cartId || !!vg_joinedCardId)" @click.prevent="delivery">Delivery</v-btn></div>
                    <div v-if="is_takeout" class="pt-5" style="margin:auto; width:280px;"><v-btn block large class="font-weight-bold" :disabled="(!!vg_cartId || !!vg_joinedCardId)"  @click.prevent="takeout">Takeout</v-btn></div>
                    
                </v-col>
                <v-col md="6" cols="12">
                    <v-card v-if="loaded" style="min-width:320px; width:380px; margin:auto;">
                        <v-card-title class="pb-0">{{name}}</v-card-title>
                        <v-card-text class="mt-0 subtitle-1">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                        <span :class="today_opening_closing_time.is_open?'green--text':'red--text'">{{today_opening_closing_time.is_open?'Open':'Closed'}}: </span>
                                        <span>{{today_opening_closing_time.data}}</span>
                                        <v-icon>mdi-menu-down</v-icon>
                                    </div>
                                </template>
                                <v-list>
                                    <template v-for="(item, index) in opening_closing_time">
                                        <v-list-item :key="index" :class="item.today?'font-weight-bold':''">
                                            <v-list-item-content>{{item.day_name}}</v-list-item-content>
                                            <v-list-item-action>{{ item.data }}</v-list-item-action>
                                        </v-list-item>
                                        <v-divider v-if="index < opening_closing_time.length - 1" :key="'d-'+index"></v-divider>
                                    </template>
                                </v-list>
                            </v-menu>

                            <div v-if="phone">
                                <v-icon>mdi-phone</v-icon>
                                <span class="text-decoration-underline ml-2">{{phone}}</span>
                            </div>
                        </v-card-text>
                        <v-card-text class="py-0" >
                            <gmap-map
                                :center="marker"
                                :zoom="15"
                                map-type-id="roadmap"
                                style="width:320px; height: 250px;"
                                :options= optionInfo
                                >
                                <gmap-marker
                                    :position="marker"
                                    icon="/img/marker.png"
                                />
                                </gmap-map>
                        </v-card-text>
                        <v-card-text class="pt-0 subtitle-1">
                            <div>{{address}}</div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div style="width:100%; position:absolute; bottom:10px;">
            <div class="text-center" style="width:145px; margin:auto;">
                <span class="float-left pr-1" >POWERED BY :</span>
                <v-img class="float-left" src="@/assets/img/white-logo-small.png" contain width="35" height="25" style="top:-5px"/>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import {UserAPI} from '@/clients/user'
import moment from 'moment'

export default {
    props: ['businessName', 'locationName'],
    data(){
        return {
            loaded: false,
            logo: '',
            image: '',
            name: '',
            phone: '',
            opening_closing_time: [],
            today_opening_closing_time: {},
            address: '',
            description: 'Short description of a business that will surely bring extra customers to an establishment to make a nice order of the most expensive dishes on the menu.',
            marker: {
                lat: null,
                lng: null
            },
            optionInfo: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false
            },
            is_delivery:false,
            is_takeout: false
        }
    },
    async mounted(){
        try{
            this.vm_setBusinessName(this.businessName)
            await this.va_setLocationAndBusinessOnly([this.businessName, this.locationName])
            await this.va_cartUsers(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
        }catch(err){
            if(err.response && err.response.data && err.response.data.msg){
            this.vm_resetLocationBusiness()
            this.$router.push({name: 'not-found'})
            }
        }
    },
    watch:{
        async vg_location(){
            this.loaded = false
            await this.getDetail()
            this.loaded = true
        }
    },
    computed: {
        ...mapGetters({
            vg_cartId: 'cart/cart_id',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_orderType: 'cart/orderType',
            vg_location: 'location/location',
            vg_business: 'location/business'
        })
    },
    methods: {
        getDetail(){
            this.logo = this.vg_business.branding.logo_url
            if(this.vg_location.id){
                this.name = this.vg_location.name
                this.image = this.getImage(this.vg_location.images)
                this.phone = this.getPhone(this.vg_location.contact_details)
                this.opening_closing_time = this.getOperationHour(this.vg_location.hours_of_operation)
                this.address = this.vg_location.address.formatted_address
                this.marker.lat = parseFloat(this.vg_location.address.latitude)
                this.marker.lng = parseFloat(this.vg_location.address.longitude)

                if(this.vg_location.settings.delivery_orders){
                    this.is_delivery = (this.vg_location.settings.delivery_orders.is_active && this.vg_location.settings.delivery_orders.is_enabled)?true: false
                }
                if(this.vg_location.settings.pickup_orders){
                    this.is_takeout = (this.vg_location.settings.pickup_orders.is_active && this.vg_location.settings.pickup_orders.is_enabled)?true: false
                }
            }
        },
        getImage(images){
            if(!images || images.length==0) return

            let defaultImages = images.filter(image => image.is_default)
            if(defaultImages && defaultImages[0] && defaultImages[0].image_url){
                return defaultImages[0].image_url
            }
        },
        getPhone(addresses){
            if(!addresses || addresses.length==0) return

            let phoneAddress = addresses.filter(address => address.type=='phone')
            if(phoneAddress && phoneAddress[0] && phoneAddress[0].value){
                return phoneAddress[0].value
            }
            
        },
        getOperationHour(operationHours){
            if(!operationHours) return

            let operationHourArr = []
            let todayDate = moment().format('YYYY-MM-DD')
            for(const ind in operationHours){
                let openDate = todayDate+' '+operationHours[ind].open_time
                let closeDate = todayDate+' '+operationHours[ind].close_time
                let openDateFormat = moment(openDate, 'YYYY-MM-DD hh:mm:ss').format('hh:mm a')
                let closeDateFormat = moment(closeDate, 'YYYY-MM-DD hh:mm:ss').format('hh:mm a')

                if(operationHours[ind].is_today){
                    if(operationHours[ind].is_open){
                        this.today_opening_closing_time = {
                            is_open: moment().isBetween(openDate, closeDate),
                            data: openDateFormat + ' - ' + closeDateFormat, 
                        }
                    }else{
                        this.today_opening_closing_time = {
                            is_open: false,
                            data: openDateFormat + ' - ' + closeDateFormat, 
                        }
                    }
                }
                operationHourArr.push({
                    day_name: operationHours[ind].day_name,
                    data: openDateFormat + ' - ' + closeDateFormat, 
                    today: operationHours[ind].is_today
                })
            }
            return operationHourArr
        },
        async delivery(){
            this.vm_orderDeliveryPickup(false)
            this.vm_orderType('delivery')
            this.vm_tableId(null)
            // await this.getAddress()
            this.$router.push({name: 'menu'})
        },
        takeout(){
            this.vm_orderDeliveryPickup(false)
            this.vm_orderType('pickup')
            this.vm_tableId(null)
            this.$router.push({name: 'menu', query: {'receive_method': 'takeout'}})
        },
        async getAddress(){
            try{
                this.spinner(true)
                let {data} = await UserAPI.getAddress()
                if(data.results && data.results[0]){
                    // set address
                    let addressData = {
                        address_line_1: data.results[0].address_line_1,
                        address_line_2: data.results[0].address_line_2,
                        address_type: 'home',
                        city: data.results[0].city,
                        country: data.results[0].country,
                        label: data.results[0].label,
                        postal_code: data.results[0].postal_code,
                        province: data.results[0].province,
                        verified:false
                    }
                    this.vm_address(addressData)

                    let geoData = {
                        lat: data.results[0].latitude,
                        lng: data.results[0].longitude
                    }
                    this.vm_geoLocation(geoData)
                }else{
                    this.vm_deliveryPop(true)
                }
                this.spinner(false)
            }catch(err){
                this.vm_deliveryPop(true)
                this.spinner(false)
            }
        },
        ...mapMutations({
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_orderDeliveryPickup: 'order/SET_ORDER_PICKUP_DELIVERY',
            vm_address: 'delivery/SET_ADDRESS',
            vm_orderType: 'cart/SET_ORDER_TYPE',
            vm_deliveryPop: 'delivery/SET_POPUP',
            vm_geoLocation: 'delivery/SET_GEO_LOCATION',
            vm_setBusinessName: 'location/SET_BUSINESS_NAME',
            vm_resetLocationBusiness: 'location/RESET_LOCATION_BUSINESS'
        }),
        ...mapActions({
            va_setLocationAndBusinessOnly: 'location/setLocationAndBusinessOnly',
            va_cartUsers: 'cartUser/cartUsers',
        })
    }
}
</script>