<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-form ref="form" v-model="valid" @submit.prevent="savePhone">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <span>How do we contact you?</span>
                        <v-spacer />
                        <v-btn fab dark x-small  @click.prevent="close">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <div class="mx-5 text-center">In order to prevent any mishaps, we want to have an easy way to reach you!</div>

                        <v-text-field @keypress="isNumber" maxlength="10" class="mt-5 mx-5" v-model="form.phone" :rules="rules.phoneRules" outlined placeholder="Input Phone Number"  /> 

                        <v-btn type="submit" class="primary px-5 mb-5" block :disabled="!valid">Continue</v-btn>
                    </v-card-text>
                    <v-card-text>
                        <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                        <span class="float-left pr-1 primary--text">POWERED BY :</span>
                        <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
            valid: false,
            form : {
                phone: null
            },
            rules: {
                phoneRules: [
                    v => !!v || 'Phone number is required',
                    v => !!v && /^\d+$/.test(v) || 'Phone number must be numbers only',
                    v => !!v && v.length==10 || 'Phone number must be of 10 digits'
                ]
            }
        }
    },
    watch: {
        vg_savePhonePop(val){
            if(val){
                this.form.phone = this.vg_phone
            }
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_savePhonePop
            },
            set(val){
                this.vm_savePhonePop(val)
            }
        },
        ...mapGetters({
            vg_savePhonePop: 'delivery/savePhonePop',
            vg_phone: 'delivery/phone'
        })
    },
    methods: {
        isNumber(e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        savePhone(){
            this.vm_phone(this.form.phone)
            this.vm_savePhonePop(false)
        },
        close(){
            this.vm_savePhonePop(false)
        },
        ...mapMutations({
            vm_savePhonePop: 'delivery/SET_SAVE_PHONE_POP',
            vm_phone: 'delivery/SET_PHONE'
        })
    }
}
</script>