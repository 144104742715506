import { AUTH_URL, AUTH_TOKEN} from '../env'
import axios from '../_client'
const authAxiosInstaceV1 = axios.create({
  baseURL: `${AUTH_URL}/api/v1`,
  headers: {
    'api-token': AUTH_TOKEN
  }
})

authAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})

export const AuthAPI = {
    getBusinessId(business_slug){
        return authAxiosInstaceV1({
          method: 'get',
          url: `/businesses/slug/${business_slug}`
        })
    }
}