import { ItemSearchAPI } from '@/clients/item'
export default {
    namespaced: true,
    state: {
      item: {},
    },
    getters: {
        item: state => state.item
    },
    mutations: {
        SET_ITEM(state, item) {
            state.item = item
        }
    },
    actions: {
        async setItem({ commit }, slug) {
            try{
                const { data } = await ItemSearchAPI.getItem(slug)
                commit('SET_ITEM', data.itemSearch)
            }catch(err){
                let slugArr = slug.split('-')
                const { data } = await ItemSearchAPI.getItemById(slugArr[slugArr.length-1])
                commit('SET_ITEM', data.item)
            }
        }
    }
}