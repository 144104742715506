<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Remove Guest</span>
                    <v-spacer />
                </v-card-title>
                <v-card-text>
                    <div class="mx-5 text-center">This action is non-reversable, are you sure?</div>

                    <v-btn @click.prevent="removeUser(vg_cartUser)" class="primary mt-5 px-5 mb-5" block>Remove {{capitalizeFirstLetter(vg_cartUser.name)}}</v-btn>
                    <v-btn @click.prevent="cancel()" class="primary px-5 mb-5" block>Cancel</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import {CartUserAPI} from '@/clients/cart-user'
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_removeUserConfirmPop
            },
            set(val){
                this.vm_removeUserConfirmPop(val)
            }
        },
        ...mapGetters({
            vg_cartId: 'cart/cart_id',
            vg_cartUser: 'cartUser/cartUser',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_removeUserConfirmPop: 'cartUser/removeUserConfirmPop',
        })
    },
    methods: {
        capitalizeFirstLetter(name){
            return StringHelper.capitalizeFirstLetter(name)
        },
        cancel(){
            this.vm_removeUserConfirmPop(false)
        },
        async removeUser(){
            try {
                let self = this
                this.spinner(true)
                await CartUserAPI.removeUserRequestCart(this.vg_cartUser.id)

                // broadcast to user_joined
                this.$pnPublish({
                    channel: self.vg_joinedCardId?self.vg_joinedCardId:self.vg_cartId,
                    message: {
                        event: 'user_removed',
                        msg: `${this.vg_cartUser.name} guest removed successfully.`
                    }
                });
                this.vm_removeUserConfirmPop(false)
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                this.vm_removeUserConfirmPop(false)
            }
        },
        ...mapMutations({
            vm_removeUserConfirmPop: 'cartUser/SET_REMOVE_USER_CONFIRM_POP'
        })
    }
}
</script>