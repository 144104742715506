import { APP_ORDER_URL, APP_API_TOKEN } from '../env'
import axios from '../_client'
import store from '../../store'
const orderAxiosInstaceV1 = axios.create({
  baseURL: `${APP_ORDER_URL}`,
  headers: {
    'x-api-token': APP_API_TOKEN
  }
})
orderAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const OrderAPI = {
    processOrder(data){
      return orderAxiosInstaceV1({
        method: 'post',
        url: `/orders`,
        data: data
      })
    },
    getPastOrder(userId, locationId, page){
        return orderAxiosInstaceV1({
          method: 'get',
          url: `/orders?user_id=${userId}&location_id=${locationId}&page=${page}`
        })
    },
    frequentOrder(userId, locationId){
      let vg_loginUser = store.getters['login/user']
      return orderAxiosInstaceV1({
        method: 'get',
        url: `/orders/frequent/orderItems?user_id=${userId}&location_id=${locationId}`,
        headers: {
          'x-auth-token': vg_loginUser['x-auth-token']
        }
      })
    },
    getPastOrderDetail(orderId){
      return orderAxiosInstaceV1({
        method: 'get',
        url: `/orders/${orderId}`
      })
    },
    lastOrder(locationId){
      let vg_loginUser = store.getters['login/user']
      return orderAxiosInstaceV1({
        method: 'get',
        url: `/orders/${locationId}/userOrders?limit=1&page=1&order_users=true&order_tips=true&order_delivery=true&status=pending,preparing,ready,out_for_delivery&order_promocode=true&order_loyalty=true`,
        headers: {
          'x-auth-token': vg_loginUser['x-auth-token']
        }
      })
    },
    getOrderDetailByCartId(cartId){
      return orderAxiosInstaceV1({
        method: 'get',
        url: `/orders?cart_id=${cartId}`
      })
    },
    emailReceipt(orderId, userInfo){
      return orderAxiosInstaceV1({
        method: 'post',
        url: `/orders/${orderId}/emailReceipt`,
        data: userInfo
      })
    },
    review(orderId, review){
      return orderAxiosInstaceV1({
        method: 'post',
        url: `/orderReviews/${orderId}`,
        data: review
      })
    }
}