import gql from 'graphql-tag'
export const ITEM_SEARCH = gql`
  query itemSearch($slug: String!) {
    itemSearch(where: {slug: $slug}) {
      id
      name
      slug
      image
      description
      price
      nutrition_summary
      order
      sku
      current_inventory
      enable_inventory_countdown
      tags {
        id
        name
        image
        type
      }
      category {
        category_hours {
          id
          day
          status
          open_time
          close_time
        }
      }
      modifiers {
        id
        name
        sku
        base_price
        included
        order
        min
        max
        status
        modifier_items {
          id
          name
          sku
          order
          price
          min
          max
          status
        }
      }
    }
  }
`
