import store from '@/store'
import { APP_PAYMENT_URL } from '../env'
import axios from '../_client'
const paymentAxiosInstaceV1 = axios.create({
  baseURL: `${APP_PAYMENT_URL}`
})
paymentAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const PaymentAPI = {
    getPayment(businessId, userId, location){
      let processor = 'moneris'
      if(location?.settings?.payment_processor_details?.provider_name){
        let processorArr = location.settings.payment_processor_details.provider_name.split('_')
        if(processorArr && processorArr[0]){
          processor = processorArr[0]
        }
      }
      return paymentAxiosInstaceV1({
          method: 'get',
          url: `/paymentMethods?business_uuid=${businessId}&user_id=${userId}&processor=${processor}`,
          headers: {
            // 'api-token': store.getters['payment/token']
          }
      })
    },
    addPayment(data){
        return paymentAxiosInstaceV1({
            method: 'post',
            url: `/paymentMethods/add`,
            data: data,
            headers: {
              'api-token': store.getters['payment/token']
            }
        })
    },
    deletePayment(payment_id, data){
        return paymentAxiosInstaceV1({
            method: 'post',
            url: `/paymentMethods/${payment_id}/remove`,
            data: data,
            headers: {
              'api-token': store.getters['payment/token']
            }
        })
    },
    getApiToken(businessId){
      console.log(businessId)
      return true
      // return paymentAxiosInstaceV1({
      //   method: 'get',
      //   url: `/applications/business/${businessId}/token`
      // })
    },
    getElavonToken(data){
      return paymentAxiosInstaceV1({
        method: 'post',
        url: `/paymentMethods/getElavonSessionToken`,
        data: data,
        headers: {
          'api-token': store.getters['payment/token']
        }
      })
    }
}