import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main/index.vue'
import Welcome from '../views/welcome/index.vue'
import Menu from '../views/menu/index.vue'
import Recommend from '../views/menu/recommend.vue'
import MenuDetail from '../views/menuDetail/index.vue'
import Cart from '../views/cart'
import ItemCart from '../views/cart/item-cart'
import Order from '../views/order'
import CloseBill from '../views/bill/closeBill'
import SelectItemToPay from '../views/select-item-to-pay'
import InviteFriend from '../views/inviteFriend'
import ManageTable from '../views/manageTable'
import Receipt from '../views/receipt'
import Review from '../views/review'
import CustomerDetail from '../views/account/customer-detail'
import AddPayment from '../views/add-payment'
import PastOrder from '../views/account/past-order'
import OrderStatus from '../views/order-status'
import Contact from '../views/contact'
import Register from '../views/register/index'
import Privacy from '../views/privacy-policy/page'
import Terms from '../views/term-condition/page'
import FiskaResponse from '../views/add-payment/fiska-reponse'
import NotFound from '../views/notFound'

import LoadingBlock from '../components/custom-components/LoadingBlock.vue';

Vue.use(VueRouter)
Vue.component("LoadingBlock", LoadingBlock)

const routes = [
  {
    path: '/',
    name: 'welcome',
    component: Welcome
  },
  {
    path: '/fiska_reponse',
    name: 'fiska-response',
    component: FiskaResponse
  },
  {
    path: '/:businessName/registerAdmin',
    props: true,
    name: 'register',
    component: Register
  },
  {
    path: '/:businessName/:locationName/',
    props: true,
    name: 'main',
    component: Main
  },
  {
    path: '/:businessName/:locationName/menu',
    props: true,
    name: 'menu',
    component: Menu
  },
  {
    path: '/:businessName/:locationName/recommend',
    props: true,
    name: 'recommend',
    component: Recommend
  },
  {
    path: '/:businessName/:locationName/menu/:slug/item',
    name: 'menu-detail',
    component: MenuDetail
  },
  {
    path: '/:businessName/:locationName/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/:businessName/:locationName/item-cart',
    name: 'item-cart',
    component: ItemCart
  },
  {
    path: '/:businessName/:locationName/order',
    name: 'order',
    component: Order
  },
  {
    path: '/:businessName/:locationName/close-bill',
    name: 'close-bill',
    component: CloseBill
  },
  {
    path: '/:businessName/:locationName/select-item-to-pay',
    name: 'select-item-to-pay',
    component: SelectItemToPay
  },
  {
    path: '/:businessName/:locationName/invite-friend',
    name: 'invite-friend',
    component: InviteFriend
  },
  {
    path: '/:businessName/:locationName/manage-table',
    name: 'manage-table',
    component: ManageTable
  },
  {
    path: '/:businessName/:locationName/receipt',
    name: 'receipt',
    component: Receipt
  },
  {
    path: '/:businessName/:locationName/review',
    name: 'review',
    component: Review
  },
  {
    path: '/:businessName/:locationName/customer-detail',
    name: 'customer-detail',
    component: CustomerDetail
  },
  {
    path: '/:businessName/:locationName/add-payment',
    name: 'add-payment',
    component: AddPayment
  },
  {
    path: '/:businessName/:locationName/past-order',
    name: 'past-order',
    component: PastOrder
  },
  {
    path: '/:businessName/:locationName/order-status',
    name: 'order-status',
    component: OrderStatus
  },
  {
    path: '/:businessName/:locationName/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/:businessName/:locationName/privacy-policy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/:businessName/:locationName/terms-and-conditions',
    name: 'terms',
    component: Terms
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
