<template>
    <div class="my-2 py-3 " style="background-color: white;">
        <v-row>
            <v-col cols="4">
                <div class="mx-5 font-weight-bold">Checkout</div>
            </v-col>
            <v-col cols="8" class="text-right">
                <a class="mx-5" @click.prevent="switchToDelivery">SWITCH TO DELIVERY</a>
            </v-col>
        </v-row>
        <div class="mx-5">Pickup at <span class="font-weight-bold">{{vg_location.name}}</span></div>
        <div class="mx-5">{{vg_location.address.formatted_address}}</div>
        <div class="mx-5">
            <a :href="'tel:'+phone" style="text-decoration: none; color: #000;">
                <v-icon>mdi-phone</v-icon> {{phone}}
            </a>
        </div>
        <div v-if="pickupAt==='now'" class="mx-5">
            <v-row>
                <v-col>
                    <div>Ready in about</div>
                </v-col>
                <v-col>
                    <div class="text-right">20 - 30 min</div>
                </v-col>
            </v-row>
            <v-row class="pt-0 mt-0">
                <v-col>
                    <v-btn class="primary" block @click.prevent="toggleDelivery('now')" :disabled="!storeOpen">Now</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-if="vg_location.settings.pickup_orders && vg_location.settings.pickup_orders.use_timeslots" outlined block @click.prevent="toggleDelivery('future')">Later</v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else  class="mx-5">
            <v-row>
                <v-col>
                    <div>{{readableDay}}</div>
                </v-col>
                <v-col>
                    <div class="text-right">{{readableTime}}</div>
                </v-col>
            </v-row>
            <v-row  class="pt-0 mt-0">
                <v-col>
                    <v-btn outlined block @click.prevent="toggleDelivery('now')"  :disabled="!storeOpen">Now</v-btn>
                </v-col>
                <v-col>
                    <v-btn class="primary" block @click.prevent="toggleDelivery('future')">Later</v-btn>
                </v-col>
            </v-row>
            <v-row  class="pt-0 mt-0">
                <v-col>
                    <v-select v-model="pickupDate" style="border: solid thin #ccc" :items="dates" label="Today"
                        flat solo hide-details dense 
                    ></v-select>
                </v-col>
                <v-col >
                    <v-select v-model="pickupTime" style="border: solid thin #ccc" :items="times" label="Time" no-data-text="Please select the date"
                        flat solo hide-details dense
                    ></v-select>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {CartAPI} from '@/clients/cart'
import {StringHelper} from '@/helpers/stringHelper'
import {mapMutations, mapGetters} from 'vuex'
import moment from 'moment'
import 'moment-timezone'
import _ from 'lodash'
import {LocationAPI} from '@/clients/location'
export default {
    data(){
        return {
            phone: '',
            locationPayload: [],
            dates: [],
            times: [],
            readableDay: '',
            readableTime: '',
            storeOpen: false
        }
    },
    async mounted(){
        this.vg_location.contact_details.forEach(obj => {
            if(obj.type==='phone' && obj.value){
                this.phone = StringHelper.standardPhoneFormat(obj.value)
            }
        })
        await this.getTimeSlot()
        this.storeOpen = this.isStoreOpenNow()

        if(!this.vg_pickupAt && this.storeOpen){
            this.vm_pickupAt('now')
        }else if(!this.storeOpen && (this.vg_pickupAt=='now' || !this.vg_pickupAt)){
            this.vm_pickupAt('future')
        }
    },
    watch: {
        pickupDate(date){
            this.getPickupDate(date)
        },
        vg_pickupTime(value){
            this.getPickupTime(value)
        }
    },
    computed: {
        pickupAt: {
            get(){
                return this.vg_pickupAt
            },
            set(val){
                this.vm_pickupAt(val)
            }
        },
        pickupDate: {
            get(){
                return this.vg_pickupDate
            },
            set(val){
                this.vm_pickupDate(val)
            }
        },
        pickupTime: {
            get(){
                return this.vg_pickupTime
            },
            set(val){
                this.vm_pickupTime(val)
            }
        },
        ...mapGetters({
            vg_cartId: 'cart/cart_id',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_deliveryPhone: 'delivery/phone',
            vg_location: 'location/location',
            vg_businessId: 'location/business_id',
            vg_pickupAt: 'cart/pickupAt',
            vg_pickupDate: 'cart/pickupDate',
            vg_pickupTime: 'cart/pickupTime',
            vg_deliveryFee: 'delivery/deliveryFee',
            vg_geoLocation: 'delivery/geoLocation',
            vg_server_cart: 'cart/serverCart',
        })
    },
    methods: {
        async getTimeSlot(){
            try{
                let {data} = await LocationAPI.fetchPickupTimeSlots(this.vg_businessId, this.vg_location.slug)
                if(data.payload){
                    this.locationPayload = data.payload

                    data.payload.forEach(eachDate => {
                        let dateOnly = eachDate.split(' ').shift()
                        // load dates
                        if(this.dates.findIndex((each) => {return each.value===dateOnly})<0) 
                            this.dates.push({text: moment(dateOnly, 'YYYY-MM-DD').format('MMM. DD'), value: dateOnly})
                        
                    })
                    // load times if selected
                    if(this.vg_pickupDate){
                        this.getPickupDate(this.vg_pickupDate)
                    }

                    // select default readable time
                    this.getPickupTime(this.vg_pickupTime)
                }

            }catch(err){
                console.log(err)
            }
        },
        isStoreOpenNow(){
            let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'
            moment.tz.setDefault(timezone)
            let todayIndex = this.vg_location.hours_of_operation.findIndex(each => each.is_today)
            let todayStoreHours = this.vg_location.hours_of_operation[todayIndex]
            let yesterdayStoreHours = this.vg_location.hours_of_operation[todayIndex>0?todayIndex-1:6]
            let todayDate = moment().format('YYYY-MM-DD')
            let tomorrowDate = moment().add(1, 'day').format('YYYY-MM-DD')
            let openTime = null
            let closeTime = null
            let storeOpen = false

            // check with yesterday open time and today close time
            if(yesterdayStoreHours.open_time>yesterdayStoreHours.close_time){
                openTime = todayDate+' 00:00:00'
                closeTime = todayDate+' '+yesterdayStoreHours.close_time
                storeOpen = moment().isBetween(openTime, closeTime) && todayStoreHours.is_open
                if(storeOpen){
                    return true
                }
            }

            if(todayStoreHours.open_time<=todayStoreHours.close_time){
                openTime = todayDate+' '+todayStoreHours.open_time
                closeTime = todayDate+' '+todayStoreHours.close_time
            }else{
                openTime = todayDate+' '+todayStoreHours.open_time
                closeTime = tomorrowDate+' 23:59:59'
            }
            if(moment().isBetween(openTime, closeTime) && todayStoreHours.is_open){
                return true
            }else{
                return false
            }
        },
        readableDate(){
            if(moment(this.vg_pickupDate, 'YYYY-MM-DD').format('DD')===moment().format('DD'))
                return 'Today'
            else if(moment(this.vg_pickupDate, 'YYYY-MM-DD').format('DD')===moment().add(1, 'd').format('DD'))
                return 'Tomorrow'
            else
                return 'Later'
        },
        getPickupDate(date){
            this.times = []
            this.locationPayload.forEach(eachDate => {
                let dateArray = eachDate.split(' ')
                let timeOnly = dateArray[1]+' '+dateArray[2]
                if(dateArray[0]===date){
                    this.times.push({text: moment(timeOnly, 'H:mm a').format('HH:mm'), value: timeOnly})
                }
            })
            _.sortBy(this.times, ['text'])

            this.readableDay = this.readableDate()
        },
        getPickupTime(time){
            let timeIndex = _.findIndex(this.times, ['value', time])
            if(this.times[timeIndex])
                this.readableTime = this.times[timeIndex].text
        },
        toggleDelivery(status){
            this.vm_pickupAt(status)
        },
        async switchToDelivery(){
            try{
                let data = {
                    order_receive_method: 'delivery'
                }
                await CartAPI.switchReceiveMethod(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, data)
                this.vm_orderType('delivery')
                if(!this.vg_deliveryFee && this.vg_deliveryFee!==0){
                    this.$router.push({name: 'cart'})
                }else{
                    await this.isDeliveryAvailable()
                }
            }catch(err){
                console.log(err)
            }
        },
        async isDeliveryAvailable(){
            try{
                if(this.vg_geoLocation.lat && this.vg_geoLocation.lng){
                    this.spinner(true)
                    let {data} = await LocationAPI.getDeliveryFee(this.vg_location.id, this.vg_geoLocation, this.vg_server_cart.subtotal)
                    if(data.payload){
                        this.vm_deliveryFee(data.payload.delivery_fee)
                    }
                    this.spinner(false)
                }
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                this.$router.push({name: 'cart'})
            }
        },
        ...mapMutations({
            vm_orderType: 'cart/SET_ORDER_TYPE',
            vm_pickupAt: 'cart/SET_PICKUP_AT',
            vm_pickupDate: 'cart/SET_PICKUP_DATE',
            vm_pickupTime: 'cart/SET_PICKUP_TIME',
            vm_deliveryFee: 'delivery/SET_DELIVERY_FEE',
            vm_changeDeliveryPopup: 'delivery/SET_CHANGE_DELIVERY_POP',
        })
    }
}
</script>