export default {
    namespaced: true,
    state: {
      status: false,
      abruptClose: false
    },
    getters: {
        status: state => state.status,
        abruptClose: state => state.abruptClose
    },
    mutations: {
        SET_STATUS(state, status) {
            state.status = status
        },
        SET_ABRUPT_CLOSE(state, data){
            state.abruptClose = data
        }
    }
}