<template>
    <div>
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Item in Cart</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <loading-block v-if="loading" class="pt-9" />
        <div v-else style="overflow:auto; margin-bottom:100px;">
            <div class="mx-5 title">Still want these?</div>
            <div class="mx-5">Would you like to send these items to the kitchen?</div>
            <v-card class="mt-2 mx-5" elevation="0" color="#E5EAF0">
                <v-card-text>
                    <!-- if logged in get from server -->
                    <template v-if="vg_loginUser.user && vg_loginUser.user.id && (vg_cartId || vg_joinedCardId) && localCleared">
                        <template v-if="!isServerCartEmpty()">
                            <template v-for="(userItem, userindex) in sortServerCart(vg_server_cart, true)">
                                <div :key="'pend-'+userindex" class="title text-center black--text pb-2">{{getUserNameOrPhone(userindex, vg_server_cart.cart_users)}}</div>
                                <template v-for="(item, itemindex) in userItem">
                                    <v-list v-if="!item.sent_to_kitchen" three-line class="py-0"  color="#E5EAF0" :key="'pend-'+userindex+'item'+itemindex">
                                        <v-list-item style="min-height: 60px !important;" class="px-0 my-0">
                                            <v-list-item-icon class="mr-2 mb-0 py-0">
                                                <v-badge class="mt-0" :content="getCourse(item.course).abbr" :color="getCourse(item.course).color"/>
                                            </v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                                <div>{{item.item_qty}}x</div>
                                            </v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start" @click.prevent="editItem(item)">
                                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                                <v-list-item-content class="pt-0 mt-0">
                                                    <!-- Modifier items -->
                                                    <template v-for="(item_modifier, modifierIndex) in item.cart_item_modifiers">
                                                        <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'a'+modifierIndex">{{item_modifier.name}}</div>
                                                        <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                            <div class="text--secondary" :key="'aa'+modifierIndex+modifierItemIndex">- {{modifier_item.name}}</div>
                                                        </template>
                                                    </template>
                                                    <!-- Note -->
                                                    <template v-if="item.note">
                                                        <div class="text--secondary">Item note:</div>
                                                        <div class="text--secondary">{{item.note}}</div>
                                                    </template>
                                                </v-list-item-content>
                                            </v-list-item-content>
                                            <v-list-item-action class="my-0 py-0" style="align-self: flex-start;">
                                                <v-list-item-action-text >
                                                    <span class="pr-3 body-2" style="color:black;">${{calculateServerPrice(item)}}</span>
                                                    <!-- <v-icon small>mdi-dots-vertical</v-icon> -->
                                                    <v-icon @click.prevent="removeServerItem(item)" small color="black">mdi-close</v-icon>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </template>
                            </template>
                        </template>
                        <div class="text-center" v-else>Your order is empty</div>
                    </template>

                    <!-- else logged in data -->
                    <template v-else-if="isLocalData()">
                        <template v-for="(category,index) in vg_cart" >
                            <template v-for="(item, itemindex) in category.items">
                                <v-list three-line class="py-0"  color="#E5EAF0" :key="'cat'+index+'item'+itemindex">
                                    <v-list-item style="min-height: 60px !important;" class="px-0 my-0">
                                        <v-list-item-icon class="mr-2 mb-0 py-0">
                                            <v-badge class="mt-0" :content="item.qty" color="primary"/>
                                        </v-list-item-icon>
                                        <v-list-item-content class="my-0 py-0" style="align-self:flex-start">
                                            <v-list-item-title>{{item.name}}</v-list-item-title>
                                            <v-list-item-content class="pt-0 mt-0">
                                                <!-- Modifier items -->
                                                <template v-for="(item_modifier, modifierIndex) in item.cart_item_modifiers">
                                                    <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'ab'+modifierIndex">{{item_modifier.name}}</div>
                                                    <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                        <div class="text--secondary" :key="'ab'+modifierIndex+modifierItemIndex">- {{modifier_item.name}}</div>
                                                    </template>
                                                </template>
                                                <!-- Note -->
                                                <template v-if="item.note">
                                                    <div class="text--secondary">Item note:</div>
                                                    <div class="text--secondary">{{item.note}}</div>
                                                </template>
                                            </v-list-item-content>
                                        </v-list-item-content>
                                        <v-list-item-action class="my-0 py-0" style="align-self: flex-start;">
                                            <v-list-item-action-text >
                                                <span class="pr-3 body-2" style="color:black;">${{calculateLocalPrice(item)}}</span>
                                                <v-icon @click.prevent="removeLocalItem(category, item)" small color="black">mdi-close</v-icon>
                                            </v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </template>
                    </template>
                    <div class="text-center" v-else>Your order is empty</div>
                </v-card-text>
                <v-card-actions>
                    <v-btn block dark color="#005" :disabled="needToSendOrder()" @click.prevent="sendOrder">Send Order</v-btn>
                </v-card-actions>
            </v-card>
        </div>

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="browseMenu">Browse Menu</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                            <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="skip" class="right-btn" dark>Skip</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </div>
</template>
<script>
import {Cart} from '@/helpers/cartHelper'
import {StringHelper} from '@/helpers/stringHelper'
import {CartAPI} from '@/clients/cart'
import {ServerCart} from '@/helpers/serverCartHelper'
import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return {
            loading: false,
            option: false,
            local: true,
            localCleared: false,
            courses: [
                {abbr: 'N', text: "Now", color: 'orange darken-3'}, 
                {abbr: '1', text: "First Course", color: 'blue darken-3'}, 
                {abbr: '2', text: "Second Course", color: 'purple darken-3'}, 
                {abbr: '3', text: "Third Course", color: 'green darken-3'}, 
                {abbr: 'T', text: "Takeout", color: 'yellow darken-3'}
            ],
        }
    },
    async mounted(){
        this.loading = true
        await this.getServerCart()
        this.loading = false
        this.spinner(false)
    },
    watch: {
        async vg_loginUser(obj){
            if(obj.msg){
                if(this.local){
                    await this.checkCardId()
                }else{
                    this.sendOrder()
                }
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        ...mapGetters({
            vg_cart : 'cart/cart',
            vg_cart_note: 'cart/cartNote',
            vg_server_cart: 'cart/serverCart',
            vg_notification: 'cartUser/notification',
            vg_payed: 'payment/payed',
            vg_payer: 'payment/payer',
            vg_loginUser: 'login/user',
            vg_location: 'location/location',
            vg_businessId: 'location/business_id',
            vg_tableId: 'cart/tableId',
            vg_businessName: 'location/businessName',
            vg_cartId: 'cart/cart_id',
            vg_orderType: 'cart/orderType',
            vg_joinedCardId: 'cart/joinedCardId'
            
        })
    },
    methods: {
        getCourse(course){
            if(this.courses[course]){
                return this.courses[course]
            }
        },
        editItem(item){
            this.vm_editItem(item)
            if(this.vg_location.slug && this.vg_businessName){
                let url = StringHelper.menuDetailUrlGenerator(
                        this.vg_businessName, 
                        this.vg_location.slug,
                        item.item_id
                    )
                this.$router.push(url)
            }
        },
        browseMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        back(){
            this.$router.go(-1)
        },
        skip(){
            this.$router.push({name: 'close-bill'})
        },
        moreOption(){
            this.option = !this.option
        },
        calculateLocalPrice(item){
            return Cart.calculatePrice(item)
        },
        calculateServerPrice(item){
            return ServerCart.calculatePrice(item)
        },
        isLocalData(){
            return !Cart.isCartEmpty(this.vg_cart)
        },
        needToSendOrder(){
            // if logged in
            if(this.vg_loginUser.user && this.vg_loginUser.user.id){
                return ServerCart.isAllServerCartSent(this.vg_server_cart)
            }else{
            // else not logged in
                return true
            }
        },
        async removeServerItem(item){
            try{
                this.spinner(true)
                await CartAPI.deleteItem(item.id, {user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser), user_id: this.vg_loginUser.user.id})
                await this.getServerCart()
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }

        },
        // isPending true for send_to_kitchen=false
        sortServerCart(serverCart, isPending){
            return ServerCart.sortCartItem(serverCart, isPending)
        },
        getUserNameOrPhone(index, users){
            let selectedUserObj = users.filter((user)=>{
                return user.user_id===index
            })
            if(selectedUserObj && selectedUserObj[0])
                return StringHelper.capitalize(selectedUserObj[0].name)
        },
        async getServerCart(){
            try{
                if(this.vg_loginUser.user && this.vg_loginUser.user.id){
                    if(!this.vg_cartId && !this.vg_joinedCardId){
                        if(!await this.checkCardId(false)){
                            return
                        }
                    }
                    this.local = false
                    let {data} = await CartAPI.getCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
                    this.vm_server_cart(data.payload)
                    this.localCleared = true
                }
            }catch(err){
                console.log(err)
            }
        },
        // show server cart empty if all item are send to kitchen
        isServerCartEmpty(){
            if(this.vg_server_cart.id){
                return ServerCart.isAllServerCartSent(this.vg_server_cart)
            }
        },
        async sendOrder(){
            // check for login
            if(this.vg_loginUser.user && this.vg_loginUser.user.id){
                this.$router.push({name: 'order'})
            }else{
                await this.checkCardId()
            }
        },
        async checkCardId(processAddUpdateItem=true){
            try{
                if(processAddUpdateItem){
                    // check for login
                    if(!this.vg_location || !this.vg_location.id){
                        this.$router.push('/')
                    }
                    if(!this.vg_loginUser || !this.vg_loginUser.user || !this.vg_loginUser.user.id){
                        this.vm_loginStatus(true)
                        return
                    }
                }
                // check for table id
                if(!this.vg_tableId && this.vg_orderType=='dinein'){
                    this.vm_identifyTableStatus(true)
                    return
                }

                this.spinner(true)
                
                // get active cart by location, table and user
                let activeCart
                if(this.vg_orderType=='dinein'){
                    let activeCartData = {
                        business_id: this.vg_businessId,
                        location_id: this.vg_location.id,
                        table_id: this.vg_tableId,
                        user_id: this.vg_loginUser.user.id,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser)
                    }
                    if(StringHelper.isLoginWithIsPhone()){
                        activeCartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        activeCartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }
                    let {data} = await CartAPI.getActiveCartByTableUserLocation(activeCartData)
                    activeCart = data.payload
                }else{
                    let {data} = await CartAPI.getActivePickupOrDeliveryCartByLocation(this.vg_location.id, this.vg_loginUser.user.id)
                    activeCart = data.payload
                }
                if(activeCart && activeCart.id){
                    if(this.vg_joinedCardId){
                        this.vm_joinedCardId(activeCart.id)
                    }else{
                        this.vm_cartId(activeCart.id)
                    }
                    // get cart
                    let {data} = await CartAPI.getCart(activeCart.id)
                    let cart = data.payload

                    if(processAddUpdateItem){
                        await this.addUpdateItem(cart)
                    }else{
                        return true
                    }
                }
            }catch(err){
                if(err.response.data.msg==='This table has ongoing order and you are not invited to this table yet.'){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.spinner(false)
                    this.vm_tableId('')
                    return false
                }else{
                    let cartData = {
                        location_id: this.vg_location.id,
                        table_id: this.vg_tableId,
                        user_id: this.vg_loginUser.user.id,
                        user_name: this.vg_loginUser.user.first_name+' '+this.vg_loginUser.user.last_name,
                        added_by: 'user',
                        order_receive_method: this.vg_orderType,
                        business_id:this.vg_businessId,
                    }
                    if(StringHelper.isLoginWithIsPhone()){
                        cartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        cartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }
                    
                    if(this.vg_cart_note.trim()){
                        cartData.note = this.vg_cart_note
                    }
                    try{
                        let {data} = await CartAPI.createCart(cartData)
                        let cart = data.payload
                        if(this.vg_joinedCardId){
                            this.vm_joinedCardId(cart.id)
                        }else{
                            this.vm_cartId(cart.id)
                        }
                        if(processAddUpdateItem){
                            await this.addUpdateItem(cart)
                        }else{
                            return true
                        }
                    }catch(err){
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                        this.spinner(false)
                        this.vm_tableId('')
                        return false
                    }
                }
            }
        },
        async addUpdateItem(cart){
            try{
                for(const ind in this.vg_cart){
                    for (const itemInd in this.vg_cart[ind].items){
                        let eachItem = this.vg_cart[ind].items[itemInd]
                        let eachCategory = this.vg_cart[ind]

                        let itemParam = {
                            cart_id: cart.id,
                            user_id: this.vg_loginUser.user.id,
                            user_name: this.vg_loginUser.user.username,
                            item_sku: eachItem.item_sku,
                            name: eachItem.name,
                            category: eachCategory.name,
                            category_id: eachCategory.id,
                            item_id: eachItem.id,
                            item_order: eachItem.item_order,
                            item_qty: eachItem.qty,
                            base_price: Number(eachItem.price),
                            actual_price: this.calculateLocalPrice(eachItem),
                            cart_item_modifiers: eachItem.cart_item_modifiers,
                            added_by: 'user'
                        }
                        if(StringHelper.isLoginWithIsPhone()){
                            itemParam.user_phone = StringHelper.getMyEmailOrPhone('phone')
                        }else{
                            itemParam.user_email = StringHelper.getMyEmailOrPhone('email')
                        }

                        if(eachItem.note.trim())
                            itemParam.note = eachItem.note
                        
                        let {data} = await CartAPI.addItem(itemParam)
                        this.vm_server_cart(data.payload)
                    }
                }

                // add note and change status to completed is different
                if(this.vg_cart_note){
                    let cartData = {
                        table_id: this.vg_tableId,
                        note: this.vg_cart_note
                    }
                    let {data} = await CartAPI.updateCart(cart.id, cartData)
                    this.vm_server_cart(data.payload)
                }

                this.spinner(false)
                this.$router.push({name: 'order'})

                //this.vm_resetCart();
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: 'Something went wrong', color: 'error'});
            }
        },
        ...mapMutations({
            // vm_cart: 'cart/SET_CART',
            vm_cartId :'cart/SET_CART_ID',
            vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',
            vm_loginStatus: 'login/SET_STATUS',
            vm_server_cart: 'cart/SET_SERVER_CART',
            vm_identifyTableStatus: 'identifyTable/SET_STATUS',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_editItem: 'cart/SET_EDIT_ITEM',
            vm_tableId: 'cart/SET_TABLE_ID',
        })
    }
}
</script>