import { render, staticRenderFns } from "./LoadingBlock.vue?vue&type=template&id=006e2412&scoped=true&"
var script = {}
import style0 from "./LoadingBlock.vue?vue&type=style&index=0&id=006e2412&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006e2412",
  null
  
)

export default component.exports