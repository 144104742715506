<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-form ref="form" v-model="valid" @submit.prevent="doSignup">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <span>Sign Up</span>
                        <v-spacer />
                        <v-btn fab dark x-small @click.prevent="closeSignUp">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="mt-2">
                        <v-img v-if="vg_business.branding && vg_business.branding.logo_url" :src="vg_business.branding.logo_url" contain max-height="50" />
                    </v-card-text>
                    <v-card-text>
                        <v-text-field
                            v-model="form.phone_number_email"
                            :rules="rules.phoneEmailRules"
                            label="Phone Number or Email (Required)"
                            required
                            outlined
                            ></v-text-field>
                        
                        
                        <v-text-field
                            v-model="form.first_name"
                            :rules="rules.firstNameRules"
                            label="First Name"
                            required
                            outlined
                            ></v-text-field>

                        <v-text-field
                            v-model="form.last_name"
                            :rules="rules.lastNameRules"
                            label="Last Name"
                            required
                            outlined
                            ></v-text-field>

                        <div class="text-center">By clicking sign up, you agree to Gr8tables <u @click.stop.prevent="terms()" style="cursor: pointer;">Terms of Use</u> and <u @click.stop.prevent="privacy()" style="cursor: pointer;">Privacy Policy</u></div>
                    </v-card-text>
                    <v-card-text>
                        <v-btn
                            type="submit"
                            color="primary"
                            block
                            :disabled="!valid"
                            >
                            Sign Up
                        </v-btn>

                        <div class="mt-5 text-center">Already have an account? <a href="#" @click.prevent="logIn">CLICK HERE</a></div>
                    </v-card-text>
                    <v-card-text>
                        <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                        <span class="float-left pr-1">POWERED BY :</span>
                        <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import {UserAPI} from '@/clients/user'
import {mapGetters, mapMutations} from 'vuex'

export default {
    data(){
        return{
            form: {
                phone_number_email: '',
                first_name: '',
                last_name: ''
            },
            rules: {
                phoneEmailRules: [
                    v => !!v || 'Please use a valid Phone Number',
                    v => (!isNaN(v) || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'E-mail must be valid',
                    v => (isNaN(v) || !!v && v.length==10) || 'Phone number must be of 10 digits'
                ],
                firstNameRules: [
                    v => !!v || 'Please enter First Name'
                ],
                lastNameRules: [
                    v => !!v || 'Please enter Last Name'
                ]
            },
            valid: false
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_phoneEmailPop
            },
            set(val){
                this.vm_phoneEmailPop(val)
            }
        },
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_phoneEmailPop: 'signup/phoneEmailPop',
            vg_businessId: 'location/business_id',
            vg_business: 'location/business'
        })
    },
    methods: {
        async doSignup(){
            if(this.$refs.form.validate()){
                try{
                    this.spinner(true)
                    if(isNaN(parseInt(this.form.phone_number_email))){
                        let registerEmailData = {
                            email: this.form.phone_number_email,
                            first_name: this.form.first_name,
                            last_name: this.form.last_name,
                            is_registered: true,
                            is_verified: false,
                            without_password: true
                        }
                        let {data} = await UserAPI.emailRegister(registerEmailData)
                        this.vm_signupPhone(this.form.phone_number_email)
                        this.vm_loginWith(this.form.phone_number_email)
                        this.vm_signupVerifyData(data)
                        this.spinner(false)
                        this.closeSignUp()
                        this.vm_verifyStatus(true)
                    }else{
                        let registerData = {
                            phone_number: this.form.phone_number_email,
                            first_name: this.form.first_name,
                            last_name: this.form.last_name,
                            is_registered: true,
                            is_verified: false
                        }
                        let {data} = await UserAPI.register(registerData)
                        this.vm_signupPhone(this.form.phone_number_email)
                        this.vm_loginWith(this.form.phone_number_email)
                        this.vm_signupVerifyData(data)
                        this.spinner(false)
                        this.closeSignUp()
                        this.vm_verifyStatus(true)
                    }
                    this.spinner(false)
                }catch(err){
                    this.spinner(false)
                    if(err.response && err.response.data && err.response.data.msg){
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
                    }
                }
            }
        },
        closeSignUp(){
            this.$refs.form.reset()
            this.vm_phoneEmailPop(false)
        },
        logIn(){
            this.closeSignUp()
            this.vm_loginStatus(true)
        },
        terms(){
            this.vm_termsConditionStatus(true)
        },
        privacy(){
            this.vm_privacyPolicyStatus(true)
        },
         ...mapMutations({
            vm_phoneEmailPop: 'signup/SET_PHONE_EMAIL_POP',
            vm_signupPhone: 'signup/SET_SIGN_UP_PHONE',
            vm_loginStatus: 'login/SET_LOGIN_POP',
            vm_verifyStatus: 'signup/SET_VERIFY',
            vm_signupVerifyData: 'signup/SET_VERIFY_DATA',
            vm_termsConditionStatus: 'terms/SET_STATUS',
            vm_privacyPolicyStatus: 'privacy/SET_STATUS',
            vm_loginWith: 'login/SET_LOGIN_WITH'
        })
    }
}
</script>
