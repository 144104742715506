import store from '@/store'
import { APP_CART_URL, APP_API_TOKEN } from '../env'
import axios from '../_client'
const cartAxiosInstaceV1 = axios.create({
  baseURL: `${APP_CART_URL}`,
  headers: {
    'x-api-token': APP_API_TOKEN
  }
})
cartAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const CartAPI = {
  createCart(data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/carts`,
      data: data
    })
  },
  
  cloneCart(cartId, data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/carts/${cartId}/cloneCart`,
      data: data
    })
  },

  getActiveCartByLocation(locationId){
    return cartAxiosInstaceV1({
      method: 'get',
      url: `/carts/activeForlocation/${locationId}`
    })
  },
  
  getActiveCartByTableUserLocation(data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/carts/getActiveForLocationTableUser`,
      data: data
    })
  },

  switchReceiveMethod(cartId, data){
    return cartAxiosInstaceV1({
      method: 'patch',
      url: `/carts/${cartId}/switchReceiveMethod`,
      data: data
    })
  },

  getCartIdByTableNumber(locationId, tableId){
    return cartAxiosInstaceV1({
      method: 'get',
      url: `/carts/activeForlocation/${locationId}/table/${tableId}`
    })
  },

  getActivePickupOrDeliveryCartByLocation(locationId, userId){
    return cartAxiosInstaceV1({
      method: 'get',
      url: `/carts/activePickupOrDeliveryCartForLocation/${locationId}/user/${userId}`
    })
  },

  updateCart(cartId, data){
    return cartAxiosInstaceV1({
      method: 'patch',
      url: `/carts/${cartId}`,
      data: data
    })
  },

  getCart(cartId){
    return cartAxiosInstaceV1({
      method: 'get',
      url: `/carts/${cartId}`
    })
  },

  callServer(cartId, data){
    let callServerAxios = cartAxiosInstaceV1({
      method: 'post',
      url: `/carts/${cartId}/callServer`,
      data: data
    })
    store.commit('menu/SET_CALL_SERVER_USER', store.getters['login/user'])
    store.commit('menu/SET_CALL_SERVER_VALUE', 36000)
    return callServerAxios
  },

  payViaServer(cartId, data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/carts/${cartId}/payViaServer`,
      data: data
    })
  },

  addItem(data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/cartItems`,
      data: data
    })
  },

  updateItem(id, data){
    return cartAxiosInstaceV1({
      method: 'patch',
      url: `/cartItems/${id}`,
      data: data
    })
  },

  deleteItem(id, data){
    return cartAxiosInstaceV1({
      method: 'delete',
      url: `/cartItems/${id}`,
      data: data
    })
  },

  sendOrderToKitchen(cartId){
    return cartAxiosInstaceV1({
      method: 'put',
      url: `/carts/${cartId}/sendOrderToKitchen`
    })
  },

  addPromo(data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/cartPromocodes`,
      data: data
    })
  },

  removePromo(promoId){
    return cartAxiosInstaceV1({
      method: 'delete',
      url: `/cartPromocodes/${promoId}`
    })
  },

  previewLoyalty(data){
    let vg_cartId = store.getters['cart/cart_id']
    let vg_joinedCartId = store.getters['cart/joinedCardId']
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/cartLoyalty/${vg_joinedCartId?vg_joinedCartId:vg_cartId}/preview`,
      data: data
    })
  },

  addRedeem(data){
    let vg_cartId = store.getters['cart/cart_id']
    let vg_joinedCartId = store.getters['cart/joinedCardId']
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/cartLoyalty/${vg_joinedCartId?vg_joinedCartId:vg_cartId}/redeem`,
      data: data
    })
  },

  removeLoyalty(redeemId){
    return cartAxiosInstaceV1({
      method: 'delete',
      url: `/cartLoyalty/${redeemId}`
    })
  },

  addTips(data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/cartTips`,
      data: data
    })
  },

  removeTips(tipsId){
    return cartAxiosInstaceV1({
      method: 'delete',
      url: `/cartTips/${tipsId}`
    })
  },

  placeOrder(cartId, data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/carts/${cartId}/placeOrder`,
      data: data
    })
  },

  partialPayment(data){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/cartPayments`,
      data:data
    })
  },

  emailReceipt(cartId, userInfo){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/carts/${cartId}/emailReceipt`,
      data: userInfo
    })
  },

  registerUser(userInfo){
    return cartAxiosInstaceV1({
      method: 'post',
      url: `/applications/invite/user`,
      data: userInfo
    })
  }
}