<template>
    <v-form ref="form" v-model="valid" @submit.prevent="doSignup">
        <v-card flat>
            <v-card-title>
                <v-spacer />
                <span>Register</span>
                <v-spacer />
            </v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="form.first_name"
                    :rules="rules.firstNameRules"
                    label="First Name"
                    required
                    outlined
                    ></v-text-field>

                <v-text-field
                    v-model="form.last_name"
                    :rules="rules.lastNameRules"
                    label="Last Name"
                    required
                    outlined
                    ></v-text-field>

                <v-text-field
                    type="number"
                    v-model="form.phone"
                    :rules="rules.phoneRules"
                    label="Phone Number"
                    required
                    outlined
                    ></v-text-field>

                <v-text-field
                    v-model="form.email"
                    :rules="rules.emailRules"
                    label="Email"
                    required
                    outlined
                    ></v-text-field>

                <v-text-field
                    type="password"
                    v-model="form.password"
                    :rules="rules.passwordRules"
                    label="Password"
                    required
                    outlined
                    ></v-text-field>

                <v-text-field
                    type="password"
                    v-model="form.confirm_password"
                    :rules="rules.confirmRules"
                    label="Confirm Password"
                    required
                    outlined
                    ></v-text-field>
            </v-card-text>
            <v-card-text>
                <v-btn
                    type="submit"
                    color="primary"
                    block
                    :disabled="!valid"
                    >
                    Sign Up
                </v-btn>
            </v-card-text>
        </v-card>
    </v-form>
</template>

<script>
import {CartAPI} from '@/clients/cart'
import {mapGetters} from 'vuex'

export default {
    data(){
        return{
            form: {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                password: '',
                confirm_password: ''
            },
            rules: {
                firstNameRules: [
                    v => !!v || 'Please enter First Name'
                ],
                lastNameRules: [
                    v => !!v || 'Please enter Last Name'
                ],
                phoneRules: [
                    v => !!v || 'Please use a valid Phone Number',
                    v => (isNaN(v) || !!v && v.length==10) || 'Phone number must be of 10 digits'
                ],
                emailRules: [
                    v => !!v || 'Please enter email',
                    v => (!isNaN(v) || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'E-mail must be valid',
                ],
                passwordRules: [
                    v => !!v || 'Please enter password'
                ],
                confirmRules: [
                    v => !!v || 'Please re-enter password'
                ]
            },
            valid: false
        }
    },
    computed: {
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_phoneEmailPop: 'signup/phoneEmailPop',
            vg_businessId: 'location/business_id',
            vg_business: 'location/business'
        })
    },
    methods: {
        async doSignup(){
            if(this.$refs.form.validate()){
                try{
                    let registerData = {
                        phone: this.form.phone,
                        email: this.form.email,
                        first_name: this.form.first_name,
                        last_name: this.form.last_name,
                        password: this.form.password,
                        confirm_password: this.form.confirm_password,
                        is_registered: true,
                        is_verified: false,
                        set_new_password: false,
                        is_corporate: false,
                        admin_access: true,
                        send_email: false,
                        business_id: this.vg_businessId
                    }
                    this.spinner(true)
                    let {data} = await CartAPI.registerUser(registerData)
                    console.log(data)
                    if(data){
                        this.$refs.form.reset()
                        this.snackbar({open: true, text: 'Register successfully', color: 'success'})
                    }
                    this.spinner(false)
                }catch(err){
                    this.spinner(false)
                    if(err.response && err.response.data && err.response.data.msg){
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
                    }
                }
            }
        }
    }
}
</script>
