<template>
    <div>
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Terms and Conditions</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <div >
            <Content />
            <version />
        </div>
        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
            <v-card-text class="other-options" v-if="option">
            </v-card-text>
            
            <v-card-actions class="mt-5">
                <div class="footer-bar">
                <v-btn @click.prevent="back" class="default-btn primary">Back</v-btn>
                <v-spacer />
                <v-btn class="default-btn primary" :disabled="true">Call</v-btn>
                </div>
            </v-card-actions>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
import Version from '@/components/custom-components/version'
import Content from './content.vue'
export default {
    components: {
        Version,
        Content
    },
    data(){
        return {
            option: false,
        }
    },
    methods: {
        back(){
            this.$router.go(-1)
        }
        
    }
}
</script>