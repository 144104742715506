<template>
    <div>
        <v-toolbar elevation="1">
            <v-spacer></v-spacer>
            <v-toolbar-title>Add Payment Method</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-form ref="form" v-model="valid" @submit.prevent="addNewCard">
            <v-card elevation="0">
                <loading-block v-if="!loaded" class="pt-9" />
                <!-- moneris -->
                <v-card-text class="add-card" v-if="paymentType==='moneris'">
                    <loading-block v-if="!loadedIframe" class="pt-9" />
                    <v-row v-if="loadedIframe">
                        <v-col class="py-0" cols="12">
                            <v-text-field class="mx-1 font-weight-medium" v-model="form.email" outlined hide-details placeholder="Email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="loadedIframe">
                        <v-col class="pt-2 pb-0 pr-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium"  v-model="form.first_name" outlined hide-details placeholder="First Name"></v-text-field>
                        </v-col>
                        <v-col class="pt-2 pb-0 pl-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium"  v-model="form.last_name" outlined hide-details placeholder="Last Name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="mx-1">
                            <iframe v-show="loadedIframe" id="monerisFrame" :src="monerisIframeUrl" @load="iframe" frameborder="0" width="100%"></iframe>
                        </v-col>
                    </v-row>
                    <v-row v-if="loadedIframe" class="mt-0">
                        <v-col class="py-0" cols="12">
                            <v-switch class="pt-0 mt-0" v-model="form.is_default_payment" hide-details  label="Default payment method"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- stripe -->
                <v-card-text class="add-card" v-if="paymentType==='stripe'">
                    <v-row>
                        <v-col class="py-0" cols="12">
                            <v-text-field class="mx-1 font-weight-medium" v-model="stripe.email" outlined hide-details placeholder="Email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-2 pb-0 pr-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium"  v-model="stripe.first_name" outlined hide-details placeholder="First Name"></v-text-field>
                        </v-col>
                        <v-col class="pt-2 pb-0 pl-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium"  v-model="stripe.last_name" outlined hide-details placeholder="Last Name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 pt-2" cols="12">
                            <v-text-field type="text" maxlength="19" class="mx-1 font-weight-medium" v-model.number="stripe.card_number" outlined hide-details placeholder="Card Number"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-2 pb-0 pr-0" cols="6" >
                            <v-text-field type="text" maxlength="2" class="mx-1 font-weight-medium"  v-model="stripe.card_month" outlined hide-details placeholder="Month"></v-text-field>
                        </v-col>
                        <v-col class="pt-2 pb-0 pl-0" cols="6" >
                            <v-text-field type="text" maxlength="4" class="mx-1 font-weight-medium"  v-model="stripe.card_year" outlined hide-details placeholder="Year"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 pt-2" cols="12">
                            <v-text-field type="text" maxlength="4" class="mx-1 font-weight-medium" v-model="stripe.card_cvc" outlined hide-details placeholder="Cvc"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="py-0" cols="12">
                            <v-switch class="pt-0 mt-0" v-model="form.is_default_payment" hide-details  label="Default payment method"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- elevon -->
                <v-card-text class="add-card" v-if="paymentType==='elevon'">
                    <v-row>
                        <v-col class="py-0" cols="12">
                            <v-text-field class="mx-1 font-weight-medium" v-model="elevon.email" :rules="rulesElevon.email" outlined placeholder="Email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0 pb-0 pr-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium" v-model="elevon.first_name" :rules="rulesElevon.firstName" outlined placeholder="First Name"></v-text-field>
                        </v-col>
                        <v-col class="pt-0 pb-0 pl-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium"  v-model="elevon.last_name"  :rules="rulesElevon.lastName" outlined placeholder="Last Name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 pt-0" cols="12">
                            <v-text-field type="text" maxlength="19" class="mx-1 font-weight-medium" v-model.number="elevon.address" :rules="rulesElevon.address" outlined placeholder="Address"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 pt-0" cols="12">
                            <v-text-field type="text" maxlength="19" class="mx-1 font-weight-medium" v-model.number="elevon.zip" :rules="rulesElevon.zip" outlined placeholder="Zip"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 pt-0" cols="12">
                            <v-text-field type="text" maxlength="19" class="mx-1 font-weight-medium" v-model.number="elevon.card_number" :rules="rulesElevon.cardNumber" outlined placeholder="Card Number"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0 pb-0 pr-2" cols="12" >
                            <v-text-field type="text" maxlength="4" class="mx-1 font-weight-medium"  v-model="elevon.card_expiry" :rules="rulesElevon.cardExpiry" outlined placeholder="Expiry Date (MMYY)"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="py-0 pt-0" cols="12">
                            <v-text-field type="text" maxlength="4" class="mx-1 font-weight-medium" v-model="elevon.card_cvc" :rules="rulesElevon.cardCvc" outlined placeholder="Cvc"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="py-0" cols="12">
                            <v-switch class="pt-0 mt-0" v-model="form.is_default_payment" hide-details  label="Default payment method"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="clover-form add-card" v-if="paymentType==='clover'">
                    <v-row>
                        <v-col class="py-0" cols="12">
                            <v-text-field class="mx-1 font-weight-medium" v-model="clover.email"  hide-details outlined placeholder="Email"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-2 pb-0 pr-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium" v-model="clover.first_name" hide-details outlined placeholder="First Name"></v-text-field>
                        </v-col>
                        <v-col class="pt-2 pb-0 pl-0" cols="6" >
                            <v-text-field class="mx-1 font-weight-medium"  v-model="clover.last_name" hide-details outlined placeholder="Last Name"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-4 pt-2 pb-0" cols="12">
                            <div id="card-number" class="clover-form-field"></div>
                            <div class="red--text clover-error-msg" id="card-number-errors"></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0 pb-0 px-4" cols="12" >
                            <div id="card-date" class="clover-form-field"></div>
                            <div class="clover-error-msg red--text" id="card-date-errors"></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-4 pt-2 pb-0" cols="12">
                            <div id="card-cvv" class="clover-form-field"></div>
                            <div class="clover-error-msg red--text" id="card-cvv-errors"></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="px-4 pt-2 pb-0" cols="12">
                            <div id="card-postal-code" class="clover-form-field"></div>
                            <div class="clover-error-msg red--text" id="card-postal-code-errors"></div>
                        </v-col>
                    </v-row>
                    <div id="card-errors"></div>
                    <div id="card-response"></div>
                </v-card-text>
                <!-- <v-card-actions>
                    <v-btn
                        type="submit"
                        color="primary"
                        block
                        >
                        Add Card
                    </v-btn>
                </v-card-actions> -->
            </v-card>

            <v-footer class="footer" fixed color="white">
                <v-card class="flex" elevation="0">
                    <v-card-text class="other-options" v-if="option">
                        <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || (vg_orderType!=='dinein') || !!vg_callServerValue">Call Server</v-btn>
                    </v-card-text>
                
                    <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                        <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                        <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn type="submit" class="right-btn" dark>Add Payment</v-btn>
                    </div>
                    </v-card-actions>
                </v-card>
            </v-footer>
        </v-form>
    </div>
</template>

<script>
import { StringHelper } from '@/helpers/stringHelper'
import { PaymentAPI } from '@/clients/payment'
import {CartAPI} from '@/clients/cart'
import { StripeAPI } from '@/clients/stripe'
import { CloverAPI} from '@/clients/clover'
import { APP_MONERIS_URL, CLOVER_SDK } from '@/clients/env'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    data(){
        return{
            loaded: false,
            option: false,
            loadedIframe: false,
            paymentType: '',
            countryOptions: ['Canada'],
            form: {
                first_name: '',
                last_name: '',
                email: '',
                is_default_payment: false
            },
            stripe: {
                first_name: '',
                last_name: '',
                email: '',
                card_number: '',
                card_year: '',
                card_month: '',
                card_cvc: '',
                is_default_payment: false
            },
            elevon: {
                merchant_id: '',
                first_name: '',
                last_name: '',
                address: '',
                zip: '',
                card_number: '',
                card_expiry: '',
                card_cvc: '',
                is_default_payment: false
            },
            rulesElevon: {
                email: [v => !!v || 'Please enter email'],
                firstName: [v => !!v || 'Please enter first name'],
                lastName: [v => !!v || 'Please enter last name'],
                address: [v => !!v || 'Please enter address'],
                zip: [v => !!v || 'Please enter zip'],
                cardNumber: [v => !!v || 'Please enter card number'],
                cardExpiry: [v => !!v || 'Please enter card expiry'],
                cardCvc: [v => !!v || 'Please enter card cvc'],
            },
            clover: {
                first_name: '',
                last_name: '',
                email: ''
            },
            valid: false
        }
    },
    mounted() {
        this.getLocationSetting()
        this.loaded = false
        this.loadedIframe = false
        if (window.addEventListener) {  
            window.addEventListener ("message", this.monerisRespMsg, false);
        }else{
            if (window.attachEvent) {
                window.attachEvent("onmessage", this.monerisRespMsg);
            }
        }
    },
    destroyed(){
        window.removeEventListener ("message", this.monerisRespMsg)
    },
    watch: {
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        async vg_newCart(val){
            if(val){
                this.getLocationSetting()
            }
        }
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        monerisIframeUrl(){
            let APP_MONERIS_ID
            if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.payment_info){
                APP_MONERIS_ID = this.vg_location.settings.payment_processor_details.payment_info
            }
            return  `${APP_MONERIS_URL}?id=${APP_MONERIS_ID}&pmmsg=true&enable_exp=1&enable_cvd=1&css_body=background:white;&css_textbox=width:100%;background:white;padding:10px;border-radius:5px;border:1px%20solid%20grey;height:40px;border-color:grey;font-size:16px;margin-top:8px;&display_labels=2&exp_label=Expiry Date (MMYY)`;
        },
        ...mapGetters({
            vg_orderType: 'cart/orderType',
            vg_payed: 'payment/payed',
            vg_payer: 'payment/payer',
            vg_newCart: 'cart/newCart',
            vg_loginUser: 'login/user',
            vg_tableId: 'cart/tableId',
            vg_location: 'location/location',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_businessId: 'location/business_id',
            vg_businessName: 'location/businessName',
            vg_notification: 'cartUser/notification',
            vg_callServerValue: 'menu/callServerValue'
        })
    },
    methods: {
        back(){
            this.$router.push({name: 'customer-detail'})
        },
        moreOption(){
            this.option = !this.option
        },
        iframe(){
            this.loadedIframe = true
        },
        async getLocationSetting(){
            if(!this.vg_location.slug || !this.vg_businessId) return

            // get location setting
            await this.va_locationOnly({locationName: this.vg_location.slug, businessId: this.vg_businessId })

            if(this.vg_loginUser && this.vg_loginUser.user){
                if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='stripe_payment'){
                    this.stripe.first_name = this.vg_loginUser.user.first_name
                    this.stripe.last_name = this.vg_loginUser.user.last_name
                    if(StringHelper.getMyEmailOrPhone('email')) this.stripe.email = StringHelper.getMyEmailOrPhone('email')
                    // stripe
                    this.paymentType = 'stripe'
                    this.loaded = true
                }else if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='elavon_payment'){
                    this.elevon.merchant_id = this.vg_location.settings.payment_processor_details.ssl_merchant_id
                    this.elevon.first_name = this.vg_loginUser.user.first_name
                    this.elevon.last_name = this.vg_loginUser.user.last_name
                    if(StringHelper.getMyEmailOrPhone('email')) this.elevon.email = StringHelper.getMyEmailOrPhone('email')
                    // elevon
                    this.paymentType = 'elevon'
                    this.loadScript()
                    this.loaded = true
                }else if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='clover_payment'){
                    this.clover.first_name = this.vg_loginUser.user.first_name
                    this.clover.last_name = this.vg_loginUser.user.last_name
                    if(StringHelper.getMyEmailOrPhone('email')) this.clover.email = StringHelper.getMyEmailOrPhone('email')
                    this.paymentType = 'clover'
                    let accessToken = await this.getCloverToken()
                    if(accessToken){
                        await this.loadCloverScript(CLOVER_SDK)
                        await this.createCardElement(accessToken)
                    }
                    this.loaded = true
                }else{
                    this.form.first_name = this.vg_loginUser.user.first_name
                    this.form.last_name = this.vg_loginUser.user.last_name
                    if(StringHelper.getMyEmailOrPhone('email')) this.form.email = StringHelper.getMyEmailOrPhone('email')
                    // moneris
                    this.paymentType = 'moneris'
                    this.loaded = true
                }
            }
        },
        async addNewCard(){
            if(this.paymentType === 'moneris'){
                // check for first name and last name
                if(this.form.email===''){
                    this.snackbar({open: true, text: 'Email is required.', color: 'error'});
                    return
                }else if(this.form.first_name==='' || this.form.last_name===''){
                    this.snackbar({open: true, text: 'First name and last name is required.', color: 'error'});
                    return
                }
                this.getMonerisData()
            }else if(this.paymentType === 'stripe'){
                // check for first name and last name
                if(this.stripe.first_name==='' || this.stripe.last_name===''){
                    this.snackbar({open: true, text: 'First name and last name is required.', color: 'error'});
                    return
                }
                this.getStripeToken()
            }else if(this.paymentType==='elevon'){
                if(this.$refs.form.validate()){
                    let {data} = await PaymentAPI.getElavonToken({
                        "business_uuid": this.vg_businessId,
                        "location_id": this.vg_location.id
                    })
                    if(data.payload){
                        let self = this
                        ConvergeEmbeddedPayment.pay({   // eslint-disable-line
                            ssl_txn_auth_token: data.payload,
                            ssl_card_number: this.elevon.card_number,
                            ssl_exp_date: this.elevon.card_expiry,
                            ssl_get_token: 'y',
                            ssl_add_token: 'y',
                            ssl_first_name: this.elevon.first_name,
                            ssl_last_name: this.elevon.last_name,
                            ssl_cvv2cvc2: this.elevon.card_cvc,
                            ssl_merchant_txn_id: this.elevon.merchant_id,
                            ssl_avs_address: this.elevon.address,
                            ssl_avs_zip: this.elevon.zip
                        }, {
                            onError: function (error) {
                                console.log('error')     
                                self.snackbar({
                                    open: true,
                                    color: "error",
                                    text: error.errorMessage
                                });
                            },
                            onDeclined: function (response) {
                                console.log('decline')
                                self.snackbar({
                                    open: true,
                                    color: "error",
                                    text: response.errorMessage ? response.errorMessage : 'Card ' + response.ssl_token_response
                                });
                            },
                            onApproval: function (response) {
                                console.log("Approved")
                                self.createPayment(response.ssl_token)
                                console.log(response.ssl_token)
                            }
                        });
                    }
                }
            }else if(this.paymentType==='clover'){
                if(this.clover.first_name==='' || this.clover.last_name===''){
                    this.snackbar({open: true, text: 'First name and last name is required.', color: 'error'});
                    return
                }
                const self = this;
    
                let cardFields = {
                    CARD_NUMBER: document.getElementById('card-number-errors'),
                    CARD_DATE: document.getElementById('card-date-errors'),
                    CARD_CVV: document.getElementById('card-cvv-errors'),
                    CARD_POSTAL_CODE: document.getElementById('card-postal-code-errors'),
                };

                self.spinner(true);
                cloverEl.createToken().then(function(result) {  // eslint-disable-line
                    if (result.errors) {
                        Object.entries(result.errors).forEach((entry,  i) => {
                            let [key, value] = entry;
                            cardFields[key].innerHTML = value
                            // lets display first error
                            if(i == 0) {
                                self.snackbar({
                                    open: true,
                                    color: "error",
                                    text: value
                                });
                            }
                        });
                    } else {
                        if(result.token) {
                            self.createPayment(result.token, result)
                        } else {
                            self.snackbar({
                                open: true,
                                color: "error",
                                text: 'Sorry, the payment processor ran in to issue.' //'Sorry, the payment processor ran in to issue.',//respData.errorMessage,
                            });
                        }
                    }
                }).catch(function(data){
                    self.spinner(false);
                    console.log(data);
                });
            }
            return false
        },
        async getStripeToken(){
            try{
                this.spinner(true)
                let {data} = await StripeAPI.getToken(this.stripe)
                await this.createPayment(data.id)
            }catch(err){
                if(err.response && err.response.data && err.response.data.error && err.response.data.error.message){
                    this.snackbar({open: true, text: err.response.data.error.message, color: 'error'});
                }
                this.spinner(false)
            }
        },
        async getCloverToken(){
            try{
                this.spinner(true)
                let {data} = await CloverAPI.getToken(this.vg_location.id)
                if(data?.payload?.apiAccessKey){
                    this.spinner(false)
                    return data.payload.apiAccessKey
                }else{
                    this.spinner(false)
                    return ''
                }
            }catch(err){
                this.spinner(false)
                return err
            }
        },
        getMonerisData() {
            let APP_MONERIS_ID
            if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.payment_info){
                APP_MONERIS_ID = this.vg_location.settings.payment_processor_details.payment_info
            }
            var monFrameRef = document.getElementById('monerisFrame').contentWindow;
            monFrameRef.postMessage('tokenize', `${APP_MONERIS_URL}?id=${APP_MONERIS_ID}`);
        },
        async monerisRespMsg(e) {
            try{
                if (e.data && typeof e.data !== 'string') {
                    // only string is allowed
                    return;
                }

                var respData = eval("(" + e.data + ")");
                console.log(respData)
                
                this.spinner(true)
                if(respData.responseCode.includes('001')){
                    await this.createPayment(respData.dataKey)
                }else if(respData.responseCode.includes('943')){
                    this.spinner(false)
                    this.snackbar({open: true, text: 'Invalid card number', color: 'error'});
                }else if(respData.responseCode.includes('944')){
                    this.spinner(false)
                    this.snackbar({open: true, text: 'Invalid expiry date', color: 'error'});
                }else if(respData.responseCode.includes('945')){
                    this.spinner(false)
                    this.snackbar({open: true, text: 'Invalid CVD data', color: 'error'});
                }else if(respData.responseCode.includes('942')){
                    this.spinner(false)
                    this.snackbar({open: true, text: 'We ran in to issue adding your credit card. Please try again.', color: 'error'});
                }else{
                    this.spinner(false)
                }
            }catch(err){
                this.spinner(false)
            }
        },
        loadScript(){
            let elevonScript = document.createElement('script')
            elevonScript.setAttribute('src', 'https://api.demo.convergepay.com/hosted-payments/Checkout.js')
            document.head.appendChild(elevonScript)
        },
        async loadCloverScript(jsUrl){
            // remove existing if any to prevent double 
            var cloverLib = document.getElementById("clover_js");
            if (cloverLib) {
                cloverLib.parentNode.removeChild(cloverLib)
            }
            // get all js and check if we have already loaded this js
            var allScript = document.scripts;
            for (var i = 0, max = allScript.length; i < max; i++) {
                if (allScript[i].src == jsUrl)
                return;
            }

            await new Promise(function (resolve) {
                let sc = document.createElement('script');
                sc.setAttribute('id', 'clover_js');
                sc.src = jsUrl;
                document.head.appendChild(sc);
                sc.onload = function () {
                    console.log('clover loaded') 
                    // Trigger ends 
                    resolve();
                };
            });
        },
        async createCardElement(access_token){
            window.cloverEl = new Clover(access_token); // eslint-disable-line
            const elements = cloverEl.elements();   // eslint-disable-line
            const styles = {
                body: {
                    fontFamily: 'Roboto, Open Sans, sans-serif',
                    fontSize: '15px',
                },
                input: {
                    fontSize: '15px',
                    padding: '8px',
                    margin: '0px',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    height: '40px',
                    borderRadius: '4px',
                    background: 'none'
                },
                "input:focus": { border: "1px solid #ccc" },
                img: {
                    marginTop: '5px',
                    marginRight: '5px'
                }
            };
            const cardNumber = elements.create('CARD_NUMBER', styles);
            const cardDate = elements.create('CARD_DATE', styles);
            const cardCvv = elements.create('CARD_CVV', styles);
            const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);
            cardNumber.mount('#card-number');
            cardDate.mount('#card-date');
            cardCvv.mount('#card-cvv');
            cardPostalCode.mount('#card-postal-code');  

            // const cardResponse = document.getElementById('card-response');
            const displayCardNumberError = document.getElementById('card-number-errors');
            const displayCardDateError = document.getElementById('card-date-errors');
            const displayCardCvvError = document.getElementById('card-cvv-errors');
            const displayCardPostalCodeError = document.getElementById('card-postal-code-errors');
            
            // // Handle real-time validation errors from the card Element.
            cardNumber.addEventListener('change', function(event) {
                displayCardNumberError.innerHTML = event.CARD_NUMBER.error || '';
            });

            cardNumber.addEventListener('blur', function(event) {
                displayCardNumberError.innerHTML = event.CARD_NUMBER.error || '';
            });

            cardDate.addEventListener('change', function(event) {
                displayCardDateError.innerHTML = event.CARD_DATE.error || '';
            });

            cardDate.addEventListener('blur', function(event) {
                displayCardDateError.innerHTML = event.CARD_DATE.error || '';
            });

            cardCvv.addEventListener('change', function(event) {
                displayCardCvvError.innerHTML = event.CARD_CVV.error || '';
            });

            cardCvv.addEventListener('blur', function(event) {
                displayCardCvvError.innerHTML = event.CARD_CVV.error || '';
            });

            cardPostalCode.addEventListener('change', function(event) {
                displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || '';
            });

            cardPostalCode.addEventListener('blur', function(event) {
                displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || '';
            });
        },
        async createPayment(respToken, response=null){
            try{
                let formattedNumber = StringHelper.formatPhone(StringHelper.getMyEmailOrPhone('phone'))
                let data = {
                    "business_uuid": this.vg_businessId,
                    "location_id": this.vg_location.id,
                    "user_id": this.vg_loginUser.user.id,
                    "phone_number": formattedNumber,
                    "payment_token": respToken,
                    "card_brand": null, 
                    "last_four" : null,
                    "convert_token": true,
                }
                if(this.paymentType==='stripe'){
                    data.first_name = this.stripe.first_name
                    data.last_name = this.stripe.last_name
                    data.email = this.stripe.email
                    data.is_default_payment = this.stripe.is_default_payment
                }else if(this.paymentType==='elevon'){
                    data.first_name = this.elevon.first_name
                    data.last_name = this.elevon.last_name
                    data.email = this.elevon.email
                    data.is_default_payment = this.elevon.is_default_payment
                }else if(this.paymentType==='clover'){
                        data.first_name = this.clover.first_name
                        data.last_name = this.clover.last_name
                        data.email = this.clover.email
                        if(response.card){
                            data.last_four = response.card.last4
                            data.card_brand = response.card.brand
                            data.expiry_year = response.card.exp_year
                            data.expiry_month = response.card.exp_month
                        }
                }else{
                    data.first_name = this.form.first_name
                    data.last_name = this.form.last_name
                    data.email = this.form.email
                    data.is_default_payment = this.form.is_default_payment
                }
                await PaymentAPI.addPayment(data);
                this.$refs.form.reset()
                this.spinner(false)
                this.snackbar({open: true, text: 'Credit card added successfully', color: 'success'});
                this.$router.push({name: "customer-detail"})
            }catch(err){
                this.spinner(false)
                if(err.response && err.response.data && err.response.data.msg){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }
            }
        },
        async callServer(){
            try{ 
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        ...mapMutations({
            vm_notification: 'cartUser/SET_NOTIFICATION',
        }),
        ...mapActions({
            va_locationOnly: 'location/setLocationOnly'
        })
    }
}
</script>