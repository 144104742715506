import store from '../store'
import {CartAPI} from '@/clients/cart'
import {CartUser} from '@/helpers/cartUserHelper'

export const PromoHelper = {
    async getPromo(){
        let vg_loginUser = store.getters['login/user']
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']

        let promoAmt = 0
        let promoCodes = []
        
        if(vg_server_cart.cart_promocodes && vg_server_cart.cart_promocodes.length>0){
            // get valid promo code
            vg_server_cart.cart_promocodes.forEach(promo => {
                if(promo.applied_by_user=='user' && promo.apply_promocode_on=='user_items' && vg_selectedPaymentType=='payForMyOrder' && promo.applied_by_user_id==vg_loginUser.user.id){
                    // add valid promo value
                    promoAmt += promo.applied_value
                    // return valid promo codes
                    promoCodes.push(promo)
                }else if(promo.applied_by_user=='user' && promo.apply_promocode_on=='partial_items' && vg_selectedPaymentType=='splitEvenly' && promo.applied_by_user_id==vg_loginUser.user.id){
                    // add valid promo value
                    promoAmt += promo.applied_value
                    // return valid promo codes
                    promoCodes.push(promo)
                }else if(promo.applied_by_user=='user' && promo.apply_promocode_on=='cart' && (vg_selectedPaymentType=='AllByOne')){
                    // add valid promo value
                    promoAmt += promo.applied_value
                    // return valid promo codes
                    promoCodes.push(promo)
                }
            })
            if(!promoCodes[0]){
                promoCodes = []
            }
        }else{
            promoAmt = 0
            promoCodes = []
        }

        return {promoAmt: promoAmt, promoCodes: promoCodes}
    },
    async getAllPromoAmt(){
        let vg_server_cart = store.getters['cart/serverCart']

        let promoAmt = 0
        if(vg_server_cart.cart_promocodes){
            vg_server_cart.cart_promocodes.forEach(promo => {
                promoAmt += promo.applied_value
            })
        }
        return promoAmt
    },
    async getPromoAmtForCart(){
        let vg_server_cart = store.getters['cart/serverCart']

        let promoAmt = 0
        if(vg_server_cart.cart_promocodes){
            vg_server_cart.cart_promocodes.forEach(promo => {
                if(promo.apply_promocode_on=='cart'){
                    // add valid promo value
                    promoAmt += promo.applied_value
                }
            })
        }
        return promoAmt
    },
    async getPromoAmtForMyItem(){
        let vg_loginUser = store.getters['login/user']
        let vg_server_cart = store.getters['cart/serverCart']
        

        let promoAmt = 0
        vg_server_cart.cart_promocodes.forEach(promo => {
            if(promo.apply_promocode_on=='user_items' && promo.applied_by_user_id==vg_loginUser.user.id){
                // add valid promo value
                promoAmt += promo.applied_value
            }
        })
        return promoAmt
    },
    async addPromo(promoCode, subtotal){
        let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']
        if(vg_selectedPaymentType=='AllByOne'){
            return await this.addPromoForCart(promoCode)
        }else if(vg_selectedPaymentType=='splitEvenly'){
            let vg_cartUser = store.getters['cartUser/cartUsers']
            let totalUser = CartUser.countActiveUser(vg_cartUser)
            let mySubtotal = (Math.round((subtotal/totalUser)*100)/100).toFixed(2)
            return await this.addPromoForSplitEvenly(promoCode, mySubtotal)
        }else if(vg_selectedPaymentType=='payForMyOrder'){
            return await this.addPromoForMyItem(promoCode)
        }
    },
    async addPromoForCart(promoCode){
        let vg_cartId = store.getters['cart/cart_id']
        let vg_joinedCardId = store.getters['cart/joinedCardId']
        let vg_loginUser = store.getters['login/user']
        let vg_orderType = store.getters['cart/orderType']
        let vg_deliveryFee = store.getters['delivery/deliveryFee']

        let data = {
            cart_id: vg_cartId?vg_cartId:vg_joinedCardId,
            user_id: vg_loginUser.user.id,
            promocode: promoCode,
            apply_promocode_on: 'cart',
            applied_by_user: 'user',
            user_type: 'registered'
        }
        if(vg_orderType=='delivery'){
            data.delivery_fee = vg_deliveryFee
        }
        return await CartAPI.addPromo(data)
    },
    async addPromoForSplitEvenly(promoCode, subtotal){
        let vg_cartId = store.getters['cart/cart_id']
        let vg_joinedCardId = store.getters['cart/joinedCardId']
        let vg_loginUser = store.getters['login/user']
        let vg_cartUser = store.getters['cartUser/cartUsers']
        let totalUser = CartUser.countActiveUser(vg_cartUser)
        let sharePercent = (100/totalUser)
        let data = {
            cart_id: vg_cartId?vg_cartId:vg_joinedCardId,
            user_id: vg_loginUser.user.id,
            apply_promocode_on: 'partial_items',
            apply_promocode_partial: sharePercent,
            partial_amount: subtotal,
            promocode: promoCode,
            applied_by_user: 'user',
            user_type: 'registered'
        }
        return await CartAPI.addPromo(data)
    },
    async addPromoForMyItem(promoCode){
        let vg_cartId = store.getters['cart/cart_id']
        let vg_joinedCardId = store.getters['cart/joinedCardId']
        let vg_loginUser = store.getters['login/user']

        let data = {
            cart_id: vg_cartId?vg_cartId:vg_joinedCardId,
            user_id: vg_loginUser.user.id,
            apply_promocode_on: 'user_items',
            promocode: promoCode,
            applied_by_user: 'user',
            user_type: 'registered'
        }
        return await CartAPI.addPromo(data)
    },
    async removeCartSplitPromo(){
        // remove promo marked as cart
        // don't need to check paid - as whole cart won't be available on paid
        let vg_server_cart = store.getters['cart/serverCart']
        if(vg_server_cart.cart_promocodes && vg_server_cart.cart_promocodes.length>0){
            for(const ind in vg_server_cart.cart_promocodes){
                if(vg_server_cart.cart_promocodes[ind].applied_by_user=='user' && (vg_server_cart.cart_promocodes[ind].apply_promocode_on=='cart' || vg_server_cart.cart_promocodes[ind].apply_promocode_on=='partial_items')){
                    await CartAPI.removePromo(vg_server_cart.cart_promocodes[ind].id)
                }
            }
        }
    },
    async removeMyCartPromo(){
        // remove promo marked as cart & only remove if added by myself
        // don't need to check paid - as whole cart won't be available on paid
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_loginUser = store.getters['login/user']
        if(vg_server_cart.cart_promocodes && vg_server_cart.cart_promocodes.length>0){
            for(const ind in vg_server_cart.cart_promocodes){
                if(vg_server_cart.cart_promocodes[ind].apply_promocode_on=='cart' && vg_server_cart.cart_promocodes[ind].applied_by_user_id==vg_loginUser.user.id){
                    await CartAPI.removePromo(vg_server_cart.cart_promocodes[ind].id)
                }
            }
        }
    },
    async removeAllMyUnpaidPromo(){
        // remove promo marked as user_items & remove only if add by myself
        // don't remove promo mark as used
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_loginUser = store.getters['login/user']
        if(vg_server_cart.cart_promocodes && vg_server_cart.cart_promocodes.length>0){
            for(const ind in vg_server_cart.cart_promocodes){
                if((vg_server_cart.cart_promocodes[ind].apply_promocode_on=='user_items' || vg_server_cart.cart_promocodes[ind].apply_promocode_on=='partial_items') && vg_server_cart.cart_promocodes[ind].status=='unused' && vg_server_cart.cart_promocodes[ind].applied_by_user_id==vg_loginUser.user.id){
                    await CartAPI.removePromo(vg_server_cart.cart_promocodes[ind].id)
                }
            }
        }
    },
    async removeAllUnpaidPromo(){
        // don't remove promo mark as used
        let vg_server_cart = store.getters['cart/serverCart']
        if(vg_server_cart.cart_promocodes && vg_server_cart.cart_promocodes.length>0){
            for(const ind in vg_server_cart.cart_promocodes){
                if((vg_server_cart.cart_promocodes[ind].applied_by_user=='user' &&  (vg_server_cart.cart_promocodes[ind].apply_promocode_on=='user_items' || vg_server_cart.cart_promocodes[ind].apply_promocode_on=='partial_items'))){
                    await CartAPI.removePromo(vg_server_cart.cart_promocodes[ind].id)
                }
            }
        }
    },
    async removeAllPromo(){
        let vg_server_cart = store.getters['cart/serverCart']
        if(vg_server_cart.cart_promocodes && vg_server_cart.cart_promocodes.length>0){
            for(const ind in vg_server_cart.cart_promocodes){
                if(vg_server_cart.cart_promocodes[ind].applied_by_user=='user'){
                    await CartAPI.removePromo(vg_server_cart.cart_promocodes[ind].id)
                }
            }
        }
    },
    async removePromo(){
        let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']
        if(vg_selectedPaymentType=='AllByOne'){
            // remove promo added by another one
            await this.removeCartSplitPromo()
        }else if(vg_selectedPaymentType=='payForMyOrder' || vg_selectedPaymentType=='splitEvenly'){
            await this.removeAllMyUnpaidPromo()
        }
    }
}