<template>
    <div>
        <div :class="itemPaidType" style="padding-top: 5px; padding-bottom:5px;">
            <v-list class="py-0">                   
                <v-list-item style="min-height: 25px !important;" class="px-0 my-0" >
                    <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                        <div>{{cartItem.item_qty}}x</div>
                    </v-list-item-icon>
                    <v-list-item-content class="my-0 py-0">
                            <v-list-item-title>{{cartItem.name}}</v-list-item-title>
                            <v-list-item-content class="pt-0 mt-0">
                                <!-- Modifier items -->
                                <template v-for="(item_modifier, modifierIndex) in cartItem.cart_item_modifiers">
                                    <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'mod-'+modifierIndex">{{item_modifier.name}}</div>
                                    <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                        <div v-if="modifier_item.qty>0" class="text--secondary" :key="'modItem-'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} ({{modifier_item.qty}}x)</div>
                                    </template>
                                </template>
                                <!-- Note -->
                                <template v-if="cartItem.note">
                                    <div class="text--secondary">Item note:</div>
                                    <div class="text--secondary">{{cartItem.note}}</div>
                                </template>

                            </v-list-item-content>
                        </v-list-item-content>
                    <v-list-item-action class="my-0 py-0">
                        <v-list-item-action-text class="d-inline-flex">
                            <span class="pr-3 body-2" style="color:black;">${{calculatePrice(cartItem)}}</span>
                            <v-checkbox v-if="vg_selectedPaymentType==='selectItems'" v-model="cart_items" :value="cartItem.id" hide-details 
                                :disabled="(parseFloat(cartItem.actual_price)*parseFloat(cartItem.item_qty)).toFixed(2)===parseFloat(cartItem.amount_paid).toFixed(2)" 
                                :indeterminate="!!partialAmount && (parseFloat(price)).toFixed(2)!==parseFloat(partialAmount).toFixed(2)" />
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="isItemChecked(cartItem.id)" class="px-0 mt-0" style="min-height:24px !important">
                    <v-list-item-content class="my-0 py-0">
                        <v-list-item-title class="text-decoration-underline" @click.prevent="editPercentage">Edit Percentage</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mr-6 my-0 py-0">
                        <v-list-item-action-text class="d-inline-flex">
                            <span class="pr-3 body-2" style="color:black;">${{parseFloat(partialAmount).toFixed(2)}}</span>
                        </v-list-item-action-text>
                    </v-list-item-action>
                </v-list-item>
                <template v-if="isItemChecked(cartItem.id) && cartItem.cart_item_payments">
                    <v-list-item  v-for="(item, ind) in cartItem.cart_item_payments" :key="ind" class="px-0 mt-0"  style="min-height:24px !important">
                        <v-list-item-content class="my-0 py-0" >
                            <v-list-item-title class="grey--text">{{getUserNameOrPhone(item.user_id)}}</v-list-item-title>
                        </v-list-item-content >
                        <v-list-item-action class="mr-6 my-0 py-0">
                            <v-list-item-action-text class="d-inline-flex">
                                <span class="pr-3 body-2 grey--text" style="color:black;">${{parseFloat(item.amount).toFixed(2)}}</span>
                            </v-list-item-action-text>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-list>
            <edit-percentage v-if="editPercentagePop" :price="price" :partialAmount="partialAmount" @updatePercentage="updatePercentage" @closeEditPercentage="closeEditPercentagePop" />
        </div>
        <div v-if="itemPaidType==='noSelected'" class="red--text mb-2">This item is not selected by any guest</div>
        <div v-if="itemPaidType==='noFullyPaid'" class="red--text mb-2">Please make sure the percentage add up to 100%</div>
    </div>
</template>

<script>
import { mapGetters, mapMutations} from 'vuex'
import {PartialPaymentHelper} from '@/helpers/PartialPaymentHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import EditPercentage from './edit-percentage'
export default {
    components: {
        EditPercentage
    },
    props: ['cartItem'],
    data() {
        return {
            partialAmount: 0,
            price: 0,
            editPercentagePop: false,
            itemPaidType: ''
        }
    },
    mounted(){
        this.calculatePrice(this.cartItem)
        this.getPrice(this.cartItem.id)
        this.itemSelectedType()
    },
    watch: {
        'vg_serverCart.paid'(){
            this.itemSelectedType()
        }
    },
    computed: {
        cart_items: {
            get(){
                return PartialPaymentHelper.getCartItemsId(this.vg_selectedCartItems)
            },
            set(){
                PartialPaymentHelper.setCartItems({cart_item_id: this.cartItem.id, amount_paid: this.price})
                this.getPrice(this.cartItem.id)
            }
        },
        ...mapGetters({
            vg_selectedCartItems: 'cart/selectedCartItems',
            vg_selectedPaymentType:'payment/selectedPaymentType',
            vg_serverCart: 'cart/serverCart'
            
        })
    },
    methods: {
        itemSelectedType(){
            if(this.cartItem.amount_paid==0){
                this.itemPaidType = 'noSelected'
            }else if(parseFloat(this.cartItem.amount_paid)<(parseFloat(this.cartItem.actual_price)*parseFloat(this.cartItem.item_qty))){
                this.itemPaidType = 'noFullyPaid'
            }
        },
        calculatePrice(item){
            this.price = ServerCart.calculatePrice(item)
            return this.price
        },
        updatePercentage(itemValue){
            PartialPaymentHelper.setCartItems({cart_item_id: this.cartItem.id, amount_paid: itemValue}, 'update')
            this.getPrice(this.cartItem.id)
            this.editPercentagePop = false
        },
        editPercentage(){
            this.editPercentagePop  = true
        },
        closeEditPercentagePop(){
            this.editPercentagePop = false
        },
        isItemChecked(itemId){
            return PartialPaymentHelper.isCartItemExists(itemId)
        },
        getPrice(itemId){
            this.partialAmount = PartialPaymentHelper.getPrice(itemId)
            return this.partialAmount
        },
        getUserNameOrPhone(userId){
            return PartialPaymentHelper.getUserNameOrPhone(userId)
        },
        ...mapMutations({
            vm_selectedCartItems: 'cart/SET_SELECTED_CART_ITEMS',
        })
    }
}
</script>

<style>
    .noSelected, 
    .noFullyPaid{
        border: solid thin red;
        border-radius: 5px;
        margin-bottom: 5px;
        /* margin-bottom: 5px; */
    }
</style>