<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-form ref="form" v-model="valid" @submit.prevent="doLogin">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <span>Sign in</span>
                        <v-spacer />
                        <v-btn fab dark x-small @click.prevent="closeLogin">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-img v-if="vg_business.branding && vg_business.branding.logo_url" :src="vg_business.branding.logo_url" contain max-height="50" />
                        <div class="text-center body-1 pt-5">Welcome back, we missed you!</div>
                    </v-card-text> 
                    <v-card-text class="pb-0">
                        <v-text-field
                            v-model="form.phone_number_email"
                            :rules="rules.phoneEmailRules"
                            label="Phone Number or Email"
                            required
                            outlined
                            ></v-text-field>
                    </v-card-text>
                    <v-card-text>
                        <v-btn
                            type="submit"
                            color="primary"
                            block
                            :disabled="!valid"
                            >
                            Sign in
                        </v-btn>

                        <div class="mt-5 text-center">Don't have an account? <a href="#" @click.prevent="signUp">CLICK HERE</a></div>
                    </v-card-text>
                    <v-card-text>
                        <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                        <span class="float-left pr-1">POWERED BY :</span>
                        <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                        </div>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return{
            form: {
                phone_number_email: '',
            },
            rules: {
                phoneEmailRules: [ 
                    v => !!v || 'Please enter your phone number or email',
                    v => (!isNaN(v) || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Email must be valid',
                    v => (isNaN(v) || !!v && v.length==10) || 'Phone number must be of 10 digits'
                ]

            },
            valid: false
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_phoneEmailPop
            },
            set(val){
                this.vm_phoneEmailPop(val)
            }
        },
        ...mapGetters({
            vg_business: 'location/business',
            vg_verifyData: 'login/verifyData',
            vg_phoneEmailPop: 'login/phoneEmailPop'
        })
    },
    methods: {
        async doLogin(){
            if(this.$refs.form.validate()){
                try{
                    this.spinner(true)

                    let data = {...this.form}

                    await this.va_setLogin(data)
                    this.spinner(false)

                    this.vm_loginWith(this.form.phone_number_email)
                    this.closeLogin()
                    if(this.vg_verifyData.msg=='unverified email address. please verify your email address and try again'){
                        this.vm_verifySignupStatus(true)
                    }else{
                        this.vm_verifyStatus(true)
                    }
                }catch(err){
                    this.spinner(false)
                    if(err.response.data && err.response.data.msg){
                        this.snackbar({open: true, text: "Either email or phone no is invalid", color: 'error'})
                    }
                }
            }
        },
        closeLogin(){
            this.$refs.form.reset()
            this.vm_phoneEmailPop(false)
        },
        signUp(){
            this.closeLogin()
            this.vm_signupStatus(true)
        },
        ...mapMutations({
            vm_phoneEmailPop: 'login/SET_PHONE_EMAIL_POP',
            vm_verifyStatus: 'login/SET_VERIFY',
            vm_signupStatus: 'signup/SET_STATUS',
            vm_verifySignupStatus: 'signup/SET_VERIFY',
            vm_loginWith: 'login/SET_LOGIN_WITH'
        }),
        ...mapActions({
            va_setLogin: 'login/setLogin'
        })
    }
}
</script>