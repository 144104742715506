import store from '@/store'
import _ from 'lodash'
import {StringHelper} from './stringHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {CartUser} from '@/helpers/cartUserHelper'

export const PartialPaymentHelper = {
    filterServerCartBySelectedItem(serverCart, selectedItem){
        if(serverCart.cart_items && serverCart.cart_items.length>0){
            let selectedItemIds = selectedItem.map(item => item.cart_item_id)
            let serverCarts = serverCart.cart_items.filter(item => {return selectedItemIds.includes(item.id)})
            return _.groupBy(serverCarts, '')
        }
        return null
    },
    filterServerCartForMyOrderOnly(serverCart, userId){
        if(serverCart.cart_items && serverCart.cart_items.length>0){
            return {[userId]: serverCart.cart_items.filter(item => {return item.user_id===userId})}
        }
        return null
    },
    getCartItemForPartialPayment(userId){
        let vg_serverCart = store.getters['cart/serverCart']
        let params = []
        if(vg_serverCart.cart_items){
            vg_serverCart.cart_items.forEach(cart_item => {
                if(cart_item.user_id===userId && cart_item.amount_paid==0 && cart_item.actual_price!==0){
                    params.push({
                        cart_item_id: cart_item.id,
                        amount_paid: (parseFloat(cart_item.actual_price)*parseFloat(cart_item.item_qty)).toFixed(2)
                    })
                }
            })
        }
        return params
    },
    getCartItemForSplitPayment(){
        let vg_serverCart = store.getters['cart/serverCart']
        let splitTo = CartUser.countActiveUser(vg_serverCart.cart_users)
        let params = []
        if(vg_serverCart.cart_items){
            vg_serverCart.cart_items.forEach(cart_item => {
                if(cart_item.actual_price!==0){
                    let remain = ((parseFloat(cart_item.actual_price)*parseFloat(cart_item.item_qty))-parseFloat(cart_item.amount_paid)).toFixed(2)
                    let split = ((parseFloat(cart_item.actual_price)*parseFloat(cart_item.item_qty))/splitTo).toFixed(2)
                    let isNearlyEqual = (Math.abs(remain-split)<0.5)
                    params.push({
                        cart_item_id: cart_item.id,
                        amount_paid: isNearlyEqual?remain:split
                    })
                }
            })
        }
        return params
    },
    getRemainingCartItemForPartialPayment(){
        let vg_serverCart = store.getters['cart/serverCart']
        let params = []
        if(vg_serverCart.cart_items){
            vg_serverCart.cart_items.forEach(cart_item => {
                if(cart_item.amount_paid===0  && cart_item.actual_price!==0){
                    params.push({
                        cart_item_id: cart_item.id,
                        amount_paid: (parseFloat(cart_item.actual_price)*parseFloat(cart_item.item_qty)).toFixed(2)
                    })
                }
            })
        }
        return params
    },
    // for checkbox in select item to pay page
    getCartItemsId(cartItems){
        if(!cartItems || cartItems.length==0) return []
        return cartItems.map(item => item.cart_item_id)
    },
    // set cart item if not set; update on price change if type='update' else remove item
    setCartItems(cartItem, type){
        let vg_selectedCartItems = store.getters['cart/selectedCartItems']
        let itemIndex = vg_selectedCartItems.findIndex(serverCartItem => serverCartItem.cart_item_id===cartItem.cart_item_id)
        if(itemIndex>=0){
            let vg_selectedCartItems_duplicate = [...vg_selectedCartItems]
            if(type!=='update'){
                vg_selectedCartItems_duplicate.splice(itemIndex, 1)
                store.commit('cart/SET_SELECTED_CART_ITEMS', vg_selectedCartItems_duplicate)
            }else{
                vg_selectedCartItems_duplicate[itemIndex].amount_paid = cartItem.amount_paid
                store.commit('cart/SET_SELECTED_CART_ITEMS', vg_selectedCartItems_duplicate)
            }
        }else{
            let paidAmount = this.sumOfUserPaidAmountInItem(cartItem.cart_item_id)
            let remainAmount = parseFloat(cartItem.amount_paid) - parseFloat(paidAmount)
            store.commit('cart/SET_SELECTED_CART_ITEMS', [...vg_selectedCartItems, {
                cart_item_id: cartItem.cart_item_id,
                amount_paid: remainAmount,
            }])
        }
    },
    // get corresponding amount of selected server cart in selectedCartItem
    getCartItemAmount(selectedCartItems, serverCartItem){
        if(selectedCartItems && selectedCartItems.length==0) return

        // show price of selected item price
        let filteredItem = selectedCartItems.filter(cartItem => {
            return cartItem.cart_item_id == serverCartItem.id
        })
        return parseFloat(filteredItem[0].amount_paid).toFixed(2)
    },
    // get corresponding percent of selected server cart in selectedCartItem 
    getCartItemInDecimal(selectedCartItems, serverCartItem){
        if(selectedCartItems && selectedCartItems.length==0) return

        // show price of selected item price
        let filteredItem = selectedCartItems.filter(cartItem => {
            return cartItem.cart_item_id == serverCartItem.id
        })
        return parseFloat(filteredItem[0].amount_paid/(serverCartItem.actual_price*serverCartItem.item_qty)).toFixed(2)
    },
    isCartItemExists(cartId){
        let vg_selectedCartItems = store.getters['cart/selectedCartItems']
        let itemIndex = vg_selectedCartItems.findIndex(serverCartItem => serverCartItem.cart_item_id===cartId)
        return itemIndex>=0
    },
    getPrice(cartId){
        let vg_selectedCartItems = store.getters['cart/selectedCartItems']
        let itemIndex = vg_selectedCartItems.findIndex(serverCartItem => serverCartItem.cart_item_id===cartId)
        if(vg_selectedCartItems[itemIndex] && vg_selectedCartItems[itemIndex].amount_paid)
            return vg_selectedCartItems[itemIndex].amount_paid
    },
    calculateSelectedItemSubTotal(){
        let vg_selectedCartItems = store.getters['cart/selectedCartItems']
        let total = 0
        vg_selectedCartItems.forEach(item => {
            total += parseFloat(item.amount_paid)
        })
        return parseFloat(total).toFixed(2)
    },
    calculatePayMyOrderSubtotal(){
        let vg_serverCart = store.getters['cart/serverCart']
        let total = 0
        if(vg_serverCart.cart_items){
            vg_serverCart.cart_items.forEach(item => {
                let itemPrice = Number(ServerCart.calculatePrice(item))
                if(itemPrice!=item.amount_paid){
                    total = Number(total) + itemPrice
                }
            })
            return (Math.round(total*100)/100).toFixed(2)
        }
    },
    // calculate tax
    calculateTax(){
        let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']
        if(store.getters["location/location"].settings && store.getters["location/location"].settings.tax_settings && store.getters["location/location"].settings.tax_settings.tax){
            if(vg_selectedPaymentType=='selectItems'){
                return (Math.round((this.calculateSelectedItemSubTotal()*store.getters["location/location"].settings.tax_settings.tax/100)*100)/100).toFixed(2)
            }else if(vg_selectedPaymentType=='payForMyOrder'){
                return (Math.round((this.calculatePayMyOrderSubtotal()*store.getters["location/location"].settings.tax_settings.tax/100)*100)/100).toFixed(2)
            }
        }
        return
    },
    sumOfUserPaidAmountInItem(itemId){
        let vg_serverCart = store.getters['cart/serverCart']
        let sum = 0
        if(vg_serverCart.cart_items){
            let findItem = vg_serverCart.cart_items.findIndex(cartItem => cartItem.id === itemId)
            if(findItem>=0){
                sum = vg_serverCart.cart_items[findItem].amount_paid
            }
        }
        return sum
    },
    getUserNameOrPhone(userId){
        let vg_serverCart = store.getters['cart/serverCart']
        let usersObject = vg_serverCart.cart_users

        let findUserObject = usersObject.findIndex(userObj => userObj.user_id==userId)
        if(findUserObject>=0){
            let username = '';
            if(usersObject[findUserObject].name!==null){
                username = usersObject[findUserObject].name
            }else{
                username = StringHelper.censorPhone(usersObject[findUserObject].phone)
            }
            return username
        }
    },
    hasSomeOnePaidCart(){
        let vg_serverCart = store.getters['cart/serverCart']
        if(vg_serverCart.id){
            return vg_serverCart.cart_payments.length>0
        }else{
            return false
        }
    }
}