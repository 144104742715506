<template>
    <v-list class="pa-0" >
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                    <span>{{modifier.name}}</span>
                    <span class="float-right"><v-icon @click="toggle">{{status?'mdi-chevron-down':'mdi-chevron-up'}}</v-icon></span>
                </v-list-item-title>
                <div :class="isValid(isRadioCheckbox(modifier.min, modifier.max))?'caption grey--text':'caption error--text'">{{modifierSelectedErrorMsg(modifier.min, modifier.max, isValid(isRadioCheckbox(modifier.min, modifier.max)))}} </div>
                <v-list-item-subtitle v-if="status">
                    <v-list class="pa-0" v-if="isRadioCheckbox(modifier.min, modifier.max)==='radio'">
                        <v-radio-group class="mt-0" v-model="singleSelect">
                            <div v-for="(modifierItem, index) in modifier.modifier_items" :key="index">
                                <v-list-item class="pa-0" style="min-height:32px !important" v-if="modifierItem.status" >
                                    <v-list-item-content class="pa-0">
                                        <v-radio  hide-details :value="modifierItem.id" :key="index" :label="modifierItem.name" style="margin:0; padding:0; font-size:14px;"></v-radio>
                                    </v-list-item-content>
                                    <v-list-item-action  class="pa-0">
                                        <v-list-item-action-text v-if="status" style="font-size:14px;">+${{parseFloat(modifierItem.price).toFixed(2)}}</v-list-item-action-text>
                                    </v-list-item-action>
                                </v-list-item>
                            </div>
                        </v-radio-group>
                    </v-list>
                    <v-list class="pa-0" v-else>
                        <template v-for="(modifierItem, index) in modifier.modifier_items">
                            <v-list-item class="pa-0" v-if="modifierItem.status" :key="index">
                                <v-list-item-content v-if="isCheckboxOrMultiselect(modifierItem.max)==='checkbox'">
                                    <v-checkbox v-model="selected" hide-details :value="modifierItem.id" :key="index" :label="modifierItem.name" style="margin:0; padding:0; font-size:14px;" />
                                </v-list-item-content>
                                <v-list-item-content v-else>
                                    <qty-option :modifier="modifier" :modifierItem="modifierItem" :preselectItem="vg_item" :modifierIncluded="modifierIncluded" :totalModifierIncluded="totalModifierIncluded" @onChange="qtyOptionChange" />
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-list-item-action-text v-if="status" style="font-size:14px;">+${{parseFloat(modifierItem.price).toFixed(2)}}</v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>
<script>
import {Cart} from '@/helpers/cartHelper'
import {ArrayHelper} from '@/helpers/arrayHelper'
import {mapGetters, mapMutations} from 'vuex'
import QtyOption from './qty-option'
export default {
    props: ['modifier'],
     components: {
        QtyOption
    },
    data(){
        return {
            status: true,
            selected: [],           // option with checkbox only

            singleSelect: '',       // option with radio only

            mixedSelect: [],        // option with checkbox and qty select box
            isMixedSelect: false,   
            totalQty: 0,

            modifierIncluded: 0,
            totalModifierIncluded: 0
        }
    },
    mounted(){
        this.selected = Cart.getPreSelectedModifierItemIds(this.modifier, this.vg_item)
        this.singleSelect = this.selected[0]?this.selected[0]:''
        this.mixedSelect = Cart.getPreSelectedModifierItems(this.modifier, this.vg_item)
        this.modifierIncluded = this.modifier.included
        this.sumTotalQty()
        this.sumTotalModifierIncluded()
        
    },
    watch: {
        singleSelect(){
            let item = Cart.changeModifierItem([this.singleSelect], this.modifier, this.vg_item)
            this.vm_setItem(item)
        },
        selected(newArr, oldArr){
            let checkboxResult = ArrayHelper.checkCheckboxGroup(newArr, oldArr)
            if(checkboxResult && checkboxResult.id){
                let tempIncluded = 0
                if((this.modifierIncluded-this.totalModifierIncluded)>0) tempIncluded = 1
                let params = {id: checkboxResult.id, qty: checkboxResult.remark==='added'?1:0, included:tempIncluded}
                let currentModifierItem = this.getModifierItemById(this.mixedSelect, checkboxResult.id)
                let haveIncluded = (currentModifierItem && currentModifierItem.included>0)?true:false
                Cart.itemOptionChanged(this.mixedSelect, params, checkboxResult.remark==='added'?'added':'subtract', haveIncluded)    // trigger mixedSelect watch
                this.sumTotalModifierIncluded()
            }
        },
        mixedSelect: {
            handler: function() {
                let item = Cart.changeModifierItemHavingQty(this.mixedSelect, this.modifier, this.vg_item)
                this.vm_setItem(item)
                this.sumTotalQty()
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            vg_cart: 'cart/cart',
            vg_item: 'cart/item',
            vg_currentCategory: 'menu/currentCategory'
        })
    },
    methods: {
        toggle(){
            this.status = !this.status
        },
        qtyOptionChange(item, status, haveIncluded){
            Cart.itemOptionChanged(this.mixedSelect, item, status, haveIncluded)
            this.sumTotalModifierIncluded()
        },
        getModifierItemById(items, id){
            if(items.length==0 || !id) return

            let findIndex = items.findIndex(eachItem => eachItem.id===id)
            if(findIndex>=0){
                return items[findIndex]
            }
        },
        sumTotalQty(){
            let total = 0
            if(this.mixedSelect){
                this.mixedSelect.forEach(item => {
                    total += item.qty
                })
            }
            this.totalQty = total
        },
        sumTotalModifierIncluded(){
            let total = 0
            if(this.mixedSelect){
                this.mixedSelect.forEach(item => {
                    total += item.included
                })
            }
            this.totalModifierIncluded = total
        },
        isValid(type){
            if(type==='radio'){
                // invalid for empty singleSelect
                return this.singleSelect!==''
            }else{
                // for qty select box
                if(this.modifier.min===0 && this.modifier.max===0){
                    return true
                }else if(this.modifier.min===0){
                    return this.totalQty<=this.modifier.max
                }else if(this.modifier.max===0){
                    return this.totalQty>=this.modifier.min
                }else{
                    return this.totalQty>=this.modifier.min && this.totalQty<=this.modifier.max
                }
            }
        },
        // select radio or checkbox based on modifiers min max
        // radio if min max ===1
        isRadioCheckbox(min, max){
            if(min===max && max===1){
                return 'radio'
            }else{
                return 'checkbox'
            }
        },
        // select checkbox or multiple qty base on modifier_items min max
        // checkbox if max<=1 elese multiple select box
        isCheckboxOrMultiselect( max){
            if(max<=1){
                return 'checkbox'
            }else{
                this.isMixedSelect = true
                return 'multiple'
            }
        },
        modifierSelectedErrorMsg(min, max, valid){
            if(min===0 && max===0){
                return `Optional`
            }else if(min===1 && max===1){
                return !valid?'Required':`Choose ${max}`
            }else if(min===max){
                return `Choose ${max}`
            }else if(min===0){
                return `Choose up to ${max}`
            }else if(max===0){
                return `Choose at least ${min}`
            }else{
                return `Choose ${min} - ${max}`
            }
        },
        ...mapMutations({
            vm_cart: 'cart/SET_CART',
            vm_setItem: 'cart/SET_ITEM'
        })
    }
}
</script>