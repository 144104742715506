<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-btn style="position:absolute; right:30px; margin-top: 5px; z-index:9999;" fab dark x-small @click.prevent="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span style="word-break:normal;">{{vg_loyaltyProgram}}</span>
                    <v-spacer />
                    <!-- <v-btn fab dark x-small @click.prevent="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn> -->
                </v-card-title>
                <v-card-text>
                    <v-card elevation="0">
                        <v-card-title class="py-0">Step 1</v-card-title>
                        <v-card-text>Earn loyalty points every time you order something, pay for your bill, or invite your friends!</v-card-text>
                    </v-card>
                    <v-card elevation="0">
                        <v-card-title class="py-0">
                            <v-spacer />
                            <v-icon>mdi-chevron-down</v-icon>
                            <v-spacer />
                        </v-card-title>
                    </v-card>
                    <v-card elevation="0">
                        <v-card-title class="py-0">Step 2</v-card-title>
                        <v-card-text>As you are eating out and earning points, you are able to use them to cover a part or your entire bill!</v-card-text>
                    </v-card>

                    <!-- terms and condtions -->
                    <v-card-title class="body-2 black--text" style="border-bottom: solid thin #eee">
                        <v-spacer />
                            <strong>FAQ</strong>
                        <v-spacer />
                    </v-card-title>

                    <!-- accordion -->
                    <v-expansion-panels flat>
                        <v-expansion-panel
                            style="border-bottom: solid thin #eee"
                            v-for="(faq,i) in faqs"
                            :key="i"
                        >
                            <v-expansion-panel-header class="expansion-header px-3" disable-icon-rotate hide-actions>
                                <template v-slot="status">
                                    <div class="pr-1" style="flex:none; min-height:15px;  max-height: 80px; width:25px;">{{i+1}}.</div>
                                    <div class="d-flex align-start  text-left" style="min-height:15px; max-height: 80px;">{{faq.question}}</div>
                                    <div class="d-flex justify-end">
                                        <v-icon>{{status.open?'mdi-minus':'mdi-plus'}}</v-icon>
                                    </div>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <ul v-if="faq.answers">
                                    <li v-for="(answer, ind) in faq.answers" :key="ind">{{answer}}</li>
                                </ul>
                                <div class="ml-4" v-if="faq.answer">{{faq.answer}}</div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <!-- terms and condtions -->
                    <v-card-title class="body-2  black--text">
                        <v-spacer />
                            <a style="word-break:normal;" @click.prevent="terms"><u>{{vg_loyaltyProgram}} Terms &amp; Conditions</u></a>
                        <v-spacer />
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn width="200" class="primary" @click.prevent="close()">GOT IT!</v-btn>
                        <v-spacer />
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
import {LoyaltyAPI} from '@/clients/loyalty'
export default {
    data() {
        return {
            faqs: []
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_faqStatus
            },
            set(val){
                this.vm_faqStatus(val)
            }
        },
        ...mapGetters({
            vg_faqStatus: 'faq/status',
            vg_loyaltyProgram: 'loyalty/programName'
        })
    },
    watch: {
        async vg_faqStatus(val){
            if(val){
                this.faqs = []
                await this.getDefaultFaq()
                await this.getFaq()
            }
        }
    },
    methods: {
        close(){
            this.vm_faqStatus(false)
        },
        async getDefaultFaq(){
            try{
               let {data} = await LoyaltyAPI.defaultFaq()
               this.faqs.push(...data.earn)
                this.faqs.push(...data.redeem)
            }catch(err){
                console.log(err)
            }
        },
        async getFaq(){
            try{
               let {data} = await LoyaltyAPI.faq()
               if(data.payload && data.payload[0] && data.payload[0].faqs){
                   this.faqs.push(...data.payload[0].faqs)
               }
            }catch(err){
                console.log(err)
            }
        },
        terms(){
            this.vm_faqTermsPop(true)
        },
        ...mapMutations({
            vm_faqStatus: 'faq/SET_STATUS',
            vm_faqTermsPop: 'faq/SET_TERMS_POP'
        })
    }
}
</script>
<style scoped>
.expansion-header{
    padding: 0px 24px;
}
</style>
