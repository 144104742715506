<template>
    <v-dialog v-model="dialog" fullscreen persistent>
        <v-app-bar fixed elevation="1">
            <v-spacer></v-spacer>
            <v-toolbar-title>welcome!</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-card>
            <div style="top: 1px !important; padding-top: 120px !important;">
                <div class="title text-center">Welcome to {{vg_location.name}}</div>
                <div class="text-center" style="padding-top: 140px !important">
                    <v-btn width="120" outlined large color="grey" @click.prevent="dineIn()">
                        <v-img src="@/assets/img/fork-knife.png" height="20" width="20" />
                        <span class="primary--text ml-2">DINE IN</span>
                    </v-btn>
                </div>
                <div class="text-center" style="padding-top: 40px !important">
                    <v-btn  width="120" outlined large color="grey" @click.prevent="takeOut()">
                        <v-img src="@/assets/img/bag.png" height="20" width="20" />
                        <span class="primary--text ml-2">TAKE OUT</span>
                    </v-btn>
                </div>
                <div class="text-center" style="padding-top: 40px !important">
                    <v-btn  width="120" outlined large color="grey" @click.prevent="delivery()">
                        <v-img src="@/assets/img/bag.png" height="20" width="20" />
                        <span class="primary--text ml-2">DELIVERY</span>
                    </v-btn>
                </div>
            </div>
        </v-card>
        <v-footer fixed color="white">
            <v-col>
                <div class="text-center" v-if="vg_location.address">{{vg_location.address.formatted_address}}</div>
            </v-col>
        </v-footer>
    </v-dialog>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
export default {
    data()  {
        return {
            dialog: null
        }
    },
    mounted(){
        this.dialog = true
    },
    computed: {
        ...mapGetters({
            vg_location: 'location/location',
            vg_cartId: 'cart/cart_id',
            vg_joinedCartId: 'cart/joinedCardId'
        })
    },
    methods: {
        dineIn(){
            this.vm_setOrderType('dinein')
            // confirmation pop for dinein and delivery = false
            this.vm_confirmDeliveryPickup(false)
            this.vm_orderDeliveryPickup(false)
            if(this.vg_cartId || this.vg_joinedCartId){
                this.$emit('changeCart', 'dinein')
            }
            this.va_setMenu(this.vg_location)
        },
        takeOut(){
            this.vm_setOrderType('pickup')
            // confirmation pop for dinein and delivery = false
            this.vm_confirmDeliveryPickup(false)
            this.vm_orderStatus('Confirming')
            this.vm_orderDeliveryPickup(false)
            if(this.vg_cartId || this.vg_joinedCartId){
                this.$emit('changeCart', 'takeout')
            }
            this.va_setMenu(this.vg_location)
        },
        delivery(){
            this.vm_setOrderType('delivery')
            // confirmation pop for dinein and delivery = false
            this.vm_confirmDeliveryPickup(false)
            this.vm_orderStatus('Confirming')
            this.vm_orderDeliveryPickup(false)
            if(this.vg_cartId || this.vg_joinedCartId){
                this.$emit('changeCart', 'takeout')
            }
            this.va_setMenu(this.vg_location.id)
        },
        ...mapMutations({
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
            vm_orderStatus: 'order/SET_ORDER_STATUS',
            vm_orderDeliveryPickup: 'order/SET_ORDER_PICKUP_DELIVERY'
        }),
        ...mapActions({
            va_setMenu: 'menu/setMenu',
        })
    }
}
</script>