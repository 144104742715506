<template>
    <div style="position:fixed; top: 0px; left:0; right:0; z-index:9999">
        <template v-for="(snackbar, ind) in vg_snackbar">
            <snackbar style="position:initial; height:inherit;" v-if="snackbar.open && (ind>(vg_snackbar.length-3))" :ind="ind" :key="ind" />
        </template>
    </div>
</template>

<script>
import Snackbar from '@/components/custom-components/snackbar/snackbar'
import { mapGetters,mapMutations } from 'vuex'
export default {
  components: {
      Snackbar
  },
  mounted(){
      let getterSnackbarObj = [...this.vg_snackbar];
      let activeSnackbar = getterSnackbarObj.filter(each => each.open==true)
      this.vm_replaceSnackbar(activeSnackbar)
  },
  computed: {
      ...mapGetters({
          vg_snackbar: 'Snackbar/snackbar'
      })
  },
  methods: {
    ...mapMutations({
          vm_replaceSnackbar: 'Snackbar/replace'
      })
  }
}
</script>
<style>
.v-snack .v-snack__wrapper{
    width:100%;
}
</style>
