<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-form ref="form" @submit.prevent="setPaymentType">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <span>Split Bill</span>
                        <v-spacer />
                        <v-btn fab dark x-small @click.prevent="closeSplitBill">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="py-0">
                        <v-radio-group class="mt-0" v-model="form.splitType">
                            <!-- <v-row class="mt-0">
                                <v-col class="mx-1">
                                    <v-radio label="Select Items" value="selectItems"></v-radio>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="mx-1">
                                    <v-radio label="Split evenly among guests" value="splitEvenly"></v-radio>
                                </v-col>
                            </v-row> -->
                            <v-row>
                                <v-col class="mx-1">
                                    <v-radio label="Pay for my order" value="payForMyOrder"></v-radio>
                                </v-col>
                            </v-row>
                        </v-radio-group>
                    </v-card-text>
                    <v-card-actions>
                    <v-btn
                        type="submit"
                        color="primary"
                        block
                        :disabled="!form.splitType"
                        >
                        Continue
                    </v-btn>
                </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
    data(){
        return {
            form: {
                splitType: null
            }
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_splitBillPopup
            },
            set(val){
                this.vm_splitBillPopup(val)
            }
        },
        ...mapGetters({
            vg_orderType: 'cart/orderType',
            vg_cartId: 'cart/cart_id',
            vg_loginUser: 'login/user',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_splitBillPopup: 'cart/splitBillPopup'
        })
    },
    methods: {
        closeSplitBill(){
            this.vm_splitBillPopup(false)
        },
        setPaymentType(){
            if(this.form.splitType==='selectItems'){
                let self = this
                this.unlockCheckout()
                this.vm_selectedPaymentType(this.form.splitType)
                this.vm_splitBillPopup(false)
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'select_item_page_to_pay',
                        msg: `select items to pay for cart`,
                    }
                });
                this.$router.push({name: 'select-item-to-pay'})
            }else if(this.form.splitType==='payForMyOrder'){
                let self = this
                this.unlockCheckout()
                this.vm_selectedPaymentType(this.form.splitType)
                this.vm_splitBillPopup(false)
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'pay_for_my_bill'
                    }
                });
                return
            }else if(this.form.splitType==='splitEvenly'){
                // this.$router.push({name: 'select-item-to-pay'})
            }
        },
        unlockCheckout(){
            this.vm_lockCheckout(false)
            // locked checkout for dine in
            if(this.vg_orderType=='dinein'){
                let self = this
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'unlocked_checkout_page'
                    }
                });
            }
        },
        ...mapMutations({
            vm_lockCheckout: 'cart/SET_LOCK_CHECKOUT',
            vm_splitBillPopup: 'cart/SET_SPLIT_BILL_POPUP',
            vm_selectedPaymentType: 'payment/SET_SELECTED_PAYMENT_TYPE'
        })
    }
}
</script>