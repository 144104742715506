import {CartUserAPI} from '@/clients/cart-user'

export default {
    namespaced: true,
    state: {
      cartUsers: [],
      cartUser: {},
      // notify on user_request
      notification: false,
      itemInCart: false,
      removeUserConfirmPop: false,
      cannotRemoveUserPop: false
    },
    getters: {
        cartUsers: state => state.cartUsers,
        cartUser: state => state.cartUser,
        notification: state => state.notification,
        itemInCart: state => state.itemInCart,
        removeUserConfirmPop: state => state.removeUserConfirmPop,
        cannotRemoveUserPop: state => state.cannotRemoveUserPop
    },
    mutations: {
        SET_CART_USERS(state, data) {
            state.cartUsers = data
        },
        SET_CART_USER(state, data){
            state.cartUser = data
        },
        SET_NOTIFICATION(state, data){
            state.notification = data
        },
        SET_ITEM_IN_CART(state, data){
            state.itemInCart = data
        },
        SET_REMOVE_USER_CONFIRM_POP(state, data){
            state.removeUserConfirmPop = data
        },
        SET_CANNOT_REMOVE_USER_POP(state, data){
            state.cannotRemoveUserPop = data
        },
        RESET(state){
            state.cartUsers = []
            state.cartUser = {}
            state.notification = false
            state.itemInCart = false
            state.removeUserConfirmPop = false
            state.cannotRemoveUserPop = false
        }
    },
    actions: {
        async cartUsers({commit}, cartId){
            try{
                const {data} = await CartUserAPI.cartUsers(cartId);
                commit('SET_CART_USERS', data.payload)
            }catch(err){
                commit('SET_CART_USERS', [])
                if(err.response.status==418){
                    this.commit('cart/SET_CART_ID', "")
                    this.commit('cart/SET_TABLE_ID', "")
                    this.commit('cart/SET_JOINED_CARD_ID', "")
                }
            }
        }
    }
}
