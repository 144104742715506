import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import Snackbar from '@/components/custom-components/snackbar/snackbar.state'
import menu from './modules/menu'
import item from './modules/item'
import location from './modules/location'
import login from './modules/login'
import signup from './modules/signup'
import cart from './modules/cart'
import cartUser from './modules/cartUser'
import identifyTable from './modules/identifyTable'
import payment from './modules/payment'
import order from './modules/order'
import account from './modules/account'
import loyalty from './modules/loyalty'
import privacy from './modules/privacy'
import terms from './modules/termsCondition'
import faq from './modules/faq'
import loyaltyEarning from './modules/loyaltyEarning'
import delivery from './modules/delivery'

const ENC_KEY = process.env.VUE_APP_ENC_KEY
var ls = new SecureLS({ isCompression: false, encryptionSecret: ENC_KEY })

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  modules: { 
    Snackbar, 
    menu, 
    item, 
    location, 
    login, 
    signup, 
    cart, 
    cartUser, 
    identifyTable, 
    payment, 
    order, 
    account, 
    loyalty,
    privacy,
    terms,
    faq,
    loyaltyEarning,
    delivery
  }
})

export default store