import { APP_CART_URL, APP_API_TOKEN } from '../env'
import axios from '../_client'
const userCartAxiosInstaceV1 = axios.create({
  baseURL: `${APP_CART_URL}`,
  headers: {
    'x-api-token': APP_API_TOKEN
  }
})
userCartAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const CartUserAPI = {
    /**
     * 
     */
    cartUsers(cartId) {
        return userCartAxiosInstaceV1({
            method: 'get',
            url: `/cartUsers/getForCart/${cartId}`
        })
    },

    sendSms(data){
      return userCartAxiosInstaceV1({
        method: 'post',
        url: `/sms/send`,
        data: data
      })
    },

    inviteUserToJoinCart(data){
        return userCartAxiosInstaceV1({
            method: 'post',
            url: `/cartUsers`,
            data: data
        })
    },

    respondToJoinCart(cartUserId, data){
        return userCartAxiosInstaceV1({
            method: 'patch',
            url: `/cartUsers/${cartUserId}`,
            data: data
        })
    },

    removeUserRequestCart(cartUserId){
        return userCartAxiosInstaceV1({
            method: 'delete',
            url: `/cartUsers/${cartUserId}`
        })
    }
}