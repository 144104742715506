import gql from 'graphql-tag'
export const GET_MENUS = gql`
  query menus($location_id: ID!, $filter_by_hour: Boolean, $timezone: String) {
    menus(location_id: $location_id) {
      id
      name
      status
      start_from
      end_on
      categories (filter_by_hour: $filter_by_hour, timezone: $timezone){
        id
        name
        start_from
        stop_on
        status
        order
        course
        takeout
        items {
          id
          name
          slug
          image
          short_description
          price
          nutrition_summary
          status
          order
          takeout
          current_inventory
          enable_inventory_countdown
          tags {
            id
            name
            image
            type
          }
          modifiers {
            id
            name
            sku
            base_price
            included
            order
            min
            max
            status
            modifier_items {
              id
              name
              sku
              order
              price
              min
              max
              status
            }
          }
        }
      }
    }
  }
`

export const GET_MENUS_WITH_ORDER_TYPE = gql`
  query menus($location_id: ID!, $filter_by_hour: Boolean, $timezone: String, $orderType: OrderType) {
    menus(location_id: $location_id) {
      id
      name
      status
      start_from
      end_on
      categories (filter_by_hour: $filter_by_hour, timezone: $timezone, order_type: $orderType){
        id
        name
        start_from
        stop_on
        status
        order
        course
        takeout
        items (order_type: $orderType) {
          id
          name
          slug
          image
          short_description
          price
          nutrition_summary
          status
          order
          takeout
          current_inventory
          enable_inventory_countdown
          tags {
            id
            name
            image
            type
          }
          modifiers {
            id
            name
            sku
            base_price
            included
            order
            min
            max
            status
            modifier_items {
              id
              name
              sku
              order
              price
              min
              max
              status
            }
          }
        }
      }
    }
  }
`

export const GET_MENUS_WITH_DATE = gql`
  query menus($location_id: ID!, $filter_by_hour: Boolean, $timezone: String, $date: DateTime) {
    menus(location_id: $location_id, date: $date) {
      id
      name
      status
      start_from
      end_on
      categories (filter_by_hour: $filter_by_hour, timezone: $timezone, date: $date){
        id
        name
        start_from
        stop_on
        status
        order
        course
        takeout
        items {
          id
          name
          slug
          image
          short_description
          price
          nutrition_summary
          status
          order
          takeout
          current_inventory
          enable_inventory_countdown
          tags {
            id
            name
            image
            type
          }
          modifiers {
            id
            name
            sku
            base_price
            included
            order
            min
            max
            status
            modifier_items {
              id
              name
              sku
              order
              price
              min
              max
              status
            }
          }
        }
      }
    }
  }
`

export const GET_MENUS_WITH_DATE_ORDER_TYPE = gql`
  query menus($location_id: ID!, $filter_by_hour: Boolean, $timezone: String, $date: DateTime, $orderType: OrderType) {
    menus(location_id: $location_id, date: $date) {
      id
      name
      status
      start_from
      end_on
      categories (filter_by_hour: $filter_by_hour, timezone: $timezone, date: $date, order_type: $orderType){
        id
        name
        start_from
        stop_on
        status
        order
        course
        takeout
        items (order_type: $orderType) {
          id
          name
          slug
          image
          short_description
          price
          nutrition_summary
          status
          order
          takeout
          current_inventory
          enable_inventory_countdown
          tags {
            id
            name
            image
            type
          }
          modifiers {
            id
            name
            sku
            base_price
            included
            order
            min
            max
            status
            modifier_items {
              id
              name
              sku
              order
              price
              min
              max
              status
            }
          }
        }
      }
    }
  }
`
