<template>
  <div>
    <v-menu offset-y v-if="vg_loginUser && vg_loginUser.user">
      <template v-slot:activator="{ on, attrs }">
        <div class="text-decoration-underline" v-bind="attrs" v-on="on">
          Hi {{ getUsernameOrPhone() }}
        </div>
      </template>
      <v-list>
        <v-list-item @click.prevent="$router.push({ name: 'customer-detail' })">
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item>
        <v-list-item @click.prevent="signOut">
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <div class="text-decoration-underline" @click.prevent="$router.push({name: 'customer-detail'})" v-if="vg_loginUser && vg_loginUser.user">Hi {{getUsernameOrPhone()}}</div> -->
    <a
      class="text-decoration-underline"
      @click.prevent="vm_loginStatus(true)"
      v-else
    >
      Sign in
    </a>
  </div>
</template>

<script>
import { StringHelper } from "@/helpers/stringHelper";
import { mapGetters, mapMutations } from "vuex";
import { UserAPI } from "@/clients/user/";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      vg_loginUser: "login/user",
    }),
  },
  methods: {
    getUsernameOrPhone() {
        return StringHelper.getUserNameOrPhone(this.vg_loginUser)
    },
    goToMenu(){
        // reload when table transfer
        let url = StringHelper.menuUrlGenerator(
            this.$router.currentRoute.params.businessName, 
            this.$router.currentRoute.params.locationName, 
            this.vg_tableId,
            this.vg_joinedCardId
        )
        this.$router.push(url)
    },
    async signOut(){
      try{
          this.spinner(true)
          let response = await UserAPI.logout()
          this.spinner(false)
          this.reset()
          this.snackbar({open: true, text: response.data.msg, color: 'success'});
          
          if(this.$router.currentRoute.query.table_id || this.$router.currentRoute.query.allow_to_join || this.$router.currentRoute.query.joiningId){
              this.goToMenu()
          }
      }catch(err){
          this.spinner(false)
          this.reset()
          // this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
      }
    },
    reset(){
        this.vm_setOrderType(null)
        this.vm_resetCart()
        this.vm_resetCartUser()
        this.vm_deliveryReset()
        this.vm_resetPayment()
        this.vm_tableId('')
        this.vm_allowToJoin(false)
        this.vm_resetUser()
        this.vm_confirmDeliveryPickup(true)
    },
    ...mapMutations({
        vm_resetCart: 'cart/RESET',
        vm_resetUser: 'login/RESET_USER',
        vm_deliveryReset: 'delivery/RESET',
        vm_setOrderType: 'cart/SET_ORDER_TYPE',
        vm_tableId: 'cart/SET_TABLE_ID',
        vm_resetPayment: 'payment/RESET',
        vm_loginStatus: 'login/SET_STATUS',
        vm_resetCartUser: 'cartUser/RESET',
        vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
        vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
    })
  }
}
</script>
