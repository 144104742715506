<template>
  <v-app>
    <router-view />
    <spinner></spinner>
    <SnackbarList></SnackbarList>

    <login-index />
    <login />
    <select-login-option />
    <login-verification />

    <signup-index />
    <signup />
    <signup-verification />

    <Loyalty />

    <add-new-card />
    <identify-table />
    <lock-checkout />
    <loyalty-earning />
    <fiska class="ma-0" />

    <remove-user-confirm />
    <cannot-remove-user />
    <delivery-address />
    <save-address />
    <contact-you />
    <change-delivery-address />

    <privacy-policy />
    <terms-condition />
    <faq />
    <faq-terms />

  </v-app>
</template>

<script>
import LoginIndex from '@/views/login/index'
import Login from '@/views/login/login'
import SelectLoginOption from '@/views/login/select-login-option'
import LoginVerification from '@/views/login/login-verification'

import SignupIndex from '@/views/signup'
import Signup from '@/views/signup/signup'
import SignupVerification from '@/views/signup/signup-verification'

import Loyalty from '@/views/loyalty/index'
import AddNewCard from '@/views/payment/add-card'
import IdentifyTable from '@/views/identify-table'
import LockCheckout from '@/views/lockCheckout'
import PrivacyPolicy from '@/views/privacy-policy'
import TermsCondition from '@/views/term-condition'
import faq from '@/views/faq'
import faqTerms from '@/views/faq/terms'
import LoyaltyEarning from '@/views/loyalty-earning'
import Fiska from '@/views/add-payment/fiska'

import RemoveUserConfirm from '@/views/manageTable/removeUserConfirm'
import CannotRemoveUser from '@/views/manageTable/cannotRemoveUser'
import DeliveryAddress from '@/views/delivery-address'
import SaveAddress from '@/views/delivery-address/save-address'
import ContactYou from '@/views/delivery-address/contact-you'
import ChangeDeliveryAddress from '@/views/delivery-address/change-delivery-address'

import SnackbarList from '@/components/custom-components/snackbar/snackbar-list'
import spinner from '@/components/custom-components/BlockSpinner'

import {TipsHelper} from '@/helpers/tipsHelper'
import {CartAPI} from '@/clients/cart'
import {FacebookHelper} from '@/helpers/facebookHelper'
import { StringHelper } from '@/helpers/stringHelper'
import {PromoHelper} from '@/helpers/PromoHelper'
import {LoyaltyHelper} from '@/helpers/LoyaltyHelper'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'App',
  components: {
    LoginIndex,
    Login,
    SelectLoginOption,
    LoginVerification,

    SignupIndex,
    Signup,
    SignupVerification,

    Loyalty,
    
    AddNewCard,
    IdentifyTable,
    LoyaltyEarning,
    Fiska,

    RemoveUserConfirm,
    CannotRemoveUser,
    DeliveryAddress,
    SaveAddress,
    ContactYou,
    ChangeDeliveryAddress,

    LockCheckout,
    PrivacyPolicy,
    TermsCondition,
    faq,
    faqTerms,

    SnackbarList,
    spinner
  },
  data(){
    return {
      callServerTimer: null,
      accepted_events: ['user_joined', 'user_declined'],
      all_accepted_events: ['user_requested_to_join', 'user_joined', 'user_declined', 'user_removed']
    }
  },
  async mounted(){
    await FacebookHelper.loadFacebookSDK()
    await FacebookHelper.initFacebook(this.vg_loginUser.user)
    if(this.vg_cartId || this.vg_joinedCardId){
      this.pubnub()
    }
    this.startCallServerCountDown()
  },
  watch: {
    // table owner
    vg_cartId(cartId){
      if(cartId!=='' && cartId!==null){
          console.log(`pubnub start on id ${cartId}, ${this.vg_loginUser.user.id} and ${this.vg_location.id}`)
          this.$pnSubscribe({
              channels: [cartId, this.vg_loginUser.user.id, this.vg_location.id]
          });
          this.$pnGetMessage(cartId, this.pubnubCompute)
          this.$pnGetMessage(this.vg_loginUser.user.id, this.pubnubCompute)
          this.$pnGetMessage(this.vg_location.id, this.pubnubCompute)
      }
    },
    // table joiner
    vg_joinedCardId(joinedCartId){
      if(joinedCartId!=='' && joinedCartId!==null){
        console.log(`pubnub start on id ${joinedCartId} and ${this.vg_location.id}`)
        this.$pnSubscribe({
            channels: [joinedCartId, this.vg_location.id]
        });
        this.$pnGetMessage(joinedCartId, this.pubnubCompute)
        this.$pnGetMessage(this.vg_location.id, this.pubnubCompute)
      }
    },
    vg_callServerValue(timer){
      if(timer){
        // listen to menu/callserverValue
        this.startCallServerCountDown()
      }
    }
  },
  computed: {
    ...mapGetters({
      vg_orderType: 'cart/orderType',
      vg_cartId: 'cart/cart_id',
      vg_joinedCardId: 'cart/joinedCardId',
      vg_loginUser: 'login/user',
      vg_server_cart: 'cart/serverCart',
      vg_tableId: 'cart/tableId',
      vg_orderId: 'payment/orderId',
      vg_location: 'location/location',
      vg_selectedPaymentType:'payment/selectedPaymentType',
      vg_callServerValue: 'menu/callServerValue',
      vg_callServerUser: 'menu/callServerUser'
    })
  },
  methods: {
    startCallServerCountDown(){
      let self = this
      if(this.vg_callServerValue){
        // user who call server will broadcast to cart
        if(this.vg_callServerUser.user && this.vg_callServerUser.user.id==this.vg_loginUser.user.id){
          this.$pnPublish({
              channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
              message: {
                  event: 'locked_call_server',
                  msg: `${StringHelper.getUserNameOrPhone(this.vg_callServerUser)} called the server`,
              }
          });
        }

        this.callServerTimer = setTimeout(function(){
          self.vm_callServerUser({})
          self.vm_callServerValue(null)
        }, this.vg_callServerValue)
      }
    },
    pubnub(){
      if(this.vg_cartId || this.vg_joinedCardId){
        if(this.vg_loginUser.user && this.vg_loginUser.user.id){
          // table owner
          console.log(`pubnub start on id ${this.vg_cartId?this.vg_cartId:this.vg_joinedCardId}, ${this.vg_loginUser.user.id} and ${this.vg_location.id}`)
          this.$pnSubscribe({
              channels: [this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, this.vg_loginUser.user.id, this.vg_location.id]
          });
          this.$pnGetMessage(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, this.pubnubCompute)
          // for table owner && for check cart after 40 min
          this.$pnGetMessage(this.vg_loginUser.user.id, this.pubnubCompute)
          // this.$pnGetMessage(this.vg_location.id, this.pubnubLocationCompute)
        }else{
          // table joiner
          // console.log(`pubnub start on id ${this.vg_cartId?this.vg_cartId:this.vg_joinedCardId}`)
          // this.$pnSubscribe({
          //     channels: [this.vg_cartId?this.vg_cartId:this.vg_joinedCardId]
          // });
          // this.$pnGetMessage(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, this.pubnubCompute)
          // for check cart after 40 min
          if(this.vg_loginUser.user && this.vg_loginUser.user.id) this.$pnGetMessage(this.vg_loginUser.user.id, this.pubnubCompute)
          this.$pnGetMessage(this.vg_location.id, this.pubnubCompute)
        }
      }
    },
    async pubnubCompute(data){
        // don't show bell if in manage table page else show bell
        if(this.accepted_events[0]===data.message.event && this.$router.currentRoute.name!=='manage-table' && this.vg_cartId){
          this.vm_notification(true)
        }

        // notification for user request to join
        if(data.message.event==='user_requested_to_join'){
          // user_requested_to_join showing on all user. other user don't have message.data key
          if(data.message.data && data.message.data.user_id!==this.vg_loginUser.user.id){
            this.vm_notification(true)

            this.snackbar({
              text: data.message.msg,
              open: true,
              color: 'black'
            })
          }
        }

        // notification for table join request
        if(data.message.event==='table_join_request'){
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
        }

        // notification for table join request
        if(data.message.event==='table_transfer'){
          this.vm_tableId(data.message.data.table_id)
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
        }
        
        // event should be above one and only payload cart user data for manage-table and must have cartId or joinigId
        if(this.all_accepted_events.includes(data.message.event) && this.$router.currentRoute.name==='manage-table' && (this.vg_cartId || this.vg_joinedCardId)){
          await this.va_cartUsers(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
        }

        // user joined
        if(data.message.event==='user_joined'){
          // check for cart
          this.vm_resetOrder()
          await this.getCartForJoiningUser()
          // for split payment - pay for my order
          if(this.vg_server_cart.paid>0){
            this.vm_selectedPaymentType('payForMyOrder')
          }
          this.vm_userJoined(true)

          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })

          // join user id after accepted
          console.log(`pubnub start on id ${this.vg_loginUser.user.id}`)
          this.$pnSubscribe({
              channels: [this.vg_loginUser.user.id]
          });
          this.$pnGetMessage(this.vg_loginUser.user.id, this.pubnubCompute)     
        }

        if(data.message.event==='cart_user_removed'){
          if(data.message?.data?.cart_users && data.message.data.cart_users.length>0){
            let selfExist = data.message.data.cart_users.find(each => each.user_id==this.vg_loginUser.user.id)
            if(!selfExist){
              this.$pnUnsubscribe({
                channels: [this.vg_cartId?this.vg_cartId:this.vg_joinedCardId]
              })
              this.vm_tableId('')
              this.vm_joinedCardId(null)
              this.vm_allowToJoin(false)
              this.vm_resetCart()
              this.vm_resetCartUser()
              console.log(`pubnub unsubscribe from table ${this.vg_cartId?this.vg_cartId:this.vg_joinedCardId}`)
            }
          }
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
        }

        // cart type changes
        if(data.message.event==='order_receive_method_switched' && this.vg_cartId==data.message.data.cart_id){
          if(data.message.data.order_receive_method==='pickup'){
            this.vm_setOrderType('pickup')
            this.vm_tableId('')
          }else if(data.message.data.order_receive_method==='dinein'){
            this.vm_setOrderType('dinein')
            // don't add vm_table_id here when user switch to dine in - table id won't be available 
            // emit 'order_receive_method_switched' on switching cart
            // table id only available during updating cart
          }
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
        }

        //
        if(data.message.event==='locked_call_server'){
          this.vm_callServerValue(36000)
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
        }

        // for cart_update or cart_submitted event & update cart
        if(data.message.event==='cart_updated' || data.message.event==='cart_submitted'){
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
          await PromoHelper.removePromo()
          await LoyaltyHelper.removeRedeem()
          this.va_reloadCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
        }

        // listen to lock checkout event
        if(data.message.event==='locked_checkout_page'){
          this.vm_lockCheckout(true)
        }
        
        // broadcast message for all
        if(data.message.event==='pay_for_my_bill'){
          if(data.message.selector!==this.vg_loginUser.user.id){
            // for other users in table
            this.vm_selectedPaymentType('payForMyOrder')
            this.vm_disableWholeItemBtn(true)
            this.vm_disableSplitEvenlyBtn(true)
            this.vm_disablePayBtn(false)
          }
          // select prev selected payment type is split. when cancel split need to return to payForMyOrder for rest of user
          if(this.vg_selectedPaymentType=='splitEvenly' && data.message.selector!==this.vg_loginUser.user.id){
            this.vm_selectedPaymentType('payForMyOrder')
            this.vm_disableMyItemBtn(false)
          }
        }

        if(data.message.event==='all_by_one'){
          if(data.message.selector!==this.vg_loginUser.user.id){
            // for other users in table
            this.vm_selectedPaymentType('AllByOne')
            this.vm_lockCheckout(true)
            this.vm_disableMyItemBtn(true)
            this.vm_disableSplitEvenlyBtn(true)
            this.vm_disablePayBtn(true)
          }
          // select prev selected payment type if current is split. when cancel split need to return to this 
          if(this.vg_selectedPaymentType=='splitEvenly' && data.message.selector!==this.vg_loginUser.user.id){
            this.vm_selectedPaymentType('payForMyOrder')
            this.vm_disableMyItemBtn(false)
          }
        }

        if(data.message.event==='split_evenly'){
          if(data.message.selector!==this.vg_loginUser.user.id){
            this.vm_disableMyItemBtn(true)
            this.vm_disableWholeItemBtn(true)

            this.vm_disableSplitEvenlyBtn(false)
            this.vm_disablePayBtn(false)
          }
          this.vm_selectedPaymentType('splitEvenly')
        }

        // route all user to select item page
        if(data.message.event==='select_item_page_to_pay'){
          this.vm_selectedPaymentType("selectItems")
        }

        // listen to unlock checkout event
        if(data.message.event==='unlocked_checkout_page'){
          this.vm_lockCheckout(false)
          this.vm_disableMyItemBtn(false)
          this.vm_disableWholeItemBtn(false)
          this.vm_disableSplitEvenlyBtn(false)
          this.vm_disablePayBtn(false)
        }

        // listen to items_left_in_cart
        if(data.message.event==='items_left_in_cart'){
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
          this.vm_itemInCart(true)
        }

        if(data.message.event==='promocode_added' || data.message.event=='promocode_removed'){
          if(data.message.data.cart_promocode && data.message.data.cart_promocode.applied_by_user && data.message.data.cart_promocode.applied_by_user=='user'){
            await this.va_reloadCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
            this.snackbar({
              text: data.message.msg,
              open: true,
              color: 'black'
            })
          }
        }

        if(data.message.event==='loyalty_reward_added' || data.message.event=='loyalty_reward_remove'){
          await this.va_reloadCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
        }

        if(data.message.event==='tip_added'){
          // don't sync tips if payment type is split
          if(this.vg_selectedPaymentType==='AllByOne'){
            await this.va_reloadCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
            TipsHelper.setTipsPercentageOrFlatFromCart()
          }else{
            await this.va_reloadCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
          }
        }

        if(data.message.event==='default_tip_set' || data.message.event==='default_tip_removed'){
          await this.va_reloadCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
        }
        
        if(data.message.event === 'partial_payment_made'){
          await this.va_reloadCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
        }

        if(data.message.event==='cart_status_changed'){
          if(data.message.data.cart_id===(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId) && data.message.data.status==='cancelled'){
            // move to recept screen
            this.vm_setOrderType(null)
            this.vm_tableId('')
            this.vm_confirmDeliveryPickup(true)

            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_cancelled(true)
            this.vm_notification(false)
            this.vm_orderMsg(data.message.msg)
            this.vm_orderStatus(data.message.data.status)

            this.snackbar({
              text: "Cart has been cancelled",
              open: true,
              color: 'black'
            })
          }
        }

        // for delivery status
        if(data.message.event==='order_status_changed' && data.message.data.order_id==this.vg_orderId){
          if(data.message.data.status==='completed' || data.message.data.status==='cancelled'){
            // move to recept screen
            this.vm_setOrderType(null)
            this.vm_confirmDeliveryPickup(true)

            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_deliveryReset()
            this.vm_notification(false)
          }
          
          this.vm_orderMsg(data.message.msg)
          this.vm_orderStatus(data.message.data.status)
          this.snackbar({
            text: data.message.msg,
            open: true,
            color: 'black'
          })
        }

        // for close cart
        if(data.message.event==='cart_closed'){
          let cartId = this.vg_joinedCardId?this.vg_joinedCardId:this.vg_cartId
          if(data.message.data && (data.message.data.cart_id==cartId)){
            if(data.message.data && data.message.data.order_type && data.message.data.order_type=='dinein'){
              this.vm_callServerTableId(data.message.data.table_id)
            }
            if(data.message.data && data.message.data.user_name){
              this.vm_payer(data.message.data.user_name)
            }
            if(data.message.data && data.message.data.order_id){
              this.vm_orderId(data.message.data.order_id)
            }
            if(data.message.data && data.message.data.order_loyalty && data.message.data.order_loyalty.length>0){
              let myLoyaltyPoints = LoyaltyHelper.getMyEarning(data.message.data, this.vg_loginUser)
              if(myLoyaltyPoints>0 && this.vg_selectedPaymentType!=='splitEvenly'){
                this.vm_earnedPoints(myLoyaltyPoints)
                this.vm_loyaltyEarningType('checkout')
                this.vm_loyaltyEarningStatus(true)
              }
            }

            if(this.vg_orderType=='dinein'){
              this.vm_resetCart()
              this.vm_resetCartUser()
              this.vm_payed(true)
              this.vm_notification(false)
              this.vm_itemInCart(false)
              this.vm_setOrderType(null)
              this.vm_confirmDeliveryPickup(true)
              this.vm_allowToJoin(false)
              this.vm_selectedPaymentType('AllByOne')
            }else{
              this.vm_resetOrder()
              this.vm_orderDeliveryPickup(true)

              // get cart tips detail
              if(data.message && data.message.data && data.message.data.cart_id){
                let result = await CartAPI.getCart(data.message.data.cart_id)
                this.vm_tips(result.data.payload)
              }
            }

            if(this.vg_loginUser.user.id!==data.message.data.placed_by_id){
              this.snackbar({
                text: data.message.msg,
                open: true,
                color: 'black'
              })
            }
          }
        }
    },
    async getCartForJoiningUser(){
      if(this.vg_joinedCardId){
        let {data} = await CartAPI.getCart(this.vg_joinedCardId)
        this.vm_server_cart(data.payload)
        await this.va_cartUsers(this.vg_joinedCardId)
      }
    },
    ...mapMutations({
      vm_notification: 'cartUser/SET_NOTIFICATION',
      vm_itemInCart: 'cartUser/SET_ITEM_IN_CART',
      vm_resetCart: 'cart/RESET',
      vm_resetCartUser: 'cartUser/RESET',
      vm_deliveryReset: 'delivery/RESET',
      vm_cancelled: 'payment/SET_CANCELLED',
      vm_payed: 'payment/SET_PAYED',
      vm_orderId: 'payment/SET_ORDER_ID',
      vm_payer: 'payment/SET_PAYER',
      vm_orderStatus: 'order/SET_ORDER_STATUS',
      vm_orderDeliveryPickup: 'order/SET_ORDER_PICKUP_DELIVERY',
      vm_setOrderType: 'cart/SET_ORDER_TYPE',
      vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
      vm_server_cart: 'cart/SET_SERVER_CART',
      vm_tips: 'cart/SET_TIPS',
      vm_lockCheckout: 'cart/SET_LOCK_CHECKOUT',
      vm_orderMsg: 'order/SET_ORDER_MSG',
      vm_resetOrder: 'order/RESET_ORDER',
      vm_tableId: 'cart/SET_TABLE_ID',
      vm_selectedPaymentType: 'payment/SET_SELECTED_PAYMENT_TYPE',
      vm_userJoined: 'cart/SET_USER_JOINED',
      vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
      vm_callServerValue: 'menu/SET_CALL_SERVER_VALUE',
      vm_callServerUser: 'menu/SET_CALL_SERVER_USER',
      vm_disableMyItemBtn: 'payment/SET_DISABLE_MY_ITEM_BTN',
      vm_disableWholeItemBtn: 'payment/SET_DISABLE_WHOLE_ITEM_BTN',
      vm_disableSplitEvenlyBtn: 'payment/SET_DISABLE_SPLIT_EVENLY_BTN',
      vm_disablePayBtn: 'payment/SET_DISABLE_PAY_BTN',
      vm_loyaltyEarningStatus: 'loyaltyEarning/SET_STATUS',
      vm_earnedPoints: 'loyaltyEarning/SET_POINTS',
      vm_loyaltyEarningType: 'loyaltyEarning/SET_TYPE',
      vm_callServerTableId: 'menu/SET_CALL_SERVER_TABLE_ID',
      vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',

    }),
    ...mapActions({
        va_cartUsers: 'cartUser/cartUsers',
        va_reloadCart: 'cart/reloadServerCart'
    })
  }
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.saw-tooth{
  background-size: 12px 12px;
  background-repeat: repeat-x;
  background-image:
    linear-gradient(135deg, #f9f9f9 50%, transparent 50%),
    linear-gradient(225deg, #f9f9f9 50%, transparent 50%),
    linear-gradient(45deg, #f9f9f9 50%, transparent 50%),
    linear-gradient(-45deg, #f9f9f9 50%, transparent 50%);
  background-position: top left, top left, bottom left, bottom left;
}
#confetti-canvas{
  z-index: 205;
}
.clover-form iframe{
  height: 40px !important;
}
/* .saw-tooth>div{
  background-color: #fff;
} */
@media screen and (max-width: 359px) {
  .footer{
    margin:0 !important;
    padding:0 !important;
  }
  .footer>div{
    justify-content: center;
  }
  .footer .other-options button{
    border: solid thin #ccc;
  }
  .footer-bar{
    min-width: 260px;
    width: 260px; 
    display:inherit; 
    margin:auto;
  }
  .footer-bar .left-btn{
    background: url('/img/right-curve-btn.png') right center no-repeat #fff !important; 
    box-shadow:none; 
    display:inline-block;
    width:125px;
  }
  .footer-bar .center-btn{
    background: #005 !important;
    position:relative; 
    left:-15px; 
    top:-26px;
    z-index:99; 
    display:inline-block;
    color: #fff;
  }
  .footer-bar .right-btn{
    background: url('/img/left-curve-btn.png') left center no-repeat #fff !important; 
    box-shadow:none; 
    display:inline-block; 
    position: relative; 
    left:-30px;
    width:125px;
  }
}

@media screen and (min-width: 360px) {
  .footer .other-options button{
    border: solid thin #ccc;
  }
  .footer-bar{
    min-width:320px; 
    width: 320px; 
    display:inherit; 
    margin:auto;
  }
  .footer-bar .default-btn{
    /* background: url('/img/right-curve-btn.png') right center no-repeat #fff !important;  */
    box-shadow:none; 
    display:inline-block;
    width:154px;
  }
  .footer-bar .left-btn{
    background: url('/img/right-curve-btn.png') right center no-repeat #fff !important; 
    box-shadow:none; 
    display:inline-block;
    width:154px;
  }
  .footer-bar .center-btn{
    background: #005 !important;
    position:relative; 
    left:-15px; 
    top:-26px;
    z-index:99; 
    display:inline-block;
    color: #fff;
  }
  .footer-bar .right-btn{
    background: url('/img/left-curve-btn.png') left center no-repeat #fff !important; 
    box-shadow:none; 
    display:inline-block; 
    position: relative; 
    left:-30px;
    width:154px;
  }
}

</style>
