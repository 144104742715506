export default {
    namespaced: true,
    state: {
      status: false,
      signupPhone: '',
      verifyData: {},
      verify: false,
      phoneEmailPop: false
    },
    getters: {
        status: state => state.status,
        signupPhone: state => state.signupPhone,
        verifyData: state => state.verifyData,
        verify: state => state.verify,
        phoneEmailPop: state => state.phoneEmailPop
    },
    mutations: {
        SET_STATUS(state, status) {
            state.status = status
        },
        SET_SIGN_UP_PHONE(state, data){
            state.signupPhone = data
        },
        SET_VERIFY_DATA(state, data){
            state.verifyData = data
        },
        SET_VERIFY(state, data){
            state.verify = data
        },
        SET_PHONE_EMAIL_POP(state, data){
            state.phoneEmailPop = data
        },
    }
}