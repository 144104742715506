<template>
    <v-container>
        <v-toolbar elevation="1">
            <v-spacer></v-spacer>
            <v-toolbar-title>Past Order</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <loading-block v-if="loading" class="pt-9" />
        <div v-else style="margin-bottom:100px;">
            <v-row>
                <v-col cols="5">
                    <div class="mt-5 ml-5">View All items</div>
                </v-col>
                <v-col cols="3" offset="4">
                    <v-switch
                        v-model="viewAll"
                        hide-details=""
                    ></v-switch>
                </v-col>
            </v-row>
            <v-card class="ma-2" elevation="2">
                <v-card-text class="pb-0">
                    <template v-for="(user, userindex) in orderDetail.order_users">
                        <!-- show self or all -->
                        <template v-if="viewAll?true:(user.user_id===vg_loginUser.user.id)">
                            <div :key="'cat-'+userindex" class="title text-center black--text pb-2">{{getNameOrPhone(user)}}</div>
                            <div :key="'order-'+userindex" class="font-weight-bold text-center">Order #{{orderDetail.external_id}}</div>
                            <template v-for="(item, index) in orderDetail.order_items">
                                <template v-if="user.user_id===item.user_id">
                                    <v-list class="py-0" three-line :key="'list-'+userindex+index">
                                        <v-list-item style="min-height: 60px !important;" class="px-0 my-0" >
                                            <v-list-item-icon class="mr-2 mb-0 py-0">
                                                <v-badge class="mt-0" v-if="getCourse(item.course)" :content="getCourse(item.course).abbr" :color="getCourse(item.course).color"/>
                                            </v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                                <div>{{item.item_qty}}x</div>
                                            </v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title>{{item.name}}</v-list-item-title>
                                                <v-list-item-content class="pt-0 mt-0">
                                                    <!-- Modifier items -->
                                                    <template v-for="(item_modifier, modifierIndex) in item.cart_item_modifiers">
                                                        <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'mod-'+modifierIndex">{{item_modifier.name}}</div>
                                                        <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                            <div v-if="modifier_item.qty>0" class="text--secondary" :key="'modItem-'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} ({{modifier_item.qty}}x)</div>
                                                        </template>
                                                    </template>
                                                    <!-- Note -->
                                                    <template v-if="item.note">
                                                        <div class="text--secondary">Item note:</div>
                                                        <div class="text--secondary">{{item.note}}</div>
                                                    </template>
                                                </v-list-item-content>
                                            </v-list-item-content>
                                            <v-list-item-action class="my-0 py-0">
                                                <v-list-item-action-text >
                                                    <span class="pr-3 body-2" style="color:black;">${{calculatePrice(item)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </template>
                            </template>
                        </template>
                        <!-- Subtotal -->
                        <v-list class="py-0" :key="'s-'+userindex">
                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                    <v-list-item-title class="my-0 py-0">Subtotal</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                    <v-list-item-action-text style="color:black;">
                                        <span class="pr-3 body-2" style="color:black;">$ {{ userItemSubtotal(user.user_id, orderDetail) }}</span>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider class="my-3" :key="'pen-d-'+userindex" />
                        </v-list>
                    </template>
                    <!-- Subtotal -->
                    <v-list class="py-0">
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Subtotal</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{parseFloat(orderDetail.subtotal).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-list class="py-0">
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Tax</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-1 mr-2 body-2" style="color:black;">${{parseFloat(orderDetail.tax).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- if Loyalty, loyalty list -->
                        <template v-if="orderDetail.reward_amount>0">
                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-for="(loyalty, ind) in orderDetail.order_loyalty" :key="'loyal'+ind">
                                <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                    <v-list-item-title class="my-0 py-0 body-2">Point Used: {{loyalty.points_to_redeem}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                    <v-list-item-action-text style="color:black;">
                                        <span class="pr-1 mr-2 body-2" style="color:green;">-${{parseFloat(loyalty.redeem_amount).toFixed(2)}}</span>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <!-- Promo -->
                        <template v-if="orderDetail.promocode>0">
                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-for="(promo, ind) in orderDetail.order_promocodes" :key="ind">
                                <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                    <v-list-item-title class="my-0 py-0 body-2">PROMO: {{promo.code}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                    <v-list-item-action-text style="color:black;">
                                        <span class="pr-1 mr-2 body-2" style="color:green;">-${{parseFloat(promo.applied_value).toFixed(2)}}</span>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <!-- delivery fee -->
                        <v-list-item v-if="orderDetail.order_receive_method=='delivery'" class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Delivery Fee</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{parseFloat(orderDetail.delivery_fee).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Tip(s)</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-1 mr-2 body-2" style="color:black;">${{parseFloat(orderDetail.tip).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0 title">Total</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-1 mr-2 title" style="color:black;">${{parseFloat(orderDetail.total).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
            <version/>
        </div>

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || (vg_orderType!=='dinein') || !!vg_callServerValue">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                <div class="footer-bar">
                    <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                    <v-btn @click.prevent="moreOption" small fab class="center-btn">
                    <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                    <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn @click.prevent="doConfirmDeliveryTakeout" class="right-btn" dark>Add to Cart</v-btn>
                </div>
                </v-card-actions>
            </v-card>
        </v-footer>
        <confirm-delivery-takeout :confirmDeliveryTakeout="confirmDeliveryTakeout" @orderType="changeOrderType" @cancel="cancelReorder" />
    </v-container>
</template>

<script>
import {CartAPI} from '@/clients/cart'
import {OrderAPI} from '@/clients/order'
import {StringHelper} from '@/helpers/stringHelper'
import {OrderCartHelper} from '@/helpers/orderCartHelper'
import { mapState, mapGetters, mapMutations } from 'vuex'
import ConfirmDeliveryTakeout from '@/views/account/confirm-delivery-takeout'
import Version from '@/components/custom-components/version'
export default {
    components: {
        ConfirmDeliveryTakeout,
        Version
    },
    data(){
        return {
            confirmDeliveryTakeout: false,
            orderType: null,
            loading: false,
            option: false,
            viewAll: true,
            orderDetail: {},
            courses: [
                {abbr: 'N', text: "Now", color: 'orange darken-3'}, 
                {abbr: '1', text: "First Course", color: 'blue darken-3'}, 
                {abbr: '2', text: "Second Course", color: 'purple darken-3'}, 
                {abbr: '3', text: "Third Course", color: 'green darken-3'}, 
                {abbr: 'T', text: "Takeout", color: 'yellow darken-3'}
            ]
        }
    },
    watch:{
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_itemInCart(val){
            if(val){
                this.$router.push({name: 'cart'})
            }
        },
        vg_tableId(val){
            if(val){
                this.cloneCart()
            }
        },
        vg_selectedPaymentType(val){
            if(val==='selectItems'){
                this.$router.push({name: 'select-item-to-pay'})
            }
        }
    },
    async mounted(){
        try{
            this.loading = true
            let {data} = await OrderAPI.getPastOrderDetail(this.vg_currentOrder.id)
            this.orderDetail = data.payload
            this.loading = false
        }catch(err){
            this.loading = false
            console.log(err)
        }
    },
    computed: {
        ...mapState('location', ['location']),
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_cartId: 'cart/cart_id',
            vg_location: 'location/location',
            vg_businessId: 'location/business_id',
            vg_notification: 'cartUser/notification',
            vg_currentOrder: 'order/current',
            vg_payer: 'payment/payer',
            vg_payed: 'payment/payed',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_tableId: 'cart/tableId',
            vg_orderType: 'cart/orderType',
            vg_selectedPaymentType: 'payment/selectedPaymentType',
            vg_callServerValue: 'menu/callServerValue'
        }),
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
    },
    methods: {
        back(){
            this.$router.go(-1)
        },
        moreOption(){
            this.option = !this.option
        },
        mergedProduct(orderDetail){
            let combine =  [...orderDetail.order_users, orderDetail.order_server]
            return combine
        },
        getNameOrPhone(userObject){
            if(userObject.server_name){
                return userObject.server_name
            }else{
                return StringHelper.getNameOrPhone(userObject)
            }
        },
        calculatePrice(item){
            return OrderCartHelper.calculatePrice(item)
        },
        getCourse(course){
            if(!course) return

            if(this.courses[course]){
                return this.courses[course]
            }
        },
        userItemSubtotal(userId, orderDetail){
            return OrderCartHelper.calculateItemsTotalByUser(userId, orderDetail)
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        async cloneCart(){
            try{
                this.spinner(true)
                let dataParam = {
                    order_receive_method: this.vg_orderType,
                    added_by: this.orderDetail.added_by,
                    added_by_id: this.vg_loginUser.user.id,
                    added_by_name: StringHelper.getUserNameOrPhone(this.vg_loginUser)
                }
                if(this.vg_orderType=='dinein'){
                    dataParam.table_id = this.vg_tableId
                }
                if(StringHelper.isLoginWithIsPhone()){
                    dataParam.user_phone = StringHelper.getMyEmailOrPhone('phone')
                }else{
                    dataParam.user_email = StringHelper.getMyEmailOrPhone('email')
                }

                let {data} = await CartAPI.cloneCart(this.orderDetail.cart_id, dataParam)
                if(data.payload){
                    if(this.orderType=='dinein'){
                        this.vm_setOrderType(this.orderType)
                    }
                    this.vm_cartId(data.payload.id)
                    this.$router.push({name: 'cart'})
                }
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                if(err.response && err.response.data && err.response.data.msg){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }
            }
        },
        cancelReorder(){
            this.confirmDeliveryTakeout = false
        },
        doConfirmDeliveryTakeout(){
            if(this.vg_orderType=='dinein'){
                if(this.vg_tableId){
                    this.cloneCart()
                }else{
                    this.vm_identifyTableStatus(true)
                    return
                }
            }else{
                // dinein takeout not selected
                this.vm_identifyTableStatus(false)
                this.confirmDeliveryTakeout = true
            }
        },
        changeOrderType(value){
            this.orderType = value
            this.vm_setOrderType(value)
            this.confirmDeliveryTakeout = false
            if(this.orderType=='dinein'){
                if(this.vg_tableId){
                    this.cloneCart()
                }else{
                    this.vm_identifyTableStatus(true)
                    return
                }
            }else{
                this.cloneCart()
            }
        },
        myTable(){
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        ...mapMutations({
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_cartId :'cart/SET_CART_ID',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_identifyTableStatus: 'identifyTable/SET_STATUS'
        }),
    }
}
</script>