<template>
    <div style="width: 100%; height: 20px;">
        <div style="width: 130px; height: 100%;">
            <div style="display:inline-block; width: 20px;">
                <v-img @click.prevent="minus" contain src="@/assets/img/remove.png" width="20" height="20"  />
                <div :class="(min>=item.qty)?'':'d-none'" style="width:20px; height:20px; position:relative; top:-20px; background: rgba(0,0,0,0.5); border-radius:10px;"></div>
            </div>
            <div style="display:inline-block; width: 25px; position:relative; bottom: 3px;">
                <v-text-field readonly type="number" class="font-weight-bold" hide-details dense v-model="item.qty" style="width:30px; margin-left:5px; border:none;" @change="changeQty" />
            </div>
            <div style="display:inline-block; width: 20px;">
                <v-img @click.prevent="add" contain src="@/assets/img/add.png" width="20" height="20" />
                <div :class="(max<=item.qty)?'':'d-none'" style="width:20px; height:20px; position:relative; top:-20px; background: rgba(0,0,0,0.5); border-radius:10px;"></div>
            </div>
            <div style="display:inline-block; margin-left:10px; font-size:16px; vertical-align:top; line-height:30px; opacity:0.6;">{{modifierItem.name}}</div>
        </div>
    </div>
</template>
<script>
import {Cart} from '@/helpers/cartHelper'
export default {
    props: ['modifier', 'modifierItem', 'preselectItem', 'modifierIncluded', 'totalModifierIncluded'],
    data() {
        return {
            min: null,
            max: null,
            item: {
                id: '',
                qty: 0,
                included: 0
            }
        }
    },
    async mounted(){
        this.min = this.modifierItem.min
        this.max = this.modifierItem.max
        this.item = await Cart.getPreSelectedModifierItem(this.modifier, this.modifierItem, this.preselectItem)
        this.$emit('onChange', this.item)
    },
    methods: {
         add(){
            if(this.item.qty<this.max){
                if((this.modifierIncluded-this.totalModifierIncluded)>0) this.item.included++
                this.item.qty++
                this.$emit('onChange', this.item, 'add', null)
            }
        },
        minus(){
            if(this.item.qty>this.min){
                let haveIncluded = ((this.item.qty-this.item.included)==0 && this.item.included>0)
                if((this.item.qty-this.item.included)<=0) this.item.included--
                this.item.qty--
                this.$emit('onChange', this.item, 'subtract', haveIncluded)
            }
        },
        changeQty(val){
            if(val>this.min && val<this.max){
                this.item.qty = val
                this.$emit('onChange', this.item)
            }else{
                this.item.qty = this.max
            }
        }
    }
}
</script>