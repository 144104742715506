<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-btn style="position:absolute; right:30px; top: 45px; z-index:9999;" fab dark x-small @click.prevent="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <Content />
        </v-dialog>
    </div>
</template>
<script>
import Content from './content'
import {mapGetters, mapMutations} from 'vuex'
export default {
    components: {
        Content
    },
    computed: {
        dialog: {
            get(){
                return this.vg_termConditionStatus
            },
            set(val){
                this.vm_termsConditionStatus(val)
            }
        },
        ...mapGetters({
            vg_termConditionStatus: 'terms/status'
        })
    },
    methods: {
        back(){
            this.$router.go(-1)
        },
        close(){
            this.vm_termsConditionStatus(false)
        },
        ...mapMutations({
            vm_termsConditionStatus: 'terms/SET_STATUS'
        })
    }
}
</script>
<style scoped>
    .terms div{
        margin-bottom: 20px;
    }
</style>