<template>
  <v-sheet color="white" class="black--text slide pt-5" height="100%">
    <v-img class="image mb-10" :src="data.image" />
    <h3 class="title mb-5">{{data.title}}</h3>
    <p class="text">{{data.desc}}</p>
  </v-sheet>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style>
.slide.v-sheet .v-image {
  height: 129px !important;
  width: 100%;
}
.slide.v-sheet .image {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 128px;
  height: 129px;
  background-position: center;
}
.slide.v-sheet .title {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.slide.v-sheet .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
.slide.v-sheet .button {
  display: block;
  margin: auto;
}
</style>
