<template>
    <v-container style="background-color:#f9f9f9;">
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Order Status</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <loading-block v-if="loading" class="pt-9" />
        <div v-else style="border-radius: 10px; margin-bottom:100px; background-color:#fafafa;">
            <div class="my-2 py-3 " style="background-color: white;">
                <div class="mx-5 mb-1">{{progressName(this.vg_orderStatus)}}</div>
                <div class="mx-5">
                    <v-progress-linear :value="progressStatus(this.vg_orderStatus)" color="primary" />
                </div>
                <div class="mx-5 mt-3">
                    <v-row>
                        <v-col>
                            <div>{{deliveryStatus.msg}}</div>
                        </v-col>
                        <!-- <v-col>
                            <div class="text-right">{{deliveryStatus.time}}</div>
                        </v-col> -->
                    </v-row>
                </div>

                <!-- address -->
                <!-- pickup -->
                <div v-if="vg_orderType=='pickup'" class="mx-5 mt-5 font-weight-bold">{{vg_location.name}}</div>
                <div v-if="vg_orderType=='pickup'" class="mx-5">{{vg_location.address.formatted_address}}</div>
                <div v-if="vg_orderType=='pickup' && storePhone" class="mx-5">
                    <a :href="'tel:'+storePhone" style="text-decoration: none; color: #000;">
                        <v-icon>mdi-phone</v-icon> {{storePhone}}
                    </a>
                </div>

                <!-- delivery -->
                <div v-if="vg_orderType=='delivery'" class="mx-5 font-weight-bold">Delivery </div>
                <div v-if="vg_orderType=='delivery'" class="mx-5">To: {{formattedAddress}}</div>
                <div v-if="vg_orderType=='delivery'" class="mx-5"><v-icon>mdi-phone</v-icon> {{phone}}</div>

                <!-- order number -->
                <div class="mx-5 mt-5 mb-2 font-weight-bold">Order Number</div>
                <div class="mx-5">
                    <v-btn class="title text-center" block large rounded outlined>{{vg_server_cart.external_id}}</v-btn>
                </div>
            </div>

            <v-card class="mx-2 my-2 py-3 saw-tooth" elevation="0">
                <v-card-text class="pb-0">
                    <template v-for="(userItem, userindex) in sortServerCart(vg_server_cart, true)">
                        <div :key="'cat-'+userindex" class="title text-center black--text pb-2">{{getUserNameOrPhone(userindex, vg_server_cart.cart_users)}}</div>
                        <template v-for="(cartItem, index) in userItem">
                            <v-list class="py-0" v-if="cartItem" :key="'list-'+userindex+index">
                                <v-list-item style="min-height: 25px !important;" class="px-0 my-0" >
                                    <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                        <div>{{cartItem.item_qty}}x</div>
                                    </v-list-item-icon>
                                    <v-list-item-content class="my-0 py-0">
                                            <v-list-item-title>{{cartItem.name}}</v-list-item-title>
                                            <v-list-item-content class="pt-0 mt-0">
                                                <!-- Modifier items -->
                                                <template v-for="(item_modifier, modifierIndex) in cartItem.cart_item_modifiers">
                                                    <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'mod-'+modifierIndex">{{item_modifier.name}}</div>
                                                    <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                        <div v-if="modifier_item.qty>0" class="text--secondary" :key="'modItem-'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} ({{modifier_item.qty}}x)</div>
                                                    </template>
                                                </template>
                                                <!-- Note -->
                                                <template v-if="cartItem.note">
                                                    <div class="text--secondary">Item note:</div>
                                                    <div class="text--secondary">{{cartItem.note}}</div>
                                                </template>
                                            </v-list-item-content>
                                        </v-list-item-content>
                                    <v-list-item-action class="my-0 py-0">
                                        <v-list-item-action-text >
                                            <span class="pr-3 body-2" style="color:black;">${{calculatePrice(cartItem)}}</span>
                                        </v-list-item-action-text>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <template v-else >
                                <div v-if="index===0" :key="'null-'+index" class="ml-2">Empty Bill</div>
                            </template>
                        </template>
                    </template>

                    <!-- Subtotal, tax and total -->
                    <v-list class="py-0" >
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Subtotal</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{subtotal}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- if Loyalty, loyalty list -->
                        <template v-if="vg_server_cart.loyalty_redeem_amount>0">
                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-for="(loyalty, ind) in vg_server_cart.cart_loyalty" :key="'loyal'+ind">
                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                    <v-list-item-title class="my-0 py-0 body-2">Point Used: {{loyalty.points_to_redeem}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                    <v-list-item-action-text style="color:black;">
                                        <span class="pr-1 mr-2 body-2" style="color:green;">-${{parseFloat(loyalty.redeem_amount).toFixed(2)}}</span>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <!-- Promo -->
                        <template v-if="vg_server_cart.promocode>0">
                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-for="(promo, ind) in vg_server_cart.cart_promocodes" :key="ind">
                                <v-list-item-icon class="mt-0 mr-2 py-0"></v-list-item-icon>
                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                    <v-list-item-title class="my-0 py-0 body-2">PROMO: {{promo.code}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                    <v-list-item-action-text style="color:black;">
                                        <span class="pr-1 mr-2 body-2" style="color:green;">-${{parseFloat(promo.applied_value).toFixed(2)}}</span>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                        <!-- delivery fee -->
                        <v-list-item v-if="vg_orderType=='delivery'" class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Delivery Fee</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{vg_deliveryFee.toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Tax</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{taxAmt}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Tip(s)</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{parseFloat(tipAmt).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0 title">Total</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 title" style="color:black;">${{parseFloat(total).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
            <version />
        </div>
        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                    <v-btn class="mb-2" block color="primary" outlined :disabled="vg_orderStatus!=='ready'" @click.prevent="getReceipt">Receipt</v-btn>
                </v-card-text>
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn class="left-btn" dark @click.prevent="back()">Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-icon v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="contact" class="right-btn" dark>Contact</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </v-container>
</template>

<script>
import { StringHelper } from '@/helpers/stringHelper'
import {GoogleHelper} from '@/helpers/googleHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {mapGetters, mapMutations} from 'vuex'
import moment from 'moment'
import Version from '@/components/custom-components/version'
export default {
    components: {
        Version
    },
    data(){
        return {
            loading: false,
            option: false,
            progress :10,
            deliveryStatus: {
                msg: '',
                text: '',
                time: ''
            },
            phone: '',
            storePhone: '',
            formattedAddress: '',

            subtotal: 0,
            redeemAmt: 0,
            promoAmt: 0,
            taxAmt: 0,
            tipAmt: 0,
            total: 0
        }
    },
    mounted() {
        this.spinner(false)

        if(!this.vg_server_cart.id){
            this.$router.push({name: 'menu'})
        }
        this.phone = StringHelper.standardPhoneFormat(this.vg_deliveryPhone)
        this.formattedAddress = GoogleHelper.getFormattedAddress(this.vg_deliveryAddress)
        this.vg_location.contact_details.forEach(obj => {
            if(obj.type==='phone' && obj.value){
                this.storePhone = StringHelper.standardPhoneFormat(obj.value)
            }
        })

        this.calculateSubTotal()

        this.calculatePromo()
        this.calculateLoyalty()
        this.calculateTax()
        this.calculateTips()
        this.calculateTotal()

        // check if payment is done
        if(this.vg_orderType===null){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug
            )
            this.$router.push(url)
            return
        }

        if(this.vg_pickupAt!=='now' && this.deliveryStatus==='preparing'){
            this.deliveryStatus.text = this.readableDate()
            this.deliveryStatus.time = this.getPickupTime(this.vg_pickupTime)
        }else{
            if(this.vg_orderType=='delivery'){
                this.deliveryStatus.msg = 'We have received your order and will confirm the delivery time'
            }else{
                this.deliveryStatus.msg = 'We have received your order and will confirm the pickup time'
            }
        }
    },
    watch: {
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_orderStatus(val){
            if(val==='completed'){
                this.$router.push({name: 'receipt', query: {payer: true}})
            }else if(val === 'cancelled'){
                this.$router.push({name: 'menu', query: {payer: true}})
            }
        },
        vg_orderMsg(){
            if(this.vg_orderStatus==='preparing'){
                this.deliveryStatus.msg = this.vg_orderMsg
            }else if(this.vg_orderStatus==='ready' || this.vg_orderStatus==='completed'){
                this.deliveryStatus.msg = ''
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_orderType: 'cart/orderType',
            vg_location: 'location/location',
            vg_businessName: 'location/businessName',
            vg_tableId: 'cart/tableId',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_server_cart: 'cart/serverCart',
            vg_orderStatus: 'order/orderStatus',
            vg_pickupAt: 'cart/pickupAt',
            vg_pickupDate: 'cart/pickupDate',
            vg_pickupTime: 'cart/pickupTime',
            vg_payed: 'payment/payed',
            vg_tips: 'cart/tipsData',
            vg_orderMsg: 'order/orderMsg',
            vg_deliveryPhone: 'delivery/phone',
            vg_deliveryAddress: 'delivery/address',
            vg_deliveryFee: 'delivery/deliveryFee',
        })
    },
    methods: {
        readableDate(){
            if(moment(this.vg_pickupDate, 'YYYY-MM-DD').format('DD')===moment().format('DD'))
                return 'Today'
            else if(moment(this.vg_pickupDate, 'YYYY-MM-DD').format('DD')===moment().add(1, 'd').format('DD'))
                return 'Tomorrow'
            else
                return 'Later'
        },
        getPickupTime(time){
            return moment(time, ["hh:mm a"]).format("HH:mm")
        },
        progressName(value){
            return value.charAt(0).toUpperCase() + value.replaceAll('_', ' ').slice(1)
        },
        progressStatus(status){
            if(status==='preparing'){
                return 50
            }else if(status==='out_for_delivery'){
                return 70
            }else if( status==='ready' || status==='completed' || status === 'cancelled'){
                return 100
            }else{
                return 10
            }
        },
        back(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        moreOption(){
            this.option = !this.option
        },
        contact(){
            this.$router.push({name: 'contact'})
        },
        getUserNameOrPhone(index, users){
            let selectedUserObj = users.filter((user)=>{
                return user.user_id===index
            })
            if(selectedUserObj && selectedUserObj[0])
                return StringHelper.capitalize(selectedUserObj[0].name)
        },
        // isPending true for send_to_kitchen=false
        sortServerCart(serverCart, isPending){
            return ServerCart.sortCartItem(serverCart, isPending)
        },
        calculatePrice(item){
            return ServerCart.calculatePrice(item)
        },
        calculateSubTotal(){
            if(this.vg_server_cart.subtotal){
                let subtotal = this.vg_server_cart.subtotal.toFixed(2)
                this.subtotal = subtotal
            }
        },
        calculatePromo(){
            if(this.vg_server_cart.promocode){
                this.promoAmt = this.vg_server_cart.promocode.toFixed(2)
            }
        },
        calculateLoyalty(){
            if(this.vg_server_cart.loyalty_redeem_amount){
                this.redeemAmt = this.vg_server_cart.loyalty_redeem_amount.toFixed(2)
            }
        },
        calculateTax(){
            if(this.vg_server_cart.tax){
                let tax = this.vg_server_cart.tax.toFixed(2)
                this.taxAmt = tax
            }else{
                this.taxAmt = '0.00'
            }
        },
        calculateTips(){
            if(this.vg_tips.tip){
                this.tipAmt = this.vg_tips.tip.toFixed(2)
            }
        },
        calculateTotal(){
            if(this.vg_orderType=='delivery'){
                this.total = (parseFloat(this.subtotal)+parseFloat(this.vg_deliveryFee)-parseFloat(this.promoAmt)-parseFloat(this.redeemAmt)+parseFloat(this.taxAmt)+parseFloat(this.tipAmt)).toFixed(2)
            }else{
                this.total = (parseFloat(this.subtotal)-parseFloat(this.promoAmt)-parseFloat(this.redeemAmt)+parseFloat(this.taxAmt)+parseFloat(this.tipAmt)).toFixed(2)
            }
        },
        getReceipt(){
            this.vm_setOrderType(null)
            this.vm_confirmDeliveryPickup(true)
            this.vm_orderDeliveryPickup(false)
            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_resetDelivery()
            this.$router.push({name: 'receipt', query: {payer: true}})
        },
        ...mapMutations({
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
            vm_orderDeliveryPickup: 'order/SET_ORDER_PICKUP_DELIVERY',
            vm_resetCart: 'cart/RESET',
            vm_resetCartUser: 'cartUser/RESET',
            vm_resetDelivery: 'delivery/RESET'
        })
    }
}
</script>