import axios from "../_client";
import {LOCATION_URL, LOCATION_API_TOKEN} from '@/clients/env'

const stripeAxiosInstaceV1 = axios.create({
  headers: {
    "Api-Token": LOCATION_API_TOKEN,
  },
});
export const CloverAPI = {
  getToken(locationId = "") {
    let url = `${LOCATION_URL}/integrators/locations/${locationId}/clover/apiKey`
    return stripeAxiosInstaceV1({
      method: "get",
      url: url,
    });
  },
};
