export default {
    namespaced: true,
    state: {
      snackbar: []
    },
    getters: {
      snackbar: state => state.snackbar
    },
    mutations : {
      replace(state, data){
        state.snackbar = data
      },
      snackbar (state, { text, open, timeout, color, x, y, icon }) {
        if(state.snackbar.length>0 && state.snackbar[state.snackbar.length-1].text===text){
          state.snackbar[state.snackbar.length-1].open = true
        }else{
          let obj = {
            open: open,
            text: text,
            timeout: (timeout !== undefined)?timeout:0,
            x: x?x:null,
            y: y?y:null,
            color: color?color:null,
            icon: icon?icon:null
          }
          state.snackbar.push(obj)
        }
      }
    }
  }
