<template>
    <div>
    </div>
</template>
<script>
import { StringHelper } from '@/helpers/stringHelper'
import { PaymentAPI } from '@/clients/payment'
import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return {
        }
    },
    async mounted(){
        if(this.$router.currentRoute.query.RESPONSETEXT=='APPROVED'){   
            await this.createPayment()
            await this.getPayments()
        }
    },
    computed: {
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_businessId: 'location/business_id',
            vg_location: 'location/location'
        })
    },
    methods: {
        async getPayments(){
            try{
                let {data} = await PaymentAPI.getPayment(this.vg_businessId, this.vg_loginUser.user.id, this.vg_location)
                this.vm_payments(data.payload)
            }catch(err){
                // this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
            }
        },
        async createPayment(){
            try{
                this.vm_fiskaPopup(false)
                let formattedNumber = StringHelper.formatPhone(StringHelper.getMyEmailOrPhone('phone'))
                let data = {
                    "business_uuid": this.vg_businessId,
                    "location_id": this.vg_location.id,
                    "user_id": this.vg_loginUser.user.id,
                    "phone_number": formattedNumber,
                    "merchant_ref_id": this.$router.currentRoute.query.MERCHANTREF,
                    "card_brand": this.$router.currentRoute.query.CARDTYPE, 
                    "last_four" : this.$router.currentRoute.query.MASKEDCARDNUMBER.slice(-4),
                    "convert_token": true,
                    "payment_token": this.$router.currentRoute.query.CARDREFERENCE,

                }
                await PaymentAPI.addPayment(data);
                this.vm_snackbar({open: true, text: 'Credit card added successfully', color: 'success', timeout: 3000});
            }catch(err){
                if(err.response && err.response.data && err.response.data.msg){
                    this.vm_snackbar({open: true, text: err.response.data.msg, color: 'error', timeout: 3000});
                }
            }
        },
        ...mapMutations({
            vm_fiskaPopup: 'payment/SET_FISKA_POPUP',
            vm_snackbar: 'Snackbar/snackbar',
            vm_payments: 'payment/SET_PAYMENTS'
        })
    }
}
</script>
