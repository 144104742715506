<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-form ref="form" v-model="valid" @submit.prevent="updateItemValue">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <span>Input Percentage</span>
                        <v-spacer />
                        <v-btn fab dark x-small @click.prevent="closeEditPercentage">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="form.percentage"
                            :rules="rules.editPercetage"
                            label="I'll Pay"
                            hint="Input a percentage you want to pay for the bill"
                            required
                            outlined
                            maxlength="4"
                            @keyup.prevent="setPercentage"
                            ></v-text-field>
                        
                        <v-row>
                            <v-col ><span class="font-weight-bold primary--text">Item Price</span></v-col>
                            <v-col>${{price}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col><span class="font-weight-bold primary--text">You will pay</span></v-col>
                            <v-col>${{value}}</v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            type="submit"
                            color="primary"
                            block
                            >
                            Ok
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
export default ({
    props: ['price', 'partialAmount'],
    data(){
        return {
            dialog: true,
            valid: false,
            form: {
                percentage: '100%'
            },
            rules: {
                editPercetage: [v => !!v || 'Please use a valid percentage']
            },
            value: ''
        }
    },
    mounted() {
        this.calculateValue()
        this.calculatePercentage()
    },
    methods: {
        setPercentage(){
            this.form.percentage = StringHelper.formatPercentage(this.form.percentage)
            this.calculateValue()
        },
        calculateValue(){
            if(this.form.percentage){
                let percentValue = StringHelper.stripPercentSign(this.form.percentage)
                this.value = (percentValue/100*this.price).toFixed(2)
            }
        },
        calculatePercentage(){
            this.form.percentage = parseFloat(this.partialAmount/this.price*100).toFixed(0)+'%'
        },
        updateItemValue(){
            this.$emit('updatePercentage', this.value)
        },
        closeEditPercentage(){
            this.$emit('closeEditPercentage')
        }
    }
})
</script>
