import {UserAPI} from '@/clients/user/'
export default {
    namespaced: true,
    state: {
      status: false,
      verifyData: {},
      verify: false,
      user: {},
      loginPop: false,
      phoneEmailPop: false,
      loginWith: ''
    },
    getters: {
        status: state => state.status,
        verifyData: state => state.verifyData,
        verify: state => state.verify,
        user: state => state.user,
        loginPop: state => state.loginPop,
        phoneEmailPop: state => state.phoneEmailPop,
        loginWith: state => state.loginWith
    },
    mutations: {
        SET_STATUS(state, status) {
            state.status = status
        },
        SET_VERIFY_DATA(state, data){
            state.verifyData = data
        },
        SET_VERIFY(state, data){
            state.verify = data
        },
        SET_USER(state, data){
            state.user = data
        },
        SET_LOGIN_POP(state, data){
            state.loginPop = data
        },
        SET_PHONE_EMAIL_POP(state, data){
            state.phoneEmailPop = data
        },
        SET_LOGIN_WITH(state, data){
            state.loginWith = data
        },
        RESET_USER(state){
            state.user = {}
            state.verify = false
            state.verifyData = {}
            state.loginWith = ''
        }
    },
    actions: {
        async setLogin({commit}, loginData) {
            if(isNaN(parseInt(loginData.phone_number_email))){
                let emailData = {
                    email: loginData.phone_number_email
                }
                const {data} = await UserAPI.email(emailData)
                data.email = loginData.phone_number_email
                commit('SET_VERIFY_DATA', data)
            }else{
                let phoneData = {
                    phone_number: loginData.phone_number_email
                }
                const {data} = await UserAPI.login(phoneData)
                data.number = loginData.phone_number_email
                commit('SET_VERIFY_DATA', data)
            }
            
        },
        async setSocialLogin({commit}, loginData){
            const {data} = await UserAPI.loginSocial(loginData)
            commit('SET_USER', data)
        },
        async setVerify({commit}, verifyData){
            const {data} = await UserAPI.verifyAuthToken(verifyData)
            commit('SET_USER', data)

        }
    }
}