<template>
    <div class="mx-5 mb-2">
        <div class="title" v-if="!isLoyalty">{{vg_business.name}}</div>
        <div v-if="isLoyalty && !loading">
            <div class="font-weight-bold"><span style="color:green;">{{redeem.points.toLocaleString()}}</span> points</div>
            <v-form class="redeemForm" ref="redeemForm" @submit.prevent="addRedeem" style="width:100%">
                <v-text-field :disabled="!isRedeemAvailable || vg_disablePayBtn" :loading="loadingPreview" v-model.number="points" small outlined dense label="Input Amount" @keypress="isNumber">
                    <v-btn :disabled="!points" slot="append" class="font-weight-bold pb-2" style="cursor:pointer;" plain text elevation="0" type="submit">APPLY</v-btn>
                </v-text-field>
            </v-form>
            <div v-if="Object.keys(redeemable).length>0">
                <div v-if="isRedeemAvailable">
                    <div v-if="parseInt(redeem.points)>=parseInt(redeemable.points_redeemable)" class="font-weight-bold">You can redeem up to <span style="color:green;">{{redeemable.points_redeemable.toLocaleString()}}</span> points for this purchase.</div>
                    <div v-else class="font-weight-bold">You can redeem up to <span style="color:green;">{{redeem.points.toLocaleString()}}</span> points for this purchase.</div>
                </div>
                <div v-else class="font-weight-bold">Your cart doesn't have any items you can use points for.</div>
            </div>
        </div>
        <div v-else-if="!loading">
            <div v-if="!isLoyalty && redeemable && redeemable.points_earned">You are moments away from earning <b style="color:green;">{{getEarnablePoints(redeemable.points_earned).toLocaleString()}} points</b> on this purchase, all you have to do is opt-in for <b>{{vg_business.name}}</b> program bellow.</div>
            <div>
                <v-checkbox v-model="agree">
                    <template v-slot:label>
                    <div>I agree to the <u @click.stop.prevent="terms()">Terms &amp; Conditions</u> and <u @click.stop.prevent="privacy()">Privacy Policy</u> for <b>{{vg_programName}}</b> loyalty program.</div>
                    </template>
                </v-checkbox>
            </div>
        </div>
    </div>
</template>
<script>
import {UserAPI} from '@/clients/user'
import {CartAPI} from '@/clients/cart'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
export default {
    data(){
        return {
            loading: false,
            isLoyalty: false,
            agree: false,
            points: null,
            redeem: {},
            isRedeemAvailable: false,
            redeemable: {},
            loadingPreview: false 
        }
    },
    async mounted(){
        this.loading = true
        await this.checkLoyalty()
        this.loadingPreview = true
        this.previewLoyalty()
        this.loading = false
    },
    watch: {
        agree(val){
            if(val){
                this.subscribe()
            }
        },
        // remove redeem if set value in local storage (watch in loyalty/removeId)
        vg_redeemRemoveId(val){
            if(val){
                this.removeRedeem()
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_cartId: 'cart/cart_id',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_loginUser: 'login/user',
            vg_businessId: 'location/business_id',
            vg_redeemRemoveId: 'loyalty/removeId',
            vg_business: 'location/business',
            vg_selectedPaymentType:'payment/selectedPaymentType',
            vg_programName: 'loyalty/programName',
            vg_loyaltyData: 'loyalty/loyaltyData',
            vg_disablePayBtn: 'payment/disablePayBtn',
        })
    },
    methods: {
        getEarnablePoints(orderPoints){
            let points = parseInt(orderPoints)
            if(this.vg_loyaltyData.actions && this.vg_loyaltyData.actions[0] && this.vg_loyaltyData.actions[0].earn_value){
                points += parseInt(this.vg_loyaltyData.actions[0].earn_value)
            }
            return points
        },
        isNumber(e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        async checkLoyalty(){
            try{
                let {data} = await UserAPI.checkLoyalty()
                this.isLoyalty = true
                this.redeem = data
            }catch(err){
                this.redeem = {}
                this.isLoyalty = false
                this.loadingPreview = true
                this.previewLoyalty()
            }
        },
        previewLoyalty: _.debounce(async function(){
            try{
                let previewData = {
                    user_id: this.vg_loginUser.user.id,
                }
                if(this.vg_selectedPaymentType==='splitEvenly'){
                    previewData.apply_loyalty_on = 'split_evenly'
                }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                    previewData.apply_loyalty_on = 'user_items'
                }else{
                    previewData.apply_loyalty_on = 'cart'
                }
                let {data} = await CartAPI.previewLoyalty(previewData)
                this.redeemable = data.payload
                if(this.redeemable.points_redeemable){
                    this.isRedeemAvailable = true
                }else{
                    this.isRedeemAvailable = false
                }
                this.loadingPreview = false
            }catch(err){
                this.redeemable = {}
                this.isRedeemAvailable = false
                this.loadingPreview = false
            }
        }, 3000),
        async subscribe(){
            try{
                if(this.agree){
                    this.spinner(true)
                    let data = {
                        business_id: this.vg_businessId,
                        user_id: this.vg_loginUser.user.id
                    }
                    await UserAPI.registerLoyalty(data)
                    this.snackbar({open: true, text: 'Loyalty added successfully.', color: 'success'})
                    await this.checkLoyalty()
                    this.loadingPreview = true
                    await this.previewLoyalty()
                    this.spinner(false)
                }
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
            }
        },
        async addRedeem(){
            try{
                if(parseInt(this.redeem.points)>=parseInt(this.redeemable.points_redeemable) && this.points>this.redeemable.points_redeemable){
                    this.snackbar({open: true, text: 'You cannot redeem more than '+this.redeemable.points_redeemable+' points', color: 'error'})
                }else if(parseInt(this.redeem.points)<parseInt(this.redeemable.points_redeemable) && this.points>this.redeem.points){
                    this.snackbar({open: true, text: 'You cannot redeem more than '+this.redeem.points+' points', color: 'error'})
                }else{
                    if(this.points && this.points>0){
                        this.spinner(true)
                        let redeemData = {
                            user_id: this.vg_loginUser.user.id,
                            points_to_redeem: this.points
                        }
                        if(this.vg_selectedPaymentType==='splitEvenly'){
                            redeemData.apply_loyalty_on = 'split_evenly'
                        }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                            redeemData.apply_loyalty_on = 'user_items'
                        }else{
                            redeemData.apply_loyalty_on = 'cart'
                        }
                        let {data} = await CartAPI.addRedeem(redeemData)
                        this.$refs.redeemForm.reset()
                        this.loadingPreview = true
                        this.previewLoyalty()
                        this.snackbar({open: true,text:data.msg, color: 'success'});
                        this.spinner(false)
                    }
                }
                
            }catch(err){
                if(err.response.status!==418){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.spinner(false)
                }
            }
        },
        async removeRedeem(){
            try{
                this.spinner(true)
                let response = await CartAPI.removeLoyalty(this.vg_redeemRemoveId)
                this.vm_redeemRemoveId(null)
                this.loadingPreview = true
                await this.previewLoyalty()
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.spinner(false)
            }catch(err){
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                this.spinner(false)
            }
        },
        terms(){
            this.vm_termsConditionStatus(true)
        },
        privacy(){
            this.vm_privacyPolicyStatus(true)
        },
        ...mapMutations({
            vm_redeemRemoveId: 'loyalty/SET_REMOVE_ID',
            vm_termsConditionStatus: 'terms/SET_STATUS',
            vm_privacyPolicyStatus: 'privacy/SET_STATUS'
        }),
        ...mapActions({
            va_reloadCart: 'cart/reloadServerCart'
        })
    }
}
</script>