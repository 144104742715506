export const Cart = {
   /*
    *   @params
    *   category    - category object to add to cart
    *   cart        - cart in which category to be added
    * 
    *   @ return - cart with added category
    */
    addCategory(category, cart){
        // check if category exist
        let result = cart.some((eachCategory) => {
            return eachCategory.id===category.id
        })
        // add category if not exist
        if(!result){
            cart.push({id: category.id, name: category.name, items: []})
            return cart
        }
        return null;
    },

    getCategoryFromItem(categories, item){
        let category = null
        for(const catIndex in categories){
            let itemIndex = categories[catIndex].items.findIndex(eachItem =>  eachItem.id===item.id)
            if(itemIndex>=0){
                category =  categories[catIndex]
            }
        }
        return category
    },

    /*
    *   @params
    *   item        - get item with min required fields
    * 
    *   @ return - item object with required field
    */
    getDataItem(item){
        let itemObj = {
            id: item.id,
            item_order: item.order,
            item_sku: item.sku,
            name: item.name,
            slug: item.slug,
            image: item.image,
            price: item.price,
            qty: 1,
            note: '',
            course: '',
            cart_item_modifiers: []
        }

        for(const modifierIndex in item.modifiers){
            if(item.modifiers[modifierIndex].status && this.isSomeModifierItemEnabled(item.modifiers[modifierIndex])){
                itemObj.cart_item_modifiers.push({
                    id: item.modifiers[modifierIndex].id,
                    modifier_id: item.modifiers[modifierIndex].id,
                    name: item.modifiers[modifierIndex].name,
                    sku: item.modifiers[modifierIndex].sku,
                    order: item.modifiers[modifierIndex].order,
                    min: item.modifiers[modifierIndex].min,
                    max: item.modifiers[modifierIndex].max,
                    included: item.modifiers[modifierIndex].included,
                    base_price: item.modifiers[modifierIndex].base_price,
                    status: item.modifiers[modifierIndex].status,
                    cart_item_modifier_items: [],
                    note: null
                })

                const modifierItems = item.modifiers[modifierIndex].modifier_items
                let latestObjIndex = itemObj.cart_item_modifiers.length-1
                let modifierMin = item.modifiers[modifierIndex].min
                let modifierMax = item.modifiers[modifierIndex].max
                let tempRadioChecked = false
                let modifierItemIncluded = item.modifiers[modifierIndex].included
                for(const modifierItemIndex in modifierItems){
                    // get last itemObj object
                    if(modifierItems[modifierItemIndex].status){
                        let tempQty = 0
                        let tempIncluded = 0
                        if(modifierMin===1 && modifierMax===1){
                            // radio button
                            if(!tempRadioChecked){
                                tempQty = 1
                                tempRadioChecked = true
                            }
                        }else{
                            // other
                            tempQty = modifierItems[modifierItemIndex].min
                        }

                        // add only item with qty
                        if(tempQty>0){
                            if(modifierItemIncluded>0){
                                if(modifierItemIncluded>tempQty){
                                    tempIncluded = tempQty
                                    modifierItemIncluded -= tempQty
                                }else{
                                    tempIncluded = modifierItemIncluded
                                    modifierItemIncluded = 0
                                }
                                
                            }
                            itemObj.cart_item_modifiers[latestObjIndex].cart_item_modifier_items.push({
                                id: modifierItems[modifierItemIndex].id,
                                modifier_item_id: modifierItems[modifierItemIndex].id, 
                                name: modifierItems[modifierItemIndex].name,
                                sku: modifierItems[modifierItemIndex].sku,
                                order: modifierItems[modifierItemIndex].order,
                                included: tempIncluded,                                     
                                min: modifierItems[modifierItemIndex].min,
                                max: modifierItems[modifierItemIndex].max,
                                qty: tempQty,                                    
                                price: modifierItems[modifierItemIndex].price
                            })
                        }
                    }
                }
            }
        }
        return itemObj
    },

    // edit, api
    getEditData(item, apiItem){
        let itemObj = {
            id: item.item_id.toString(),
            item_order: apiItem.order,
            item_sku: apiItem.sku,
            name: apiItem.name,
            slug: apiItem.slug,
            price: apiItem.price,
            qty: item.item_qty,
            note: item.note,
            course: item.course,
            cart_item_modifiers: []
        }
        if(item.cart_item_modifiers.length>0){
            for(const modifierIndex in item.cart_item_modifiers){

                const modifierItems = item.cart_item_modifiers[modifierIndex].cart_item_modifier_items
                let included = item.cart_item_modifiers[modifierIndex].included
                let tempCartItemModifierItems = []
                for(const modifierItemIndex in modifierItems){
                    included = included-modifierItems[modifierItemIndex].qty
                    tempCartItemModifierItems.push(
                        {
                            id: modifierItems[modifierItemIndex].modifier_item_id.toString(),  
                            modifier_item_id: modifierItems[modifierItemIndex].modifier_item_id.toString(), 
                            name: modifierItems[modifierItemIndex].name,
                            sku: modifierItems[modifierItemIndex].sku,
                            order: modifierItems[modifierItemIndex].order,
                            min: modifierItems[modifierItemIndex].min,
                            max: modifierItems[modifierItemIndex].max,
                            qty: modifierItems[modifierItemIndex].qty,
                            price: modifierItems[modifierItemIndex].price,
                            included: included>=0?modifierItems[modifierItemIndex].qty:0
                        }
                    )
                }
                itemObj.cart_item_modifiers.push({
                    temp_id: item.cart_item_modifiers[modifierIndex].id.toString(),
                    id: item.cart_item_modifiers[modifierIndex].modifier_id.toString(),
                    modifier_id: item.cart_item_modifiers[modifierIndex].modifier_id.toString(),
                    name: item.cart_item_modifiers[modifierIndex].name,
                    sku: item.cart_item_modifiers[modifierIndex].sku,
                    order: item.cart_item_modifiers[modifierIndex].order,
                    min: item.cart_item_modifiers[modifierIndex].min,
                    max: item.cart_item_modifiers[modifierIndex].max,
                    included: item.cart_item_modifiers[modifierIndex].included,
                    base_price: item.cart_item_modifiers[modifierIndex].base_price,
                    status: item.cart_item_modifiers[modifierIndex].status,
                    cart_item_modifier_items: tempCartItemModifierItems
                })
            }
        }
        // add missing modifier items
        for(const modifierIndex in apiItem.cart_item_modifiers){
            let modifierInd = itemObj.cart_item_modifiers.findIndex(each => each.id==apiItem.cart_item_modifiers[modifierIndex].id)
            if(modifierInd<0){
                itemObj.cart_item_modifiers.push({
                    temp_id: apiItem.cart_item_modifiers[modifierIndex].id.toString(),
                    id: apiItem.cart_item_modifiers[modifierIndex].modifier_id.toString(),
                    modifier_id: apiItem.cart_item_modifiers[modifierIndex].modifier_id.toString(),
                    name: apiItem.cart_item_modifiers[modifierIndex].name,
                    sku: apiItem.cart_item_modifiers[modifierIndex].sku,
                    order: apiItem.cart_item_modifiers[modifierIndex].order,
                    min: apiItem.cart_item_modifiers[modifierIndex].min,
                    max: apiItem.cart_item_modifiers[modifierIndex].max,
                    included: apiItem.cart_item_modifiers[modifierIndex].included,
                    base_price: apiItem.cart_item_modifiers[modifierIndex].base_price,
                    status: apiItem.cart_item_modifiers[modifierIndex].status,
                    cart_item_modifier_items: []
                })
            }
        }
        return itemObj
    },

    // change temp_id to id for update api
    getForApiEditData(item){
        for(const modifierIndex in item.cart_item_modifiers){
            item.cart_item_modifiers[modifierIndex].id = item.cart_item_modifiers[modifierIndex].temp_id
            for(const modifierItemIndex in item.cart_item_modifiers[modifierIndex].cart_item_modifier_items){
                delete item.cart_item_modifiers[modifierIndex].cart_item_modifier_items[modifierItemIndex].id
            }
        }
    },


    /*
    *   @params
    *   category    - category object from which item to be extracted
    *   item        - item which need to be extract from store
    *   cart        - cart from which item to be extract
    * 
    *   @ return - item only
    */
    getStoreItem(category, item, cart){
        // find category index
        let catIndex = cart.findIndex(eachCategory =>  eachCategory.id===category.id)
        if(catIndex<0) return

        let itemIndex = cart[catIndex].items.findIndex(eachItem => eachItem.id===item.id)
        if(itemIndex>=0){
            return cart[catIndex].items[itemIndex]
        }
    },

    setItem(qty, note, course, item){
        item.qty = qty
        item.note = note
        item.course = course
        item.base_price = this.calculatePrice(item)
        return item
    },

    stringifyModifierWithModifierItemIdQty(item){
        let Modifiers = []
        Modifiers = item.cart_item_modifiers.map(eachModifier => {
            let modifierItem = eachModifier.cart_item_modifier_items.map(eachModifierItem => {
                return {modifier_item_id:Number(eachModifierItem['modifier_item_id']), qty: eachModifierItem['qty']}
            })
            modifierItem.sort((a,b) => (a.modifier_item_id > b.modifier_item_id) ? 1 : ((b.modifier_item_id > a.modifier_item_id) ? -1 : 0))
            return {modifier_id: Number(eachModifier.modifier_id), cart_item_modifier_items: modifierItem}
        })
        Modifiers.sort((a,b) => (a.modifier_id > b.modifier_id) ? 1 : ((b.modifier_id > a.modifier_id) ? -1 : 0))
        return JSON.stringify(Modifiers)
    },

    isItemWithSameModifierExists(catIndex, item, cart){
        let result = {match: false, cartItemIndex: null}
        for(let itemIndex in cart[catIndex].items){
            if(Number(cart[catIndex].items[itemIndex].id)===Number(item.id) && cart[catIndex].items[itemIndex].course===item.course && cart[catIndex].items[itemIndex].note===item.note){
                if(this.stringifyModifierWithModifierItemIdQty(cart[catIndex].items[itemIndex])===this.stringifyModifierWithModifierItemIdQty(item)){
                    result = {
                        match: true, 
                        cartItemIndex: itemIndex,
                    }
                }
            }
        }
        return result
    },

   /*
    *   @params
    *   category    - parent category of item
    *   item        - item object to add to cart
    *   cart        - cart in which category to be added
    * 
    *   @ return - cart with added category
    */
    addItem(category, item, cart){
        // find category index
        let catIndex = cart.findIndex(eachCategory =>  eachCategory.id===category.id)
        if(catIndex<0) return
        let {match, cartItemIndex} = this.isItemWithSameModifierExists(catIndex, item, cart)
        if(match){
            cart[catIndex].items[cartItemIndex].qty = cart[catIndex].items[cartItemIndex].qty + item.qty
        }else{
            cart[catIndex].items.push(item)
        }

        return cart
    },

    // check if item exist in cart
    itemExists(category, item, cart){
        let catIndex = cart.findIndex(eachCategory =>  eachCategory.id==category.id)
        if(catIndex<0) return

        let itemIndex = cart[catIndex].items.findIndex(eachItem =>  eachItem.id===item.id)
        return itemIndex >= 0;
    },

    // check if cart don't have items
    isCartEmpty(cart){
        return cart.every(category => {
            return category.items.length===0
        })
    },

    // count cart items
    countCartItems(cart){
        let number = 0
        cart.forEach(category => {
            number = number + category.items.length
        })
        return number
    },

    countCartItemQty(cart){
        let count = 0
        cart.forEach(category => {
            category.items.forEach(item => {
                count = count + item.qty
            })
        })
        return count
    },

    countModifierItemsQty(modifierItems){
        let total = 0
        modifierItems.forEach(modifierItem => {
            total+=modifierItem.qty
        })
        return total
    },

    // calculate item total price with according to modifier item and qty
    calculatePrice(item){
        if(!item || !item.price) return (0).toFixed(2)

        let total = this.calculateItemPrice(item)
        return (total*Number(item.qty)).toFixed(2)
    },

    // calculate item price with according to modifier item
    calculateItemPrice(item){
        if(!item || !item.id) return
        let total = Number(item.price)

        item.cart_item_modifiers.forEach(modifier => {
            let totalQty = this.countModifierItemsQty(modifier.cart_item_modifier_items)
            if(totalQty>0){
                total += modifier.base_price
                if(modifier.included>0){
                    let modifierItems = [...modifier.cart_item_modifier_items]  
                    let included =  modifier.included
                    //let sortedModifierItems = modifierItems.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
                    for(let i=0; i<modifierItems.length; i++){
                        let modifierItem = modifierItems[i]
                        if(modifierItem.qty){
                            if(modifier.included>0){
                                included = included - modifierItem.qty
                                if(included<0){
                                    total = total + modifierItem.qty*modifierItem.price
                                }
                            }else{
                                total = total + modifierItem.qty*modifierItem.price
                            }
                        }
                    }
                }else{
                    // modifier with different values
                    let totalModifier = 0
                    modifier.cart_item_modifier_items.forEach(modifierItem => {
                        totalModifier += modifierItem.qty*modifierItem.price
                    })
                    total = total + totalModifier
                }
            }
        })
        return total.toFixed(2)
    },
    clearNullQtyItem(item){
        let cart_item_modifiers = []
        for(let i in item.cart_item_modifiers){
            let cart_item_modifier_items = []
            for(let j in item.cart_item_modifiers[i].cart_item_modifier_items){
                if(item.cart_item_modifiers[i].cart_item_modifier_items[j].qty!==0){
                    cart_item_modifier_items.push(item.cart_item_modifiers[i].cart_item_modifier_items[j])
                }
            }
            if(cart_item_modifier_items.length>0){
                cart_item_modifiers.push({
                    base_price: item.cart_item_modifiers[i].base_price,
                    cart_item_modifier_items: cart_item_modifier_items,
                    id: item.cart_item_modifiers[i].id,
                    included: item.cart_item_modifiers[i].included,
                    max: item.cart_item_modifiers[i].max,
                    min: item.cart_item_modifiers[i].min,
                    modifier_id: item.cart_item_modifiers[i].modifier_id,
                    name: item.cart_item_modifiers[i].name,
                    order: item.cart_item_modifiers[i].order,
                    sku: item.cart_item_modifiers[i].sku
                })
            }
        }
        return {
            cart_item_modifiers: cart_item_modifiers,
            id: item.id,
            item_order: item.item_order,
            item_sku: item.item_sku,
            name: item.name,
            slug: item.slug,
            note: item.note,
            price: item.price,
            qty: item.qty
        }
    },

    // let list of options preselected based on included in array [1,2,3] => modifier items ids
    getPreSelectedModifierItemIds(modifier, item){
        let modifierIndex = item.cart_item_modifiers.findIndex(eachModifier => eachModifier.id===modifier.id)
        if(modifierIndex>=0){
            let modifierItemIds =  []
            item.cart_item_modifiers[modifierIndex].cart_item_modifier_items.forEach(modifierItem => {
                modifierItemIds.push(modifierItem.id)
            })
            return modifierItemIds
        }
        return []
    },

    // get list of preselected item base on included
    getPreSelectedModifierItem(modifier, modifierItem, item){ 
        let modifierIndex = item.cart_item_modifiers.findIndex(eachModifier => eachModifier.id===modifier.id)
        if(modifierIndex>=0){
            let modifierItemIndex = item.cart_item_modifiers[modifierIndex].cart_item_modifier_items.findIndex(eachModifierItem => eachModifierItem.id===modifierItem.id)
            if(modifierItemIndex>=0){
                let modifierItem = item.cart_item_modifiers[modifierIndex].cart_item_modifier_items[modifierItemIndex]
                return {id: modifierItem.id, qty: modifierItem.qty?modifierItem.qty:0, included: modifierItem.included}
            }
            return {id: modifierItem.id, qty: 0, included: 0}
        }
        return null
    },

    // get list of preselected items base on included
    getPreSelectedModifierItems(modifier, item){ 
        let modifierIndex = item.cart_item_modifiers.findIndex(eachModifier => eachModifier.id===modifier.id)
        if(modifierIndex>=0){
            let modifiers = []
            item.cart_item_modifiers[modifierIndex].cart_item_modifier_items.forEach(modifier_item => {
                modifiers.push({id: modifier_item.id, qty: modifier_item.qty?modifier_item.qty:0, included: 0})
            })
            return modifiers
        }
        return null
    },

    // append, remove or add checkbox or qty item in array
    itemOptionChanged(items, item, status, haveIncluded){
        let findIndex = items.findIndex(eachItem => eachItem.id===item.id)
        if(findIndex>=0){
            if(item.qty===0){
                // remove item from items
                items.splice(findIndex, 1)

                // add include value if previous contain included
                if(haveIncluded){
                    items = this.addIncludedValueToNextModifierItem(items)
                }
            }else if(status=='subtract'){
                // subtract qty value
                if(haveIncluded){
                    items = this.addIncludedValueToNextModifierItem(items)
                }else{
                    items[findIndex].qty = item.qty
                }
            }else{
                // add qty value
                items[findIndex].qty = item.qty
                items[findIndex].included = item.included
            }
        }else{
            // add new
            items.push(item)
        }
        return items
    },

    addIncludedValueToNextModifierItem(items){
        for(let i=0; i<items.length; i++){
            if(items[i].qty>items[i].included){
                items[i].included+=1
                break;
            }
        }
        return items
    },

    sumOfTotalModifierIncluded(items){
        let total = 0
        if(items && items.length>0){
            items.forEach(item => {
                console.log(item.included)
                total += item.included
            })
        }
        return total
    },

    // add or edit modifier items for checkbox and radio
    changeModifierItem(modifierItemIds, modifier, item){
        let modifierIndex = item.cart_item_modifiers.findIndex(eachModifier => eachModifier.id===modifier.id)
        if(modifierIndex>=0){
            item.cart_item_modifiers[modifierIndex].cart_item_modifier_items = []
            modifierItemIds.forEach(modifierItemId => {
                let modifierItemIndex = modifier.modifier_items.findIndex(eachModifierItem => eachModifierItem.id===modifierItemId)
                item.cart_item_modifiers[modifierIndex].cart_item_modifier_items.push({
                    id: modifierItemId,
                    modifier_item_id: modifierItemId,
                    name: modifier.modifier_items[modifierItemIndex].name,
                    sku: modifier.modifier_items[modifierItemIndex].sku,
                    order: modifier.modifier_items[modifierItemIndex].order,
                    included: modifier.included>0?1:0,                                                    // needed for api
                    qty: 1,
                    min: modifier.modifier_items[modifierItemIndex].min,
                    max: modifier.modifier_items[modifierItemIndex].max,
                    price: modifier.modifier_items[modifierItemIndex].price
                })
            })
            return item
        }
        return item
    },

    // add or edit modifier items for qty select box
    changeModifierItemHavingQty(modifierItems, modifier, item){
        let modifierIndex = item.cart_item_modifiers.findIndex(eachModifier => eachModifier.id===modifier.id)
        if(modifierIndex>=0){
            item.cart_item_modifiers[modifierIndex].cart_item_modifier_items = []
            modifierItems.forEach(modifierItem => {
                let modifierItemIndex = modifier.modifier_items.findIndex(eachModifierItem => eachModifierItem.id===modifierItem.id)
                item.cart_item_modifiers[modifierIndex].cart_item_modifier_items.push({
                    id: modifierItem.id,
                    modifier_item_id: modifierItem.id,
                    name: modifier.modifier_items[modifierItemIndex].name,
                    sku: modifier.modifier_items[modifierItemIndex].sku,
                    order: modifier.modifier_items[modifierItemIndex].order,
                    included: modifierItem.included,                                                    // needed for api
                    qty: modifierItem.qty,
                    min: modifier.modifier_items[modifierItemIndex].min,
                    max: modifier.modifier_items[modifierItemIndex].max,
                    price: modifier.modifier_items[modifierItemIndex].price
                })
            })
            return item
        }
        return item
    },

    //check if modifier contain min modifier item and don't exceed max
    isSelectedModifierItemsValid(item){
        if(!item || !item.cart_item_modifiers) return

        return item.cart_item_modifiers.every(modifier => {
            // return true if status is disabled
            if(!modifier.status) return true

            if(modifier.min===0 && modifier.max===0){
                return true
            }else if(modifier.min===1 && modifier.max===1){
                return this.countModifierItemsQty(modifier.cart_item_modifier_items)===1
            }else if(modifier.min===0){
                return this.countModifierItemsQty(modifier.cart_item_modifier_items)<=modifier.max
            }else if(modifier.max===0){
                return this.countModifierItemsQty(modifier.cart_item_modifier_items)>=modifier.min
            }else{
                return this.countModifierItemsQty(modifier.cart_item_modifier_items)>=modifier.min && this.countModifierItemsQty(modifier.cart_item_modifier_items)<=modifier.max
            }
        })
    },

    // check at least one modifier item is enabled in modifiers
    isSomeModifierItemEnabled(modifier){
        let count = 0
            modifier.modifier_items.forEach(modifierItem => {
                if(modifierItem.status)
                    count++
            })
            return count>0
    },

    // remove item from cart
    removeItem(category, item, cart){
        let catIndex = cart.findIndex(eachCategory =>  eachCategory.id==category.id)
        if(catIndex<0) return
        const selectedItem =  cart[catIndex].items.filter(eachItem =>  {
            return !(eachItem.id==item.id && eachItem.course==item.course && eachItem.note==item.note)
        })
        cart[catIndex].items = selectedItem
        return cart
    },

    pastOrderToMenuItem(orderItem, menuItem){
        let modifiers = []
        menuItem.modifiers.forEach(modifier => {
            let orderModifier = orderItem.cart_item_modifiers.find(each => each.modifier_id==modifier.id)
            let modifierItems = []
            modifier.modifier_items.forEach(modifier_item => {
                let orderModifierItem = orderModifier?.cart_item_modifier_items.find(each => each.modifier_item_id==modifier_item.id)
                let tempModifierItems = {
                    id: modifier_item.id,
                    modifier_item_id: modifier_item.id,
                    max: modifier_item.max,
                    min: modifier_item.min,
                    name: modifier_item.name,
                    order: modifier_item.order,
                    price: modifier_item.price,
                    qty: (orderModifierItem?.qty)?orderModifierItem.qty:0,
                    sku: modifier_item.sku,
                }
                modifierItems.push(tempModifierItems)
            })

            let tempModifiers = {
                base_price: modifier.base_price,
                id: modifier.id,
                modifier_id: modifier.id,
                included: modifier.included,
                max: modifier.max,
                min: modifier.min,
                name: modifier.name,
                order: modifier.order,
                sku: modifier.sku,
                status: modifier.status,
                cart_item_modifier_items: modifierItems
            }
            modifiers.push(tempModifiers)
        })
        let item = {
            past_order: true,
            base_price: orderItem.base_price,
            course: orderItem.course,
            item_id: menuItem.id,
            image: menuItem.image,
            item_order: menuItem.order,
            item_sku: menuItem.sku,
            name: menuItem.name,
            slug: menuItem.slug,
            note: orderItem.note?orderItem.note:'',
            price: menuItem.price,
            item_qty: orderItem.item_qty?orderItem.item_qty:1,
            cart_item_modifiers: modifiers,
        }
        return item
    },

    // pastOrderToMenuItem(orderItem, menuItem){
    //     let modifiers = []
    //     orderItem.cart_item_modifiers.forEach(orderItemModifier => {
    //         let modifierItems = []
    //         orderItemModifier.cart_item_modifier_items.forEach(orderItemModifierItem => {
    //             let tempModifierItems = {
    //                 id: orderItemModifierItem.modifier_item_id,
    //                 included: orderItemModifierItem.included,
    //                 max: orderItemModifierItem.max,
    //                 min: orderItemModifierItem.min,
    //                 modifier_item_id: orderItemModifierItem.modifier_item_id,
    //                 name: orderItemModifierItem.name,
    //                 order: orderItemModifierItem.order,
    //                 price: orderItemModifierItem.price,
    //                 qty: orderItemModifierItem.qty,
    //                 sku: orderItemModifierItem.sku
    //             }
    //             modifierItems.push(tempModifierItems)
    //         })
            
    //         let tempModifiers = {
    //             base_price: orderItemModifier.base_price,
    //             id: orderItemModifier.modifier_id,
    //             included: orderItemModifier.included,
    //             max: orderItemModifier.max,
    //             min: orderItemModifier.min,
    //             modifier_id: orderItemModifier.modifier_id,
    //             name: orderItemModifier.name,
    //             note: "",
    //             order: orderItemModifier.order,
    //             sku: orderItemModifier.sku,
    //             status: true,
    //             cart_item_modifier_items: modifierItems
    //         }
    //         modifiers.push(tempModifiers)
    //     })

    //     let item = {
    //         base_price: orderItem.base_price.toString(),
    //         course: orderItem.course,
    //         id: orderItem.item_id,
    //         item_id: orderItem.item_id,
    //         image: orderItem.image_url,
    //         item_order: orderItem.item_order,
    //         item_sku: orderItem.item_sku,
    //         name: orderItem.name,
    //         slug: orderItem.slug,
    //         note: orderItem.note,
    //         price: orderItem.base_price,
    //         item_qty: orderItem.item_qty,
    //         cart_item_modifiers: modifiers,
    //     }

    //     console.log(item)
    //     console.log(menuItem)
    //     return item
    // },

    clean(cart){
        let local =[...cart]
        for(const ind in local){
            local[ind].items = []
        }
        return local
    }
}