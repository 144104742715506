<template>
    <div class="my-2 py-3 " style="background-color: white;">
        <div class="mx-5">Pickup at <span class="font-weight-bold">{{vg_location.name}}</span></div>
        <div class="mx-5">{{vg_location.address.formatted_address}}</div>
        <div class="mx-5">
            <a :href="'tel:'+phone" style="text-decoration: none; color: #000;">
                <v-icon>mdi-phone</v-icon> {{phone}}
            </a>
        </div>
        <div class="mx-5">
            <v-row>
                <v-col>
                    <div>{{text}}</div>
                </v-col>
                <v-col>
                    <div class="text-right">{{time}}</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import moment from 'moment'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            phone: '',
            text: '', 
            time: ''
        }
    },
    mounted(){
        this.vg_location.contact_details.forEach(obj => {
            if(obj.type==='phone' && obj.value){
                this.phone = StringHelper.standardPhoneFormat(obj.value)
            }
        })

        if(this.vg_pickupAt==='now'){
            this.text = 'Ready in about'
            this.time = moment().add('20', 'm').format('HH:mm')
        }else{
            this.text = this.readableDate()
            this.time = this.getPickupTime(this.vg_pickupTime)
        }
    },
    computed: {
        ...mapGetters({
            vg_deliveryPhone: 'delivery/phone',
            vg_location: 'location/location',
            vg_pickupAt: 'cart/pickupAt',
            vg_pickupDate: 'cart/pickupDate',
            vg_pickupTime: 'cart/pickupTime'
        })
    },
    methods: {
        readableDate(){
            if(moment(this.vg_pickupDate, 'YYYY-MM-DD').format('DD')===moment().format('DD'))
                return 'Today'
            else if(moment(this.vg_pickupDate, 'YYYY-MM-DD').format('DD')===moment().add(1, 'd').format('DD'))
                return 'Tomorrow'
            else
                return 'Later'
        },
        getPickupTime(time){
            return moment(time, ["hh:mm a"]).format("HH:mm")
        },
    }
}
</script>