<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Sign in</span>
                    <v-spacer />
                    <v-btn fab dark x-small @click.prevent="closeSelectLogin">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-img v-if="vg_business.branding && vg_business.branding.logo_url" :src="vg_business.branding.logo_url" contain max-height="50" />
                </v-card-text>   
                <v-card-text class="px-2">
                    <v-btn block outlined href="#" style="justify-content: left; padding-left:5px;" @click.prevent="loginWithGoogle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 18 18" aria-hidden="true"><title>Google</title><g fill="none" fill-rule="evenodd"><path fill="#4285F4" d="M17.64 9.2045c0-.6381-.0573-1.2518-.1636-1.8409H9v3.4814h4.8436c-.2086 1.125-.8427 2.0782-1.7959 2.7164v2.2581h2.9087c1.7018-1.5668 2.6836-3.874 2.6836-6.615z"></path><path fill="#34A853" d="M9 18c2.43 0 4.4673-.806 5.9564-2.1805l-2.9087-2.2581c-.8059.54-1.8368.859-3.0477.859-2.344 0-4.3282-1.5831-5.036-3.7104H.9574v2.3318C2.4382 15.9832 5.4818 18 9 18z"></path><path fill="#FBBC05" d="M3.964 10.71c-.18-.54-.2822-1.1168-.2822-1.71s.1023-1.17.2823-1.71V4.9582H.9573A8.9965 8.9965 0 0 0 0 9c0 1.4523.3477 2.8268.9573 4.0418L3.964 10.71z"></path><path fill="#EA4335" d="M9 3.5795c1.3214 0 2.5077.4541 3.4405 1.346l2.5813-2.5814C13.4632.8918 11.426 0 9 0 5.4818 0 2.4382 2.0168.9573 4.9582L3.964 7.29C4.6718 5.1627 6.6559 3.5795 9 3.5795z"></path></g></svg>
                        <span class="body-2" style="border-left: solid thin #ccc; line-height: 25px; margin-left:10px; padding-left:10px;">Sign in with Google</span>
                    </v-btn>
                </v-card-text>
                <v-card-text class="px-2">
                    <v-btn block outlined href="#" style="justify-content: left; padding-left:5px;" @click.prevent="loginWithFacebook">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><path  fill="#4285F4" d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
                        <span class="body-2" style="border-left: solid thin #ccc; line-height: 25px; margin-left:10px; padding-left:10px;">Sign in with Facebook</span>
                    </v-btn> 
                    <div class="text-center py-3">Or Continue with: </div>
                    <v-btn style="justify-content: left; padding:5px;" block outlined @click.prevent="loginWithGr8">
                        <v-img src="@/assets/img/logo-small.png" contain style="max-width:25px; max-height:25px;"/>
                        <span class="body-2" style="border-left: solid thin #ccc; line-height: 25px; margin-left:5px; padding-left:5px;">Sign in with Gr8 account</span>
                    </v-btn>
                    <div class="mt-5 text-center">Don't have an account? <a href="#" @click.prevent="signUp">CLICK HERE</a></div>
                </v-card-text>
                <v-card-text>
                    <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                    <span class="float-left pr-1">POWERED BY :</span>
                    <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapGetters, mapMutations, mapActions} from 'vuex'
export default {
    data(){
        return{
            
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_loginPop
            },
            set(val){
                this.vm_loginPop(val)
            }
        },
        ...mapGetters({
            vg_loginPop: 'login/loginPop',
            vg_business: 'location/business'
        })
    },
    methods: {
        loginWithGoogle(){
            const self = this;
            this.spinner(true);
            this.$gAuth.signIn().then( async (GoogleUser) => {
                let googleResponse = GoogleUser.getAuthResponse();
                let googleProfile = GoogleUser.getBasicProfile();
                try{
                    await self.va_setSocialLogin({
                        social_type: 'google',
                        access_token: googleResponse.access_token
                    });
                    self.vm_loginWith(googleProfile.getEmail())
                    self.vm_loginPop(false)
                    self.snackbar({open: true, text: 'Sign In successful', color: 'success'})
                    self.spinner(false);
                }catch(err){
                    self.snackbar({open: true, text: err.response.data.msg, color: 'error'})
                    self.spinner(false);
                }
            }).catch(error => {
                this.spinner(false);
                console.log('error', error)
            })
        },
        loginWithFacebook(){
            const self = this;
            self.spinner(true);
            window.FB.login(async function(response) {
                if (response.authResponse) {
                    window.FB.api('/me?fields=first_name,last_name,email', async function(res){
                        try{
                            await self.va_setSocialLogin({
                                social_type: 'facebook',
                                access_token: response.authResponse.accessToken
                            });
                            self.vm_loginWith(res.email)
                            self.vm_loginPop(false)
                            self.snackbar({open: true, text: 'Sign In successful', color: 'success'})
                            self.spinner(false);
                        }catch(err){
                            self.snackbar({open: true, text: err.response.data.msg, color: 'error'})
                            self.spinner(false);
                        }
                    })
                }else{
                    self.spinner(false);
                }
            }, { 
                scope: 'email'
            });
            return false;
        },
        loginWithGr8(){
            this.vm_loginPop(false)
            this.vm_phoneEmailPop(true)
        },
        closeSelectLogin(){
            this.vm_loginPop(false)
        },
        signUp(){
            this.vm_loginPop(false)
            this.vm_signupStatus(true)
        },
        ...mapMutations({
            vm_loginPop : 'login/SET_LOGIN_POP',
            vm_phoneEmailPop: 'login/SET_PHONE_EMAIL_POP',
            vm_signupWelcomeStatus: 'signup/SET_STATUS',
            vm_signupStatus: 'signup/SET_STATUS',
            vm_loginWith: 'login/SET_LOGIN_WITH'
        }),
        ...mapActions({
            va_setSocialLogin: 'login/setSocialLogin'
        })
    }
}
</script>