import {CartAPI} from '@/clients/cart'
export default {
    namespaced: true,
    state: {
      tableId: '',
      joinedCardId: '',
      cart: [],
      item: [],
      cart_note: '',
      server_cart: {},
      kitchen_data: {},
      tips: {},
      selectedTips: {
          value: '0',
          type: 'percentage',
          amount: ''
      },
      new_cart: false,
      cart_id: '',
      editItem: {},
      orderType: null,    // dinein, pickup, delivery
      confirm_delivery_pickup: false,   // pickup delivery popup
      pickupAt: 'now',
      pickupDate: '',
      pickupTime: '',
      lockCheckout: false,
      lockCheckoutPop: false,
      splitBillPopup: false,
      selectedCartItems: [],
      userJoined: false
    },
    getters: {
        tableId: state => state.tableId,
        joinedCardId: state => state.joinedCardId,
        cart: state => state.cart,
        item: state => state.item,
        cartNote: state => state.cart_note,
        serverCart: state => state.server_cart,
        kitchenData: state => state.kitchen_data,
        tipsData: state => state.tips,
        selectedTips: state => state.selectedTips,
        newCart: state => state.new_cart,
        cart_id: state => state.cart_id,
        editItem: state => state.editItem,
        orderType: state => state.orderType,
        confirm_delivery_pickup: state => state.confirm_delivery_pickup,
        pickupAt: state => state.pickupAt,
        pickupDate: state => state.pickupDate,
        pickupTime: state => state.pickupTime,
        lockCheckout: state  => state.lockCheckout,
        lockCheckoutPop: state => state.lockCheckoutPop,
        splitBillPopup: state => state.splitBillPopup,
        selectedCartItems: state => state.selectedCartItems,
        userJoined: state => state.userJoined
    },
    mutations: {
        SET_TABLE_ID(state, data){
            state.tableId = data
        },
        SET_JOINED_CARD_ID(state, data){
            state.joinedCardId = data
        },
        SET_CART(state, data) {
            state.cart = data
        },
        SET_ITEM(state, data){
            state.item = data
        },
        SET_CART_NOTE(state, data){
            state.cart_note = data
        },
        SET_SERVER_CART(state, data){
            state.server_cart = data
        },
        SET_KITCHEN_DATA(state, data){
            state.kitchen_data = data
        },
        SET_TIPS(state, data){
            state.tips = data
        },
        SET_SELECTED_TIPS(state, data){
            state.selectedTips = data
        },
        SET_NEW_CART(state, data){
            state.new_cart = data
        },
        SET_CART_ID(state, data){
            state.cart_id = data
        },
        SET_EDIT_ITEM(state, data){
            state.editItem = data
        },
        SET_ORDER_TYPE(state, data){
            state.orderType = data
        },
        SET_CONFIRM_DELIVERY_PICKUP(state, data){
            state.confirm_delivery_pickup = data
        },
        SET_PICKUP_AT(state, data){
            state.pickupAt = data
        },
        SET_PICKUP_DATE(state, data){
            state.pickupDate = data
        },
        SET_PICKUP_TIME(state, data){
            state.pickupTime = data
        },
        SET_LOCK_CHECKOUT(state, data){
            state.lockCheckout = data
        },
        SET_LOCK_CHECKOUT_POP(state, data){
            state.lockCheckoutPop = data
        },
        SET_SPLIT_BILL_POPUP(state, data){
            state.splitBillPopup = data
        },
        SET_SELECTED_CART_ITEMS(state, data){
            state.selectedCartItems = data
        },
        SET_USER_JOINED(state, data){
            state.userJoined = data
        },
        RESET(state){
            state.tableId = ''
            state.joinedCardId = '',
            state.cart = []
            state.cart_note = ''
            state.server_cart = {}
            state.kitchen_data= {}
            state.tips = {}
            state.cart_id = '',
            state.pickupAt = 'now',
            state.pickupDate = '',
            state.pickupTime = '',
            state.lockCheckout = false,
            state.selectedCartItems= [],
            state.selectedTips = {
                value: '0',
                type: 'percentage',
                amount: ''
            },
            state.userJoined = false
        }
    },
    actions: {
        async sendOrderToKitchen({commit}, cartId){
            const {data} = await CartAPI.sendOrderToKitchen(cartId);
            commit('SET_KITCHEN_DATA', data.payload)
        },
        async addTips({commit, state}, cartData){
            try{
                //remove previous tips if exist
                if(state.server_cart && state.server_cart.cart_tips){
                    
                    for(const ind in state.server_cart.cart_tips){
                        await CartAPI.removeTips(state.server_cart.cart_tips[ind].id)
                    }
                }
                
                const {data} = await  CartAPI.addTips(cartData)
                commit('SET_TIPS', data.payload)
            }catch(err){
                const {data} = await  CartAPI.addTips(cartData)
                commit('SET_TIPS', data.payload)
            }
            
        },
        async removeTips({state}){
            try{
                //remove previous tips if exist
                if(state.server_cart && state.server_cart.cart_tips){
                    for(const ind in state.server_cart.cart_tips){
                        await CartAPI.removeTips(state.server_cart.cart_tips[ind].id)
                    }
                }
            }catch(err){
                return
            }
        },
        async reloadServerCart({commit}, cartId){
            const {data} = await CartAPI.getCart(cartId)
            commit('SET_SERVER_CART', data.payload)
        }
    }
}