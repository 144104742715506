<template>
    <v-snackbar
        v-if="snackbarOpen"
        v-model="snackbarOpen"
        :timeout="vg_snackbar[ind].timeout"
        :color="vg_snackbar[ind].color"
    >
        <template v-if="vg_snackbar[ind].icon">
            <v-icon left color="white">{{vg_snackbar[ind].icon}}</v-icon>
            {{ vg_snackbar[ind].text }}
            <!-- <v-btn text fab @click.capture.prevent="setOpen(false)">
                <v-icon>mdi-close</v-icon>
            </v-btn> -->
        </template>
        <template v-else>
            {{ vg_snackbar[ind].text }}
            <!-- <v-btn text fab @click.capture.prevent="setOpen(false)">
                <v-icon>mdi-close</v-icon>
            </v-btn> -->
        </template>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['ind'],
  computed: {
      snackbarOpen: {
          get(){
              return this.vg_snackbar[this.ind]
          },
          set(value){
            let getterObj = [...this.vg_snackbar]
            getterObj[this.ind].open = value
            this.vm_replaceSnackbar(getterObj)
          }
      },
      ...mapGetters({
          vg_snackbar: 'Snackbar/snackbar'
      })
  },
  methods: {
      ...mapMutations({
          vm_snackbar: 'Snackbar/snackbar',
          vm_replaceSnackbar: 'Snackbar/replace'
      })
  }
}
</script>