export const KitchenCart = {
    // check if item exist in cart
    itemExists(item, kitchenCart){
        if(kitchenCart.cart_items && kitchenCart.cart_items.length>0){
            let itemIndex = kitchenCart.cart_items.findIndex(eachItem =>  eachItem.item_id==item.id)
            return itemIndex>=0
        }
    },
    isEmptyKitchenData(kitchenData){
        if(kitchenData.cart_items){
            return kitchenData.cart_items.length===0
        }
        return true
    }
}