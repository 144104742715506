import _ from 'lodash'
import {StringHelper} from './stringHelper'

export const CartUser = {
    isUserActive(user, cartUsers){
        let userName = StringHelper.getUserNameOrPhone(user)
        let result = _.findKey(cartUsers, {'name': userName, 'status': "joined"})
        return result>=0
    },
    countActiveUser(cartUsers){
        let result = _.filter(cartUsers, {'status': "joined"})
        return result.length
    },
    countJoiningUser(cartUsers){
        if(!cartUsers) return 
        
        let result = _.filter(cartUsers, {'status': "requested_to_join"})
        return result.length
    },
    isUserNotAccepted(user, cartUsers){
        let userName = StringHelper.getUserNameOrPhone(user)
        let result = _.findKey(cartUsers, {'name': userName, 'status': "requested_to_join"})
        return result>=0
    },
    isMultipleUserOnTable(cartUsers){
        let result = _.size(cartUsers, {'status': "joined"})
        return result>1
    },
    isUserCartOwner(user, cart){
        if(!user && !user.user.id && !cart) return
        if(user.user.id==cart.user_id){
            return true
        }
        return false
    },
    // only table owner can delete and cannot delete self
    isAllowedToDeleteUser(user, cartUsers, cart){
        if(this.isUserActive(user, cartUsers) && this.isUserCartOwner(user, cart)){
            return true
        }
        return false
    },
    isSelf(user, cartUser){
        if(!user || !user.user || !user.user.id || !cartUser) return

        return(user.user.id==cartUser.user_id)
    }
}