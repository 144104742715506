import { apolloClient } from '@/vue-apollo'
import { ITEM_SEARCH } from '@/graphql/queries/itemSearch'
import { GET_ITEM } from '@/graphql/queries/item'

export const ItemSearchAPI = {
  getItem(slug) {
    return apolloClient.query({
      query: ITEM_SEARCH,
      variables: { slug: slug },
      fetchPolicy: 'no-cache'
    })
  },

  getItemById(id){
    return apolloClient.query({
      query: GET_ITEM,
      variables: { id: id },
      fetchPolicy: 'no-cache'
    })
  }
}