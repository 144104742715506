import { APP_LOYALTY_URL } from '../env'
import axios from '../_client'
import store from '../../store'

const loyaltyAxiosInstaceV1 = axios.create({
  baseURL: `${APP_LOYALTY_URL}/api/v1`,
})
loyaltyAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const LoyaltyAPI = {
  loyaltyService(businessId, options){
    let option = ''
    if(options){
      option = '?settings=true&signup_actions=true&branding=true&tiers=true&program_token=true'
    }
    return loyaltyAxiosInstaceV1({
      method: 'get',
      url: `/apps/business/${businessId}/programs${option}`
    })
  },

  defaultFaq(){
    let vg_loyaltyData = store.getters['loyalty/loyaltyData']
    let token = null
    if(vg_loyaltyData && vg_loyaltyData.tokens && vg_loyaltyData.tokens[0] && vg_loyaltyData.tokens[0].token){
      token = vg_loyaltyData.tokens[0].token
    }
    return loyaltyAxiosInstaceV1({
      method: 'get',
      url: `/apps/faqs/default`,
      headers: {
        'api-token': token
      }
    })
  },

  faq(){
    let vg_loyaltyData = store.getters['loyalty/loyaltyData']
    let token = null
    if(vg_loyaltyData && vg_loyaltyData.tokens && vg_loyaltyData.tokens[0] && vg_loyaltyData.tokens[0].token){
      token = vg_loyaltyData.tokens[0].token
    }
    return loyaltyAxiosInstaceV1({
      method: 'get',
      url: `/apps/faqs`,
      headers: {
        'api-token': token
      }
    })
  }
}