<template>
  <div class="container-fluid home" style="padding-top: 20% !important; background-color: #000 !important; width:100% !important; height:100% !important;">
    <v-img src="@/assets/img/home.png" />
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <v-card-text>
          <v-carousel
            class="home-screen"
            v-model="current"
            :continuous="false"
            :cycle="false"
            :show-arrows="false"
            hide-delimiter-background
            height="400"
          >
            <v-carousel-item v-for="(each, index) in data" :key="index">
                <slide :data="each" />
            </v-carousel-item>
          </v-carousel>
          <v-btn class="button primary" block dark @click="changeCarousel">{{data[current].btnName}}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import slide from './slide'
import {mapGetters} from 'vuex'
import { StringHelper } from '@/helpers/stringHelper'
export default {
  components: {
    slide
  },
  data () {
    return {
      dialog: true,
      current: 0,
      data: [
        {
          image: require('@/assets/img/book_list.png'), 
          title: 'Browse and Order from your phone', 
          desc: 'Browse the menu, specials, recommendations and place order right from the devices. One more line of sample text.', 
          btnName: 'Continue'
        },
        {
          image: require('@/assets/img/food_tray_service.png'), 
          title: 'Make reorder and call for server easy', 
          desc: 'When you want to call for server, or get another drink, just do it in the app, always ready for you', 
          btnName: 'Continue'
        },
        {
          image: require('@/assets/img/bill_pay.png'), 
          title: 'View, Split and Pay the bill at ease', 
          desc: 'View the bill, split the bill, pay and go whenever you are ready, right from your device.', 
          btnName: 'Lets Get Started'
        }
      ]
    }
  },
  watch: {
    dialog(value){
      if(value===true){
        this.current = 0
      }
    },
    vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
              this.$router.push({name: 'receipt', query: {payer: false}})
            }
        }
  },
  computed: {
    ...mapGetters({
      vg_location: 'location/location',
      vg_businessName: 'location/businessName',
      vg_joinedCardId: 'cart/joinedCardId',
      vg_tableId: 'cart/tableId',
      vg_payed: 'payment/payed',
      vg_loginUser: 'login/user'
    })
  },
  methods: {
    changeCarousel(){
      if(this.current<(this.data.length-1)){
        this.current++
      }else{
        if((this.$router.currentRoute.params.businessName && this.$router.currentRoute.params.locationName) || (this.vg_businessName && this.vg_location.slug)){
          let url = StringHelper.menuUrlGenerator(
              this.vg_businessName?this.vg_businessName:this.$router.currentRoute.params.businessName, 
              this.vg_location.slug?this.vg_location.slug:this.$router.currentRoute.params.locationName,
              this.vg_tableId,
              this.vg_joinedCardId
          )
          this.$router.push(url)
          this.dialog = false
        }
      }
    },

  }
}
</script>
<style>
.home-screen .v-carousel__controls .mdi:before{
  font-size: 10px;
}
.home-screen .v-carousel__controls__item{
  margin: 0;
}
.home-screen .v-carousel__controls .v-item-group>button{
  width: 20px;
}
.home-screen .v-carousel__controls .v-btn span i{
  color:#c4c4c4 !important;
}

.home-screen .v-carousel__controls .v-btn--active span i{
  color:#000055 !important;
  opacity: 1;
}
.v-carousel__controls .v-btn .v-btn__content .v-icon {
  color: black;
}
</style>
