<template>
    <div>
        <v-dialog class="earning" v-model="dialog" persistent fullscreen>
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <v-btn fab dark x-small @click.prevent="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <div style="width:280px; height:300px; margin:auto;">
                        <!-- <v-img v-if="vg_business.branding && vg_business.branding.logo_url" :src="vg_business.branding.logo_url" contain max-height="100" /> -->
                        <v-img src="@/assets/img/circle.png"  />
                        <div style="font-size:60px; font-weight:bold; z-index:9; text-align:center; position:relative; position:relative; top:-150px; color:white;">+{{vg_points}}</div>
                    </div>
                </v-card-text>
                <v-card-text>
                    <p class="text-center my-5 py-5" style="font-size:60px; color: #282C88;">Points</p>
                </v-card-text>
                <v-card-text>
                    <p v-if="vg_loyaltyEarningType=='signup'" class="text-center mb-5 pb-5" style="font-size:20px; font-weight:bold; color: #282C88;">Welcome to {{vg_loyaltyName}}!</p>
                    <p v-else class="text-center mb-5 pb-5" style="font-size:20px; font-weight:bold; color: #282C88;">Thank you for your purchase!</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
export default {
    computed: {
        dialog: {
            get(){
                return this.vg_loyaltyEarningStatus
            },
            set(val){
                this.vm_loyaltyEarningStatus(val)
            }
        },
        ...mapGetters({
            vg_business: 'location/business',
            vg_server_cart: 'cart/serverCart',
            vg_points: 'loyaltyEarning/points',
            vg_loyaltyEarningStatus: 'loyaltyEarning/status',
            vg_loyaltyName: 'loyalty/programName',
            vg_loyaltyEarningType: 'loyaltyEarning/type'
        })
    },
    watch: {
        vg_loyaltyEarningStatus(val){
            if(val){
                this.$confetti.start({
                    particles: [
                        {type: 'heart'},
                        {type: 'rect'},
                        {type: 'circle'}
                    ]
                })
            }
        }
    },
    methods: {
        close(){
            this.$confetti.stop()
            this.vm_loyaltyEarningStatus(false)
        },
        ...mapMutations({
            vm_loyaltyEarningStatus: 'loyaltyEarning/SET_STATUS'
        })
    }
}
</script>
<style scoped>
    .earning{
        overflow:hidden !important;
    }
    .privacy div{
        margin-bottom: 10px;
    }
</style>