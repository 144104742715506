<template>
    <div>
        <div v-if="popup"></div>
        <loading-block v-else-if="loading" class="pt-9" />
        <div class="mt-5" v-else style="overflow:auto; margin-bottom:100px;">
            <frequent-order />
                
            <v-list two-line class="mx-5">
                <v-list-item-title class="font-weight-bold mb-2">Past Orders</v-list-item-title>
                <v-divider></v-divider>
                <div v-if="vg_order && vg_order.data">
                    <past-order-each v-for="(order, index) in vg_order.data" :key="index" :order="order" />

                    <div v-if="vg_order.total_pages!==vg_orderPage">
                        <loading-block v-if="loadingMore" class="my-2" />
                        <v-btn v-else block text class="my-2" @click.prevent="loadMore">More</v-btn>
                    </div>
                </div>
                <div v-else>
                    <div class="font-weight-bold text-center ma-3">No item found.</div>
                    <div class="text-center ma-2">The list is currently empty.</div>
                </div>
                <version />
            </v-list>
        </div>

        <v-footer class="footer" fixed color="white" v-if="!loading">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || (vg_orderType!=='dinein') || !!vg_callServerValue">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                <div class="footer-bar">
                    <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                    <v-btn @click.prevent="moreOption" small fab class="center-btn">
                    <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                    <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn @click.prevent="viewTable" class="right-btn" :disabled="vg_orderType!='dinein' || !vg_tableId" dark>View Table</v-btn>
                </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </div>
</template>
<script>
import {StringHelper} from '@/helpers/stringHelper'
import {DateHelper} from '@/helpers/dateHelper'
import {CartAPI} from '@/clients/cart'
import { mapActions,mapState, mapGetters, mapMutations } from 'vuex'
import FrequentOrder from '@/views/account/frequent-order'
import PastOrderEach from '@/views/account/past-order-each'
import Version from '@/components/custom-components/version'
import LoadingBlock from '@/components/custom-components/LoadingBlock.vue'
export default {
    components: {
    FrequentOrder,
    PastOrderEach,
    Version,
    LoadingBlock
},
    data(){
        return {
            popup: false,
            loading: false,
            loadingMore: false,
            option: false,
            panel: [1]
        }
    },
    mounted(){
        this.vm_orderPage(1)
        this.orderHistory()
        this.frequentOrder()
    },
    watch:{
        vg_tab(val){
            if(val===0){
                this.vm_orderPage(1)
                this.orderHistory()
                this.frequentOrder()
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_itemInCart(val){
            if(val){
                this.$router.push({name: 'cart'})
            }
        },
        async vg_loginUser(){
            try{
                await this.va_orders({userId: this.vg_loginUser.user.id, locationId: this.vg_location.id, page: 1})
                this.popup = false
                this.loading = false
            }catch(err){
                this.popup = false
                this.loading = false
            }
        },
        vg_loginPop(val){
            // redirect to menu when signup and login pop is closed
            if(val==false && this.vg_signupPop==false){
                this.navigateToMenu()
            }
        },
        vg_signupPop(val){
            // redirect to menu when signup and login pop is closed
            if(val==false && this.vg_loginPop==false){
                this.navigateToMenu()
            }
        },
        vg_selectedPaymentType(val){
            if(val==='selectItems'){
                this.$router.push({name: 'select-item-to-pay'})
            }
        }
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        ...mapState('location', ['location']),
        ...mapGetters({
            vg_tab: 'account/tab',
            vg_location: 'location/location',
            vg_loginUser: 'login/user',
            vg_loginPop: 'login/status',
            vg_signupPop: 'signup/status',
            vg_businessName: 'location/businessName',
            vg_businessId: 'location/business_id',
            vg_tableId: 'cart/tableId',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_cartId: 'cart/cart_id',
            vg_notification: 'cartUser/notification',
            vg_order: 'order/orders',
            vg_orderPage: 'order/orderPage',
            vg_orderType: 'cart/orderType',
            vg_payer: 'payment/payer',
            vg_payed: 'payment/payed',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_selectedPaymentType: 'payment/selectedPaymentType',
            vg_callServerValue: 'menu/callServerValue'
        }),
    },
    methods: {
        getOrderType(orderType){
            if(orderType==='dinein'){
                return 'Dine in'
            }else if(orderType=='pickup'){
                return 'Take out'
            }else if(orderType=='delivery'){
                return 'Delivery'
            }
        },
        async loadMore(){
            try{
                this.loadingMore = true
                this.vm_orderPage((this.vg_orderPage+1))
                await this.va_orders({userId:this.vg_loginUser.user.id, locationId: this.vg_location.id, page: this.vg_orderPage})
                this.loadingMore = false
            }catch(err){
                this.loadingMore = false
                console.log(err)
            }
        },
        async orderHistory(){
            try{
                if(this.vg_loginUser.user && this.vg_loginUser.user.id && this.vg_location.id){
                    this.loading = true
                    await this.va_orders({userId:this.vg_loginUser.user.id, locationId: this.vg_location.id, page: this.vg_orderPage})
                    this.loading = false
                }else{
                    this.popup = true
                    this.vm_signupStatus(true)
                }
                this.spinner(false)
            }catch(err){
                this.loading = false
                this.spinner(false)
            }
        },
        async frequentOrder(){
            try{
                if(this.vg_loginUser.user && this.vg_loginUser.user.id && this.vg_location.id){
                    this.loading = true
                    await this.va_frequentOrders({userId:this.vg_loginUser.user.id, locationId: this.vg_location.id})
                    this.loading = false
                }else{
                    this.popup = true
                    this.vm_signupStatus(true)
                }
                this.spinner(false)
            }catch(err){
                if(err.response.status===404){
                    this.vm_frequentOrder([])
                }else if(err.response.data.msg=='Looks like your not logged in. Please login and try again'){
                    this.reset()
                    this.snackbar({open: true, text:err.response.data.msg, color: 'red'})
                    this.$router.push({name: 'menu'})
                }else{
                    this.snackbar({open: true, text:err.response.data.msg, color: 'red'})
                }
            }
        },
        formatDate(date){
            if(!date) return
            return DateHelper.formatDate(date)
        },
        getName(orderItems){
            let names = ''
            orderItems.forEach(item => {
                names = names+', '+item.name;
            })
            return names.substring(2)
        },
        orderDetail(order){
            this.vm_currentOrder(order)
            this.$router.push({name: 'past-order'})
        },
        back(){
            this.navigateToMenu()
        },
        navigateToMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        moreOption(){
            this.option = !this.option
        },
        async callServer(){
            try{ 
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId

                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        viewTable(){
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        reset(){
            this.vm_setOrderType(null)
            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_deliveryReset()
            this.vm_resetPayment()
            this.vm_tableId('')
            this.vm_allowToJoin(false)
            this.vm_resetUser()
            this.vm_confirmDeliveryPickup(true)
        },
        ...mapMutations({
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_resetCart: 'cart/RESET',
            vm_resetCartUser: 'cartUser/RESET',
            vm_deliveryReset: 'delivery/RESET',
            vm_resetPayment: 'payment/RESET',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
            vm_resetUser: 'login/RESET_USER',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_signupStatus: 'signup/SET_STATUS',
            vm_currentOrder: 'order/SET_CURRENT',
            vm_frequentOrder: 'order/SET_FREQUENT_ORDERS',
            vm_orderPage: 'order/SET_ORDER_PAGE',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
        }),
        ...mapActions({
            va_orders: 'order/orders',
            va_frequentOrders: 'order/frequentOrders'
        })
    }
}
</script>