import {FACEBOOK_API_ID} from '@/clients/env';

export const FacebookHelper = {
    /**
   * Create facebook sdk js
   */
  async loadFacebookSDK(){

    // If no fb root element then generate
    if (!document.getElementById('fb-root')) {
      const fbDiv = document.createElement("div");
      fbDiv.id = "fb-root";
      document.body.appendChild(fbDiv);
    }

    let s = 'script';
    let id = 'facebook-jssdk';
    var js,
      fjs = document.getElementsByTagName(s)[0];
    if (document.getElementById(id)) {
      return;
    }
    js = document.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  },

  /**
   * Initialize facebook
   */

  async initFacebook(loggedIn) {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: FACEBOOK_API_ID,
        cookie: false, 
        status: true,
        xfbml: true,
        version: "v12.0"
      });
      if(!loggedIn){
        window.FB.getLoginStatus((fbloginRes) =>{
          if(fbloginRes.status == 'connected'){
            window.FB.logout(function(){
              console.log('fb out.')
            });
          }
        })
      }
    };
  },
}