<template>
    <v-dialog v-model="dialog" persistent>
        <v-form ref="form" v-model="valid" @submit.prevent="doVerify">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Code Verification</span>
                    <v-spacer />
                    <v-btn fab dark x-small @click.prevent="closeVerification">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-img v-if="vg_business.branding && vg_business.branding.logo_url" :src="vg_business.branding.logo_url" contain max-height="50" />
                </v-card-text> 
                <v-card-text class="pb-0">
                    <div class="text-center pb-5" v-if="vg_signupPhone">Code sent via text message to {{formatPhone(vg_signupPhone)}}</div>

                    <v-text-field
                        v-model.trim="form.auth_code"
                        :rules="rules.codeRules"
                        label="Please Enter the 6 digit code"
                        required
                        outlined
                        ></v-text-field>

                    <v-btn block text color="primary" @click.prevent="resend">Resend</v-btn>
                </v-card-text>
                <v-card-text>
                    <v-btn
                        type="submit"
                        color="primary"
                        block
                        >
                        Submit
                    </v-btn>
                </v-card-text>
                <v-card-text>
                    <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                    <span class="float-left pr-1">POWERED BY :</span>
                    <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {UserAPI} from '@/clients/user'
export default {
    data(){
        return{
            form: {
                auth_code: ''
            },
            rules: {
                codeRules: [ 
                    v => !!v || 'Code is required',
                    v => (v && v.length>=6) || 'Please Enter the 6 digit code'
                ]
            },
            valid: false
        }
    },
    mounted(){
        this.spinner(false)
    },
    computed: {
        dialog: {
            get(){
                return this.vg_verifyStatus
            },
            set(val){
                this.vm_verifyStatus(val)
            }
        },
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_signupPhone: 'signup/signupPhone',
            vg_verifyStatus: 'signup/verify',
            vg_signupResponse: 'signup/verifyData',
            vg_business: 'location/business',
            vg_businessId: 'location/business_id',
            vg_loyaltyEnabled: 'loyalty/enabled',
        })
    },
    methods: {
        formatPhone(number){
            if(!number) return
            return number.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3")
        },
        async doVerify(){
            if(this.$refs.form.validate()){
                try{
                    this.spinner(true)
                    const {data} = await UserAPI.verify(this.form.auth_code, this.vg_businessId)
                    this.vm_setUser(data)
                    this.snackbar({open: true, text: 'Sign up completed. Signing you in.', color: 'success'})
                    this.closeVerification()

                    if(this.vg_loyaltyEnabled){
                        await this.subscribeLoyalty()
                    }
                    this.spinner(false)
                }catch(err){
                    this.spinner(false)
                    this.snackbar({open: true, text: 'Verification code is incorrect', color: 'error'})
                    console.log(err)
                }
            }
        },
        closeVerification(){
            this.$refs.form.reset()
            this.vm_verifyStatus(false)
        },
        async resend(){
            try{
                this.spinner(true);
                let data = {}
                if(isNaN(parseInt(this.vg_signupPhone))){
                    data = {
                        contact_type: 'email',
                        contact_value: this.vg_signupPhone
                    }
                }else{
                    data = {
                        contact_type: 'phone',
                        contact_value: this.vg_signupPhone
                    }
                }
                
                await UserAPI.resend(data);
                this.snackbar({open: true, text: 'Verification code sent', color: 'success'})
                this.spinner(false);
            }catch(err){
                console.log(err)
            }
        },
        async subscribeLoyalty(){
            try{
                let data = {
                    business_id: this.vg_businessId,
                    user_id: this.vg_loginUser.user.id
                }
               await UserAPI.registerLoyalty(data)
               await this.checkLoyalty()
            }catch(err){
                console.log(err)
            }
        },
        async checkLoyalty(){
            try{
                let {data} = await UserAPI.checkLoyalty()
                if(data.points && data.points>0){
                    this.vm_earnedPoints(data.points)
                    this.vm_loyaltyEarningStatus(true)
                    this.vm_loyaltyEarningType('signup')
                }
            }catch(err){
                this.redeem = {}
            }
        },
        ...mapMutations({
            vm_verifyStatus: 'signup/SET_VERIFY',
            vm_loginStatus: 'login/SET_STATUS',
            vm_setUser: 'login/SET_USER',
            vm_earnedPoints: 'loyaltyEarning/SET_POINTS',
            vm_loyaltyEarningStatus: 'loyaltyEarning/SET_STATUS',
            vm_loyaltyEarningType: 'loyaltyEarning/SET_TYPE'
        })
    }
}
</script>