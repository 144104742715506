import {ServerCart} from '@/helpers/serverCartHelper'
import {CartAPI} from '@/clients/cart'
import store from '../store'

export const TipsHelper = {
    async addTips(tipsAmt){
        try{
            let vg_server_cart = store.getters['cart/serverCart']
            let vg_loginUser = store.getters['login/user']
            let vg_selectedTips = store.getters['cart/selectedTips']

            if(this.isMyTipsSet()){
                // might be set due to error during payment
                await this.removeTipsByUserIdIfExists(vg_loginUser.user.id)
            }

            // add tips
            let tipsData = {
                "cart_id": vg_server_cart.id,
                "value": vg_selectedTips.value,
                "type": vg_selectedTips.type,
                "amount": tipsAmt,
                "user_id": vg_loginUser.user.id
            }
            const addTips = await  CartAPI.addTips(tipsData)
            store.commit('cart/SET_SERVER_CART', addTips.data.payload)
        }catch(err){
            return
        }
    },
    async addTipsIfNotSet(){
        try{
            let vg_cartId = store.getters['cart/cart_id']
            let vg_loginUser = store.getters['login/user']
            let vg_server_cart = store.getters['cart/serverCart']
            let vg_joinedCardId = store.getters['cart/joinedCardId']

            let tipsAmt =  ServerCart.calculateTips(vg_server_cart.subtotal, 15)

            // add tips if cart_tips is empty or dont have user tips for selectItems
            if((!vg_server_cart.cart_tips || vg_server_cart.cart_tips.length===0)){
                // add tips in server
                let tipsData = {
                    "cart_id": vg_server_cart.id,
                    "value": 15,
                    "type": 'percentage',
                    "amount": tipsAmt,
                    "user_id": vg_loginUser.user.id
                }
                const addTips = await  CartAPI.addTips(tipsData)
                store.commit('cart/SET_SERVER_CART', addTips.data.payload)

                // reload cart
                let {data} = await CartAPI.getCart(vg_cartId?vg_cartId:vg_joinedCardId)
                store.commit('cart/SET_SERVER_CART', data.payload)

                // add to selected tips
                store.commit('cart/SET_SELECTED_TIPS', {amount: tipsAmt, type: 'percentage', value: 15})
                return tipsAmt
            }
        } catch(err){
           return
        }
    },

    async addMyTipsIfNotSet(subtotal){
        try{
            let vg_cartId = store.getters['cart/cart_id']
            let vg_loginUser = store.getters['login/user']
            let vg_server_cart = store.getters['cart/serverCart']
            let vg_joinedCardId = store.getters['cart/joinedCardId']
            let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']

            let tipsAmt =  ServerCart.calculateTips(subtotal, 15)

            // for select options to pay
            // don't allow to add tips if already added by user
            let isUserTips = true
            let userTipsIndex = vg_server_cart.cart_tips.findIndex(tip => tip.user_id==vg_loginUser.user.id)
            if(userTipsIndex>=0 && (vg_selectedPaymentType=='selectItems' || vg_selectedPaymentType=='payForMyOrder')){
                isUserTips = false
            }

            // add tips if cart_tips is empty or dont have user tips for selectItems
            if((!vg_server_cart.cart_tips || vg_server_cart.cart_tips.length===0) || isUserTips){
                // add tips in server
                let tipsData = {
                    "cart_id": vg_server_cart.id,
                    "value": 15,
                    "type": 'percentage',
                    "amount": tipsAmt,
                    "user_id": vg_loginUser.user.id
                }
                const addTips = await  CartAPI.addTips(tipsData)
                store.commit('cart/SET_SERVER_CART', addTips.data.payload)

                // reload cart
                let {data} = await CartAPI.getCart(vg_cartId?vg_cartId:vg_joinedCardId)
                store.commit('cart/SET_SERVER_CART', data.payload)

                // add to selected tips
                store.commit('cart/SET_SELECTED_TIPS', {amount: tipsAmt, type: 'percentage', value: 15})
                return tipsAmt
            }
        } catch(err){
           return
        } 
    },

    async updateTips(tips){
        try{
            let vg_loginUser = store.getters['login/user']
            let vg_server_cart = store.getters['cart/serverCart']

            let tipsAmt
            if(tips.type==='percentage'){
                tipsAmt =  ServerCart.calculateTips(vg_server_cart.subtotal, tips.value)
            }else{
                tipsAmt = tips.value?tips.value:'0.00'
            }
            if(!vg_server_cart.cart_tips || vg_server_cart.cart_tips.length>0){
                // add tips in server
                let tipsData = {
                    "cart_id": vg_server_cart.id,
                    "value": tips.value,
                    "type": tips.type,
                    "amount": tipsAmt,
                    "user_id": vg_loginUser.user.id
                }
                await store.dispatch('cart/addTips', tipsData)

                // add to selected tips
                store.commit('cart/SET_SELECTED_TIPS', {amount: tipsAmt, type: tips.type, value: tips.value})
                return tipsAmt
            }
        }catch(err){
            return
        }
    },

    async changeMyTipsIfNotMatch(subtotal){
        let vg_loginUser = store.getters['login/user']
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']
        let vg_selectedTips = store.getters['cart/selectedTips']

        // for select options to pay
        // don't allow to add tips if already added by user
        let isUserTips = false
        let userTipsIndex = vg_server_cart.cart_tips.findIndex(tip => tip.user_id==vg_loginUser.user.id)
        if(userTipsIndex>=0 && (vg_selectedPaymentType=='selectItems' || vg_selectedPaymentType=='payForMyOrder')){
            isUserTips = true
        }
        if(isUserTips){
            let tipsAmt =  ServerCart.calculateTips(subtotal, vg_selectedTips.value)
            let cartTipsAmt = vg_server_cart.cart_tips[userTipsIndex].amount
            if(parseFloat(tipsAmt).toFixed(2)!==parseFloat(cartTipsAmt).toFixed(2)){
                this.updateMyTips(vg_selectedTips, subtotal)
            }
            return tipsAmt
        }else{
            return await this.addMyTipsIfNotSet(subtotal)
        }
        
    },

    // change tips
    // returns - boolean
    async changeTipsIfNotMatch(){
        try {        
            let vg_selectedTips = store.getters['cart/selectedTips']
            let vg_serverCart = store.getters['cart/serverCart']
            let vg_cartId = store.getters['cart/cart_id']
            let vg_joinedCardId = store.getters['cart/joinedCardId']
            let vg_loginUser = store.getters['login/user']

            if(vg_serverCart && vg_serverCart.subtotal && vg_selectedTips){
                let tipsAmt
                if(vg_selectedTips.type==='percentage'){
                    tipsAmt =  ServerCart.calculateTips(vg_serverCart.subtotal, vg_selectedTips.value)
                }else{
                    tipsAmt = vg_selectedTips.value?vg_selectedTips.value:'0.00'
                }

                // if tips Amt and cart tips amt not match
                if(tipsAmt!=vg_serverCart.tip && vg_selectedTips.type!=='flat'){
                    let tipsData = {
                        "cart_id": vg_serverCart.id,
                        "value": vg_selectedTips.type==='flat'?tipsAmt:vg_selectedTips.value,
                        "type": vg_selectedTips.type,
                        "amount": tipsAmt,
                        "user_id": vg_loginUser.user.id
                    }
                    await store.dispatch('cart/addTips', tipsData)

                    let {data} = await CartAPI.getCart(vg_cartId?vg_cartId:vg_joinedCardId)
                    store.commit('cart/SET_SERVER_CART', data.payload)

                    // add to selected tips
                    store.commit('cart/SET_SELECTED_TIPS', {amount: tipsAmt, type: vg_selectedTips.type, value: vg_selectedTips.value})
                }
                return tipsAmt
            }
        }catch(err){
            return false
        }
    },

    async updateMyTips(tips, subtotal){
        try{
            if(!tips.type || !subtotal) return

            let vg_loginUser = store.getters['login/user']
            let vg_server_cart = store.getters['cart/serverCart']

            let tipsAmt
            if(tips.type==='percentage'){
                tipsAmt =  ServerCart.calculateTips(subtotal, tips.value)
            }else{
                tipsAmt = tips.value?tips.value:'0.00'
            }

            if(vg_server_cart.cart_tips && vg_server_cart.cart_tips.length>0){
                await this.removeTipsByUserIdIfExists(vg_loginUser.user.id)

                //add tips in server
                let tipsData = {
                    "cart_id": vg_server_cart.id,
                    "value": tips.value,
                    "type": tips.type,
                    "amount": tipsAmt,
                    "user_id": vg_loginUser.user.id
                }
                const {data} = await  CartAPI.addTips(tipsData)
                store.commit('cart/SET_SERVER_CART', data.payload)

                // add to selected tips
                store.commit('cart/SET_SELECTED_TIPS', {amount: tipsAmt, type: tips.type, value: tips.value})
                return tipsAmt
            }
        }catch(err){
            return
        }
    },

    async getMyTips(){
        let vg_loginUser = store.getters['login/user']
        let vg_server_cart = store.getters['cart/serverCart']

        let userTipsIndex = vg_server_cart.cart_tips.findIndex(tip => tip.user_id==vg_loginUser.user.id)
        if(userTipsIndex>=0){
            return vg_server_cart.cart_tips[userTipsIndex].amount
        }else{
            return 0
        }
    },

    isMyTipsSet(){
        let vg_loginUser = store.getters['login/user']
        let vg_server_cart = store.getters['cart/serverCart']

        let userTipsIndex = vg_server_cart.cart_tips.findIndex(tip => tip.user_id==vg_loginUser.user.id)
        if(userTipsIndex>=0){
            return true
        }else{
            return false
        }
    },

    // change tips
    // returns - boolean
    async resetTipsIfNotMatch(){
        try {       
            let vg_selectedTips = store.getters['cart/selectedTips']
            let vg_serverCart = store.getters['cart/serverCart']
            let vg_cartId = store.getters['cart/cart_id']
            let vg_joinedCardId = store.getters['cart/joinedCardId']
            let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']

            if(vg_serverCart && vg_serverCart.subtotal && vg_selectedTips && vg_selectedPaymentType=='AllByOne'){

                let tipsAmt =  ServerCart.calculateTips(vg_serverCart.subtotal, vg_selectedTips.value)
                // if tips Amt and cart tips amt not match
                if(vg_serverCart.cart_tips.length!=0 && tipsAmt!=vg_serverCart.tip){
                    await store.dispatch('cart/removeTips')
                    let {data} = await CartAPI.getCart(vg_cartId?vg_cartId:vg_joinedCardId)
                    store.commit('cart/SET_SERVER_CART', data.payload)
                    return true
                }
                return false
            }
        }catch(err){
            return false
        }
    },

    calculateTips(){
        try{
            let vg_selectedTips = store.getters['cart/selectedTips']
            let vg_serverCart = store.getters['cart/serverCart']
            if(vg_serverCart && vg_serverCart.subtotal && vg_selectedTips){
                let tipsAmt =  ServerCart.calculateTips(vg_serverCart.subtotal, vg_selectedTips.value)
                if(tipsAmt==vg_serverCart.tip){
                    return vg_serverCart.tip
                }else{
                    return 0
                }
            }
        }catch(err){
            return false
        }
    },

    async removeTipsByUserIdIfExists(userId){
        let vg_serverCart = store.getters['cart/serverCart']
        if(vg_serverCart && vg_serverCart.cart_tips){
            let findIndexOfTipsId = vg_serverCart.cart_tips.findIndex(tip => tip.user_id==userId)
            if(findIndexOfTipsId>=0){
                await CartAPI.removeTips(vg_serverCart.cart_tips[findIndexOfTipsId].id)
            }
        }
    },

    setTipsPercentageOrFlatFromCart(){
        let vg_serverCart = store.getters['cart/serverCart']

        if(vg_serverCart && vg_serverCart.cart_tips && vg_serverCart.cart_tips[0]){
            if(vg_serverCart.cart_tips[0].type==='percentage' && vg_serverCart.cart_tips[0].added_by!=='manager'){
                store.commit('cart/SET_SELECTED_TIPS', {value: vg_serverCart.cart_tips[0].value, type:vg_serverCart.cart_tips[0].type, amount: ''})
            }else if(vg_serverCart.cart_tips[0].added_by!=='manager'){
                store.commit('cart/SET_SELECTED_TIPS', {value: vg_serverCart.tip, type:vg_serverCart.cart_tips[0].type, amount: vg_serverCart.tip})
            }
            
        }
    },

    getServerAddedTipsFromCart(){
        let vg_serverCart = store.getters['cart/serverCart']

        let tipsAmt = 0
        if(vg_serverCart && vg_serverCart.cart_tips && vg_serverCart.cart_tips.length>0){
            vg_serverCart.cart_tips.forEach(tips => {
                if(tips.added_by==='manager'){
                    tipsAmt += tips.amount
                }
            })
        }
        return tipsAmt
    }
}