<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-btn style="position:absolute; right:30px; top: 45px; z-index:9999;" fab dark x-small @click.prevent="close">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-card class="faq-terms pa-5 text-justify">
                <div class="font-weight-bold">Welcome to GR8tables!</div>
                <div>Shopley Inc., operating as GR8tables ("GR8tables," "us," "our," "we", “Solution”), provides the services on behalf of hospitality venues (“merchant partner”) described in these Terms of Service to you through its GR8tables Web Application (the "Site") and through its mobile applications and related services (collectively, such services, including any new features and applications, and the Site, the "Service(s)"), subject to the following Terms of Service (as amended from time to time, the "Terms of Service"). Your use and continued use of the Service after the date any changes become effective constitutes your acceptance of the new Terms of Service. GR8tables reserves the right, at its sole discretion, to change or modify portions of these Terms of Service at any time. Changes will be posted to this page and will indicate at the top the date these terms were last revised, and we will notify you directly through the site, email or other forms of digital communication. Changes for new functionalities shall be effective immediately and all other changes shall become effective no earlier than fourteen days after they are posted.</div>
                <div>PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS IN ADDITION TO AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION. THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST GR8TABLES ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY A JURY OR IN A COURT OF LAW.</div>
                <div>In addition, when using certain services, you will be subject to any additional terms applicable to such services that may be posted on the Service from time to time, including, without limitation, the Privacy Policy located at https://Shopley.com/privacy. All such terms are hereby incorporated by reference into these Terms of Service.</div>
                <div class="text-decoration-underline">ABOUT GR8TABLES</div>
                <div>GR8tables is a service that connects GR8tables collectives or cooperatives (collectively,“Merchant Partner”) with their customers.</div>
                <div><b>GR8tables is a Technology Platform:</b> It is the sole responsibility of the merchant partner to offer its services to you in a legal and compliant manner, which are facilitated through its use of the Site and Services. You agree and acknowledge that GR8tables: (i) is a technology provider; (ii) does not itself provide delivery services to consumers; (iii) is not a restaurant, retailer, manufacturer or brand.</div>
                <div><b>Compliance with Provincial Law:</b> You expressly acknowledge that each Province may have specific bylaws and restrictions on recreational and medical use of the products and services sold by Dispensaries on GR8tables.ca. You expressly acknowledge and assume full responsibility for cooperating with the laws. Further, GR8tables disclaims any and all liability relating to the use of its website and applications in violation of any Federal or Provincial laws, rules and/or regulations.</div>
                <div class="text-decoration-underline">Access and Use of the Service</div>
                <div><b>Services Description:</b> GR8tables connects merchant partners with their customers (the "Services").</div>
                <div><b>Registration and Use:</b> In order to use certain features of the service you may be required to register with GR8tables AND Merchant Partners. If you choose to register with our Solution, you agree to provide and maintain current, accurate, complete and true information about yourself as requested at the time of registration or any other time information is requested. You acknowledge that GR8tables reserves the right to terminate accounts that are inactive for an extended period of time. Our Privacy Policy governs the data you provide and certain other information about you. By using the Services, you expressly represent and warrant that you are legally entitled and compliant with Provincial laws to enter into these Terms of Service, and have the right, authority, and capacity to enter into and abide by the terms and conditions of these Terms of Service. You acknowledge that GR8tables, at its sole discretion, may establish general practices and limits concerning use of the Service. GR8tables has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded by the Service. You further acknowledge that GR8tables reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.</div>
                <div><b>Security:</b> The security and protection of your password and other information is your responsibility and you are fully responsible for any and all activities that occur under your password or account. GR8tables may as part of the process of creating an account, ask you to select a username and password. GR8tables reserves the right, at its sole discretion to refuse a username or password if it deems it may be impersonating another person, may cause confusion, is disrespectful or otherwise offensive, or may be protected by trademark or other proprietary rights law. You agree to (i) ensure that you exit from your account at the end of each session on any device you are using when accessing the Service, and (ii) immediately notify GR8tables of any unauthorized use of your password or account or any other breach of security. GR8tables will not be liable for any loss or damage arising from your failure to comply with this Section.</div>
                <div><b>Services:</b> GR8tables reserves the right to modify, temporarily or permanently discontinue any Service (or any part thereof) with or without notice. You agree that GR8tables will not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</div>
                <div><b>Mobile and Communication:</b> GR8tables or Merchant Partner (through GR8tables) is available and may communicate with you on your mobile or other device through the browser, native app, SMS, Email standard charges, data rates and other fees from your carrier or other provider may apply. The Service includes certain services that are available via a mobile device, including (i) the ability to upload content to the Service via a mobile device, (ii) the ability to browse the Service and the Site from a mobile device and (iii) the ability to access certain features through an application downloaded and installed on a mobile device (collectively, the "Mobile Services"). To the extent you access the Service through a mobile device, In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by your carrier, and not all Mobile Services may work with all carriers or devices. By using the Mobile Services, you agree that we may communicate with you regarding GR8tables and other entities by SMS, MMS, text message or other electronic means to your mobile device and that certain information about your usage of the Mobile Services may be communicated to us. In the event you change or deactivate your mobile telephone number, you agree to promptly update your GR8tables account information to ensure that your messages are not sent to the person that acquires your old number.</div>
                <div class="text-decoration-underline">Transactions Users and Merchant Partners</div>
                <div>GR8tables may provide through the Site, electronic web-based platforms for exchanging information between Users and Merchant Partners of products and services. GR8tables does not control and is not liable or responsible for the quality, safety, lawfulness or availability of the products or services offered for sale on the Site, the ability of the Merchant Partners to complete a sale or the ability of Users to complete a purchase.</div>
                <div><b>Transaction Risk:</b> By using the Service you shall assume the risks of conducting any purchase and sale transactions in connection with or through the Site or Services. User accessing or using the Site or Services shall also fully assume all risks of liability or harm of any kind arising out of or in connection with any subsequent activity relating to the products or services that are the subject of the transactions on the Site. Examples of such risks shall include, but are not limited to, mis-representation of products and services, fraudulent schemes, unsatisfactory product quality, failure to meet specifications, defective or dangerous products, unlawful products, delay or default in delivery or payment, cost mis-calculations, breach of warranty, breach of contract, transportation accidents, the risk that the manufacture, importation, export, distribution, offer, display, purchase, sale and/or use of products or services offered or displayed on the Site may violate or may be asserted to violate third party rights, and the risk that you may incur costs of defense or other costs in connection with third parties' assertion of such third party rights, or in connection with any claims by any party that they are entitled to defense or indemnification in relation to the assertion of rights, demands or claims by claimants of third party rights. Examples of such risks also include the risk of claims from consumers, other purchasers, end-users of products or other third parties that they have suffered injuries or harm from their use of the products obtained through the Site or Services. All of the foregoing risks are referred to as "Transaction Risks". GR8tables is not liable or responsible for any damages, claims, liabilities, costs, harm, inconveniences, business disruptions or expenditures of any kind that may arise a result of or in connection with any Transaction Risks.</div>
                <div>You agree to provide all information and materials as may be reasonably required by GR8tables in connection with your transactions conducted on, through or as a result of use of the Site or Services. GR8tables has the right to suspend or terminate your account if you fail to provide the required information and materials without liability for any losses or damages arising out of or in connection with such suspension or termination.</div>
                <div>In the event that you have a dispute with any party to a transaction, you agree to release and indemnify GR8tables (and our agents, affiliates, directors, officers and employees) from all claims, demands, actions, proceedings, costs, expenses and damages (including without limitation any actual, special, incidental or consequential damages) arising out of or in connection with such dispute or the transaction.</div>
                <div class="text-decoration-underline">Membership Program</div>
                <div>
                    <ol>
                        <li>
                            <b>The Program</b>
                            <ol>
                                <li>The program is offered by Merchant Partner, referred to as "us", "our", "we", from this point forward</li>
                                <li>In order to participate in the Membership Program and this site, you warrant that the information provided during sign-up is accurate, current and complete. The Program is limited to one account per individual and is only for personal use.  We retain to our sole discretion to deny access to anyone on the site, the Membership program, and of services offered at any time or reason for any violations of this agreement.</li>
                            </ol>
                        </li>
                        <li>
                            <b>Enrollment &amp; Membership</b>
                            <ol>
                                <li>No purchase is required to join the program.   You may enroll online or in-store</li>
                                <li>You will be required to verify your email and/or phone number to complete enrollment in the Membership program </li>
                                <li>You are solely responsible for maintaining the accuracy of your account information </li>
                                <li>Your Membership program is unique to your email address and/or phone number and cannot be associated with more than one program account</li>
                                <li>By signing up for the Membership Program, you hereby accept the terms and conditions that apply to the program.  You understand that terms and conditions may change from time to time.  Updated Terms and Conditions will be date stamped for your reference.   By enrolling, you also agree to our [privacy policy] and [terms and conditions of our website].</li>
                                <li>
                                    <span>By participating in the Program, including by signing up for membership, you consent to receive Program-related emails that may be tailored to your perceived or identified interests, including:</span>
                                    <ol>
                                        <li>Program marketing emails;</li>
                                        <li>Program product marketing emails;</li>
                                        <li>Information regarding our stores, including when new stores are opened; and</li>
                                        <li>Information regarding our promotional and marketing events.</li>
                                    </ol>
                                </li>
                                <li>We also may give you the option to opt-in to receive other marketing communications from us at the time of enrolment in the Program.</li>
                                <li>If you wish to stop participating in the Program for any reason, you can do so by terminating your membership which is at your sole discretion.  We have no obligation, liability or responsibility to you except as expressed by law. If you wish to cancel your membership for any reason, you may do so from your account profile.</li>
                                <li>We reserve the right to audit your account for any reason from time to time, at our sole discretion.</li>
                            </ol>
                        </li>
                        <li>
                            <b>Program Changes</b>
                            <ol>
                                <li>We reserve the right to modify the Program at our sole discretion. This may include cancelling, modifying, restricting any aspect or feature related to the program, including the program as a whole without any prior notice, except where prohibited by law. </li>
                                <li>Material changes to the program will be communicated in advance by email or other means. </li>
                                <li>Continued participation in the program following changes to terms constitute acceptance of new and updated terms.  If you do not agree to any changes that have been made, you can stop using the site and all services.  You may terminate your membership at any time from your account profile.</li>
                            </ol>
                        </li>
                        <li>
                            <b>Earning and Redeeming Rewards</b>
                            <ol>
                                <li>The Program allows you to earn and redeem rewards based on a variety of ways at our sole discretion.  Please review the Merchant Partner FAQs for more details on earning and redeeming methods</li>
                                <li>Some products may be excluded at our sole discretion without any notice.  </li>
                                <li>Returns, exchanges &amp; refunds for items purchased through the Program are approved at the discretion of Merchant Partner store-specific policy on a case by case basis.</li>
                                <li>Discounts or promotions cannot be combined or stacked together with this program</li>
                                <li>Membership rewards can only be redeemed in-store or online and are only available to the account holder</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                
                <div class="text-decoration-underline">Third Party Distribution Channels</div>
                <div>GR8tables offers Software applications that may be made available through the Apple App Store, Android Marketplace or other distribution channels ("Distribution Channels"). If you obtain such Software through a Distribution Channel, you may be subject to additional terms of the Distribution Channel. These Terms of Service are between you and us only, and not with the Distribution Channel. To the extent that you utilize any other third party products and services in connection with your use of our Services, you agree to comply with all applicable terms of any agreement for such third party products and services.</div>
                <div class="text-decoration-underline">Intellectual Property Rights</div>
                <div><b>Service Content, Software and Trademarks:</b> You acknowledge and agree that the Service may contain content or features ("Service Content") that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorized by GR8tables, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the Service or the Service Content, in whole or in part, except that the foregoing does not apply to your own User Content (as defined below) that you legally upload to the Service. In connection with your use of the Service you will not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are blocked by GR8tables from accessing the Service (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Service or the Service Content other than as specifically authorized herein is strictly prohibited. The technology and software underlying the Service or distributed in connection therewith are the property of GR8tables, our affiliates and our partners (the "Software"). You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are reserved by GR8tables.</div>
                <div>The GR8tables name and logos are trademarks and service marks of GR8tables (collectively the "GR8tables Trademarks"). Other GR8tables, product, and service names and logos used and displayed via the Service may be trademarks or service marks of their respective owners who may or may not endorse or be affiliated with or connected to GR8tables. Nothing in this Terms of Service or the Service should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of GR8tables Trademarks displayed on the Service, without our prior written permission in each instance. All goodwill generated from the use of GR8tables Trademarks will inure to our exclusive benefit.</div>
                <div><b>Third Party Material:</b> Under no circumstances will GR8tables be liable in any way for any content or materials of any third parties (including users), including, but not limited to, for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge that GR8tables does not pre-screen content, but that GR8tables and its designees will have the right (but not the obligation) in their sole discretion to refuse or remove any content that is available via the Service. Without limiting the foregoing, GR8tables and its designees will have the right to remove any content that violates these Terms of Service or is deemed by GR8tables, in its sole discretion, to be otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any content, including any reliance on the accuracy, completeness, or usefulness of such content.</div>
                <div><b>User Content Transmitted Through the Service:</b> With respect to the content or other materials you upload through the Service or share with other users or recipients (collectively, "User Content"), you represent and warrant that you own all right, title and interest in and to such User Content, including, without limitation, all copyrights and rights of publicity contained therein. By uploading any User Content you hereby grant and will grant GR8tables and its affiliated companies a nonexclusive, worldwide, royalty free, fully paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, store, modify and otherwise use your User Content in connection with the operation of the Service or the promotion, advertising or marketing thereof, in any form, medium or technology now known or later developed.</div>
                <div>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the Service ("Submissions"), provided by you to GR8tables are non-confidential and GR8tables will be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.</div>
                <div>You acknowledge and agree that GR8tables may preserve content and may also disclose content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with legal process, applicable laws or government requests; (b) enforce these Terms of Service; (c) respond to claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of GR8tables, its users and the public. You understand that the technical processing and transmission of the Service, including your content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</div>
                <div><b>Copyright Complaints:</b> GR8tables respects the intellectual property of others, and we ask our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, you should notify GR8tables of your infringement claim in accordance with the procedure set forth below.</div>
                <div>GR8tables will process and investigate notices of alleged infringement and will take appropriate actions under the Digital Millennium Copyright Act ("DMCA") and other applicable intellectual property laws with respect to any alleged or actual infringement. A notification of claimed copyright infringement should be emailed to GR8tables's Copyright Agent at <a href="mailto:info@shopley.com">info@shopley.com</a> (Subject line: "DMCA Takedown Request"). You may also contact us by mail at:</div>
                <div>670 Caledonia Rd, suite 300, Toronto, ON, M6E 4V9</div>
                <div>To be effective, the notification must be in writing and contain the following information:</div>
                <div>
                    <ul>
                        <li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;</li>
                        <li>a description of the copyrighted work or other intellectual property that you claim has been infringed;</li>
                        <li>a description of where the material that you claim is infringing is located on the Service, with enough detail that we may find it on the Service;</li>
                        <li>your address, telephone number, and email address;</li>
                        <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law;</li>
                        <li>a statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</li>
                    </ul>
                </div>
                <div><b>Counter-Notice:</b> If you believe that your User Content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant to the law, to upload and use the content in your User Content, you may send a written counter-notice containing the following information to the Copyright Agent:</div>
                <div>
                    <ul>
                        <li>your physical or electronic signature;</li>
                        <li>identification of the content that has been removed or to which access has been disabled and the location at which the content appeared before it was removed or disabled;</li>
                        <li>a statement that you have a good faith belief that the content was removed or disabled as a result of mistake or a misidentification of the content; and</li>
                        <li>your name, address, telephone number, and email address, a statement that you consent to the jurisdiction of the federal court located within the District of Oregon and a statement that you will accept service of process from the person who provided notification of the alleged infringement.</li>
                    </ul>
                </div>
                <div>If a counter-notice is received by the Copyright Agent, GR8tables will send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider, member or user, the removed content may be replaced, or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at our sole discretion.</div>
                <div><b>Repeat Infringer Policy:</b> In accordance with the DMCA and other applicable law, GR8tables has adopted a policy of terminating, in appropriate circumstances and at GR8tables's sole discretion, users who are deemed to be repeat infringers. GR8tables may also at its sole discretion limit access to the Service and/or terminate the memberships of any users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</div>
                <div class="text-decoration-underline">Third Party Websites</div>
                <div>The Service may provide, or third parties may provide, links or other access to other sites and resources on the Internet. GR8tables has no control over such sites and resources and GR8tables is not responsible for and does not endorse such sites and resources. You further acknowledge and agree that GR8tables will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through any such site or resource. Any dealings you have with third parties found while using the Service are between you and the third party, and you agree that GR8tables is not liable for any loss or claim that you may have against any such third party.</div>
                <div class="text-decoration-underline">Social Networking Services</div>
                <div>You may enable or log in to the Service via various online third party services, such as social media and social networking services like Facebook or Twitter ("Social Networking Services"). By logging in or directly integrating these Social Networking Services into the Service, we make your online experiences richer and more personalized. To take advantage of this feature and capabilities, we may ask you to authenticate, register for or log into Social Networking Services on the websites of their respective providers. As part of such integration, the Social Networking Services will provide us with access to certain information that you have provided to such Social Networking Services, and we will use, store and disclose such information in accordance with our Privacy Policy. For more information about the implications of activating these Social Networking Services and GR8tables's use, storage and disclosure of information related to you and your use of such services within GR8tables (including your friend lists and the like), please see our Privacy Policy at <u>https://GR8tables.com/privacy</u>. However, please remember that the manner in which Social Networking Services use, store and disclose your information is governed solely by the policies of such third parties, and GR8tables shall have no liability or responsibility for the privacy practices or other actions of any third party site or service that may be enabled within the Service.</div>
                <div>In addition, GR8tables is not responsible for the accuracy, availability or reliability of any information, content, goods, data, opinions, advice or statements made available in connection with Social Networking Services. As such, GR8tables is not liable for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such Social Networking Services. GR8tables enables these features merely as a convenience and the integration or inclusion of such features does not imply an endorsement or recommendation.</div>
                <div class="text-decoration-underline">Indemnity and Release</div>
                <div>You agree to release, indemnify and hold GR8tables and its affiliates and their officers, employees, directors and agents (collectively, "Indemnitees") harmless from any from any and all losses, damages, expenses, including reasonable attorneys' fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Service, any User Content, your connection to the Service, your violation of these Terms of Service or your violation of any rights of another. Notwithstanding the foregoing, you will have no obligation to indemnify or hold harmless any Indemnitee from or against any liability, losses, damages or expenses incurred as a result of any action or inaction of such Indemnitee. If you are a California resident, you waive California Civil Code Section 1542, which says: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor." If you are a resident of another jurisdiction, you waive any comparable statute or doctrine.</div>
                <div class="text-decoration-underline">Disclaimer of Warranties</div>
                <div>YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. GR8tables EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</div>
                <div>GR8tables MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.</div>
                <div>GR8tables MAKES NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY OF ANY SERVICES, PRODUCTS OR GOODS OBTAINED BY THIRD PARTIES THROUGH THE USE OF THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY THIRD-PARTY SERVICES OR PRODUCTS, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED BY LAW.</div>
                <div class="text-decoration-underline">Limitation of Liability</div>
                <div>YOU EXPRESSLY UNDERSTAND AND AGREE THAT GR8tables WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF GR8tables HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL GR8tables'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID GR8tables IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).</div>
                <div>SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.</div>
                <div>IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS TITLED "DISCLAIMER OF WARRANTIES" AND "LIMITATION OF LIABILITY" ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.</div>
                <div class="text-decoration-underline">Spamming</div>
                <div>GR8tables  respects  all  anti-spam  laws  and  regulations.  If  you  receive  any  email  or  other  electronic communication from GR8tables, the name GR8tables.ca or Buyers Unite will always be clearly indicated. As  a  user,  GR8tables  reminds  you  that  you  have  expressly  accepted  that  emails  or  other  commercial electronic messages be sent to you by GR8tables. You have the right to unsubscribe from our service at any time and free of charge, either by clicking on the unsubscribe option on the emails GR8tables sends you or by sending GR8tables an email requesting to be unsubscribed (see “Contact Us” section below).</div>
                <div>Gathering email addresses from GR8tables through harvesting or automated means is prohibited.  Posting or  transmitting  unauthorised  or  unsolicited  advertising,  promotional  materials,  or  any  other  forms  of solicitation to other users is prohibited. </div>
                <div>Inquiries regarding a commercial relationship with GR8tables or if you have any concerns relating to this section, please see the “Contact Us” section below.</div>
                <div class="text-decoration-underline">Jurisdiction</div>
                <div>The  Website  is  directed  to  those  individuals  and  entities  located  in  Canada  and  USA  or  purchasing products  and  services  in  Canada  and  USA.  It  is  not  directed  to  any  person  or  entity  in  any  jurisdiction where the publication or availability of the Website and its content, including its products and services, are  unavailable  or  otherwise  contrary  to  local  laws  or  regulations.  If  this  applies  to  you,  you  are  not authorised to access or use any of the information on this Website. GR8tables makes no representation that the information, opinions, advice or other content on the Website is appropriate or that its products and services are available outside Canada. Those who choose to access this Website from other locations do so at their own risk and are responsible for compliance with applicable local laws.</div>
                <div class="text-decoration-underline">Governing Law, Location and Miscellaneous</div>
                <div>These  Terms  of  Use  shall  be  governed  in  all  respects  by  the  laws  of  the  Province  of  Ontario,  Canada, without reference to its choice of law rules. If an applicable law is in conflict with any part of the Terms of Use, the Terms of Use will be deemed modified to conform to the law. The other provisions will not be affected by any such modification.</div>
                <div class="text-decoration-underline">Separate Agreements</div>
                <div>You may have other agreements with GR8tables or Shopley Inc.. Those agreements are separate and in addition to these Terms of Use. These Terms of Use do not modify, revise or amend the terms of any other agreements you may have with GR8tables.</div>
                <div class="text-decoration-underline">No Professional Advice</div>
                <div>The information available on the Website is intended to be a general information resource regarding the matters covered, and is not tailored to your specific circumstance.</div>
                <div class="text-decoration-underline">Users Disputes</div>
                <div>You are solely responsible for your interactions with other users. GR8tables reserves the right, but has no obligation, to monitor disputes between you and other users.</div>
                <div class="text-decoration-underline">Arbitration</div>
                <div>Except  as  regarding  any  action  seeking  equitable  relief,  including  without  limitation  for  the  purpose  of protecting any GR8tables confidential information and/or intellectual property rights, any controversy or claim arising out of or relating to these Terms of Use or this Website shall be settled by binding arbitration before a single arbitrator and in accordance with the provisions of the Ontario Arbitration Act. Any such controversy  or  claim  shall  be  arbitrated  on  an  individual  basis,  and  shall  not  be  consolidated  in  any arbitration  with  any  claim  or  controversy  of  any  other  party.  The  arbitration  shall  be  held  in  Toronto, Ontario, Canada, without giving effect to any principles that may provide for the application of the laws of another jurisdiction.</div>
                <div>All  information  relating  to  or  disclosed  by  any  party  in  connection  with  the  arbitration  of  any  disputes hereunder shall be treated by the parties, their representatives, and the arbitrator as proprietary business information.  Such  information  shall  not  be  disclosed  by  any  party  or  their  respective  representatives without the prior written authorisation of the party furnishing such information. Such information shall not be disclosed by the arbitrator without the prior written authorisation of all parties. Each party shall bear the burden of its own counsel fees incurred in connection with any arbitration proceedings.</div>
                <div>Judgment upon the award returned by the arbitrator may be entered in any court having jurisdiction over the parties or their assets or application of enforcement, as the case may be. Any award by the arbitrator shall be the sole and exclusive remedy of the parties. The parties hereby waive all rights to judicial review of the arbitrator's decision and any award contained therein.</div>
                <div class="text-decoration-underline">Limitation of Liability</div>
                <div>YOUR USE OF THE CONTENT IS AT YOUR OWN RISK. GR8TABLES SPECIFICALLY DISCLAIMS ANY LIABILITY, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, FOR ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY  CONNECTED  WITH  ACCESS  TO,  USE  OF  OR  RELIANCE  ON  THE  CONTENT  (EVEN  IF  GR8TABLES  HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) OR THAT ARISE IN CONNECTION WITH MISTAKES OR OMISSIONS IN, OR DELAYS IN TRANSMISSION OF, INFORMATION TO OR FROM THE USER, ANY FAILURE OF  PERFORMANCE,  ERROR,  OMISSION,  INTERRUPTION,  DELETION,  DEFECT,  DELAY  IN  OPERATION  OR TRANSMISSION   OR   DELIVERY,   COMPUTER   VIRUS,   COMMUNICATION   LINE   FAILURE,   THEFT   OR DESTRUCTION  OR  UNAUTHORISED  ACCESS  TO,  ALTERATION  OF,  OR  USE  OF  RECORDS,  PROGRAMS  OR FILES,   INTERRUPTIONS   IN   TELECOMMUNICATIONS   CONNECTIONS   TO   THE   WEBSITE   OR   VIRUSES, WHETHER  CAUSED  IN  WHOLE  OR  IN  PART  BY  NEGLIGENCE,  ACTS  OF  GOD,  TELECOMMUNICATIONS FAILURE, THEFT OR DESTRUCTION OF, OR UNAUTHORISED ACCESS TO THE WEBSITE OR THE CONTENT. THIS  LIMITATION  OF  LIABILITY  MAY  BE  DIFFERENT  IN  CONNECTION  WITH  SPECIFIC  PRODUCTS  AND SERVICES OFFERED BY GR8TABLES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY, IN WHICH CASE THIS SECTION SHALL BE AMENDED TO COMPLY WITH APPLICABLE LEGISLATION.</div>
                <div class="text-decoration-underline">Indemnity</div>
                <div>You agree to defend, indemnify, and hold GR8tables, its directors, officers, employees, agents, licensors, and  suppliers,  harmless  from  and  against  any  claims,  actions  or  demands,  liabilities  and  settlements including  without  limitation,  reasonable  legal  and  accounting  fees,  resulting  from,  or  alleged  to  result from, your violation of these Terms of Use and the Privacy Policy.</div>
                <div class="text-decoration-underline">Accounts and Security</div>
                <div>This section only applies to registered users.</div>
                <div>GR8tables does not warrant that the functions contained in the service provided by the Website will be uninterrupted or error-free, that defects will be corrected or that this service or the server that makes it available will be free of viruses or other harmful components.</div>
                <div>As  part  of  the  registration  process,  each  user  will  select  a  password  (<b>“Password”</b>)  and  Username (<b>“Username”</b>). You shall provide GR8tables with accurate, complete, and updated Account information. </div>
                <div>Failure to  do  so  shall  constitute  a  breach  of  these  Terms  of  Use,  which  may  result  in  immediate termination of your account.</div>
                <div>You may not:</div>
                <div>
                    <ol>
                        <li>select or use a Username of another person with the intent to impersonate that person;</li>
                        <li>use a name subject to the rights of any other person without authorisation;</li>
                        <li>use a Username that Website, in its sole discretion, deems inappropriate or offensive.</li>
                    </ol>
                </div>
                <div>You shall notify GR8tables of any known or suspected unauthorised use(s) of your Account, or any known or suspected breach of security, including loss, theft, or unauthorised disclosure of your password.   You shall be responsible for maintaining the confidentiality of your password.</div>
                <div>Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your account, at GR8tables’s sole discretion, and you may be reported to appropriate law-enforcement agencies.</div>
                <div class="text-decoration-underline">Termination</div>
                <div>You agree that GR8tables, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of the Service and remove and discard any content within the Service, for any reason, including, without limitation, for lack of use or if GR8tables believes that you have violated or acted inconsistently with the letter or spirit of these Terms of Service. Any suspected fraudulent, abusive or illegal activity that may be grounds for termination of your use of Service, may be referred to appropriate law enforcement authorities. GR8tables may also in its sole discretion and at any time discontinue providing the Service, or any part thereof, with or without notice. You agree that any termination of your access to the Service under any provision of this Terms of Service may be effected without prior notice, and acknowledge and agree that GR8tables may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Service. Further, you agree that GR8tables will not be liable to you or any third party for any termination of your access to the Service.</div>
                <div class="text-decoration-underline">User Disputes</div>
                <div>You agree that you are solely responsible for your interactions with any other user in connection with the Service and GR8tables will have no liability or responsibility with respect thereto. GR8tables reserves the right, but has no obligation, to become involved in any way with disputes between you and any other user of the Service.</div>
                <div class="text-decoration-underline">Your Privacy</div>
                <div>At GR8tables, we respect the privacy of our users. For details please see our Privacy Policy. By using the Service, you consent to our collection and use of personal data as outlined therein.</div>
                <div class="text-decoration-underline">Questions? Concerns? Suggestions?</div>
                <div>Please contact us at <a href="mailto:info@shopley.com">info@shopley.com</a> to report any violations of these Terms of Service or to pose any questions regarding this Terms of Service or the Service.</div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
export default {
    computed: {
        dialog: {
            get(){
                return this.vg_faqTermsPop
            },
            set(val){
                this.vm_faqTermsPop(val)
            }
        },
        ...mapGetters({
            vg_faqTermsPop: 'faq/termsPop'
        })
    },
    methods: {
        close(){
            this.vm_faqTermsPop(false)
        },
        ...mapMutations({
            vm_faqTermsPop: 'faq/SET_TERMS_POP'
        })
    }
}
</script>
<style scoped>
    .faq-terms div{
        margin-bottom: 20px;
    }
    .faq-terms ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }
    .faq-terms ol > li {
        display: table;
        counter-increment: item;
        margin-bottom: 0.6em;
    }
    .faq-terms ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;    
    }
    .faq-terms li ol > li {
        margin: 0;
    }
    .faq-terms li ol > li:before {
        content: counters(item, ".") " ";
    }
</style>