<template>
    <div>
        <v-dialog
        v-model="dialog"
        width="500"
        persistent
        >
            <v-card>
                <v-card-title class="mt-0 pt-0">
                    <v-spacer />
                    Allergens
                    <v-spacer />
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="4" class="primary--text font-weight-bold" style="text-transform: uppercase; min-width:125px;">May Contain:</v-col>
                        <v-col cols="8" class="font-weight-bold">{{mayContains.join(', ')}}.</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" class="error--text font-weight-bold" style="text-transform: uppercase; min-width:125px;">Contains:</v-col>
                        <v-col cols="8" class="font-weight-bold">{{contains.join(', ')}}.</v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn block class="primary" @click.prevent="close">Back</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: ["tagInfo", "item"],
    data(){
        return {
            dialog: false,
            mayContains: [],
            contains: []
        }
    },
    mounted(){
        this.mayContains = []
        this.contains = []
        this.getContains()
        this.getMayContains()
        this.dialog = this.tagInfo
    },
    watch: {
        tagInfo(value){
            this.mayContains = []
            this.contains = []
            this.getContains()
            this.getMayContains()
            this.dialog = value
        }
    },
    methods: {
        getContains(){
            if(this.item.tags){
                this.item.tags.forEach(tag => {
                    if(tag.type=='contains'){
                        this.contains.push(tag.name)
                    }
                })
            }
        },
        getMayContains(){
            if(this.item.tags){
                this.item.tags.forEach(tag => {
                    if(tag.type=='may_contain'){
                        this.mayContains.push(tag.name)
                    }
                })
            }
        },
        close(){
            this.$emit("close")
        }
    }
}
</script>