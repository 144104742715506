export default {
    namespaced: true,
    state: {
      tab: 0
    },
    getters: {
        tab: state => state.tab
    },
    mutations: {
        SET_TAB(state, data) {
            state.tab = data
        }
    }
}