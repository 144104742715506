<template>
    <v-container style="background-color:#f9f9f9;">
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="startedCountDown">
                <div class="mt-2">Hang tight, placing your order... </div>
                <div class="text-center mb-1">({{timeLeft}}s...)</div>
                <v-progress-linear :value="progress" color="primary" />
            </v-toolbar-title>
            <v-toolbar-title v-else-if="vg_orderType=='dinein'">Checkout</v-toolbar-title>
            <v-toolbar-title v-else>Review Order</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <loading-block v-if="loading" class="pt-9" />
        <div v-else style=" background-color: #f9f9f9; margin-bottom:100px;">
            <show-pickup-at v-if="startedCountDown && vg_orderType=='pickup'" />
            <pickup-at v-else-if="vg_orderType=='pickup'" />

            <show-delivery-at v-if="startedCountDown && vg_orderType=='delivery'" />
            <delivery-at v-else-if="vg_orderType=='delivery'" />

            <v-card class="mx-2 my-2 py-3 saw-tooth" elevation="0">
                <v-card-text class="pb-0">
                    <template v-for="(userItem, userindex) in cart">
                        <div v-if="userindex==''" :key="'cat-'+userindex" class="title black--text pb-2">
                            <img class="mr-2" style="vertical-align: middle"  src="@/assets/img/Bill.png" width="28" height="28" />
                            <span style="opacity:1; color: black;">Bill</span>
                        </div>
                        <div v-else :key="'cat-'+userindex" class="title text-center black--text pb-2">{{getUserNameOrPhone(userindex, vg_server_cart.cart_users)}}</div>
                        <template v-if="userItem">
                            <template v-for="(cartItem, index) in userItem">
                                <template v-if="vg_orderType=='dinein'">
                                    <!-- Dine in -->
                                    <v-list class="py-0" v-if="cartItem.sent_to_kitchen" :key="'list-'+userindex+index">
                                        <v-list-item style="min-height: 25px !important;" class="px-0 my-0" >
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                                <div>{{getQtyOrInDecimal(cartItem)}}</div>
                                            </v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0">
                                                    <v-list-item-title>{{cartItem.name}}</v-list-item-title>
                                                    <v-list-item-content class="pt-0 mt-0">
                                                        <!-- Modifier items -->
                                                        <template v-for="(item_modifier, modifierIndex) in cartItem.cart_item_modifiers">
                                                            <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'mod-'+modifierIndex">{{item_modifier.name}}</div>
                                                            <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                                <div v-if="modifier_item.qty>0" class="text--secondary" :key="'modItem-'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} {{(modifier_item.qty>1)?'('+modifier_item.qty+'x)':''}}</div>
                                                            </template>
                                                        </template>
                                                        <!-- Note -->
                                                        <template v-if="cartItem.note">
                                                            <div class="text--secondary">Item note:</div>
                                                            <div class="text--secondary" style="overflow-wrap:anywhere;">{{cartItem.note}}</div>
                                                        </template>
                                                    </v-list-item-content>
                                                </v-list-item-content>
                                            <v-list-item-action class="my-0 py-0">
                                                <v-list-item-action-text>
                                                    <span :class="(paidOrPrice(cartItem)=='Paid')?'green--text pr-3 body-2 font-weight-bold':'pr-3 body-2'" style="color:black;">{{paidOrPrice(cartItem)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <!-- <template v-else >
                                        <div v-if="index===0" :key="'null-'+index" class="ml-5 pl-3">Empty Bill</div>
                                    </template> -->
                                </template>

                                <!-- Pickup -->
                                <template v-else>
                                    <v-list class="py-0" :key="'list-'+userindex+index">
                                        <v-list-item style="min-height: 25px !important;" class="px-0 my-0" >
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                                <div>{{getQtyOrInDecimal(cartItem)}}</div>
                                            </v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0">
                                                    <v-list-item-title>{{cartItem.name}}</v-list-item-title>
                                                    <v-list-item-content class="pt-0 mt-0">
                                                        <!-- Modifier items -->
                                                        <template v-for="(item_modifier, modifierIndex) in cartItem.cart_item_modifiers">
                                                            <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'mod-'+modifierIndex">{{item_modifier.name}}</div>
                                                            <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                                <div v-if="modifier_item.qty>0" class="text--secondary" :key="'modItem-'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} {{(modifier_item.qty>1)?'('+modifier_item.qty+'x)':''}}</div>
                                                            </template>
                                                        </template>
                                                        <!-- Note -->
                                                        <template v-if="cartItem.note">
                                                            <div class="text--secondary">Item note:</div>
                                                            <div class="text--secondary" style="overflow-wrap:anywhere;">{{cartItem.note}}</div>
                                                        </template>
                                                    </v-list-item-content>
                                                </v-list-item-content>
                                            <v-list-item-action class="my-0 py-0">
                                                <v-list-item-action-text >
                                                    <span class="pr-3 body-2" style="color:black;">${{calculatePrice(cartItem)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </template>
                            </template>
                        </template>
                        <!-- Subtotal -->
                        <v-list class="py-0" :key="'sub-'+userindex" v-if="!startedCountDown && isMultipleUserOnTable()">
                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                    <v-list-item-title class="my-0 py-0 font-weight-bold">Subtotal</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                    <v-list-item-action-text style="color:black;">
                                        <span class="pr-3 body-2 font-weight-bold" style="color:black;">${{userItemSubtotal(userItem)}}</span>
                                    </v-list-item-action-text>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider class="my-3" :key="'sub-d-'+userindex" />
                        </v-list>
                    </template>
                    <!-- Subtotal -->
                    <v-list class="py-0" v-if="!startedCountDown">
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0 font-weight-bold">Subtotal</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text>
                                    <span class="pr-3 body-2 font-weight-bold" style="color:black;">${{subtotal}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- My Subtotal -->
                        <v-list-item v-if="vg_selectedPaymentType=='splitEvenly'" class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">My Subtotal</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text>
                                    <span class="pr-3 body-2" :style="'color:black;'+((promoCodes.length>0 || loyalties.length>0)?'text-decoration:line-through':'')">${{((parseFloat(subtotal)/countActiveUser)).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- Promo Code -->
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-if="promoCodes && promoCodes.length>0">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0" style="white-space:inherit">Promo Code:
                                    <template v-for="(eachPromo,ind) in promoCodes">
                                        <v-chip v-if="eachPromo" :key="ind" class="mb-1 mr-1" outlined label close :disabled="promoAmt==0 || eachPromo.status=='used'" @click:close="removePromo(eachPromo)">{{eachPromo.code}}</v-chip>
                                    </template>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text>
                                    <span class="pr-3 body-2" style="color:green;">-${{(parseFloat(showPromoAmt)).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- Redeem -->
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-if="loyalties && loyalties.length>0">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0" style="white-space:inherit">Point Used:
                                    <template v-for="(eachLoyalty,ind) in loyalties">
                                        <v-chip v-if="eachLoyalty && eachLoyalty.points_to_redeem>0" :key="ind" class="mb-1 mr-1" outlined label close :disabled="eachLoyalty.user_id!==vg_loginUser.user.id || eachLoyalty.status=='completed'" @click:close="removeRedeem(eachLoyalty)">{{eachLoyalty.points_to_redeem}}</v-chip>
                                    </template>
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text>
                                    <span class="pr-3 body-2" style="color:green;">-${{(parseFloat(showRedeemAmt)).toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- New Subtotal -->
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-if="(promoCodes && promoCodes.length>0) || (loyalties && loyalties.length>0)">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0 font-weight-bold">New Subtotal</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text>
                                    <span class="pr-3 body-2 font-weight-bold" style="color:green;" v-if="vg_selectedPaymentType=='splitEvenly'">${{calculationFix((parseFloat(subtotal)/countActiveUser)-parseFloat(promoAmt)-parseFloat(redeemAmt))}}</span>
                                    <span class="pr-3 body-2 font-weight-bold" style="color:green;" v-else>${{calculationFix(parseFloat(subtotal)-parseFloat(promoAmt)-parseFloat(redeemAmt))}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- delivery fee -->
                        <v-list-item v-if="vg_orderType=='delivery'" class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Delivery Fee</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{vg_deliveryFee.toFixed(2)}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- Tax and total -->
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 py-0">Tax</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text style="color:black;">
                                    <span class="pr-3 body-2" style="color:black;">${{taxAmt}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-if="vg_selectedPaymentType==='splitEvenly'">
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 pt-1 pb-0 title">Total ({{getSplitPercent()}}%)</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text >
                                    <span class="pr-3 title" style="color:black;">${{getSplitSubtotal()}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-else>
                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                <v-list-item-title class="my-0 pt-1 pb-0 title">Total</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                <v-list-item-action-text >
                                    <span class="pr-3 title" style="color:black;">${{totalWithoutTips}}</span>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
            <v-container style="height:670px;" v-if="!startedCountDown">
                <template v-if="!single_user && vg_orderType=='dinein'">
                    <v-row>
                        <v-col cols="12" class="ml-5">
                            <img class="mr-2" style="vertical-align: middle"  src="@/assets/img/cashor_credit.png" width="24" height="24" />
                            <span class="font-weight-bold title" style="opacity:1; color: black;">Checkout Option</span>
                            <v-spacer />
                        </v-col>
                    </v-row>
                    <v-row class="mx-2 ">
                        <v-col cols="4" class="text-center py-0 my-0 body-2">My Items</v-col>
                        <v-col cols="4" class="text-center py-0 my-0 body-2">Whole Bill</v-col>
                        <v-col cols="4" class="text-center py-0 my-0 body-2">Split Evenly</v-col>
                    </v-row>
                    <v-row class="mx-3 py-0 my-0">
                        <v-col cols="4"><v-btn elevation="0" text outlined block :class="vg_selectedPaymentType=='payForMyOrder'?'primary':'primary--text text--lighten-2'" :dark="vg_selectedPaymentType=='payForMyOrder'" @click.prevent="selectMyBill" :disabled="((vg_selectedPaymentType=='splitEvenly' && vg_server_cart.paid>0 || this.vg_disableMyItemBtn))">${{myTotal}}</v-btn></v-col>
                        <v-col cols="4"><v-btn elevation="0" text outlined block :class="vg_selectedPaymentType=='AllByOne'?'primary':'primary--text text--lighten-2'" :dark="vg_selectedPaymentType=='AllByOne'" @click.prevent="selectAllByOne" :disabled="((vg_selectedPaymentType=='splitEvenly' && vg_server_cart.paid>0) || this.vg_disableWholeItemBtn)">${{wholeTotal}}</v-btn></v-col>
                        <v-col cols="4"><v-btn elevation="0" text outlined block :class="vg_selectedPaymentType=='splitEvenly'?'primary':'primary--text text--lighten-2'"  :dark="vg_selectedPaymentType=='splitEvenly'" @click.prevent="selectSplitEvenly" :disabled="(vg_server_cart.paid>0 || getTotalWithoutTips()==0 || this.vg_disableSplitEvenlyBtn)">Split</v-btn></v-col>
                    </v-row>
                </template>
                <template v-if="vg_loyaltyEnabled && needToBeActivateRedeem()">
                    <redeem />
                </template>
                <v-form ref="promoForm" v-if="needToBeActivatePromo()"  @submit.prevent="addPromo" style="width:100%">
                    <div class="ma-5">
                        <v-text-field v-model="promo" small outlined dense label="Promo Code" :disabled="vg_disablePayBtn">
                            <v-btn :disabled="!promo" slot="append" class="font-weight-bold pb-2" style="cursor:pointer;" plain text elevation="0" type="submit">APPLY</v-btn>
                        </v-text-field>
                    </div>
                </v-form>
                <v-row>
                    <v-col cols="12" class="text-center" v-if="!vg_server_cart.cart_default_tip">
                        <v-btn @click.prevent="selectTips(tip)" :class="(selectedTip.value==tip.value || (selectedTip.type=='flat' && tip.type=='flat'))?'primary mr-2 mt-2':'primary--text text--lighten-2 mr-2 mt-2'" color="#fff" v-for="(tip, ind) in tips" :key="ind">
                            <span v-if="tip.type==='percentage'" v-text="tip.value+'%'"></span>
                            <span v-else>Edit</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" v-if="selectedTip.type==='flat'">
                        <v-text-field class="mx-4" dense label="Tip(s) Amount" outlined type="number" min="0" :value="parseFloat(tipsAmt).toFixed(2)" @keydown="validValueForTips" @change="v => manualTipsAmt(v)"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="ml-1 mr-5 mb-3">
                    <v-col v-if="vg_server_cart.cart_default_tip" cols="6" class="py-0"><span>Tip(s) ({{vg_server_cart.cart_default_tip.value}}%)</span></v-col>
                    <v-col v-else cols="6" class="py-0"><span>Tip(s)</span></v-col>
                    <v-col cols="6" class="py-0 text-right"><span>${{parseFloat(tipsAmt).toFixed(2)}}</span></v-col>
                </v-row>
                <v-row class="ml-1 mr-5 mb-3">
                    <v-col cols="4" class="py-0"><span>Pay with</span></v-col>
                    <v-col cols="8" class="py-0 text-right">
                        <span>
                            <v-select class="mt-0 pt-0" hide-details="" v-model="selected_cart" :items="credit_cards" item-text="last_four" item-value="id" label="Select Payment Option"></v-select>
                        </span>
                    </v-col>
                </v-row>
                <v-row class="ml-1 mr-5 mb-3" v-if="vg_showFiskaAddress && vg_selectedPayment">
                    <v-col cols="12" class="pa-0">
                        <v-text-field hide-details="" class="mx-4" dense label="Billing Address" outlined v-model="address"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="ml-1 mr-5 mb-3" v-if="vg_showFiskaAddress && vg_selectedPayment">
                    <v-col cols="12" class="pa-0">
                        <v-text-field hide-details="" class="mx-4" dense label="Billing Postal Code" outlined v-model="postal_code"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="ml-1 mr-5 pb-5 font-weight-bold">
                    <v-col cols="6" class="py-0 title"><span>Total</span></v-col>
                    <v-col cols="6" class="py-0 text-right title" v-if="vg_selectedPaymentType=='splitEvenly'"><span>$ {{getSplitTotal()}}</span></v-col>
                    <v-col cols="6" class="py-0 text-right title" v-else><span>${{total}}</span></v-col>
                </v-row>
                <version />
            </v-container>
        </div>

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="browserOrder">Browse &amp; Order</v-btn>
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="inviteFriend" :disabled="(vg_orderType!=='dinein' || isSplitEvenlyPaid())">Invite A Friend</v-btn>
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="account">Account</v-btn>
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || (vg_orderType!=='dinein') || !!vg_callServerValue">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn class="left-btn" dark @click.prevent="undo" v-if="startedCountDown">Undo ({{timeLeft}}S)</v-btn>
                        <v-btn class="left-btn" dark @click.prevent="back()" v-else>Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                            <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="clearCountdownPay" class="right-btn" dark v-if="startedCountDown">Submit</v-btn>
                        <v-btn @click.prevent="startCountdown" class="right-btn" dark :disabled="isNotAllowedToPay()" v-else-if="vg_orderType!=='dinein'">Pay</v-btn>
                        <v-btn @click.prevent="pay" class="right-btn" dark :disabled="isNotAllowedToPay()" v-else>Pay</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
        <split-bill />
    </v-container>
</template>

<script>
import { StringHelper } from '@/helpers/stringHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {PartialPaymentHelper} from '@/helpers/PartialPaymentHelper'
import {PromoHelper} from '@/helpers/PromoHelper'
import {TipsHelper} from '@/helpers/tipsHelper'
import {LoyaltyHelper} from '@/helpers/LoyaltyHelper'
import {CartAPI} from '@/clients/cart'
import {PaymentAPI} from '@/clients/payment'
import PickupAt from './pickupAt'
import ShowPickupAt from './showPickupAt'
import DeliveryAt from './deliveryAt'
import ShowDeliveryAt from './showDeliveryAt'
import SplitBill from './splitBill'
import Redeem from './redeem'
import moment from 'moment'
import 'moment-timezone'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Version from '@/components/custom-components/version'
import { CartUser } from '../../helpers/cartUserHelper'
import { LoyaltyAPI } from '@/clients/loyalty/index'

export default {
    components: {
        PickupAt,
        ShowPickupAt,
        DeliveryAt,
        ShowDeliveryAt,
        SplitBill,
        Redeem,
        Version
    },
    data(){
        return {
            loading: false,
            option: false,
            url: '',
            tips: [
                {
                    value: '15',
                    type: 'percentage',
                    amount: ''
                },
                {
                    value: '20',
                    type: 'percentage',
                    amount: ''
                },
                {
                    value: '30',
                    type: 'percentage',
                    amount: ''
                }, 
                {
                    value: '',
                    type: 'flat',
                    amount: ''
                }
            ],

            // 
            countActiveUser:0,

            // dom
            cart: {},
            promo: '',
            promoCodes: [],
            loyalties: [],
            subtotal: 0,
            promoAmt: 0,
            showPromoAmt: 0,    // just to show if paid
            redeemAmt: 0,
            showRedeemAmt: 0,   // just to show if paid
            taxAmt: 0,
            totalWithoutTips: 0,
            tipsAmt: 0,
            total: 0,
            credit_cards: [],
            single_user: false,

            // fiska
            address: '',
            postal_code: '',

            wholeSubtotal: 0,
            wholePromoAmt: 0,
            wholeRedeemAmt: 0,
            wholeTaxAmt: 0,
            wholeTotal: 0,

            mySubtotal: 0,
            myPromoAmt: 0,
            myRedeemAmt: 0,
            myTaxAmt: 0,
            myTotal: 0,

            // countdown
            startedCountDown: false,
            progress :0,
            timeLeft: 13,
            interval: null

        }
    },
    computed: {
        selectedTip: {
            get(){
                return this.vg_selectedTips;
            },
            set(value){
                this.vm_selectedTips(value)
            }
        },
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        selected_cart: {
            get(){
                return this.vg_selectedPayment
            },
            set(val){
                this.vm_selectedPayment(val)
            }
        },
        ...mapGetters({
            vg_location: 'location/location',
            vg_server_cart: 'cart/serverCart',
            vg_kitchen_data: 'cart/kitchenData',
            vg_cartId: 'cart/cart_id',
            vg_loginUser: 'login/user',
            vg_paymentToken: 'payment/token',
            vg_payments: 'payment/payments',
            vg_selectedPayment: 'payment/selectedPayment',
            vg_businessName: 'location/businessName',
            vg_businessId: 'location/business_id',
            vg_notification: 'cartUser/notification',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_tableId: 'cart/tableId',
            vg_newCart: 'cart/newCart',
            vg_tips: 'cart/tipsData',
            vg_orderType: 'cart/orderType',
            vg_pickupAt: 'cart/pickupAt',
            vg_pickupDate: 'cart/pickupDate',
            vg_pickupTime: 'cart/pickupTime',
            vg_selectedTips: 'cart/selectedTips',
            vg_lockCheckout: 'cart/lockCheckout',
            vg_payed: 'payment/payed',
            vg_payer: 'payment/payer',
            vg_selectedPaymentType:'payment/selectedPaymentType',
            vg_selectedCartItems: 'cart/selectedCartItems',
            vg_callServerValue: 'menu/callServerValue',
            vg_loyaltyEnabled: 'loyalty/enabled',
            vg_disableMyItemBtn: 'payment/disableMyItemBtn',
            vg_disableWholeItemBtn: 'payment/disableWholeItemBtn',
            vg_disableSplitEvenlyBtn: 'payment/disableSplitEvenlyBtn',
            vg_disablePayBtn: 'payment/disablePayBtn',
            vg_deliveryFee: 'delivery/deliveryFee',
            vg_deliveryAt: 'delivery/deliveryAt',
            vg_deliveryAddress : 'delivery/address',
            vg_deliveryPhone: 'delivery/phone',
            vg_deliveryDate: 'delivery/deliveryDate',
            vg_deliveryTime: 'delivery/deliveryTime',
            vg_deliveryInstruction: 'delivery/note',
            vg_geoLocation: 'delivery/geoLocation',
            vg_showFiskaAddress: 'payment/showFiskaAdress',
            vg_fiskaPopup: 'payment/fiskaPopup',
            vg_loginWith: 'login/loginWith'

        })
    },
    async mounted(){
        this.loading = true
        // this.vm_selectedCartItems([])
        // location timezone
        let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'
        moment.tz.setDefault(timezone)

        // set location for store hour
        await this.va_setLocationOnly({locationName:this.vg_location.slug ,businessId:this.vg_businessId})

        // check if payment is done - route to menu if dine is null
        if(this.vg_orderType===null){
            this.routeToMenu()
        }
        this.getMenuUrl()

        await this.getPaymentToken()

        // locked checkout for dine and if partial payment not done
        // fast switching page cause currentRoute.name other than close-bill
        if(this.$router.currentRoute.name=='close-bill' && this.vg_orderType=='dinein'){
            this.lockCheckout()
        }

        // browser back button
        window.onpopstate = (event) => {
            if(event.state && event.state.key && (event.state.key%1)<0.7){
                this.unlockCheckout()
            }
        }

        // count joined user and owner
        this.countActiveUser = CartUser.countActiveUser(this.vg_server_cart.cart_users)
        
        let prom1 = this.getLoyaltyProgram()
        let prom2 = this.getCart()
        // item sent to kitchen or only selected item ...
        let prom3 = this.getCartByFilterItems()
        let prom4 = this.calculateSubTotal(this.cart)
        let prom5 = this.getPromoCode()
        let prom6 = this.getLoyalties()
        let prom7 = this.calculateTax(this.cart)
        let prom8 = this.getTotalWithoutTips()
        let prom9 = this.addTips()
        let prom10 = this.getTotal()
        let prom11 = this.getPayments()

        // get my price or while price
        let prom12 = this.calculateMyItemPrice()
        let prom13 = this.calculateWholePrice()

        await Promise.all([prom1,prom2,prom3,prom4,prom5,prom6,prom7,prom8,prom9,prom10,prom11,prom12,prom13])

        // hide if not fiska
        if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name!=='fiska_payment'){
            this.vm_showFiskaAddress(false)
        }
        this.loading = false
    },
    destroyed(){
        setTimeout(function(){
            window.onpopstate = () => {}
        }, 1000)
    },
    watch: {
        async vg_newCart(val){
            if(!val){
                await this.getPayments()
            }
        },
        async vg_orderType(){
            await this.getTotalWithoutTips()
            await this.addTips()
            await this.getTotal()
            await this.getPayments()
        },
        selected_cart(val){
            if(val==='add-card'){
                if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                    this.vm_fiskaPopup(true)
                    this.vm_showFiskaAddress(true)
                }else{
                    this.selected_cart = ''
                    this.vm_newCart(true)
                    this.vm_showFiskaAddress(false)
                }
            }else if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                this.vm_showFiskaAddress(true)
            }else{
                this.vm_showFiskaAddress(false)
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        'vg_server_cart.cart_items': {
            handler: async function(items){
                if(items && items.length===0){
                    this.back()
                }else{
                    await this.getCartByFilterItems()
                    await this.calculateSubTotal(this.cart)
                    await this.getPromoCode()
                    await this.getLoyalties()
                    await this.calculateTax(this.cart)
                    await this.getTotalWithoutTips()
                    await this.addTips()
                    await this.getTotal()

                    await this.calculateMyItemPrice()
                    await this.calculateWholePrice()
                }
            }
        },
        async vg_selectedPaymentType(){
            await this.getCartByFilterItems()
            await this.calculateSubTotal(this.cart)
            await this.calculateTax(this.cart)
            await this.getTotalWithoutTips()
            await this.addTips()
            await this.getTotal()
            await this.getPayments()
        },
        'vg_server_cart.paid': {
            handler: async function(){
                await this.getCartByFilterItems()
                await this.calculateSubTotal(this.cart)
                await this.getPromoCode()
                await this.getLoyalties()
                await this.calculateTax(this.cart)
                await this.getTotalWithoutTips()
                await this.addTips()
                await this.getTotal()

                await this.calculateMyItemPrice()
                await this.calculateWholePrice()
            }
        },
        'vg_server_cart.promocode': {
            handler: async function(){
                await this.getCart()
                await this.getCartByFilterItems()
                await this.calculateSubTotal(this.cart)
                await this.getPromoCode()
                await this.getLoyalties()
                await this.calculateTax(this.cart)
                await this.getTotalWithoutTips()
                await this.addTips()
                await this.getTotal()

                await this.calculateMyItemPrice()
                await this.calculateWholePrice()
            }
        },
        'vg_server_cart.loyalty_redeem_amount': {
            handler: async function(){
                await this.getCart()
                await this.getCartByFilterItems()
                await this.calculateSubTotal(this.cart)
                await this.getPromoCode()
                await this.getLoyalties()
                await this.calculateTax(this.cart)
                await this.getTotalWithoutTips()
                await this.addTips()
                await this.getTotal()

                await this.calculateMyItemPrice()
                await this.calculateWholePrice()
            }
        },
        'vg_deliveryFee': {
            handler: async function(){
                await this.getCart()
                await this.getCartByFilterItems()
                await this.calculateSubTotal(this.cart)
                await this.getPromoCode()
                await this.getLoyalties()
                await this.calculateTax(this.cart)
                await this.getTotalWithoutTips()
                await this.addTips()
                await this.getTotal()

                await this.calculateMyItemPrice()
                await this.calculateWholePrice()
            }
        },
        'vg_server_cart.cart_default_tip': {
            handler: async function(){
                await this.addTips()
                await this.getTotal()
            }
        },
        async 'vg_payments.length'(){
            await this.getPayments()
        },
        vg_fiskaPopup(val){
            if(!val && this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                this.vm_selectedPayment("")
            }
        }
    },
    methods: {
        // mounts
        routeToMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
            return
        },
        getMenuUrl(){
            this.url = window.location.origin+'/'+this.vg_businessName+'/'+this.vg_location.slug+'/menu'
        },
        async getPaymentToken(){
            // get token - todo (remove this - not used anymore)
            try{
                await PaymentAPI.getApiToken(this.vg_businessId)
                this.vm_paymentToken(true)
            }catch(err){
                // disable card on token error
                this.vm_payments([])
                this.vm_paymentToken('')
            }
        },
        lockCheckout(){
            if(this.vg_server_cart && this.vg_server_cart.paid==0){
                let self = this
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'locked_checkout_page',
                        selector: this.vg_loginUser.user.id
                    }
                });
            }
        },
        unlockCheckout(){
            this.vm_lockCheckout(false)
            if(this.vg_server_cart && this.vg_server_cart.paid==0){
                let self = this
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'unlocked_checkout_page'
                    }
                });
            }
        },
        partialPaymentMade(){
            let self = this
            this.$pnPublish({
                channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                message: {
                    event: 'partial_payment_made',
                    msg: `partial payment made on cart`,
                }
            });
        },

        // uis
        back(){
            // don't unlock checkout if one of user have selected whole bill payment
            if(!this.vg_disableWholeItemBtn){
                this.unlockCheckout()
            }

            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        moreOption(){
            this.option = !this.option
        },
        myTable(){
            this.unlockCheckout()
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        browserOrder(){
            this.unlockCheckout()
            this.routeToMenu()
        },
        inviteFriend(){
            this.unlockCheckout()
            this.$router.push({name: 'invite-friend'})
            return
        },
        account(){
            this.unlockCheckout()
            this.$router.push({name: 'customer-detail'})
            return
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        isSplitEvenlyPaid(){
            return (this.vg_server_cart.paid>0 && this.vg_selectedPaymentType=='splitEvenly')
        },
        splitBill(){
            this.option = false
            this.vm_splitBillPopup(true)
        },
        async selectMyBill(){
            if(this.vg_selectedPaymentType!='payForMyOrder'){
                let self = this
                this.unlockCheckout()
                this.vm_selectedPaymentType("payForMyOrder")
                await LoyaltyHelper.removeCartSplitRedeem()
                await PromoHelper.removeCartSplitPromo()
                await this.getCart()
                await this.getCartByFilterItems()
                await this.calculateSubTotal(this.cart)
                await this.getPromoCode()
                await this.getLoyalties()
                await this.calculateTax(this.cart)
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'pay_for_my_bill',
                        selector: this.vg_loginUser.user.id
                    }
                });
            }
        },
        async selectAllByOne(){
            if(this.vg_selectedPaymentType!='AllByOne'){
                let self = this
                this.vm_selectedPaymentType('AllByOne')
                // remove all unpaid promo and loyalty on pay all by one selected
                await LoyaltyHelper.removeAllUnpaidRedeemImmediately()
                await PromoHelper.removeAllUnpaidPromo()
                await this.getCart()
                await this.getCartByFilterItems()
                await this.calculateSubTotal(this.cart)
                await this.getPromoCode()
                await this.getLoyalties()
                await this.calculateTax(this.cart)
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'all_by_one',
                        selector: this.vg_loginUser.user.id
                    }
                });
            }
        },
        async selectSplitEvenly(){
            if(this.vg_selectedPaymentType!='splitEvenly'){
                let self = this
                this.vm_selectedPaymentType('splitEvenly')
                await LoyaltyHelper.removeAllRedeem()
                await PromoHelper.removeAllPromo()
                await this.getCart()
                await this.getCartByFilterItems()
                await this.calculateSubTotal(this.cart)
                await this.getPromoCode()
                await this.getLoyalties()
                await this.calculateTax(this.cart)
                this.$pnPublish({
                    channel: self.vg_cartId?self.vg_cartId:self.vg_joinedCardId,
                    message: {
                        event: 'split_evenly',
                        selector: this.vg_loginUser.user.id
                    }
                });
            }
        },
        getSplitPercent(){
            if(this.countActiveUser>1){
                return (100/this.countActiveUser).toFixed(0)
            }
        },
        getSplitSubtotal(){
            let subtotal = ((parseFloat(this.subtotal)/this.countActiveUser)-parseFloat(this.promoAmt)-parseFloat(this.redeemAmt))
            let splitTax = parseFloat(this.taxAmt)
            return (Math.round((parseFloat(subtotal)+parseFloat(splitTax))*100)/100).toFixed(2)
        },
        getSplitTotal(){
            let splitSubtotal = this.getSplitSubtotal()
            let splitTotal = parseFloat(splitSubtotal)+parseFloat(this.tipsAmt)
            return ((splitTotal*100)/100).toFixed(2)
        },

        addNewCard(){
            this.vm_newCart(true)
        },

        // methods
        // only allow number and digit for edit tips
        validValueForTips(e){
            if(!(((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || (e.keyCode > 36 && e.keyCode < 41)  || e.keyCode == 8 || e.keyCode==110 || e.keyCode==190) && (e.target.value.length<5 || e.keyCode == 8))) {
                e.preventDefault()
                return false;
            }
        },
        async getCart(){
            try{
                let {data} = await CartAPI.getCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
                this.vm_server_cart(data.payload)
                this.single_user = (this.countActiveUser<=1)
            }catch(err){
                console.log(err)
            }
        },
        getCartByFilterItems(){
            // for dine in
            if(this.vg_selectedPaymentType==='selectItems'){
                this.cart =  PartialPaymentHelper.filterServerCartBySelectedItem(this.vg_server_cart, this.vg_selectedCartItems)
            }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                this.cart = PartialPaymentHelper.filterServerCartForMyOrderOnly(this.vg_server_cart, this.vg_loginUser.user.id)
            }else{
                if(this.vg_orderType=='dinein'){
                    this.cart =  this.sortServerCart(this.vg_server_cart, false)
                }else{
                    this.cart = this.sortServerCart(this.vg_server_cart, true)
                }
            }
        },
        // isPending true for send_to_kitchen=false
        sortServerCart(serverCart, isPending){
            return ServerCart.sortCartItem(serverCart, isPending)
        },
        async calculateMyItemPrice(){
            let cart = PartialPaymentHelper.filterServerCartForMyOrderOnly(this.vg_server_cart, this.vg_loginUser.user.id)
            this.mySubtotal = ServerCart.calculateSubTotal(cart)
            // if not paid
            if(this.mySubtotal>0){
                this.myPromoAmt = await PromoHelper.getPromoAmtForMyItem()
                this.myRedeemAmt = await LoyaltyHelper.getRedeemAmtForMyItem()
                this.myTaxAmt = ServerCart.calculateTax(cart, this.myPromoAmt, this.myRedeemAmt)
                this.myTotal = this.calculationFix(parseFloat(this.mySubtotal)-parseFloat(this.myPromoAmt)-parseFloat(this.myRedeemAmt)+parseFloat(this.myTaxAmt))
            }else{
                this.myPromoAmt = 0
                this.myTaxAmt = 0
                this.myTotal = 0
            }
        },
        async calculateWholePrice(){
            let cart = []
            if(this.vg_orderType=='dinein'){
                cart =  this.sortServerCart(this.vg_server_cart, false)
            }else{
                cart = this.sortServerCart(this.vg_server_cart, true)
            }
            if(this.vg_server_cart.paid>0){
                this.wholeSubtotal = ServerCart.calculateSubTotal(cart)
                this.wholePromoAmt = await PromoHelper.getPromoAmtForCart()
                this.wholeRedeemAmt = await LoyaltyHelper.getRedeemAmtForCart()
                this.wholeTaxAmt = ServerCart.calculateTax(cart, this.wholePromoAmt, this.wholeRedeemAmt)
                this.wholeTotal = this.calculationFix(parseFloat(this.wholeSubtotal)-parseFloat(this.wholePromoAmt)-parseFloat(this.wholeRedeemAmt)+parseFloat(this.wholeTaxAmt))
            }else{
                this.wholeSubtotal = this.vg_server_cart.subtotal
                this.wholePromoAmt = await PromoHelper.getPromoAmtForCart()
                this.wholeRedeemAmt = await LoyaltyHelper.getRedeemAmtForCart()
                this.wholeTaxAmt = ServerCart.calculateTax(cart, this.wholePromoAmt, this.wholeRedeemAmt)
                this.wholeTotal = this.calculationFix(parseFloat(this.wholeSubtotal)-parseFloat(this.wholePromoAmt)-parseFloat(this.wholeRedeemAmt)+parseFloat(this.wholeTaxAmt))
            }
        },
        needToBeActivateRedeem(){
            // disable if already paid and selecting pay for my order
            if(this.vg_server_cart.cart_payments){
                let findUserIndex = this.vg_server_cart.cart_payments.findIndex(cartPayment => cartPayment.added_by_id==this.vg_loginUser.user.id)
                if((this.vg_selectedPaymentType=='payForMyOrder') && findUserIndex>=0){
                    return false
                }
            }
            return true
        },
        needToBeActivatePromo(){
            // disable if already paid and selecting pay for my order
            // disable if it is split evenly
            // if(this.vg_selectedPaymentType=='splitEvenly'){
            //     return false
            // }else 
            if(this.vg_server_cart.cart_payments){
                let findUserIndex = this.vg_server_cart.cart_payments.findIndex(cartPayment => cartPayment.added_by_id==this.vg_loginUser.user.id)
                if((this.vg_selectedPaymentType=='payForMyOrder') && findUserIndex>=0){
                    return false
                }
            }
            return true
        },
        async getLoyaltyProgram(){
            // loyalty
            try{
                const loyaltyData = await LoyaltyAPI.loyaltyService(this.vg_businessId)
                // get loyalty service - enable or disable
                if(loyaltyData && loyaltyData.data){
                    this.vm_setEnabled(loyaltyData.data.is_active)
                    this.vm_setProgramName(loyaltyData.data.name)
                    this.vm_setLoyaltyData(loyaltyData.data)
                }
            }catch(err){
                this.vm_setEnabled(false)
            }
        },
        async getPromoCode(){
            // don't get if subtotal is 0 (paid)
            let promos = await PromoHelper.getPromo()
            if(this.subtotal>0){
                this.promoAmt = promos.promoAmt
                this.showPromoAmt = promos.promoAmt
                this.promoCodes = promos.promoCodes
            }else{
                // show promo code but don't calculate if paid
                this.promoAmt = 0
                this.showPromoAmt = promos.promoAmt
                this.promoCodes = promos.promoCodes
            }
        },
        async getLoyalties(){
            // don't get if subtotal is 0 (paid)
            let loyalties = await LoyaltyHelper.getLoyalties()
            if(this.subtotal>0){
                this.redeemAmt = loyalties.redeemAmt
                this.showRedeemAmt = loyalties.redeemAmt
                this.loyalties = loyalties.loyalties
            }else{
                // show promo code but don't calculate if paid
                this.redeemAmt = 0
                this.showRedeemAmt = loyalties.redeemAmt
                this.loyalties = loyalties.loyalties
            }
        },
        async removeRedeem(loyalty){
            // remove redeem from redeem component
            this.vm_redeemRemoveId(loyalty.id)
        },
        async addPromo(){
            try{
                if(this.promo && this.promo.length>1){
                    this.spinner(true)
                    await PromoHelper.addPromo(this.promo, this.subtotal)
                    this.$refs.promoForm.reset()
                    this.spinner(false)
                }
            }catch(err){
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                this.spinner(false)
            }
        },
        async removePromo(promo){
            try{
                this.spinner(true)
                await CartAPI.removePromo(promo.id)
                await this.getCart()
                this.spinner(false)
            }catch(err){
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                this.spinner(false)
            }
        },
        calculationFix(value){
            return (Math.round(value * 100) / 100).toFixed(2)
        },
        async addTips(){
            // calculate tips percent or get value
            if(this.vg_server_cart.cart_default_tip && this.vg_server_cart.cart_default_tip){
                this.tipsAmt = ServerCart.calculateTips(this.subtotal-this.promoAmt-this.redeemAmt, this.vg_server_cart.cart_default_tip.value)
            }else if(this.vg_selectedTips.type==='flat'){
                this.tipsAmt = this.vg_selectedTips.amount
            }else{
                // set default 15 if initial value is 0
                let tipsPercent
                if(this.vg_selectedTips.value==0){
                    let tips = {...this.vg_selectedTips}
                    tips.value = "15"
                    this.vm_selectedTips(tips)

                    tipsPercent = "15"
                }else if(this.vg_selectedTips.value)
                    tipsPercent = this.vg_selectedTips.value
                else{
                    tipsPercent = "15"
                }
                if(this.vg_selectedPaymentType=='splitEvenly'){
                    let subtotal = ((parseFloat(this.subtotal)/this.countActiveUser)-parseFloat(this.promoAmt)-parseFloat(this.redeemAmt))
                    this.tipsAmt = ServerCart.calculateTips(subtotal, tipsPercent)
                }else{
                    this.tipsAmt = ServerCart.calculateTips(this.subtotal-this.promoAmt-this.redeemAmt, tipsPercent)
                }
            }
        },
        async getTotalWithoutTips(){
            if(this.vg_selectedPaymentType=='splitEvenly'){
                this.totalWithoutTips = this.calculationFix(parseFloat(this.subtotal/this.countActiveUser) - parseFloat(this.promoAmt) - parseFloat(this.redeemAmt) + parseFloat(this.taxAmt))
            }else{
                if(this.vg_orderType=='delivery'){
                    this.totalWithoutTips = this.calculationFix(parseFloat(this.subtotal) - parseFloat(this.promoAmt) - parseFloat(this.redeemAmt) + parseFloat(this.vg_deliveryFee) + parseFloat(this.taxAmt))
                }else{
                    this.totalWithoutTips = this.calculationFix(parseFloat(this.subtotal) - parseFloat(this.promoAmt) - parseFloat(this.redeemAmt) + parseFloat(this.taxAmt))
                }
            }
        },
        async getTotal(){
            if(this.vg_selectedPaymentType=='splitEvenly'){
                this.total = this.calculationFix(parseFloat(this.totalWithoutTips)+parseFloat(this.tipsAmt))
            }else{
                if(this.vg_orderType=='delivery'){
                    this.total = this.calculationFix(parseFloat(this.subtotal) + parseFloat(this.vg_deliveryFee) - parseFloat(this.promoAmt) - parseFloat(this.redeemAmt) + parseFloat(this.tipsAmt) + parseFloat(this.taxAmt))
                }else{
                    this.total = this.calculationFix(parseFloat(this.subtotal) - parseFloat(this.promoAmt) - parseFloat(this.redeemAmt) + parseFloat(this.tipsAmt) + parseFloat(this.taxAmt))
                }
            }
        },
        async getPayments(){
            try{
                this.loading = true
                
                let payload = []
                let addCardData = {}
                if(this.vg_paymentToken){
                    payload = await this.va_payments({
                        businessId: this.vg_businessId,
                        userId: this.vg_loginUser.user.id,
                        location: this.vg_location
                    });
                    addCardData = {
                        id: 'add-card',
                        last_four: 'Add Card'
                    }
                }

                // show Pay at store for takeaway only
                if(this.vg_orderType=='pickup'){
                    // takeaway
                    // add card only if setting > pay_now is enabled
                    if(this.vg_location.settings && this.vg_location.settings.pickup_orders && this.vg_location.settings.pickup_orders.pay_now)
                        this.credit_cards =  (this.vg_paymentToken)?[...payload, addCardData]:[]

                    // add Pay at store only if setting > pay_later is enabled
                    if(this.vg_selectedPaymentType!=='payForMyOrder' && this.vg_location.settings && this.vg_location.settings.pickup_orders && this.vg_location.settings.pickup_orders.pay_later){
                        this.credit_cards =  [...this.credit_cards, {id: 'pay-later', last_four: 'Pay At Store'}]
                    }
                }else if(this.vg_orderType=='delivery'){
                    // delivery
                    // add card only if setting > pay_now is enabled
                    if(this.vg_location.settings && this.vg_location.settings.delivery_orders && this.vg_location.settings.delivery_orders.pay_now)
                        this.credit_cards =  (this.vg_paymentToken)?[...payload, addCardData]:[]

                    // add Pay at store only if setting > pay_later is enabled
                    if(this.vg_selectedPaymentType!=='payForMyOrder' && this.vg_location.settings && this.vg_location.settings.delivery_orders && this.vg_location.settings.delivery_orders.pay_later){
                        this.credit_cards =  [...this.credit_cards, {id: 'pay-later', last_four: 'Pay At Door'}]
                    }
                } else{
                    // dine
                    this.credit_cards =  (this.vg_paymentToken)?[...payload, addCardData]:[]

                    // pay via server
                    let payViaServer = {
                        id: 'pay-with-server',
                        last_four: 'Pay with server'
                    }
                    this.credit_cards = [...this.credit_cards, payViaServer]
                }

                this.loading = false
            }catch(err){
                let data = {}
                if(this.vg_paymentToken){
                    data = {
                        id: 'add-card',
                        last_four: 'Add Card'
                    }
                }

                // show Pay at store for takeaway only
                if(this.vg_orderType!=='dinein'){
                    // takeaway
                    // add card only if setting > pay_now is enabled
                    if(this.vg_location.settings && this.vg_location.settings.pickup_orders && this.vg_location.settings.pickup_orders.pay_now)
                        this.credit_cards = (this.vg_paymentToken)?[data]:[]

                    // add Pay at store only if setting > pay_later is enabled
                    if(this.vg_selectedPaymentType!=='payForMyOrder' && this.vg_location.settings && this.vg_location.settings.pickup_orders && this.vg_location.settings.pickup_orders.pay_later)
                        this.credit_cards =  [...this.credit_cards, {id: 'pay-later', last_four: 'Pay At Store'}]
                        
                    // this.credit_cards =  [data, {id: 'pay-later', last_four: 'Pay At Store'}]
                }else{
                    this.credit_cards = (this.vg_paymentToken)?[data]:[]

                    // pay via server
                    let payViaServer = {
                        id: 'pay-with-server',
                        last_four: 'Pay with server'
                    }
                    this.credit_cards = [...this.credit_cards, payViaServer]
                    this.loading = false
                }
            }
        },
        getUserNameOrPhone(index, users){
            let selectedUserObj = users.filter((user)=>{
                return user.user_id===index
            })
            if(selectedUserObj && selectedUserObj[0])
                return StringHelper.capitalize(selectedUserObj[0].name)
        },
        userItemSubtotal(userItems){
            if(this.vg_selectedPaymentType==='AllByOne'){
                return ServerCart.calculateUserItemsTotal(userItems)
            }else if(this.vg_selectedPaymentType==='splitEvenly'){
                return ServerCart.calculateUserItemUnpaidTotal(userItems)
                // return ServerCart.calculateUserItemsTotal(userItems)
            }else if(this.vg_selectedPaymentType==='selectItems'){
                return PartialPaymentHelper.calculateSubTotal()
            }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                return ServerCart.calculateUserItemsTotal(userItems)
            }
        },
        calculateSubTotal(items){
            if(this.vg_selectedPaymentType==='AllByOne'){
                this.subtotal = ServerCart.calculateSubTotal(items)
            }else if(this.vg_selectedPaymentType==='splitEvenly'){
                this.subtotal = ServerCart.calculateSubTotal(items)
            }else if(this.vg_selectedPaymentType==='selectItems'){
                this.subtotal = PartialPaymentHelper.calculateSubTotal()
            }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                this.subtotal = ServerCart.calculateSubTotal(items)
            }
            return this.subtotal
            
        },
        calculateTax(items){
            if(this.vg_selectedPaymentType==='AllByOne'){
                this.taxAmt = ServerCart.calculateTax(items, this.promoAmt, this.redeemAmt)
            }else if(this.vg_selectedPaymentType==='splitEvenly'){
                this.taxAmt = ServerCart.calculateSplitTax(items, this.promoAmt, this.redeemAmt)
            }else if(this.vg_selectedPaymentType==='selectItems'){
                this.taxAmt = PartialPaymentHelper.calculateTax()
            }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                this.taxAmt = ServerCart.calculateTax(items, this.promoAmt, this.redeemAmt)
            }
            return this.taxAmt
        },
        calculateTotal(){
            return (parseFloat(this.subtotal)+ parseFloat(this.taxAmt)).toFixed(2)
        },
        getQtyOrInDecimal(serverCartItem){
            if(!serverCartItem) return

            if(this.vg_selectedPaymentType==='AllByOne'){
                return serverCartItem.item_qty+'x'
            }else if(this.vg_selectedPaymentType==='selectItems'){
                let decimals = PartialPaymentHelper.getCartItemInDecimal(this.vg_selectedCartItems, serverCartItem);
                return +decimals+'x'
            }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                return serverCartItem.item_qty+'x'
            }
        },
        calculatePrice(item){
            if(!item) return

            if(this.vg_selectedPaymentType==='selectItems'){
                return PartialPaymentHelper.getCartItemAmount(this.vg_selectedCartItems, item)
            }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                return ServerCart.calculatePrice(item)
            }else{
                return ServerCart.calculatePrice(item)
            }
        },

        paidOrPrice(item){
            if(!item) return
            let itemPrice = ServerCart.calculatePrice(item)
            if(itemPrice==item.amount_paid && parseInt(itemPrice)!=0){
                return 'Paid'
            }else if(this.vg_selectedPaymentType=='splitEvenly'){
                return ServerCart.calculateUnpaidItemPrice(item)
            }else{
                return '$'+itemPrice
            }
        },

        manualTipsAmt(value){
            this.tipsAmt = (value==='')?'0.00':(parseFloat(value).toFixed(2))
            this.selectTips(this.tips[3])
        },
        async selectTips(tips){
            try{
                if(this.vg_server_cart.id){
                    if(tips.type=='flat'){
                        tips.value = this.tipsAmt
                        tips.amount = this.tipsAmt
                        this.vm_selectedTips(tips)
                        await this.getTotal()
                    }else{
                        if(this.vg_selectedPaymentType==='splitEvenly'){
                            let subtotal = ((parseFloat(this.subtotal)/this.countActiveUser)-parseFloat(this.promoAmt)-parseFloat(this.redeemAmt))
                            this.tipsAmt = ServerCart.calculateTips(subtotal, tips.value)
                            this.vm_selectedTips(tips)
                            await this.getTotal()
                        }else{
                            this.tipsAmt = ServerCart.calculateTips(this.subtotal-this.promoAmt-this.redeemAmt, tips.value)
                            this.vm_selectedTips(tips)
                            await this.getTotal()
                        }
                    }
                }
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        getServerTips(){
            if(!this.tipsAmt){
                this.tipsAmt = this.vg_tips.tip
            }
        },

        undo(){
            clearInterval(this.interval)
            this.startedCountDown = false
        },
        startCountdown(){
            // check if billing id is selected
            if(!this.selected_cart){
                this.snackbar({open: true, text: 'Please select payment method', color: 'error'});
                return
            }
            // check for data and time
            if(this.vg_orderType=='pickup' && this.vg_pickupAt==='future' && (!this.vg_pickupDate || !this.vg_pickupTime)){
                this.snackbar({open: true, text: 'Select pickup date and time', color: 'error'});
                return
            }else if(this.vg_orderType=='delivery' && this.vg_deliveryAt==='future' && (!this.vg_deliveryDate || !this.vg_deliveryTime)){
                this.snackbar({open: true, text: 'Select pickup date and time', color: 'error'});
                return
            }
            // check for delivery address
            if(this.vg_orderType=='delivery'){
                if(!this.vg_deliveryAddress.address_line_1){
                    this.snackbar({open: true, text: 'Delivery address must have street name', color: 'error'});
                    return
                }
            }
            // check for minimum amount no need for delivery - delivery fee api will check this
            if(this.vg_orderType=='pickup'){
                if(this.vg_location.settings && this.vg_location.settings.pickup_orders && this.vg_location.settings.pickup_orders.min_amount && parseFloat(this.vg_location.settings.pickup_orders.min_amount)>parseFloat(this.subtotal)){
                    this.snackbar({open: true, text: `Minimum order amount of ${this.vg_location.settings.pickup_orders.min_amount} is required for takeout order`, color: 'error'});
                    return
                }
            }
            // fiska payment
            if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment' && !this.address){
                this.snackbar({open: true, text: 'Billing address is required', color: 'error'});
                return
            }
            if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment' && !this.postal_code){
                this.snackbar({open: true, text: 'Billing postal code is required', color: 'error'});
                return
            }

            this.startedCountDown = true
            this.timeLeft = 13
            let self = this
            this.interval = setInterval(() => {
                self.timeLeft--
                self.progress = 100-(self.timeLeft/13*100)
                if(self.timeLeft===0){
                    clearInterval(self.interval)
                    this.pay()
                }
            }, 1000)
        },
        clearCountdownPay(){
            clearInterval(self.interval)
            this.pay()
        },
        isNotAllowedToPay(){
            if(this.vg_disablePayBtn){
                return true
            }else if(this.vg_server_cart.total==0){
                return false
            }else if(parseFloat(this.total)==0 && (this.promoAmt>0 || this.redeemAmt>0)){
                // total 0 because of promo
                return false
            }else if(parseFloat(this.total)==0){
                return true
            }else {
                return false
            }
        },
        isStoreOpenNow(){
            let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'
            moment.tz.setDefault(timezone)
            let todayIndex = this.vg_location.hours_of_operation.findIndex(each => each.is_today)
            let todayStoreHours = this.vg_location.hours_of_operation[todayIndex]
            let yesterdayStoreHours = this.vg_location.hours_of_operation[todayIndex>0?todayIndex-1:6]
            let todayDate = moment().format('YYYY-MM-DD')
            let tomorrowDate = moment().add(1, 'day').format('YYYY-MM-DD')
            let openTime = null
            let closeTime = null
            let storeOpen = false

            // check with yesterday open time and today close time
            if(yesterdayStoreHours.open_time>yesterdayStoreHours.close_time){
                openTime = todayDate+' 00:00:00'
                closeTime = todayDate+' '+yesterdayStoreHours.close_time
                storeOpen = moment().isBetween(openTime, closeTime) && todayStoreHours.is_open
                if(storeOpen){
                    return true
                }
            }

            if(todayStoreHours.open_time<=todayStoreHours.close_time){
                openTime = todayDate+' '+todayStoreHours.open_time
                closeTime = todayDate+' '+todayStoreHours.close_time
            }else{
                openTime = todayDate+' '+todayStoreHours.open_time
                closeTime = tomorrowDate+' 23:59:59'
            }
            if(moment().isBetween(openTime, closeTime) && todayStoreHours.is_open){
                return true
            }else{
                return false
            }
        },
        async pay(){
            // check if store is open if dinein or takout
            if(this.vg_orderType!=='delivery' && !this.isStoreOpenNow()){
                this.snackbar({open: true, text: 'Store is closed at the moment. Please try again later', color: 'error'});
                return
            }

            // check if billing id is selected
            if(!this.selected_cart){
                this.snackbar({open: true, text: 'Please select payment method', color: 'error'});
                return
            }
            // check for data and time
            if(this.vg_orderType=='pickup' && this.vg_pickupAt==='future' && (!this.vg_pickupDate || !this.vg_pickupTime)){
                this.snackbar({open: true, text: 'Select pickup date and time', color: 'error'});
                return
            }else if(this.vg_orderType=='delivery' && this.vg_deliveryAt==='future' && (!this.vg_deliveryDate || !this.vg_deliveryTime)){
                this.snackbar({open: true, text: 'Select pickup date and time', color: 'error'});
                return
            }
            // check for delivery address
            if(this.vg_orderType=='delivery'){
                if(!this.vg_deliveryAddress.address_line_1){
                    this.snackbar({open: true, text: 'Delivery address must have street name', color: 'error'});
                    return
                }

            }
            // check for minimum amount no need for delivery - delivery fee api will check this
            if(this.vg_orderType=='pickup'){
                if(this.vg_location.settings && this.vg_location.settings.pickup_orders && this.vg_location.settings.pickup_orders.min_amount && parseFloat(this.vg_location.settings.pickup_orders.min_amount)>parseFloat(this.subtotal)){
                    this.snackbar({open: true, text: `Minimum order amount of ${this.vg_location.settings.pickup_orders.min_amount} is required for takeout order`, color: 'error'});
                    return
                }
            }
            // fiska payment
            if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment' && !this.address){
                this.snackbar({open: true, text: 'Billing address is required', color: 'error'});
                return
            }
            if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment' && !this.postal_code){
                this.snackbar({open: true, text: 'Billing postal code is required', color: 'error'});
                return
            }

            this.spinner(true)
            clearInterval(this.interval)
            // pay via server
            if(this.selected_cart==='pay-with-server'){
                await this.payByServer()
                return
            }

            // split payment - select item, pay my order
            if(this.vg_selectedPaymentType==='selectItems' || this.vg_selectedPaymentType==='payForMyOrder' || this.vg_selectedPaymentType==='splitEvenly'){
                await this.partialPayment()
                return
            }

            // normal payment or remaining payment
            if(this.vg_server_cart.paid>0){
                await this.remainingPayment()
            }else{
                await this.fullPayment()
            }
        },
        async payByServer(){
            try{
                await CartAPI.payViaServer(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, 
                    {
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                        tip: {
                            value: this.vg_selectedTips.value,
                            type: this.vg_selectedTips.type,
                            amount: this.tipsAmt
                        }
                    }
                )
                this.snackbar({open: true, text: 'Server has been notified', color: 'success'})
                this.spinner(false)
                return 
            }catch(err){
                this.spinner(false)
                if(err.response && err.response.data && (err.response.data.msg==='cart is already processed' || err.response.data.msg==='Your selected item(3695) has been fully paid!')){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.resetAlreadyProcessCart()
                    this.$router.push({name: 'menu'})
                }else if(err.response && err.response.data && err.response.data.msg){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }else{
                    //this.snackbar({open: true, text: 'Something went wrong', color: 'error'});
                }
            }
        },
        async partialPayment(){
            try{
                let cartData
                let locationTimezone
                let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'

                let myCartItems = []
                if(this.vg_selectedPaymentType==='selectItems'){
                    myCartItems = this.vg_selectedCartItems
                }else if(this.vg_selectedPaymentType==='payForMyOrder'){
                    myCartItems = PartialPaymentHelper.getCartItemForPartialPayment(this.vg_loginUser.user.id)
                }else if(this.vg_selectedPaymentType==='splitEvenly'){
                    myCartItems = PartialPaymentHelper.getCartItemForSplitPayment()
                }

                // setting percentage cause 0.01 to 0.02 price difference due to round off
                let cartTotal = null
                let myTotal = null
                if(this.vg_selectedPaymentType=='splitEvenly'){
                    cartTotal = (parseFloat(this.vg_server_cart.total)-parseFloat(this.vg_server_cart.paid)).toFixed(2)
                    myTotal = (parseFloat(this.total)-parseFloat(this.tipsAmt)).toFixed(2)
                }else{
                    cartTotal = (parseFloat(this.vg_server_cart.total)-parseFloat(this.vg_server_cart.paid)).toFixed(2)
                    myTotal = (parseFloat(this.total)-parseFloat(this.tipsAmt)).toFixed(2)
                }

                // get upto one decimal normal without roundoff
                let isNearlyEqual = (Math.abs(myTotal-cartTotal)<0.5)

                let data = {
                    cart_id: this.vg_cartId?this.vg_cartId:this.vg_joinedCardId,
                    user_id: this.vg_loginUser.user.id,
                    amount_paid: isNearlyEqual?cartTotal:myTotal,
                    payment_mode: "card",
                    billing_id: this.selected_cart,
                    cart_items: myCartItems,
                    added_by: "user",
                    location_name: this.vg_location.name,
                    tip: {
                        value: parseInt(((this.vg_server_cart.cart_default_tip)?this.vg_server_cart.cart_default_tip.value:this.vg_selectedTips.value)),
                        type: this.vg_selectedTips.type,
                        amount: this.tipsAmt
                    },
                    web_url: this.url
                }
                // send apply_loyalty_on flag for split evenly
                if(this.vg_selectedPaymentType=='splitEvenly'){
                    data.apply_loyalty_on = 'split_evenly'
                }
                // fiska
                if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                    data.address1 = this.address
                    data.postal_code = this.postal_code
                }

                let partialData = await CartAPI.partialPayment(data)

                // loyalty earning
                let loyaltyPoints = LoyaltyHelper.getMyEarning(partialData.data.payload, this.vg_loginUser)
                if(loyaltyPoints>0){
                    this.vm_loyaltyEarningType('checkout')
                    this.vm_earnedPoints(loyaltyPoints)
                    this.vm_loyaltyEarningStatus(true)
                }

                // disable pay button after payment
                if(this.vg_selectedPaymentType=='splitEvenly'){
                    this.vm_disablePayBtn(true)
                }

                // all user paid + tips added by server == total amount to be paid
                let serverAddedTips = TipsHelper.getServerAddedTipsFromCart()
                if((partialData.data.payload.paid+serverAddedTips)==partialData.data.payload.total){
                    locationTimezone = moment().tz(timezone)
                    cartData = {
                        order_type: "regular",
                        order_receive_type: 'now',
                        order_receive_time: locationTimezone.utc().add(45, 'seconds').format(),
                        billing_id: this.selected_cart,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                        order_user: this.vg_loginWith,
                        placed_by: "user",
                        placed_by_id: this.vg_loginUser.user.id,
                        location_name: this.vg_location.name,
                        web_url: this.url
                    }
                    // fiska
                    if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                        cartData.address1 = this.address
                        cartData.postal_code = this.postal_code
                    }
                    let {data} = await CartAPI.placeOrder(this.vg_server_cart.id, cartData)
                    if(data && data.payload){
                        // navigate to receipt
                        if(this.vg_orderType=='dinein'){
                            this.vm_orderId(data.payload.id)
                            this.spinner(false)
                            this.trackPurchase(data.payload)
                            this.resetAlreadyProcessCart()
                            this.vm_allowToJoin(false)
                            this.snackbar({open: true, text: 'Order placed successfully.', color: 'success'});
                            this.$router.push({name: 'receipt', query: {payer: true}})
                            // otherwise redirect to order
                            return
                        }else{
                            this.vm_payed(true)
                            this.vm_resetPayment()
                            this.vm_tips(data.payload)
                            this.vm_orderId(data.payload.id)
                            this.vm_orderDeliveryPickup(false)
                            this.trackPurchase(data.payload)
                            this.snackbar({open: true, text: 'Order placed successfully.', color: 'success'});
                            this.$router.push({name: 'order-status'})
                            // otherwise redirect to order
                            return
                        }
                    }
                }

                // navigate to cart
                this.vm_orderCartId(partialData.data.payload.id)
                this.vm_callServerTableId(this.vg_tableId)
                this.spinner(false)
                this.trackPurchase(partialData.data.payload)
                this.snackbar({open: true, text: 'Order placed successfully.', color: 'success'});
                this.$router.push({name: 'receipt', query: {payer: true}})
            }catch(err){
                this.spinner(false)
                if(err.response && err.response.data && (err.response.data.msg==='cart is already processed' || err.response.data.msg==='Your selected item(3695) has been fully paid!')){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.resetAlreadyProcessCart()
                    this.$router.push({name: 'menu'})
                }else if(err.response && err.response.data && err.response.data.msg){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }else{
                    //this.snackbar({open: true, text: 'Something went wrong', color: 'error'});
                }
            }
        },
        async remainingPayment(){
            try{
                let cartData
                let locationTimezone
                let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'

                let remainingCartItems = PartialPaymentHelper.getRemainingCartItemForPartialPayment()

                // setting percentage cause 0.01 to 0.02 price difference due to round off
                let cartTotal = (parseFloat(this.vg_server_cart.total)-parseFloat(this.vg_server_cart.paid)).toFixed(2)
                let myTotal = (parseFloat(this.total)-parseFloat(this.tipsAmt)).toFixed(2)

                // get upto one decimal normal without roundoff
                let isNearlyEqual = (Math.abs(myTotal-cartTotal)<0.5)

                let data = {
                    cart_id: this.vg_cartId?this.vg_cartId:this.vg_joinedCardId,
                    user_id: this.vg_loginUser.user.id,
                    amount_paid: isNearlyEqual?cartTotal:myTotal,
                    payment_mode: "card",
                    billing_id: this.selected_cart,
                    cart_items: remainingCartItems,
                    added_by: "user",
                    location_name: this.vg_location.name,
                    tip: {
                        value: parseInt(((this.vg_server_cart.cart_default_tip)?this.vg_server_cart.cart_default_tip.value:this.vg_selectedTips.value)),
                        type: this.vg_selectedTips.type,
                        amount: this.tipsAmt
                    },
                    web_url: this.url
                }
                // fiska
                if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                    data.address1 = this.address
                    data.postal_code = this.postal_code
                }

                let partialData = await CartAPI.partialPayment(data)

                // all user paid + tips added by server == total amount to be paid
                let serverAddedTips = TipsHelper.getServerAddedTipsFromCart()
                if((partialData.data.payload.paid+serverAddedTips)==partialData.data.payload.total){
                    locationTimezone = moment().tz(timezone)
                    cartData = {
                        order_type: "regular",
                        order_receive_type: 'now',
                        order_receive_time: locationTimezone.utc().add(45, 'seconds').format(),
                        billing_id: this.selected_cart,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                        order_user: this.vg_loginWith,
                        placed_by: "user",
                        placed_by_id: this.vg_loginUser.user.id,
                        location_name: this.vg_location.name,
                        web_url: this.url
                    }
                    // fiska
                    if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                        cartData.address1 = this.address
                        cartData.postal_code = this.postal_code
                    }
                    let remainingPayment = await CartAPI.placeOrder(this.vg_server_cart.id, cartData)
                    if(remainingPayment.data && remainingPayment.data.payload && remainingPayment.data.payload.id){
                        this.vm_orderId(remainingPayment.data.payload.id)
                    }
                }
                
                // loyalty earning
                let loyaltyPoints = LoyaltyHelper.getMyEarning(partialData.data.payload, this.vg_loginUser)
                if(loyaltyPoints>0 && this.vg_selectedPaymentType!=='splitEvenly'){
                    this.vm_loyaltyEarningType('checkout')
                    this.vm_earnedPoints(loyaltyPoints)
                    this.vm_loyaltyEarningStatus(true)
                }

                if(this.vg_orderType=='dinein'){
                    this.spinner(false)
                    this.trackPurchase(partialData.data.payload)
                    this.resetAlreadyProcessCart()
                    this.vm_allowToJoin(false)
                    this.snackbar({open: true, text: 'Order placed successfully.', color: 'success'});
                    this.$router.push({name: 'receipt', query: {payer: true}})
                }else{
                    this.vm_payed(true)
                    this.vm_resetPayment()
                    this.vm_tips(partialData.data.payload)
                    this.vm_orderId(partialData.data.payload.id)
                    this.vm_orderDeliveryPickup(false)
                    this.trackPurchase(partialData.data.payload)
                    this.snackbar({open: true, text: 'Order placed successfully.', color: 'success'});
                    this.$router.push({name: 'order-status'})
                }

            }catch(err){
                this.spinner(false)
                if(err.response && err.response.data && (err.response.data.msg==='cart is already processed' || err.response.data.msg==='Your selected item(3695) has been fully paid!')){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.resetAlreadyProcessCart()
                    this.$router.push({name: 'menu'})
                }else if(err.response && err.response.data && err.response.data.msg){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }else{
                    //this.snackbar({open: true, text: 'Something went wrong', color: 'error'});
                }
            }
        },
        async fullPayment(){
            try{
                let cartData
                let locationTimezone
                let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'

                if(this.vg_orderType=='dinein'){
                    // dine in
                    locationTimezone = moment().tz(timezone)
                    cartData = {
                        order_type: "regular",
                        order_receive_type: 'now',
                        order_receive_time: locationTimezone.utc().add(45, 'seconds').format(),
                        billing_id: this.selected_cart,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                        order_user: this.vg_loginWith,
                        placed_by: "user",
                        placed_by_id: this.vg_loginUser.user.id,
                        location_name: this.vg_location.name,
                        tip: {
                            value: parseInt(((this.vg_server_cart.cart_default_tip)?this.vg_server_cart.cart_default_tip.value:this.vg_selectedTips.value)),
                            type: this.vg_selectedTips.type,
                            amount: this.tipsAmt
                        },
                        web_url: this.url
                    }
                    // fiska
                    if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                        cartData.address1 = this.address
                        cartData.postal_code = this.postal_code
                    }
                }else{
                    // pickup
                    let orderReceiveTime
                    if(this.vg_orderType=='pickup'){
                        if(this.vg_pickupAt==='future'){
                            locationTimezone = moment(this.vg_pickupDate+' '+this.vg_pickupTime, "YYYY-MM-DD HH:mm a").tz(timezone)
                            orderReceiveTime = locationTimezone.utc().format()
                        }else{
                            locationTimezone = moment().tz(timezone)
                            orderReceiveTime = locationTimezone.utc().add(45, 'seconds').format()
                        }
                    }else if(this.vg_orderType=='delivery'){
                        if(this.vg_deliveryAt==='future'){
                            locationTimezone = moment(this.vg_deliveryDate+' '+this.vg_deliveryTime, "YYYY-MM-DD HH:mm a").tz(timezone)
                            orderReceiveTime = locationTimezone.utc().format()
                        }else{
                            locationTimezone = moment().tz(timezone)
                            orderReceiveTime = locationTimezone.utc().add(45, 'seconds').format()
                        }
                    }

                    cartData = {
                        order_type: "regular",
                        order_receive_type: this.vg_pickupAt,
                        order_receive_time: orderReceiveTime,
                        payment_schedule: (this.selected_cart==='pay-later')?'later':'now',
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                        order_user: this.vg_loginWith,
                        placed_by: "user",
                        placed_by_id: this.vg_loginUser.user.id,
                        location_name: this.vg_location.name,
                        tip: {
                            value: parseInt(((this.vg_server_cart.cart_default_tip)?this.vg_server_cart.cart_default_tip.value:this.vg_selectedTips.value)),
                            type: this.vg_selectedTips.type,
                            amount: this.tipsAmt
                        },
                        web_url: this.url,
                        contact_number: this.vg_deliveryPhone

                    }
                    if(this.selected_cart!=='pay-later'){
                        cartData.billing_id = this.selected_cart
                    }
                    // for delivery
                    if(this.vg_orderType=='delivery'){
                        let deliveryAddress = {
                            address1: this.vg_deliveryAddress.address_line_1,
                            city: this.vg_deliveryAddress.city,
                            province: this.vg_deliveryAddress.province,
                            note: this.vg_deliveryInstruction,
                            phone: this.vg_deliveryPhone,
                            latitude: this.vg_geoLocation.lat,
                            longitude: this.vg_geoLocation.lng
                        }
                        if(this.vg_deliveryAddress.address_line_2){
                            deliveryAddress.address2 = this.vg_deliveryAddress.address_line_2
                        }
                        if(this.vg_deliveryAddress.postal_code){
                            deliveryAddress.postal_code = this.vg_deliveryAddress.postal_code
                        }
                        
                        cartData.delivery = deliveryAddress
                        cartData.delivery_fee = this.vg_deliveryFee
                    }
                    // fiska
                    if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                        cartData.address1 = this.address
                        cartData.postal_code = this.postal_code
                    }
                }

                let {data} = await CartAPI.placeOrder(this.vg_server_cart.id, cartData)
                if(data && data.payload){
                    
                    // loyalty earning
                    let loyaltyPoints = LoyaltyHelper.getMyEarning(data.payload, this.vg_loginUser)
                    if(loyaltyPoints>0 && this.vg_selectedPaymentType!=='splitEvenly'){
                        this.vm_loyaltyEarningType('checkout')
                        this.vm_earnedPoints(loyaltyPoints)
                        this.vm_loyaltyEarningStatus(true)
                    }

                    if(this.vg_orderType!=='dinein' && this.vg_orderType!==null){
                        // pickup - dont reset still need to check order status
                        this.vm_payed(true)
                        this.vm_resetPayment()
                        this.vm_tips(data.payload)
                        this.vm_orderId(data.payload.id)
                        this.vm_orderDeliveryPickup(false)
                        this.trackPurchase(data.payload)
                        this.vm_tableId('')
                        this.snackbar({open: true, text: 'Order placed successfully.', color: 'success'});
                        this.$router.push({name: 'order-status'})

                    }else{
                        // dining
                        this.vm_orderId(data.payload.id)
                        this.trackPurchase(data.payload)
                        this.resetAlreadyProcessCart()
                        this.spinner(false)
                        this.snackbar({open: true, text: 'Order placed successfully.', color: 'success'});
                        this.$router.push({name: 'receipt', query: {payer: true}})
                    }
                }
            }catch(err){
                this.spinner(false)
                if(err.response && err.response.data && (err.response.data.msg==='cart is already processed' || err.response.data.msg==='Your selected item(3695) has been fully paid!')){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.resetAlreadyProcessCart()
                    this.$router.push({name: 'menu'})
                }else if(err.response && err.response.data && err.response.data.msg){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }else{
                    //this.snackbar({open: true, text: 'Something went wrong', color: 'error'});
                }
            }
        },
        resetAlreadyProcessCart(){
            this.vm_callServerTableId(this.vg_tableId)
            this.vm_setOrderType(null)
            this.vm_confirmDeliveryPickup(true)
            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_resetPayment()
            this.vm_tableId('')
        },
        trackPurchase(transaction){
            if(transaction.id){
                this.$gtag.purchase({
                    "transaction_id": transaction.id,
                    "affiliation": "",
                    "value": parseFloat(this.total),
                    "currency": "USD",
                    "tax": parseFloat(this.taxAmt),
                    "shipping": 0,
                    "items": ServerCart.gtagItems(this.vg_server_cart, true)
                })
            }
        },
        isMultipleUserOnTable(){
            this.multipleUserOnTable = CartUser.isMultipleUserOnTable(this.vg_cartUsers)
        },
        ...mapMutations({
            vm_selectedCartItems: 'cart/SET_SELECTED_CART_ITEMS',
            vm_loginStatus: 'login/SET_STATUS',
            vm_resetCart: 'cart/RESET',
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_resetCartUser: 'cartUser/RESET',
            vm_newCart: 'cart/SET_NEW_CART',
            vm_payed: 'payment/SET_PAYED',
            vm_selectedPayment: 'payment/SET_SELECTED_PAYMENT',
            vm_payments: 'payment/SET_PAYMENTS',
            vm_orderId: 'payment/SET_ORDER_ID',
            vm_resetPayment: 'payment/RESET',
            vm_disablePayBtn: 'payment/SET_DISABLE_PAY_BTN',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_selectedTips: 'cart/SET_SELECTED_TIPS',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
            vm_orderDeliveryPickup: 'order/SET_ORDER_PICKUP_DELIVERY',
            vm_lockCheckout: 'cart/SET_LOCK_CHECKOUT',
            vm_server_cart: 'cart/SET_SERVER_CART',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_paymentToken: 'payment/SET_TOKEN',
            vm_splitBillPopup: 'cart/SET_SPLIT_BILL_POPUP',
            vm_selectedPaymentType: 'payment/SET_SELECTED_PAYMENT_TYPE',
            vm_tips: 'cart/SET_TIPS',
            vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
            vm_redeemRemoveId: 'loyalty/SET_REMOVE_ID',
            vm_setEnabled: 'loyalty/SET_ENABLED',
            vm_setProgramName: 'loyalty/SET_PROGRAM_NAME',
            vm_setLoyaltyData: 'loyalty/SET_LOYALTY_DATA',
            vm_loyaltyEarningStatus: 'loyaltyEarning/SET_STATUS',
            vm_earnedPoints: 'loyaltyEarning/SET_POINTS',
            vm_loyaltyEarningType: 'loyaltyEarning/SET_TYPE',
            vm_callServerTableId: 'menu/SET_CALL_SERVER_TABLE_ID',
            vm_orderCartId: 'payment/SET_ORDER_CART_ID',
            vm_fiskaPopup: 'payment/SET_FISKA_POPUP',
            vm_showFiskaAddress : 'payment/SET_SHOW_FISKA_ADDRESS',

        }),
        ...mapActions({
            va_addTips: 'cart/addTips',
            va_payments: 'payment/payments',
            va_setLocationOnly: 'location/setLocationOnly'
        })
    }
}
</script>