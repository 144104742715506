import {OrderAPI} from '@/clients/order'
export default {
    namespaced: true,
    state: {
        orders: [],
        frequentOrders: [],
        current: {},
        orderStatus: 'Confirming',
        orderPickupDelivery: false,
        orderMsg: '',
        orderPage: 1,
    },
    getters: {
        orders: state => state.orders,
        frequentOrders: state => state.frequentOrders,
        current: state => state.current,
        orderStatus: state => state.orderStatus,
        orderPickupDelivery: state => state.orderPickupDelivery,
        orderMsg: state => state.orderMsg,
        orderPage: state => state.orderPage,
    },
    mutations: {
        SET_ORDERS(state, data) {
            state.orders = data
        },
        SET_FREQUENT_ORDERS(state, data){
            state.frequentOrders = data
        },
        SET_CURRENT(state, data) {
            state.current = data
        },
        SET_ORDER_STATUS(state, data){
            state.orderStatus = data
        },
        SET_ORDER_PICKUP_DELIVERY(state, data){
            state.orderPickupDelivery = data
        },
        SET_ORDER_MSG(state, data){
            state.orderMsg = data
        },
        SET_ORDER_PAGE(state, data){
            state.orderPage = data
        },
        RESET_ORDER(state){
            state.orderStatus= 'Confirming',
            state.orderPickupDelivery= false,
            state.orderMsg= ''
            state.orderPage = 1
        }
    },
    actions: {
        async orders({commit}, {userId, locationId, page}){
            try{
                const {data} = await OrderAPI.getPastOrder(userId, locationId, page)
                if(page>1){
                    let orders = {...this.getters['order/orders']}
                    orders.data = orders.data.concat(data.payload.data)
                    commit('SET_ORDERS', orders)
                }else{
                    commit('SET_ORDERS', data.payload)
                }
            }catch(err){
                commit('SET_ORDERS', [])
            }
        },
        async frequentOrders({commit}, {userId, locationId}){
            try{
                const {data} = await OrderAPI.frequentOrder(userId, locationId)
                commit('SET_FREQUENT_ORDERS', data.payload)
            }catch(err){
                commit('SET_FREQUENT_ORDERS', [])
            }
        }
    }
}