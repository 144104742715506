export default {
    namespaced: true,
    state: {
      status: false,
      points: 0,
      type: ''    // signup, checkout
    },
    getters: {
        status: state => state.status,
        points: state => state.points,
        type: state => state.type
    },
    mutations: {
        SET_STATUS(state, status) {
            state.status = status
        },
        SET_POINTS(state, data){
            state.points = data
        },
        SET_TYPE(state, data){
            state.type = data
        }
    }
}