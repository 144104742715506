<template>
    <v-dialog v-model="dialog" persistent>
        <v-form ref="form" v-model="valid" @submit.prevent="doVerify">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Code Verification</span>
                    <v-spacer />
                    <v-btn fab dark x-small @click.prevent="closeVerification">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                        <v-img v-if="vg_business.branding && vg_business.branding.logo_url" :src="vg_business.branding.logo_url" contain max-height="50" />
                </v-card-text> 
                <v-card-text class="pb-0">
                    <div class="text-center pb-5" v-if="vg_loginResponse && vg_loginResponse.number">Code sent via text message to {{formatPhone(vg_loginResponse.number)}}</div>

                    <v-text-field
                        v-model.trim="form.auth_code"
                        :rules="rules.codeRules"
                        label="Please Enter the 6 digit code"
                        required
                        outlined
                        ></v-text-field>

                    <v-btn block text color="primary" @click.prevent="resend()">Resend</v-btn>
                </v-card-text>
                <v-card-text>
                    <v-btn
                        type="submit"
                        color="primary"
                        block
                        >
                        Submit
                    </v-btn>
                </v-card-text>
                <v-card-text>
                    <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                    <span class="float-left pr-1">POWERED BY :</span>
                    <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import {CartUserAPI} from '@/clients/cart-user'
import {mapGetters, mapMutations, mapActions} from 'vuex'
export default {
    data(){
        return{
            form: {
                auth_code: ''
            },
            rules: {
                codeRules: [ 
                    v => !!v || 'Code is required',
                    v => (v && v.length>=6) || 'Please Enter the 6 digit code'
                ]
            },
            valid: false
        }
    },
    mounted(){
        this.spinner(false)
    },
    computed: {
        dialog: {
            get(){
                return this.vg_verifyStatus
            },
            set(val){
                this.vm_verifyStatus(val)
            }
        },
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_verifyStatus: 'login/verify',
            vg_loginResponse: 'login/verifyData',
            vg_business: 'location/business',
            vg_businessId: 'location/business_id',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_loyaltyEnabled: 'loyalty/enabled'
        })
    },
    methods: {
        formatPhone(number){
            if(!number) return
            return number.replace(/(\d{3})(\d{3})(\d{4})/, "($1)-$2-$3")
        },
        async doVerify(){
            if(this.$refs.form.validate()){
                try{
                    this.spinner(true)
                    let data = this.form
                    data.auth_code_token = this.vg_loginResponse.auth_code_token

                    await this.va_setVerify(data)
                    await this.joinCartIfNecessary()
                    this.snackbar({open: true, text: 'Sign In successful', color: 'success'})
                    this.closeVerification()
                    this.spinner(false)
                }catch(err){
                    this.spinner(false)
                    this.snackbar({open: true, text: 'Verification code is incorrect', color: 'error'})
                    console.log(err)
                }
            }
        },
        closeVerification(){
            this.$refs.form.reset()
            this.vm_verifyStatus(false)
        },
        async resend(){
            try{
                this.spinner(true)

                let data = {
                    phone_number_email: this.vg_loginResponse.email?this.vg_loginResponse.email:this.vg_loginResponse.number
                }
                await this.va_setLogin(data)

                this.snackbar({open: true, text: `Code sent via text message`, color: 'success'})
                this.spinner(false)
            }catch(err){
                console.log(err)
            }
        },
        async joinCartIfNecessary(){
            try{
                let joiningCard = this.vg_joinedCardId?this.vg_joinedCardId:this.$route.query.join_cart
                if(this.$route.query.join_cart){
                    this.vm_joinedCardId(this.$route.query.join_cart)
                }
                if(this.$route.query.table_id){
                    this.vm_tableId(this.$route.query.table_id)
                }
                if(joiningCard){
                    let data = {
                        cart_id: joiningCard,
                        user_id: this.vg_loginUser.user.id,
                        name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    }
                    if(StringHelper.isLoginWithIsPhone()){
                        data.phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        data.email = StringHelper.getMyEmailOrPhone('email')
                    }
                    await CartUserAPI.inviteUserToJoinCart(data)

                    // broadcast to user_requested_to_join
                    this.$pnPublish({
                        channel: joiningCard,
                        message: {
                            event: 'user_requested_to_join'
                        }
                    });
                }

            }catch(err){
                console.log(err.response)
            }
        },
        ...mapMutations({
            vm_verifyStatus: 'login/SET_VERIFY',
            vm_loyaltyStatus: 'loyalty/SET_STATUS',
            vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',
            vm_tableId: 'cart/SET_TABLE_ID',
        }),
        ...mapActions({
            va_setLogin: 'login/setLogin',
            va_setVerify: 'login/setVerify'
        })
    }
}
</script>