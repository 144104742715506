<template>
    <v-list-item @click.prevent="menuDetail(item)">
        <v-list-item-content >
            <v-list-item-subtitle class="text--primary title" style="font-size:1rem;">{{item.name}}</v-list-item-subtitle>

            <v-list-item-subtitle>
                <div class="tags">
                    <template v-for="(tag, index) in sortTags(item.tags)">
                        <v-img v-if="tag.image" :src="tag.image" :key="index" :class="'tag '+tag.type" width="20" height="20" />
                        <div :class="'tag-text '+tag.type" v-else :key="index">{{tag.text}}</div>
                    </template>
                </div>
            </v-list-item-subtitle>

            <v-list-item-subtitle
            class="text--primary"
            v-text="item.short_description"
            ></v-list-item-subtitle>
            <v-list-item-subtitle class="red--text font-weight-bold">{{getAvailabilityStatus(item)}}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
            <v-list-item-action-text>
                <v-img :src="item.image" max-width="100" max-height="90" width="100" />
                <v-btn-toggle>
                    <v-btn color="#fff" small class="px-0 font-weight-bold">
                        <span class="px-0" style="letter-spacing: 0.1px">${{calculatePrice()}}</span>
                    </v-btn>
                    <v-btn v-if="item.nutrition_summary && item.nutrition_summary!='0'" color="#fff" small class="px-0 caption">
                        <span style="letter-spacing: 0.1px">{{item.nutrition_summary.substring(0,8)}}</span>
                    </v-btn>
                </v-btn-toggle>
            </v-list-item-action-text>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import _ from 'lodash'
import {Cart} from '@/helpers/cartHelper'
export default {
    props: ['itemId','item'],
    data(){
        return {
        }
    },
    computed: {
        
    },
    methods: {
        getAvailabilityStatus(item){
            if(item.enable_inventory_countdown){
                if(item.current_inventory){
                    return item.current_inventory+' left'
                }else{
                    return 'Out of stock'
                }
            }
        },
        sortTags(tags){
            let filteredTags = []
            // contains, may_contains
            let catTags = _.countBy(tags, {'type': 'contains'})
            let count = 0
            tags.forEach(tag => {
                if(tag.type=='contains' && count<2){
                    filteredTags.push(tag)
                    count++;
                }
            })
            if(catTags.true && catTags.true>2){
                filteredTags.push({
                    name: 'other',
                    type: 'contains',
                    image: '',
                    text: (catTags.true-2)+'+'
                })
            }

            count = 0
            tags.forEach(tag => {
                if(tag.type=='may_contain' && count<2){
                    filteredTags.push(tag)
                    count++;
                }
            })
            if(catTags.false && catTags.false>2){
                filteredTags.push({
                    name: 'other',
                    type: 'may_contain',
                    image: '',
                    text: (catTags.false-2)+'+'
                })
            }

            return filteredTags
        },
        menuDetail(item){
            this.$router.push({name: 'menu-detail', params: {slug: item.slug}})
        },
        calculatePrice(){
            let item = Cart.getDataItem(this.item)
            return Cart.calculatePrice(item)
        }
    }

}
</script>