import moment from 'moment'
import store from '@/store'

export const MenuHelper = {
    async getValidMenuItemsForTakeout(data){
        let validMenuItems = []
        // set 1st valid menu.
        if(data.menus.length>0){
            let menus = data.menus.filter(menu => {
                return menu.status && moment().isBetween(menu.start_from, moment(menu.end_on).add(1, 'd'))
            })

            if(menus.length>0){
                let categories = []
                for(let ind in menus[0].categories){
                    if(menus[0].categories[ind].takeout){
                        let items = []
                        for(let itm in menus[0].categories[ind].items){
                            if(menus[0].categories[ind].items[itm].status && menus[0].categories[ind].items[itm].takeout) items.push(menus[0].categories[ind].items[itm])
                        }
                        let category = {
                            course: menus[0].categories[ind].course,
                            id: menus[0].categories[ind].id,
                            name: menus[0].categories[ind].name,
                            order: menus[0].categories[ind].order,
                            start_from: menus[0].categories[ind].start_from,
                            status: menus[0].categories[ind].status,
                            stop_on: menus[0].categories[ind].stop_on,
                            takeout: menus[0].categories[ind].takeout,
                            items: items
                        }
                        if(items.length>0){
                            categories.push(category)
                        }
                    }
                }
                let menu = {
                    end_on: menus[0].end_on,
                    id: menus[0].id,
                    name: menus[0].name,
                    start_from: menus[0].start_from,
                    status: menus[0].status,
                    categories: categories
                }
                validMenuItems.push(menu)
            }else{
                validMenuItems.push([])
            }

            return validMenuItems
        }else{
            return false
        }
    },
    async getValidMenuItemForDinein(data){
        let validMenuItems = []
        // set 1st valid menu.
        if(data.menus.length>0){
            let menus = data.menus.filter(menu => {
                return menu.status && moment().isBetween(menu.start_from, moment(menu.end_on).add(1, 'd'))
            })

            if(menus.length>0){
                let categories = []
                for(let ind in menus[0].categories){
                    let items = []
                    for(let itm in menus[0].categories[ind].items){
                        if(menus[0].categories[ind].items[itm].status) items.push(menus[0].categories[ind].items[itm])
                    }
                    let category = {
                        course: menus[0].categories[ind].course,
                        id: menus[0].categories[ind].id,
                        name: menus[0].categories[ind].name,
                        order: menus[0].categories[ind].order,
                        start_from: menus[0].categories[ind].start_from,
                        status: menus[0].categories[ind].status,
                        stop_on: menus[0].categories[ind].stop_on,
                        takeout: menus[0].categories[ind].takeout,
                        items: items
                    }
                    if(items.length>0){
                        categories.push(category)
                    }
                }

                let menu = {
                    end_on: menus[0].end_on,
                    id: menus[0].id,
                    name: menus[0].name,
                    start_from: menus[0].start_from,
                    status: menus[0].status,
                    categories: categories
                }
                validMenuItems.push(menu)
            }else{
                validMenuItems.push([])
            }

            return validMenuItems
        }else{
            return false
        }
    },

    async setQueryParam(query){
        let vg_tableId = store.getters['cart/tableId']

        // set table id
        if(query && query.table_id){
            if(query && query.table_id && query.table_id!==vg_tableId){
                // reset 
                store.commit('cart/RESET')
                store.commit('cartUser/RESET')
                store.commit('payment/RESET')
                // set table and off popup
                store.commit('cart/SET_TABLE_ID', query.table_id)
                store.commit('cart/SET_CONFIRM_DELIVERY_PICKUP', false)
            }
        }else{
            if(!vg_tableId){
                store.commit('cart/SET_TABLE_ID', null)
                store.commit('cart/SET_CONFIRM_DELIVERY_PICKUP', true)
            }
        }

        // set receive method
        if(query && query.receive_method){
            if(query.table_id || vg_tableId){
                store.commit('cart/SET_ORDER_TYPE', 'dinein')
            }

            if(query.receive_method==='dinein'){
                store.commit('cart/SET_ORDER_TYPE', 'dine')
            }else if(query.receive_method==='takeout'){
                store.commit('cart/SET_ORDER_TYPE', 'pickup')
            }
        }
    }

}