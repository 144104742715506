import { APP_CART_URL, APP_API_TOKEN } from '../env'
import axios from '../_client'
const cartAxiosInstaceV1 = axios.create({
  baseURL: `${APP_CART_URL}`,
  headers: {
    'x-api-token': APP_API_TOKEN
  }
})
cartAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const PubnubAPI = {
  getConnectionDetail(){
    return cartAxiosInstaceV1({
      method: 'get',
      url: `/pubnub/credentials`
    })
  }
}