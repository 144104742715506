import store from '../store'
import {CartAPI} from '@/clients/cart'

export const LoyaltyHelper = {
    async getLoyalties(){
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_loginUser = store.getters['login/user']
        let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']

        let redeemAmt = 0
        let loyalties = []

        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            // get valid promo code
            vg_server_cart.cart_loyalty.forEach(loyalty => {
                if(loyalty.apply_loyalty_on=='user_items' && vg_selectedPaymentType=='payForMyOrder' && loyalty.user_id==vg_loginUser.user.id){
                    if(loyalty.redeem_amount){
                        // add valid promo value
                        redeemAmt += loyalty.redeem_amount
                        // return valid promo codes
                        loyalties.push(loyalty)
                    }
                }else if(loyalty.apply_loyalty_on=='split_evenly' && vg_selectedPaymentType=='splitEvenly' && loyalty.user_id==vg_loginUser.user.id){
                    if(loyalty.redeem_amount){
                        // add valid promo value
                        redeemAmt += loyalty.redeem_amount
                        // return valid promo codes
                        loyalties.push(loyalty)
                    }
                }else if(loyalty.apply_loyalty_on=='cart' && vg_selectedPaymentType=='AllByOne'){
                    if(loyalty.redeem_amount){
                        // add valid promo value
                        redeemAmt += loyalty.redeem_amount
                        // return valid promo codes
                        loyalties.push(loyalty)
                    }
                }
            })
            if(!loyalties[0]){
                loyalties = []
            }
        }else{
            redeemAmt = 0
            loyalties = []
        }

        return {redeemAmt: redeemAmt, loyalties: loyalties}
    },

    async getAllRedeemAmt(){
        let vg_server_cart = store.getters['cart/serverCart']

        let redeemAmt = 0
        if(vg_server_cart.cart_loyalty){
            vg_server_cart.cart_loyalty.forEach(loyalty => {
                redeemAmt += loyalty.redeem_amount
            })
        }
        return redeemAmt
    },

    async getRedeemAmtForCart(){
        let vg_server_cart = store.getters['cart/serverCart']

        let redeemAmt = 0
        if(vg_server_cart.cart_loyalty){
            vg_server_cart.cart_loyalty.forEach(loyalty => {
                if(loyalty.apply_loyalty_on=='cart'){
                    // add valid redeem value
                    redeemAmt += loyalty.redeem_amount
                }
            })
        }
        return redeemAmt
    },

    async getRedeemAmtForMyItem(){
        let vg_loginUser = store.getters['login/user']
        let vg_server_cart = store.getters['cart/serverCart']
        

        let redeemAmt = 0
        vg_server_cart.cart_loyalty.forEach(loyalty => {
            if(loyalty.apply_loyalty_on=='user_items' && loyalty.user_id==vg_loginUser.user.id){
                // add valid promo value
                redeemAmt += loyalty.redeem_amount
            }
        })
        return redeemAmt
    },

    async removeMyCartRedeem(){
        // remove promo marked as cart & only remove if added by myself
        // don't need to check paid - as whole cart won't be available on paid
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_loginUser = store.getters['login/user']
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            for(const ind in vg_server_cart.cart_loyalty){
                if(vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='cart' && vg_server_cart.cart_loyalty[ind].applied_by_user_id==vg_loginUser.user.id){
                    store.commit('loyalty/SET_REMOVE_ID', vg_server_cart.cart_loyalty[ind].id)
                }
            }
        }
    },
    async removeCartSplitRedeem(){
        // remove promo marked as cart and split evenly
        // don't need to check paid - as whole cart won't be available on paid
        let vg_server_cart = store.getters['cart/serverCart']
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            for(const ind in vg_server_cart.cart_loyalty){
                if(vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='cart' || vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='split_evenly'){
                    store.commit('loyalty/SET_REMOVE_ID', vg_server_cart.cart_loyalty[ind].id)
                }
            }
        }
    },
    async removeCartSplitRedeemInNonCheckout(){
        // remove promo marked as cart and split evenly
        // don't need to check paid - as whole cart won't be available on paid
        let vg_server_cart = store.getters['cart/serverCart']
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            for(const ind in vg_server_cart.cart_loyalty){
                if(vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='cart' || vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='split_evenly'){
                    await CartAPI.removeLoyalty(vg_server_cart.cart_loyalty[ind].id)
                }
            }
        }
    },
    async removeAllMyUnpaidRedeem(){
        // remove promo marked as user_items & remove only if add by myself
        // don't remove promo mark as used
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_loginUser = store.getters['login/user']
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            for(const ind in vg_server_cart.cart_loyalty){
                if((vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='user_items' || vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='split_evenly') && vg_server_cart.cart_loyalty[ind].status=='pending' && vg_server_cart.cart_loyalty[ind].user_id==vg_loginUser.user.id){
                    store.commit('loyalty/SET_REMOVE_ID', vg_server_cart.cart_loyalty[ind].id)
                }
            }
        }
    },
    async removeAllUnpaidRedeemImmediately(){
        // remove promo marked as user_items & remove only if add by myself
        // don't remove promo mark as used
        let vg_server_cart = store.getters['cart/serverCart']
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            for(const ind in vg_server_cart.cart_loyalty){
                if((vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='user_items' || vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='split_evenly') && vg_server_cart.cart_loyalty[ind].status=='pending'){
                    await CartAPI.removeLoyalty(vg_server_cart.cart_loyalty[ind].id)
                }
            }
        }
    },
    async removeAllMyUnpaidRedeemInNonCheckout(){
        // remove promo marked as user_items & remove only if add by myself
        // don't remove promo mark as used
        let vg_server_cart = store.getters['cart/serverCart']
        let vg_loginUser = store.getters['login/user']
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            for(const ind in vg_server_cart.cart_loyalty){
                if(vg_server_cart.cart_loyalty[ind].apply_loyalty_on=='user_items' && vg_server_cart.cart_loyalty[ind].status=='pending' && vg_server_cart.cart_loyalty[ind].user_id==vg_loginUser.user.id){
                    await CartAPI.removeLoyalty(vg_server_cart.cart_loyalty[ind].id)
                }
            }
        }
    },
    async removeAllRedeem(){
        // remove promo marked as cart
        // don't need to check paid - as whole cart won't be available on paid
        let vg_server_cart = store.getters['cart/serverCart']
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0){
            for(const ind in vg_server_cart.cart_loyalty){
                await CartAPI.removeLoyalty(vg_server_cart.cart_loyalty[ind].id)
            }
        }
    },

    async removeRedeem(){
        let vg_selectedPaymentType = store.getters['payment/selectedPaymentType']
        if(vg_selectedPaymentType=='AllByOne'){
            // remove redeem added by another one
            await this.removeCartSplitRedeemInNonCheckout()
        }else if(vg_selectedPaymentType=='payForMyOrder' || vg_selectedPaymentType=='splitEvenly'){
            await this.removeAllMyUnpaidRedeemInNonCheckout()
        }
    },

    getMyEarning(vg_server_cart, vg_user){
        // partial payment
        if(vg_server_cart.cart_loyalty && vg_server_cart.cart_loyalty.length>0) {
            let loyaltyIndex = vg_server_cart.cart_loyalty.findIndex(eachLoyalty => eachLoyalty.user_id===vg_user.user.id)
            if(loyaltyIndex>=0)
                return vg_server_cart.cart_loyalty[loyaltyIndex].points_earned
            else
                return 0
        }else if(vg_server_cart.order_loyalty && vg_server_cart.order_loyalty.length>0){
            let loyaltyIndex = vg_server_cart.order_loyalty.findIndex(eachLoyalty => eachLoyalty.user_id===vg_user.user.id)
            if(loyaltyIndex>=0)
                return vg_server_cart.order_loyalty[loyaltyIndex].points_earned
            else
                return 0
        }
        return 0
    }
    
}