<template>
    <v-container>
        <v-card elevation="0">
            <v-toolbar elevation="0">
                <v-spacer></v-spacer>
                <v-toolbar-title>Invite friends to your table</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>

            <div v-if="qrcodeValue">
                <div class="text-center mx-10">Let your friend scan this Code to join the table #{{vg_tableId}}</div>
                <v-form ref="form" v-model="valid" @submit.prevent="sendSms">
                    <template>
                        <qrcode-vue class="d-block mx-auto text-center" :value="qrcodeValue" size="220" level="H"></qrcode-vue>
                        <div class="text-center font-weight-bold my-2">OR</div>
                        <v-text-field class="mx-5" v-model="form.phone" :rules="rules.phoneEmailRules" outlined placeholder="Friends Phone Number or Email"  />
                        <div class="mx-7 mt-3 grey--text">We'll send your friend a link to join!</div>

                        <v-card-actions>
                            <v-btn type="submit" color="primary" block :disabled="!valid">Invite</v-btn>
                        </v-card-actions>
                    </template>
                </v-form>
                <version />
            </div>
        </v-card>
        
        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                <!-- <v-btn class="mb-2" block color="primary" outlined>Invite A Friend</v-btn> -->
                <!-- <v-btn class="mb-2" block color="primary" outlined>Account</v-btn> -->
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="(!vg_tableId || !vg_loginUser.user || vg_orderType!=='dinein' || !!vg_callServerValue)">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                            <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="myTable" class="right-btn" dark>{{(vg_orderType=='dinein')?'My Table':'My Order'}}</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </v-container>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import {StringHelper} from '@/helpers/stringHelper'
import {CartAPI} from '@/clients/cart'
import {CartUserAPI} from '@/clients/cart-user'
import { mapGetters, mapMutations } from 'vuex'
import Version from '@/components/custom-components/version'

export default {
    components: {
      QrcodeVue,
      Version
    },
    data(){
        return {
            valid: false,
            loading: false,
            option: false,
            cartId: '',
            qrcodeValue: '',
            form: {
                phone: ''
            },
            rules: {
                phoneEmailRules: [ 
                    v => !!v || 'Please enter your phone number or email',
                    v => (!isNaN(v) || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || 'Email must be valid',
                    v => (isNaN(v) || !!v && v.length==10) || 'Phone number must be of 10 digits'
                ]
            }
        }
    },
    async mounted(){
        // popup login
        if(!this.vg_loginUser || !this.vg_loginUser.user || !this.vg_loginUser.user.id){
            this.vm_signupStatus(true)
            return
        }
        if(!this.vg_tableId && this.vg_orderType=='dinein'){
            this.vm_identifyTableStatus(true)
            return
        }
        this.spinner(true)
        await this.getServerCart()
        await this.getQrCode()
        this.spinner(false)
    },
    watch: {
        async vg_tableId(){
            await this.getServerCart()
            await this.getQrCode()
        },
        async vg_loginUser(){
            if(!this.vg_tableId){
                this.vm_identifyTableStatus(true)
                return
            }

            await this.getServerCart()
            await this.getQrCode()
        },
        vg_loginPop(val){
            // redirect to menu when signup and login pop is closed
            if(val==false && this.vg_signupPop==false){
                this.navigateToMenu()
            }
        },
        vg_signupPop(val){
            // redirect to menu when signup and login pop is closed
            if(val==false && this.vg_loginPop==false){
                this.navigateToMenu()
            }
        },
        vg_identifyTablePop(val){
            if(val==false && !this.vg_tableId){
                this.navigateToMenu()
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_itemInCart(val){
            if(val){
                this.$router.push({name: 'cart'})
            }
        },
        vg_selectedPaymentType(val){
            if(val==='selectItems'){
                this.$router.push({name: 'select-item-to-pay'})
            }
        }
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        ...mapGetters({
            vg_location: 'location/location',
            vg_tableId: 'cart/tableId',
            vg_identifyTablePop: 'identifyTable/status',
            vg_loginPop: 'login/status',
            vg_signupPop: 'signup/status',
            vg_loginUser: 'login/user',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_businessName: 'location/businessName',
            vg_businessId: 'location/business_id',
            vg_cartId: 'cart/cart_id',
            vg_orderType: 'cart/orderType',
            vg_notification: 'cartUser/notification',
            vg_payed: 'payment/payed',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_selectedPaymentType: 'payment/selectedPaymentType',
            vg_callServerValue: 'menu/callServerValue'
        })
    },
    methods: {
        back(){
            this.navigateToMenu()
        },
        navigateToMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        moreOption(){
            this.option = !this.option
        },
        myTable(){
            this.$router.push({name: 'manage-table'})
        },
        getQrCode(){
            if(this.vg_orderType=='dinein'){
                this.qrcodeValue = `${location.origin}/${this.vg_businessName}/${this.vg_location.slug}/menu?table_id=${this.vg_tableId}&join_cart=${this.cartId}&allow_to_join=true`
            }else{
                this.qrcodeValue = `${location.origin}/${this.vg_businessName}/${this.vg_location.slug}/menu?join_cart=${this.cartId}&allow_to_join=true`
            }
            console.log(this.qrcodeValue)
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        async sendSms(){
            try{
                if(this.$refs.form.validate()){
                    let data = {
                        send_to: this.form.phone.toString(),
                        message: StringHelper.getUserNameOrPhone(this.vg_loginUser)+' has invited you to join a table at '+this.vg_businessName+'. Please follow the link to join '+this.qrcodeValue
                    }
                    if(isNaN(parseInt(this.form.phone))){
                        data.location_name = this.vg_location.name
                        data.invitation_url= this.qrcodeValue
                        data.invitation_by_user_name= StringHelper.getUserNameOrPhone(this.vg_loginUser)
                    }
                    await CartUserAPI.sendSms(data);
                    this.$refs.form.reset()
                    this.snackbar({open: true, text: `An invitation link has been sent to ${data.send_to}.`, color: 'success'});
                }
            }catch(err){
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                console.log(err)
            }
        },
        async getServerCart(){
            try {
                if(this.vg_cartId){
                    this.cartId = this.vg_cartId
                }else if(this.vg_joinedCardId){
                    // invited user
                    this.vm_cartId(this.vg_joinedCardId)
                    this.cartId = this.vg_joinedCardId
                }else{
                    let activeCart = null
                    // main user
                    if(this.vg_orderType=='dinein'){
                        let activeCartData = {
                            business_id: this.vg_businessId,
                            location_id: this.vg_location.id,
                            table_id: this.vg_tableId,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser)
                        }
                        if(StringHelper.isLoginWithIsPhone()){
                            activeCartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                        }else{
                            activeCartData.user_email = StringHelper.getMyEmailOrPhone('email')
                        }
                        activeCart = await CartAPI.getActiveCartByTableUserLocation(activeCartData)
                    }else{
                        activeCart = await CartAPI.getActivePickupOrDeliveryCartByLocation(this.vg_location.id, this.vg_loginUser.user.id)
                    }
                    this.cartId = activeCart.data.payload.id
                    this.vm_cartId(activeCart.data.payload.id)
                    this.vm_resetPayerInfo()
                }
            }catch(err){
                if(err.response.data.msg==='This table has ongoing order and you are not invited to this table yet.'){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.spinner(false)
                    this.vm_tableId('')
                    this.back()
                }else{
                    try{
                        let cartData = {
                            location_id: this.vg_location.id,
                            table_id: this.vg_tableId,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                            added_by: 'user',
                            order_receive_method: this.vg_orderType,
                            business_id:this.vg_businessId,
                            user_phone: StringHelper.getMyEmailOrPhone('phone')
                        }
                        if(StringHelper.isLoginWithIsPhone()){
                            cartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                        }else{
                            cartData.user_email = StringHelper.getMyEmailOrPhone('email')
                        }
                        
                        let {data} = await CartAPI.createCart(cartData)
                        this.cartId = data.payload.id
                        this.vm_cartId(data.payload.id)
                        this.vm_resetPayerInfo()
                    }catch(err){
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
                        this.vm_tableId('')
                        this.spinner(false)
                        this.back()
                    }
                }
            }
        },
        ...mapMutations({
            vm_signupStatus: 'signup/SET_STATUS',
            vm_cartId :'cart/SET_CART_ID',
            vm_identifyTableStatus: 'identifyTable/SET_STATUS',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_resetPayerInfo: 'payment/RESET_PAYER_INFO'
        })
    }
}
</script>