import {LOCATION_URL, LOCATION_API_TOKEN } from '../env'
import axios from '../_client'
const locationAxiosInstaceV1 = axios.create({
  baseURL: `${LOCATION_URL}`,
  headers: {
    'api-token': LOCATION_API_TOKEN
  }
})
locationAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const LocationAPI = {
  /**
     * Fetch data
     */
  fetch(locationId) {
    return locationAxiosInstaceV1({
      method: 'get',
      url: `/integrators/locations/${locationId}?settings=true&printers=true`,
    })
  },
  fetchBySlug(businessId, locationName){
    return locationAxiosInstaceV1({
      method: 'get',
      url: `/clients/businesses/${businessId}/locations/${locationName}?operation_hours=true&address=true&contact_details=true&settings=true&images=true&delivery_hours=true`,
    })
  },
  fetchPickupTimeSlots(businessId, locationName){
    return locationAxiosInstaceV1({
      method: 'get',
      url: `/clients/businesses/${businessId}/locations/${locationName}/timeslots?pickup_timeslots=true`
    })
  },
  fetchDeliveryTimeSlots(businessId, locationName){
    return locationAxiosInstaceV1({
      method: 'get',
      url: `/clients/businesses/${businessId}/locations/${locationName}/timeslots?delivery_timeslots=true`
    })
  },
  getDeliveryFee(locationId, geo, total){
    return locationAxiosInstaceV1({
      method: 'get',
      url: `/locations/${locationId}/delivery_fee?lat=${geo.lat}&long=${geo.lng}&cart_total=${total}`
    })
  }
}


