<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-card>
                <v-card-title class="mx-0 px-0">
                    <v-spacer />
                    <span>Looks like someone is paying</span>
                    <v-spacer />
                </v-card-title>
                <v-card-subtitle>
                    <div class="title black--text text-center"> the bill.</div>
                </v-card-subtitle>
                <v-card-text class="py-0">
                    <div class="pb-3 text-center">Seems like someone in your party is taking care of the bill!  Once payment is completed, you will be good to go. </div>
                    <div class="pb-3 text-center">Hope you enjoyed your experience at {{vg_business.name}}.  Come back soon!</div>
                </v-card-text>
                <v-card-actions>
                <v-btn
                    color="primary"
                    block
                    @click.prevent="closeLockCheckoutPopup"
                    >
                    GOT IT
                </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
export default {
    mounted(){
        this.vm_lockCheckPop(false)
    },
    computed: {
        dialog: {
            get(){
                return this.vg_lockCheckoutPop
            },
            set(val){
                this.vm_lockCheckPop(val)
            }
        },
        ...mapGetters({
            vg_lockCheckoutPop: 'cart/lockCheckoutPop',
            vg_business: 'location/business'
        })
    },
    methods: {
        closeLockCheckoutPopup(){
            this.vm_lockCheckPop(false)
        },
        ...mapMutations({
            vm_lockCheckPop: 'cart/SET_LOCK_CHECKOUT_POP'
        })
    }
}
</script>