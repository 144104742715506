<template>
    <div>
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Contact</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <div >
            <div class="my-2 py-3 " >
                <div class="mx-5 mb-1 font-weight-bold">{{vg_location.name}}</div>
                <div class="mx-5">{{vg_location.address.formatted_address}}</div>
            </div>
            <div class="my-2 py-3 font-weight-bold">
                    <div class="mx-5">
                        <a :href="'tel:'+phone.value" style="text-decoration: none; color: #000;">
                        <v-icon>mdi-phone</v-icon> {{formatPhone(phone.value)}}
                    </a></div>
            </div>
            <!-- <div class="my-2 py-3 font-weight-bold">
                    <div class="mx-5">{{email.value}}</div>
            </div> -->
            <div class="font-weight-bold" style="margin-top: 60%;" >
                <div class="mx-5 text-center">{{website.value}}</div>
            </div>
            <version />
        </div>
        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
            <v-card-text class="other-options" v-if="option">
            </v-card-text>
            
            <v-card-actions class="mt-5">
                <div class="footer-bar">
                <v-btn @click.prevent="back" class="default-btn primary">Back</v-btn>
                <!-- <v-btn @click.prevent="moreOption" small fab class="center-btn">
                    <v-icon v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                </v-btn> -->
                <v-spacer />
                <v-btn @click.prevent="callServer" class="default-btn primary" :disabled="!vg_tableId || !vg_loginUser.user || vg_orderType!=='dinein'">Call</v-btn>
                </div>
            </v-card-actions>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import {CartAPI} from '@/clients/cart'
import {mapGetters} from 'vuex'
import Version from '@/components/custom-components/version'
export default {
    components: {
        Version
    },
    data(){
        return {
            option: false,
            phone: {},
            website: {},
            email: {}
        }
    },
    mounted(){
        if(!this.vg_location.contact_details) return

        this.vg_location.contact_details.forEach(obj => {
            if(obj.type==='website'){
                this.website = obj
            }else if(obj.type==='phone'){
                this.phone = obj
            }else if(obj.type==='email'){
                this.email = obj
            }
        })
    },
    watch: {
        vg_orderStatus(val){
            if(val==='completed'){
                this.$router.push({name: 'receipt', query: {payer: true}})
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_businessId: 'location/business_id',
            vg_loginUser: 'login/user',
            vg_location: 'location/location',
            vg_cartId: 'cart/cart_id',
            vg_tableId: 'cart/tableId',
            vg_orderType: 'cart/orderType',
            vg_orderStatus: 'order/orderStatus'
        })
    },
    methods: {
        back(){
            this.$router.go(-1)
        },
        formatPhone(phone){
            if(!phone) return
            return StringHelper.standardPhoneFormat(phone)
        },
        moreOption(){
            this.option = !this.option
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        }
    }
}
</script>