export default {
    namespaced: true,
    state: {
      status: false,
      termsPop: false
    },
    getters: {
        status: state => state.status,
        termsPop: state => state.termsPop
    },
    mutations: {
        SET_STATUS(state, data) {
            state.status = data
        },
        SET_TERMS_POP(state, data){
            state.termsPop = data
        }
    }
}