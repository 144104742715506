<template>
    <v-container class="toolbar">
        <loading-block v-if="loading" class="pt-9" />
        <v-container v-else style="margin-bottom: 55px">
            <v-row>
                <v-col cols="12" class="pt-0" v-if="item.image">
                    <v-img :src="item.image" max-width="100%" height="250px" contain />
                </v-col>
                <v-col cols="12" class="pt-0">
                    <v-toolbar-title class="ml-3">{{item.name}}</v-toolbar-title>
                </v-col>
                <v-col cols="12" class="py-0">
                    <div class="tags ml-2" @click.prevent="openTagInfo" style="cursor: pointer"> 
                        <template v-for="(tag, index) in sortTags(item.tags)">
                            <v-img v-if="tag.image" :src="tag.image" :key="index" :class="'tag '+tag.type" width="20" height="20" />
                            <div :class="'tag-text '+tag.type" v-else :key="index">{{tag.text}}</div>
                        </template>
                    </div>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <p class="ml-3 mb-0 pb-0">{{item.description}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-0 pt-0" cols="7">
                    <span class="pl-2 price">${{calculatePrice()}}</span>
                    <span class="pl-1 calorie" v-if="parseFloat(item.nutrition_summary).toString()!==item.nutrition_summary && item.nutrition_summary!==''">{{item.nutrition_summary}}</span>
                </v-col>
                <v-col class="mt-2 pt-0 text-right" cols="5">
                    <span class="red--text pb-2 pr-3">{{getAvailabilityStatus(item)}}</span>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col cols="12">
                    <template v-for="(modifier, index) in item.modifiers">
                        <option-menu v-if="modifier.status && isModifierItemEnabled(modifier.modifier_items)" :key="index" :modifier="modifier" />
                    </template>
                    <v-list class="pa-0">
                        <!-- show only for dining -->
                        <v-list-item v-if="vg_orderType=='dinein'">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">Select a course</v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-select v-model="course" :items="courses" placeholder="SELECT A COURSE" hide-details />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">Notes</v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-textarea v-model="note" placeholder="Leave a note" rows="3" counter maxlength="200" />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>

            <div style="width: 100%; height: 80px;">
                <div style="width: 130px; height: 100%; margin:auto;">
                    <div style="display:inline-block; width: 50px;">
                        <v-img @click.prevent="minus" contain src="@/assets/img/remove.png" width="20" height="20"  />
                    </div>
                    <div style="display:inline-block; width: 57px;">
                        <v-text-field readonly class="qty" hide-details dense v-model="value" style="width:30px; border:none;" />
                    </div>
                    <div style="display:inline-block; width: 20px;">
                        <v-img @click.prevent="add" contain src="@/assets/img/add.png" width="20" height="20" />
                    </div>
                    <version />
                </div>
            </div>
            
            <tag-info :tagInfo="tagInfo" :item="item" @close="closeTagInfo" />
        </v-container>

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="inviteFriend" :disabled="(!vg_loginUser.user || vg_orderType!=='dinein' || isSplitEvenlyPaid())">Invite A Friend</v-btn>
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="account" :disabled="!vg_loginUser.user">Account</v-btn>
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || vg_orderType!=='dinein' || !!vg_callServerValue">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn @click.prevent="goToMenu" class="left-btn" dark>Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                            <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="saveCart" class="right-btn" dark :disabled="isInvalidAddToCart || (!item.current_inventory && !!item.enable_inventory_countdown)">Add To Cart</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </v-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import OptionMenu from './option'
import {Cart} from '@/helpers/cartHelper'
import {StringHelper} from '@/helpers/stringHelper'
import {PromoHelper} from '@/helpers/PromoHelper'
import {LoyaltyHelper} from '@/helpers/LoyaltyHelper'
import {CartAPI} from '@/clients/cart'
import {CartUserAPI} from '@/clients/cart-user'
import {ServerCart} from '@/helpers/serverCartHelper'
import {PartialPaymentHelper} from '@/helpers/PartialPaymentHelper'
import {mapGetters, mapActions, mapState, mapMutations} from 'vuex';

import TagInfo from '@/views/menuDetail/tag-info'
import Version from '@/components/custom-components/version'
import { OrderAPI } from '../../clients/order'
export default {
    components: {
        OptionMenu,
        TagInfo,
        Version
    },
    data(){
        return {
            id: 0,
            value: 1,
            course: '',
            note: '',
            option: false,
            loading: false,
            tagInfo: false,
            courses: [{value: 0, text: "Now"}, {value: 1, text: "First Course"}, {value: 2, text: "Second Course"}, {value: 3, text: "Third Course"}, {value: 4, text: "Takeout"}]
        }
    },
    async mounted(){
        //this.vm_setEditItem({})
        this.loading = true
        // get menu item detail
        await this.va_setItem(this.$router.currentRoute.params.slug)

        let item = {}
        // edit exisiting item
        if(this.vg_editItem.id || this.vg_editItem.past_order){
            item = Cart.getEditData(this.vg_editItem, this.vg_item)
            this.vm_setCartItem(item)
        }else{
            // for new item
            // get item with preselected modifier
            item = Cart.getDataItem(this.item)

            // preselect course 4 for takeout and from category for dinein
            if(this.vg_orderType=='dinein'){
                let currentCategory = Cart.getCategoryFromItem(this.menus[0].categories, item)
                if(currentCategory){
                    item.course = currentCategory.course
                }
            }else if(this.vg_orderType=='delivery'){
                item.course = 0
            }else{
                item.course = 4
            }

            this.vm_setCartItem(item)
        }
        this.id = item.id
        this.value = item.qty
        this.note = item.note
        this.course = item.course

        // set location for store hour
        await this.va_setLocationOnly({locationName:this.vg_location.slug ,businessId:this.vg_businessId})

        // subscribe to only pusblish event for new_user_request
        if(this.vg_loginUser.user && this.vg_loginUser.user.id){
            if((this.vg_joinedCardId || this.vg_cartId)){
                this.$pnSubscribe({
                    channels: [this.vg_joinedCardId?this.vg_joinedCardId:this.vg_cartId]
                })
            }
        }
        
        this.trackDetail()
        this.loading = false
    },
    watch: {
        value(){
            let item = Cart.setItem(this.value, this.note, this.course, {...this.vg_cartItem} )
            this.vm_setCartItem(item)
        },
        note(){
            let item = Cart.setItem(this.value, this.note, this.course, {...this.vg_cartItem} )
            this.vm_setCartItem(item)
        },
        course(){
            let item = Cart.setItem(this.value, this.note, this.course, {...this.vg_cartItem} )
            this.vm_setCartItem(item)
        },
        async vg_tableId(val, oldVal){
            if(val!=='' && !oldVal){
                this.addToCart()
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_itemInCart(val){
            if(val){
                this.$router.push({name: 'cart'})
            }
        },
        vg_orderStatus(val){
            if(val==='completed'){
                this.$router.push({name: 'receipt', query: {payer: true}})
            }
        },
        vg_selectedPaymentType(val){
            if(val==='selectItems'){
                this.$router.push({name: 'select-item-to-pay'})
            }
        }
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        isInvalidAddToCart() {
            // check if all modifier selected is valid
            if(!Cart.isSelectedModifierItemsValid(this.vg_cartItem) || (!this.vg_cartItem.course && this.vg_cartItem.course!==0)){
                return true
            }else{
                // if valid then check
                if(this.vg_selectedPaymentType=='splitEvenly' && this.vg_server_cart.id){
                    return PartialPaymentHelper.hasSomeOnePaidCart()
                }else{
                    return false;
                }
            }
        },
        ...mapGetters({
            vg_cart : 'cart/cart',
            vg_cartId: 'cart/cart_id',
            vg_item: 'item/item',
            vg_cartItem: 'cart/item',
            vg_editItem: 'cart/editItem',
            vg_orderType: 'cart/orderType',
            vg_currentCategory: 'menu/currentCategory',
            vg_notification: 'cartUser/notification',
            vg_tableId: 'cart/tableId',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_server_cart: 'cart/serverCart',
            vg_kitchenData: 'cart/kitchenData',
            vg_loginUser: 'login/user',
            vg_location: 'location/location',
            vg_businessId: 'location/business_id',
            vg_payed: 'payment/payed',
            vg_payer: 'payment/payer',
            vg_orderStatus: 'order/orderStatus',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_selectedPaymentType: 'payment/selectedPaymentType',
            vg_callServerValue: 'menu/callServerValue'
        }),
        ...mapState('item', ['item']),
        ...mapState('menu', ['menus']),
    },
    methods: {
        getAvailabilityStatus(item){
            if(item.enable_inventory_countdown){
                if(item.current_inventory){
                    return item.current_inventory+' left'
                }else{
                    return 'Out of stock'
                }
            }
        },
        trackDetail(){
            this.$gtag.event('view_item',{
                "items": [
                {
                    "id": this.item.id,
                    "name": this.item.name,
                    "list_name": this.vg_currentCategory.name+' menus',
                    "brand": "",
                    "category": this.vg_currentCategory.name,
                    "variant": "",
                    "list_position": 1,
                    "quantity": this.value,
                    "price": this.item.price.toString()
                }]
            })
        },
        trackAddToCart(){
            this.$gtag.event('add_to_cart',{
                "items": [
                {
                    "id": this.item.id,
                    "name": this.item.name,
                    "list_name": this.vg_currentCategory.name+' menus',
                    "brand": "",
                    "category": this.vg_currentCategory.name,
                    "variant": "",
                    "list_position": 1,
                    "quantity": this.value,
                    "price": this.item.price.toString()
                }
                ]
            })
        },
        sortTags(tags){
            let filteredTags = []
            if(tags){
                // contains, may_contains
                let catTags = _.countBy(tags, {'type': 'contains'})
                let count = 0
                tags.forEach(tag => {
                    if(tag.type=='contains' && count<2){
                        filteredTags.push(tag)
                        count++;
                    }
                })
                if(catTags.true && catTags.true>2){
                    filteredTags.push({
                        name: 'other',
                        type: 'contains',
                        image: '',
                        text: (catTags.true-2)+'+'
                    })
                }

                count = 0
                tags.forEach(tag => {
                    if(tag.type=='may_contain' && count<2){
                        filteredTags.push(tag)
                        count++;
                    }
                })
                if(catTags.false && catTags.false>2){
                    filteredTags.push({
                        name: 'other',
                        type: 'may_contain',
                        image: '',
                        text: (catTags.false-2)+'+'
                    })
                }
            }
            return filteredTags
        },
        add(){
            if(this.value<99){
                this.value++
            }
        },
        minus(){
            if(this.value>1){
                this.value--
            }
        },
        myTable(){
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        openTagInfo(){
            this.tagInfo = true
        },
        closeTagInfo(){
            this.tagInfo = false
        },
        goToMenu(){
            // remove edit 
            this.vm_setEditItem({})
            let url = StringHelper.menuUrlGenerator(
                this.$router.currentRoute.params.businessName, 
                this.$router.currentRoute.params.locationName, 
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        goToCart(){
            this.$router.push({name: 'cart'})
        },
        calculatePrice(){
            return Cart.calculatePrice(this.vg_cartItem)
        },
        calculateItemPrice(){
            return Cart.calculateItemPrice(this.vg_cartItem)
        },
        saveCart(){
            // check if store is open if dinein or takout
            if(this.vg_orderType!=='delivery' && !this.isStoreOpenNow()){
                this.snackbar({open: true, text: 'Store is closed at the moment. Please try again later', color: 'error'});
                return
            }

            if(!this.isCategoryScheduledNow()){
                this.snackbar({open: true, text: 'This category is not scheduled for ordering at the moment.', color: 'error'});
                return
            }
            
            if(this.vg_editItem.id && this.vg_editItem.item_id && this.vg_editItem.id!=this.vg_editItem.item_id){
                this.editCart()
            }else{
                this.addToCart()
            }
        },
        async editCart(){
            let result = Cart.isSelectedModifierItemsValid(this.vg_cartItem);
            if(this.vg_cartItem.qty && this.vg_cartItem.qty>0){
                if(result){
                    try{
                        this.spinner(true)
                        // interact with server 
                        let itemParam = {
                            cart_id: this.vg_cartId?this.vg_cartId:this.vg_joinedCardId,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                            item_sku: this.vg_cartItem.item_sku,
                            name: this.vg_cartItem.name,
                            category: this.vg_currentCategory.name,
                            category_id: this.vg_currentCategory.id,
                            item_id: this.vg_cartItem.id,
                            item_order: this.vg_cartItem.item_order,
                            item_qty: this.vg_cartItem.qty,
                            base_price: Number(this.vg_cartItem.price),
                            actual_price: this.calculateItemPrice(),
                            cart_item_modifiers: Cart.clearNullQtyItem(this.vg_cartItem).cart_item_modifiers,
                            added_by: 'user',
                            course: this.vg_cartItem.course,
                            note: this.vg_cartItem.note
                        }
                        Cart.getForApiEditData(itemParam)
                        await CartAPI.updateItem(this.vg_editItem.id, itemParam)
                        this.vm_setEditItem({})

                        this.spinner(false)
                        
                        this.goToCart()
                    }catch(err){
                        this.spinner(false)
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    }
                }else{
                    this.snackbar({
                        text: 'Selected valid no of options',
                        open: true,
                        color: 'error'
                    })
                }
            }else{
                this.snackbar({
                        text: 'Item qty must be at least 1',
                        open: true,
                        color: 'error'
                    })
            }
        },
        isStoreOpenNow(){
            let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'
            moment.tz.setDefault(timezone)
            let todayIndex = this.vg_location.hours_of_operation.findIndex(each => each.is_today)
            let todayCategoryHours = this.vg_location.hours_of_operation[todayIndex]
            let yesterdayStoreHours = this.vg_location.hours_of_operation[todayIndex>0?todayIndex-1:6]
            let todayDate = moment().format('YYYY-MM-DD')
            let tomorrowDate = moment().add(1, 'day').format('YYYY-MM-DD')
            let openTime = null
            let closeTime = null
            let categoryOpen = false

            // check with yesterday open time and today close time
            if(yesterdayStoreHours.open_time>yesterdayStoreHours.close_time){
                openTime = todayDate+' 00:00:00'
                closeTime = todayDate+' '+yesterdayStoreHours.close_time
                categoryOpen = moment().isBetween(openTime, closeTime) && todayCategoryHours.is_open
                if(categoryOpen){
                    return true
                }
            }

            if(todayCategoryHours.open_time<=todayCategoryHours.close_time){
                openTime = todayDate+' '+todayCategoryHours.open_time
                closeTime = todayDate+' '+todayCategoryHours.close_time
            }else{
                openTime = todayDate+' '+todayCategoryHours.open_time
                closeTime = tomorrowDate+' 23:59:59'
            }
            if(moment().isBetween(openTime, closeTime) && todayCategoryHours.is_open){
                return true
            }else{
                return false
            }
        },
        isCategoryScheduledNow(){
            let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'
            moment.tz.setDefault(timezone)
            let todayIndex = moment().format('d')
            let todayCategoryHours = this.vg_item.category.category_hours[todayIndex]
            let yesterdayCategoryHours = this.vg_item.category.category_hours[todayIndex>0?todayIndex-1:6]
            let todayDate = moment().format('YYYY-MM-DD')
            let tomorrowDate = moment().add(1, 'day').format('YYYY-MM-DD')
            let openTime = null
            let closeTime = null
            let categoryOpen = false

            // check with yesterday open time and today close time
            if(yesterdayCategoryHours.open_time>yesterdayCategoryHours.close_time){
                openTime = todayDate+' 00:00:00'
                closeTime = todayDate+' '+yesterdayCategoryHours.close_time
                categoryOpen = moment().isBetween(openTime, closeTime) && todayCategoryHours.status
                if(categoryOpen){
                    return true
                }
            }

            if(todayCategoryHours.open_time<=todayCategoryHours.close_time){
                openTime = todayDate+' '+todayCategoryHours.open_time
                closeTime = todayDate+' '+todayCategoryHours.close_time
            }else{
                openTime = todayDate+' '+todayCategoryHours.open_time
                closeTime = tomorrowDate+' 23:59:59'
            }
            if(moment().isBetween(openTime, closeTime) && todayCategoryHours.status){
                return true
            }else{
                return false
            }
        },
        async addToCart(){
            // @Cart - add item
            if(this.vg_cartItem.qty && this.vg_cartItem.qty>0){
                // is current category selected
                if(this.vg_currentCategory.id===undefined){
                    this.vm_currentCategory(this.menus[0].categories[0])
                }

                // check for login
                if(this.vg_loginUser.user && this.vg_loginUser.user.id){
                    try{
                        this.spinner(true)
                        // check for cart id
                        if(!this.vg_cartId && !this.vg_joinedCardId){
                            if(!await this.checkCart()){
                                this.spinner(false)
                                return
                            }
                        }
                        this.trackAddToCart()

                        let userIdInCard = await ServerCart.IsUserIdInCart()
                        if(!userIdInCard.status && userIdInCard.haveOwner){
                            this.vm_joinedCardId(userIdInCard.cartId)
                            this.joinCart()
                        }

                        // interact with server 
                        let currentCategory = Cart.getCategoryFromItem(this.menus[0].categories, this.vg_cartItem)
                        let itemParam = {
                            cart_id: this.vg_cartId?this.vg_cartId:this.vg_joinedCardId,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                            item_sku: this.vg_cartItem.item_sku,
                            name: this.vg_cartItem.name,
                            slug: this.vg_cartItem.slug,
                            image_url: this.vg_cartItem.image,
                            category: currentCategory.name,
                            category_id: currentCategory.id,
                            item_id: this.vg_cartItem.id,
                            item_order: this.vg_cartItem.item_order,
                            item_qty: this.vg_cartItem.qty,
                            base_price: Number(this.vg_cartItem.price),
                            actual_price: this.calculateItemPrice(),
                            cart_item_modifiers: Cart.clearNullQtyItem(this.vg_cartItem).cart_item_modifiers,
                            added_by: 'user',
                            course: this.vg_cartItem.course,
                            note: this.vg_cartItem.note
                        }
                         if(StringHelper.isLoginWithIsPhone()){
                            itemParam.user_phone = StringHelper.getMyEmailOrPhone('phone')
                        }else{
                            itemParam.user_email = StringHelper.getMyEmailOrPhone('email')
                        }

                        // check for modifier and note in cart
                        let {match, serverCartItemId, itemQty, cart_item_modifiers} = ServerCart.isItemWithMatchedModifierOnServerCardNotSend(this.vg_server_cart, itemParam, this.vg_loginUser.user.id)
                        if(match){
                            itemParam.item_qty = itemParam.item_qty + itemQty
                            itemParam.cart_item_modifiers = cart_item_modifiers
                            await CartAPI.updateItem(serverCartItemId, itemParam)
                        }else{
                            await CartAPI.addItem(itemParam)
                            await PromoHelper.removePromo()
                            await LoyaltyHelper.removeRedeem()
                        }
                        this.spinner(false)

                        this.goToMenu()
                    }catch(err){
                        if(err.response.status===418){
                            try{
                                let {data} = await OrderAPI.lastOrder(this.vg_location.id)
                                if(data.payload && data.payload.data && data.payload.data.length>0 && this.vg_orderType!='dinein'){
                                    this.spinner(false)
                                    this.snackbar({open: true, text: 'There is ongoing cart', color: 'error'});
                                    this.vm_server_cart(ServerCart.userOrderToServerCart(data.payload.data[0]))
                                    this.vm_tips(this.vg_server_cart)
                                    this.vm_cartId(this.vg_server_cart.id)
                                    this.vm_orderId(data.payload.data[0].id)
                                    this.vm_orderStatus(data.payload.data[0].status)
                                    this.$router.push({name: 'order-status'})
                                }else{
                                    this.snackbar({open: true, text: 'Current cart is already processed. Resetting order session.', color: 'error'});
                                    this.vm_setOrderType(null)
                                    this.vm_confirmDeliveryPickup(true)
                                    this.vm_resetCart()
                                    this.vm_resetCartUser()
                                    this.vm_deliveryReset()
                                    this.vm_resetPayment()
                                    this.vm_resetPayerInfo()
                                    this.vm_tableId('')
                                    this.spinner(false)
                                    this.$router.push({name: 'menu'})
                                }
                            }catch(err){
                               this.spinner(false) 
                               this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                               if(err.response.data.msg=='Looks like your not logged in. Please login and try again'){
                                   this.reset()
                                   this.$router.push({name: 'menu'})
                               }
                            }
                        }else{
                            this.spinner(false)
                            this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                        }
                    }
                }else{
                    this.vm_loginStatus(true)
                }
            }else{
                this.snackbar({
                        text: 'Item qty must be at least 1',
                        open: true,
                        color: 'error'
                    })
            }
        },
        async checkCart(){
            try{
                // check for table id
                if(!this.vg_tableId && this.vg_orderType=='dinein'){
                    this.vm_identifyTableStatus(true)
                    return
                }

                // get active cart by location, table and user
                let activeCart
                if(this.vg_orderType=='dinein'){
                    let activeCartData = {
                        business_id: this.vg_businessId,
                        location_id: this.vg_location.id,
                        table_id: this.vg_tableId,
                        user_id: this.vg_loginUser.user.id,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser)
                    }
                    if(StringHelper.isLoginWithIsPhone()){
                        activeCartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        activeCartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }
                    
                    let {data} = await CartAPI.getActiveCartByTableUserLocation(activeCartData)
                    activeCart = data.payload
                }else{
                    let {data} = await CartAPI.getActivePickupOrDeliveryCartByLocation(this.vg_location.id, this.vg_loginUser.user.id)
                    activeCart = data.payload
                }

                if(activeCart && activeCart.id){
                    if(this.vg_joinedCardId){
                        this.vm_joinedCardId(activeCart.id)
                    }else{
                        this.vm_cartId(activeCart.id)
                    }
                    this.vm_resetPayerInfo()
                    return true
                }
            }catch(err){
                if(err.response.data.msg==='This table has ongoing order and you are not invited to this table yet.'){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.spinner(false)
                    this.vm_tableId('')
                    return false
                }else{
                    let cartData
                    if(this.vg_orderType=='dinein'){
                        cartData = {
                            location_id: this.vg_location.id,
                            table_id: this.vg_tableId,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                            added_by: 'user',
                            order_receive_method: this.vg_orderType,
                            business_id:this.vg_businessId
                        }
                    }else{
                        cartData = {
                            location_id: this.vg_location.id,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                            added_by: 'user',
                            order_receive_method: this.vg_orderType,
                            business_id:this.vg_businessId
                        }
                    }
                    // add user_email or user_phone based on email or phone login
                    if(StringHelper.isLoginWithIsPhone()){
                        cartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        cartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }
                    try{
                        let {data} = await CartAPI.createCart(cartData)
                        let cart = data.payload
                        if(cart.id){
                            if(this.vg_joinedCardId){
                                this.vm_joinedCardId(cart.id)
                            }else{
                                this.vm_cartId(cart.id)
                            }
                            this.vm_resetPayerInfo()
                            return true
                        }
                        return false
                    }catch(err){
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                        this.spinner(false)
                        this.vm_tableId('')
                        return false
                    }
                }
            }
        },
        async joinCart(){
            try{
                // empty cart id and will have joining cart id
                this.vm_cartId(null)
                this.vm_resetPayment()
                
                let data = {
                cart_id: this.vg_joinedCardId,
                user_id: this.vg_loginUser.user.id,
                name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                }
                if(StringHelper.isLoginWithIsPhone()){
                    data.phone = StringHelper.getMyEmailOrPhone('phone')
                }else{
                    data.email = StringHelper.getMyEmailOrPhone('email')
                }

                await CartUserAPI.inviteUserToJoinCart(data)

                // broadcast to user_requested_to_join
                this.$pnPublish({
                channel: this.vg_joinedCardId,
                message: {
                    event: 'user_requested_to_join'
                }
                });

                if(this.$router.currentRoute.query.table_id){
                this.vm_tableId(this.$router.currentRoute.query.table_id)
                this.vm_setOrderType('dinein')
                }else{
                this.vm_setOrderType('pickup')
                }
                
                this.vm_confirmDeliveryPickup(false)
            }catch(err){
                console.log(err.response.data)
            }
        },
        moreOption(){
            this.option = !this.option
        },
        inviteFriend(){
            this.$router.push({name: 'invite-friend'})
        },
        account(){
            this.$router.push({name: 'customer-detail'})
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        isModifierItemEnabled(modifierItems){
            let count = 0
            modifierItems.forEach(modifierItem => {
                if(modifierItem.status)
                    count++
            })
            return count>0
        },
        isSplitEvenlyPaid(){
            if(this.vg_server_cart){
                return (this.vg_server_cart.paid>0 && this.vg_selectedPaymentType=='splitEvenly')
            }
        },
        reset(){
            this.vm_setOrderType(null)
            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_deliveryReset()
            this.vm_resetPayment()
            this.vm_tableId('')
            this.vm_allowToJoin(false)
            this.vm_resetUser()
            this.vm_confirmDeliveryPickup(true)
        },
        ...mapMutations({
            vm_cart: 'cart/SET_CART',
            vm_resetCart: 'cart/RESET',
            vm_cartId :'cart/SET_CART_ID',
            vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',
            vm_setCartItem: 'cart/SET_ITEM',
            vm_tips: 'cart/SET_TIPS',
            vm_resetUser: 'login/RESET_USER',
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_loginStatus: 'login/SET_STATUS',
            vm_resetCartUser: 'cartUser/RESET',
            vm_deliveryReset: 'delivery/RESET',
            vm_setEditItem: 'cart/SET_EDIT_ITEM',
            vm_kitchenData: 'cart/SET_KITCHEN_DATA',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_currentCategory: 'menu/SET_CURRENT_CATEGORY',
            vm_identifyTableStatus: 'identifyTable/SET_STATUS',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
            vm_orderId: 'payment/SET_ORDER_ID',
            vm_resetPayment: 'payment/RESET',
            vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
            vm_resetPayerInfo: 'payment/RESET_PAYER_INFO',
            vm_server_cart: 'cart/SET_SERVER_CART',
            vm_orderStatus: 'order/SET_ORDER_STATUS',
        }),
        ...mapActions({
            va_setItem: 'item/setItem',
            va_setLocationOnly: 'location/setLocationOnly'
        })
    }
}
</script>
<style>
    .toolbar p{
        font-size: 16px;
    }
    .toolbar .price{
        font-size: 24px;
    }
    .toolbar .calorie{
        font-size: 16px;
        color: #666;
    }
    .toolbar .tag-text{
        float: left;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 50%;
        font-size: 12px;
        font-weight: bold;
        position: relative;
        background-color: #fff;
    }
    .toolbar .tags .tag{
        float: left;
        background: #fff;
        border-radius: 50%;
    }
    .toolbar .row .contains.tag-text{
        border: solid thin #F44336;
    }
    .toolbar .row .may_contain.tag-text{
        border: solid thin #008;
    }
    .toolbar .v-input__slot:before{
        border:none !important;
    }
    .qty input{
        text-align: center;
    }
    .v-input--selection-controls__input .v-input--selection-controls__ripple{
        display:none;
    }
    /* .toolbar .v-list-item__content{
        padding: 12px 0 12px 8px;
    } */
</style>
