import _ from 'lodash'
import store from '../store'
import {CartAPI} from '@/clients/cart'
import {CartUser} from '@/helpers/cartUserHelper'

export const ServerCart = {
    async IsUserIdInCart(){
        try{
            let vg_cartId = store.getters['cart/cart_id']
            let vg_joinedCardId = store.getters['cart/joinedCardId']
            let vg_loginUser = store.getters['login/user']

            let {data} = await CartAPI.getCart(vg_cartId?vg_cartId:vg_joinedCardId)
            if(data.payload && data.payload.cart_users){
                let findIndx = data.payload.cart_users.findIndex(cart_user => cart_user.user_id==vg_loginUser.user.id)
                return {status: findIndx>=0?true:false, haveOwner: data.payload.user_id, cartId: data.payload.id}
            }
            return {status: false, haveOwner: false, cartId: null}

        }catch(err){
            return {status: false, haveOwner: false, cartId: null}
        }
    },
    // calculate item price with according to modifier item and qty
    calculatePrice(item){
        let total = Number(item.actual_price)
        return (total*Number(item.item_qty)).toFixed(2)
    },

    calculateUnpaidItemPrice(item){
        let total = Number(item.actual_price-item.amount_paid)
        return (Math.round((total*parseInt(item.item_qty))*100)/100).toFixed(2)
    },

    // calculate total
    calculateSubTotal(cart_items){
        if(!cart_items) return

        let total = 0
        let orderType = store.getters['cart/orderType']
        if(orderType=='dinein'){
            Object.keys(cart_items).forEach(key => {
                if(cart_items[key]){
                    cart_items[key].forEach(item => {
                        if(item.sent_to_kitchen){
                            let itemTotal = Number(this.calculatePrice(item))
                            if(itemTotal!==item.amount_paid){
                                total = Number(total) + itemTotal
                            }
                        }
                    })
                }
            })
        }else{
            Object.keys(cart_items).forEach(key => {
                if(cart_items[key]){
                    cart_items[key].forEach(item => {
                        let itemTotal = Number(this.calculatePrice(item))
                        if(itemTotal!==item.amount_paid){
                            total = Number(total) + itemTotal
                        }
                    })
                }
            })
        }
        return (Math.round(total*100)/100).toFixed(2)
    },

    calculateUnpaidSubtotal(){
        let vg_serverCart = store.getters['cart/serverCart']
        let total = 0
        if(vg_serverCart.cart_items){
            vg_serverCart.cart_items.forEach(item => {
                let itemPrice = Number(ServerCart.calculatePrice(item))
                if(itemPrice!=item.amount_paid && item.sent_to_kitchen){
                    total = Number(total) + itemPrice
                }
            })
            return (Math.round(total*100)/100).toFixed(2)
        }
    },

    calculatePromoAmt(subtotal){
        let vg_serverCart = store.getters['cart/serverCart']
        if(subtotal==vg_serverCart.subtotal){
            return vg_serverCart.promocode
        }else{
            // split payment
            let subtotalPercent = subtotal/vg_serverCart.subtotal
            let splitPromoAmt = subtotalPercent*vg_serverCart.promocode
            return (Math.round(splitPromoAmt*100)/100).toFixed(2)
        }
    },

    // calculate tax
    calculateTax(cart_items, promo, redeem){
        if(store.getters["location/location"].settings && store.getters["location/location"].settings.tax_settings && store.getters["location/location"].settings.tax_settings.tax){
            if(!promo) promo = 0
            if(!redeem) redeem = 0
            return (Math.round(((this.calculateSubTotal(cart_items)-parseFloat(promo)-parseFloat(redeem))*store.getters["location/location"].settings.tax_settings.tax/100)*100)/100).toFixed(2)
        }else{
            return "0.00"
        }
        
    },

    calculateSplitTax(cart_items, promo, redeem){
        if(store.getters["location/location"].settings && store.getters["location/location"].settings.tax_settings && store.getters["location/location"].settings.tax_settings.tax){
            let vg_cartUser = store.getters['cartUser/cartUsers']
            if(!promo) promo = 0
            if(!redeem) redeem = 0
            let countActiveUser = CartUser.countActiveUser(vg_cartUser)
            let cartTotal = this.calculateSubTotal(cart_items)/countActiveUser
            return (Math.round(((cartTotal-parseFloat(promo)-parseFloat(redeem))*store.getters["location/location"].settings.tax_settings.tax/100)*100)/100).toFixed(2)
        }else{
            return "0.00"
        }
    },

    calculateUnpaidTax(unpaidSubtotal){
        if(store.getters["location/location"].settings && store.getters["location/location"].settings.tax_settings && store.getters["location/location"].settings.tax_settings.tax){
            return (Math.round((unpaidSubtotal*store.getters["location/location"].settings.tax_settings.tax/100)*100)/100).toFixed(2)
        }else{
            return "0.00"
        }

    },

    // calculate tips
    calculateTips(total, percent){
        if(total && percent){
            let tips = Number(total)*Number(percent)/100;
            return (Math.round(tips*100)/100).toFixed(2)
        }else{
            return 0
        }
    },

    // get tips edit or percent
    getTipsValue(cart){
        if(cart.cart_tips && cart.cart_tips[0] && cart.cart_tips[0].type){
            if(cart.cart_tips[0].type==='flat') 
                return 'edit'
            else
                return cart.cart_tips[0].value
        }
    },

    // calculate total
    calculateTotal(subtotal, tax, tips){
        let total = Number(subtotal)+Number(tax)+Number(tips)
        return total.toFixed(2)
    },

    // boolean - 
    isAllModifierItemMatched(modifierItems, testModifierItems){
        // check by counting
        if(modifierItems.length===testModifierItems.length){
            // check each modifier item id
            let modiferObj  = modifierItems.map(modifierItem => {
                return {modifier_item_id:modifierItem['modifier_item_id'], qty: modifierItem['qty']}
            })
            let testModifierObj = testModifierItems.map(modifierItem => {
                return {modifier_item_id:modifierItem['modifier_item_id'], qty: modifierItem['qty']}
            })
            console.log(JSON.stringify(modiferObj))
            console.log(JSON.stringify(testModifierObj))
            return JSON.stringify(modiferObj)===JSON.stringify(testModifierObj)
        }
    },

    stringifyModifierWithModifierItemIdQty(item){
        let Modifiers = []
        Modifiers = item.cart_item_modifiers.map(eachModifier => {
            let modifierItem = eachModifier.cart_item_modifier_items.map(eachModifierItem => {
                return {modifier_item_id:Number(eachModifierItem['modifier_item_id']), qty: eachModifierItem['qty']}
            })
            modifierItem.sort((a,b) => (a.modifier_item_id > b.modifier_item_id) ? 1 : ((b.modifier_item_id > a.modifier_item_id) ? -1 : 0))
            return {modifier_id: Number(eachModifier.modifier_id), cart_item_modifier_items: modifierItem}
        })
        Modifiers.sort((a,b) => (a.modifier_id > b.modifier_id) ? 1 : ((b.modifier_id > a.modifier_id) ? -1 : 0))
        return JSON.stringify(Modifiers)
    },

    isItemWithMatchedModifierOnServerCardNotSend(serverCart, itemParam, userId){
        let result = {match: false, serverCartItemIndex: null, serverCartItemId: null, itemQty: null}
        if(serverCart.cart_items){ 
            let matchedItems = serverCart.cart_items.filter(item => {
                // get matched item_id which is not send to kitchen and match user_id
                return Number(item.item_id)===Number(itemParam.item_id) && item.sent_to_kitchen===false && item.user_id===userId && itemParam.course===item.course && itemParam.note===item.note
            })
            if(matchedItems && matchedItems.length>0){
                matchedItems.forEach(matchedItem => {
                    if(this.stringifyModifierWithModifierItemIdQty(matchedItem)===this.stringifyModifierWithModifierItemIdQty(itemParam)){
                        result = {
                            match: true, 
                            serverCartItemIndex: this.findServerCartItemById(serverCart, matchedItem.id),
                            serverCartItemId: matchedItem.id,
                            itemQty: matchedItem.item_qty,
                            cart_item_modifiers: matchedItem.cart_item_modifiers
                        }
                    }
                })
            }
        }
        return result
    },

    findServerCartItemById(serverCart, id){
        if(id && serverCart.cart_items){
            return serverCart.cart_items.findIndex(item => {
                return item.id===id
            })
        }
    },

    doUserHaveItemInCart(serverCart, cartUser){
        if(!serverCart || !cartUser || !cartUser.user_id) return

        let itemIndex = serverCart.cart_items.findIndex(item => {
            return item.user_id == cartUser.user_id
        })
        return itemIndex>=0
    },

    isServerCartEmpty(serverCart){
        if(!serverCart.cart_items) return true

        return serverCart.cart_items.length===0
    },

    isAllServerCartSent(serverCart){
        if(!serverCart.cart_items) return

        return serverCart.cart_items.every(item => {
            return item.sent_to_kitchen===true
        })
    },

    isSomeServerCartSent(serverCart){
        if(!serverCart.cart_items) return

        return serverCart.cart_items.some(item => {
            return item.sent_to_kitchen===true
        })
    },

    isSomeServerCartUnsent(serverCart){
        if(!serverCart.cart_items) return

        return serverCart.cart_items.some(item => {
            return item.sent_to_kitchen===false
        })
    },

    isThereAnyServerCartSend(serverCart){
        if(!serverCart.cart_items) return

        return serverCart.cart_items.length>0
    },

    countUnsentCartItemQty(serverCart){
        if(!serverCart.cart_items) return
        let count = 0

        serverCart.cart_items.forEach(item => {
            if(item.sent_to_kitchen===false){
                count = count+item.item_qty
            }
        })

        return count;
    },

    countSentCartItemQty(serverCart){
        if(!serverCart.cart_items) return
        let count = 0

        serverCart.cart_items.forEach(item => {
            if(item.sent_to_kitchen===true){
                count = count+item.item_qty
            }
        })

        return count;
    },

    filterServerCart(serverCart, selectedItem){
        if(serverCart.cart_items && serverCart.cart_items.length>0){
            return _.groupBy(serverCart.cart_items.filter(item => {return selectedItem.includes(item.id)}), '')
        }
        return null
    },

    getSplitItemsIdAmount(cart){
        if(cart.length>0){
            let items = []
            cart.forEach(item => {
                items.push({cart_item_id: item.id, amount_paid: parseFloat(item.actual_price*item.item_qty).toFixed(2)})
            })
            return items
        }
    },

    sortCartItem(serverCart, isPending){
        // group item by added user id omiting sent to kitchen
        if(serverCart.cart_items && serverCart.cart_items.length>0){
            let result = _.groupBy(serverCart.cart_items.filter(item => {return isPending?!item.sent_to_kitchen:item.sent_to_kitchen}), 'user_id')
            for(const ind in serverCart.cart_users){
                if(serverCart.cart_users[ind].status=='joined' && !Object.keys(result).includes(serverCart.cart_users[ind].user_id)){
                    result[serverCart.cart_users[ind].user_id] = null
                }
            }
            return result
        }
        return null
    },

    sortCartByCourse(cartItems, isPending){
        // group item by course omiting sent to kitchen
        if(cartItems && cartItems.length>0){
            return _.groupBy(cartItems.filter(item => {return isPending?!item.sent_to_kitchen:item.sent_to_kitchen}), 'course')
        }
        return null
    },

    calculateUserItemsTotal(userItems){
        let total = 0
        if(userItems){
            let orderType = store.getters['cart/orderType']
            if(orderType=='dine'){
                userItems.forEach(userItem => {
                    if(userItem.sent_to_kitchen){
                        let itemTotal = Number(this.calculatePrice(userItem))
                        if(itemTotal!=userItem.amount_paid){
                            total = total + Number(this.calculatePrice(userItem))
                        }
                    }
                })
            }else{
                userItems.forEach(userItem => {
                    let itemTotal = Number(this.calculatePrice(userItem))
                    if(itemTotal!=userItem.amount_paid){
                        total = total + Number(this.calculatePrice(userItem))
                    }
                })
            }
        }
        return total.toFixed(2)
    },

    calculateUserItemUnpaidTotal(userItems){
        if(userItems){
            let total = 0
            userItems.forEach(userItem => {
                let itemTotal = Number(this.calculateUnpaidItemPrice(userItem))
                total = total + itemTotal
            })
            return (Math.round(total*100)/100).toFixed(2)
        }
        return "0.00"
    },

    calculatePendingTotal(serverCart){
        let total = 0
        serverCart.cart_items.forEach(item => {
            if(!item.sent_to_kitchen){
                total = total + Number(this.calculatePrice(item))
            }
        })
        return total.toFixed(2)
    },

    calculateSubmittedTotal(serverCart){
        let total = 0
        serverCart.cart_items.forEach(item => {
            if(item.sent_to_kitchen){
                total = total + Number(this.calculatePrice(item))
            }
        })
        return total.toFixed(2)
    },

    calculatePickupSubtotal(serverCart){
        let total = 0
        serverCart.cart_items.forEach(item => {
            if(!item.sent_to_kitchen){
                total = total + Number(this.calculatePrice(item))
            }
        })
        return total
    },

    calculatePickupTax(serverCart, rate){
        let tax = Number(rate)/100*this.calculatePickupSubtotal(serverCart)
        return tax.toFixed(2)
    },

    calculatePickupTotal(serverCart,rate){
        let total = 0
        total = Number(this.calculatePickupTax(serverCart, rate))+Number(this.calculatePickupSubtotal(serverCart))
        return total.toFixed(2)
    },

    userOrderToServerCart(orderDetail){
        if(!orderDetail || !orderDetail.id) return

        let serverCart = {
            id: orderDetail.cart_id,
            added_by: orderDetail.added_by,
            business_id: orderDetail.business_id,
            cart_items: orderDetail.order_items,
            cart_loyalty: orderDetail.order_loyalty,
            cart_promocodes: orderDetail.order_promocode,
            cart_tips: orderDetail.order_tips,
            cart_users: orderDetail.order_users,
            external_id: orderDetail.external_id,
            location_id: orderDetail.location_id,
            loyalty_points_earned: orderDetail.points_earned,
            loyalty_points_redeem: orderDetail.points_redeemed,
            loyalty_redeem_amount: orderDetail.reward_amount,
            note: orderDetail.note,
            order_receive_method: orderDetail.order_receive_type,
            promocode: orderDetail.promocode,
            status: orderDetail.status,
            subtotal: orderDetail.subtotal,
            table_id: orderDetail.table_id,
            tax: orderDetail.tax,
            tip: orderDetail.tip,
            total: orderDetail.total
        }

        return serverCart
    },

    gtagItems(serverCart, pending){
        let carts = []
        if(serverCart.cart_items){
            serverCart.cart_items.forEach(item => {
                if(pending?item.sent_to_kitchen:!item.sent_to_kitchen){
                    carts.push({
                        id: item.id,
                        name: item.name,
                        list_name: item.category+' menu',
                        brand: '',
                        category: item.category,
                        variant: '',
                        list_position: 1,
                        quantity: item.item_qty,
                        price: this.calculatePrice(item).toString()
                    })
                }
            })
            return carts;
        }
    }
}