export const OrderCartHelper = {
    calculatePrice(item){
        let total = Number(item.actual_price)
        return (total*Number(item.item_qty)).toFixed(2)
    },

    calculateItemsTotalByUser(userId, orderDetail){
        if(!userId || !orderDetail || !orderDetail.order_items) return

        let userItems = orderDetail.order_items.filter(each => each.user_id===userId)

        let total = 0
        userItems.forEach(userItem => {
            total = total + Number(this.calculatePrice(userItem))
        })
        return total.toFixed(2)

    }
}