import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import store from './store'
import PubNubVue from 'pubnub-vue';
import VueGtag from "vue-gtag";
import VueQrcodeReader from "vue-qrcode-reader";
import { PUBNUB_PUBLIC_KEY, PUBNUB_SUBSCRIBE_KEY } from '@/clients/env'
import 'vue2-timepicker/dist/VueTimepicker.css'

import './plugins/global.mixins'
import { createProvider } from './vue-apollo'
import {PubnubAPI} from '@/clients/pubnub'
import GAuth from 'vue-google-oauth2'
import VueConfetti from 'vue-confetti'
import * as VueGoogleMaps from 'vue2-google-maps'

PubnubAPI.getConnectionDetail().then(({data: {payload}}) => {
  Vue.use(PubNubVue, {
    subscribeKey: payload.subscribe_key,
    publishKey: payload.publish_key,
    presenceTimeout: 320,
    heartbeatInterval: 60,
    ssl: true,
    uuid: 'gr8table-web'
  });
  
  init()

}).catch(err => {
  console.log(err)
  console.log("pubnub key fail from api")

  Vue.use(PubNubVue, {
    subscribeKey: PUBNUB_SUBSCRIBE_KEY,
    publishKey: PUBNUB_PUBLIC_KEY,
    presenceTimeout: 320,
    heartbeatInterval: 60,
    ssl: true,
    uuid: 'gr8table-web'
  });

  init()
})


function init(){
  Vue.use(VueGtag, {
    config: { 
      id:  'G-VNJM10T419'
    },
  }, router);
  
  Vue.use(VueQrcodeReader);

  const gauthOption = {
    clientId: '857697306824-t5v3rilt3ead18e8pspvpq0h0av2oug5.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
  }
  Vue.use(GAuth, gauthOption)
  
  Vue.config.productionTip = false

  Vue.use(VueConfetti)

  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyBJs2f0TNNui_OcRHgVRTIsJAvGQ0EB7oA',
      libraries: 'places',
    },
    installComponents: true
  })
  
  window._$store = store;
  new Vue({
    router,
    vuetify,
    store: window === window.top ? window._$store : window.parent._$store,
    render: h => h(App),
    apolloProvider: createProvider(),
  
    data(){
      return {
        spinners: {
          main: false
        }
      }
    }
  }).$mount('#app')
}


