<template>
    <v-container>
        <v-toolbar class="mt-5" elevation="0" style="height:65px;">
            <v-spacer></v-spacer>
            <v-toolbar-title style="height:70px;">
                <div>Sending order to Kitchen... </div>
                <div class="text-center mb-1">({{timeLeft}}s...)</div>
                <v-progress-linear :value="progress" color="primary" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <loading-block v-if="loading" class="pt-9" />
        <div v-else style="overflow: auto; margin-bottom:100px;">
            <v-card class="mx-5" elevation="0">
                <v-card-title class="pb-0">
                    <v-spacer />
                    <div style="opacity:1; color: black;">My Order</div>
                    <v-spacer />
                </v-card-title>
                <v-card-text>
                    <template v-for="(userItem, userIndex) in sortServerCart(vg_server_cart, true)">
                        <div :key="'user-'+userIndex" class="title text-center black--text pb-2" v-if="vg_orderType=='dinein' && userItem">{{getUserNameOrPhone(userIndex, vg_server_cart.cart_users)}}</div>
                        <template v-for="(couserItem, courseIndex) in sortServerCartByCourse(userItem, true)">
                            <div :key="'user-'+userIndex+'course-'+courseIndex" class="title black--text pb-2">{{getCourse(courseIndex).text}}</div>
                            <template v-for="(item, itemIndex) in couserItem">
                                <v-list v-if="!item.sent_to_kitchen" three-line class="py-0" :key="'user-'+userIndex+'course-'+courseIndex+'item'+itemIndex">
                                    <v-list-item style="min-height: 60px !important;" class="px-0 my-0">
                                        <v-list-item-icon class="mr-2 mb-0 py-0">
                                            <v-badge class="mt-0" :content="getCourse(courseIndex).abbr" :color="getCourse(courseIndex).color"/>
                                        </v-list-item-icon>
                                        <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                            <div>{{item.item_qty}}x</div>
                                        </v-list-item-icon>
                                        <v-list-item-content class="my-0 py-0" style="align-self:start">
                                            <v-list-item-title>{{item.name}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.note}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action class="my-0 py-0">
                                            <v-list-item-action-text >
                                                <span class="pr-3 body-2" style="color:black;">${{calculatePrice(item)}}</span>
                                            </v-list-item-action-text>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </template>
                        </template>
                    </template>
                </v-card-text>
            </v-card>
            <div  class="mx-5 mt-5 body-2">Notes:</div>
            <div class="pt-0 mx-5 body-2">{{vg_server_cart.note}}</div>
            <version />
        </div>

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                <v-btn class="mb-2" block color="primary" outlined :disabled="isSplitEvenlyPaid()">Invite A Friend</v-btn>
                <!-- <v-btn class="mb-2" block color="primary" outlined>Account</v-btn>
                <v-btn class="mb-0" block color="primary">Call Server</v-btn> -->
                </v-card-text>
            
            <v-card-actions class="mt-5">
                <div class="footer-bar">
                    <v-btn @click.prevent="undo" class="left-btn" dark>Undo ({{timeLeft}}S)</v-btn>
                    <v-btn @click.prevent="moreOption" small fab class="center-btn">
                        <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                        <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn @click.prevent="submit" class="right-btn" dark>Submit ({{countItem()}})</v-btn>
                </div>
            </v-card-actions>
            </v-card>
        </v-footer>
    </v-container>
</template>

<script>
import {Cart} from '@/helpers/cartHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {StringHelper} from '@/helpers/stringHelper'
import {mapGetters, mapActions, mapMutations} from 'vuex'
import Version from '@/components/custom-components/version'
export default {
    components: {
        Version
    },
    data(){
        return {
            loading: false,
            option: false,
            progress :0,
            timeLeft: 13,
            interval: null,
            courses: [
                {abbr: 'N', text: "Now", color: 'orange darken-3'}, 
                {abbr: '1', text: "First Course", color: 'blue darken-3'}, 
                {abbr: '2', text: "Second Course", color: 'purple darken-3'}, 
                {abbr: '3', text: "Third Course", color: 'green darken-3'}, 
                {abbr: 'T', text: "Takeout", color: 'yellow darken-3'}
            ]
        }
    },
    mounted(){
        this.startCountdown()
    },
    watch: {
        'vg_server_cart.cart_items'(){
            let isItemToSent = ServerCart.isSomeServerCartUnsent(this.vg_server_cart)
            if(isItemToSent){
                // check if any other user add item to send to kitchen reset count down
                clearInterval(this.interval)
                this.timeLeft = 13
                this.startCountdown()
            }else{
                // if someone already submitted the cart. navigate to cart page
                this.$router.push({name: 'cart'})
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_cancelled(val){
            if(val){
                this.$router.push({name: 'menu'})
            }
        },
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        ...mapGetters({
            vg_cart : 'cart/cart',
            vg_server_cart: 'cart/serverCart',
            vg_kitchen_data: 'cart/kitchenData',
            vg_orderType: 'cart/orderType',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_notification: 'cartUser/notification',
            vg_cancelled: 'payment/cancelled',
            vg_payed: 'payment/payed',
            vg_payer: 'payment/payer',
            vg_loginUser: 'login/user',
            vg_selectedPaymentType: 'payment/selectedPaymentType'
        })
    },
    methods: {
        trackCheckout(){
            this.$gtag.event('begin_checkout',{
                "items": ServerCart.gtagItems(this.vg_server_cart, false),
                "coupon": ""
            });  
        },
        undo(){
            clearInterval(this.interval)
            this.$router.go(-1)
        },
        calculatePrice(item){
           return ServerCart.calculatePrice(item)
        },
        countItem(){
            if(this.vg_server_cart && this.vg_server_cart .cart_items){
                let result = this.vg_server_cart.cart_items.filter(item => {
                    return item.sent_to_kitchen===false
                })
                return result.length
            }
        },
        startCountdown(){
            let self = this
            this.interval = setInterval(() => {
                self.timeLeft--
                self.progress = 100-(self.timeLeft/13*100)
                if(self.timeLeft===0){
                    clearInterval(self.interval)
                    self.submit()
                }
            }, 1000)
        },
        sortServerCart(serverCart, isPending){
            return ServerCart.sortCartItem(serverCart, isPending)
        },
        sortServerCartByCourse(cartItems, isPending){
            return ServerCart.sortCartByCourse(cartItems, isPending)
        },
        getUserNameOrPhone(index, users){
            let selectedUserObj = users.filter((user)=>{
                return user.user_id===index
            })
            if(selectedUserObj && selectedUserObj[0])
                return StringHelper.capitalize(selectedUserObj[0].name)
        },
        getCourse(course){
            if(this.courses[course])
                return this.courses[course]
        },
        async submit(){
            try{
                clearInterval(this.interval)
                this.spinner(true)
                this.trackCheckout()
                await this.va_sendOrderToKitchen(this.vg_server_cart.id)
                this.vm_cart(Cart.clean(this.vg_cart))
                this.spinner(false)
                this.$router.push({name: 'cart'})
            }catch(err){
                if(err.response.status==418){
                    this.snackbar({open: true, text: 'Current cart is already processed. Resetting order session.', color: 'error'});
                    this.vm_setOrderType(null)
                    this.vm_confirmDeliveryPickup(true)
                    this.vm_resetCart()
                    this.vm_resetCartUser()
                    this.vm_resetPayment()
                    this.vm_tableId('')
                    this.spinner(false)
                    this.$router.push({name: 'menu'})
                }else{
                    this.spinner(false)
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }
            }
        },
        myTable(){
            clearInterval(this.interval)
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        isSplitEvenlyPaid(){
            if(this.vg_server_cart){
                return (this.vg_server_cart.paid>0 && this.vg_selectedPaymentType=='splitEvenly')
            }
        },
        moreOption(){
            this.option = !this.option
        },
        ...mapMutations({
            vm_cart: 'cart/SET_CART',
            vm_resetCart: 'cart/RESET',
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_resetPayment: 'payment/RESET',
            vm_resetCartUser: 'cartUser/RESET',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
        }),
        ...mapActions({
            va_sendOrderToKitchen: 'cart/sendOrderToKitchen'
        })
    }
}
</script>