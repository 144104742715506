import router from '../../router'
import { MenuAPI } from '@/clients/menu/index'
import {MenuHelper} from '@/helpers/menuHelper'
import moment from 'moment'
import 'moment-timezone'
export default {
    namespaced: true,
    state: {
      menus: [],
      currentCategory:{},
      currentTab: 0,
      date: '',
      time: '',
      allowToJoin: false,
      lastUsedDate: '',
      callServerValue: null,
      callServerUser: {},
      callServerTableId: null
    },
    getters: {
        menus: state => state.menus,
        currentCategory: state => state.currentCategory,
        currentTab: state => state.currentTab,
        date: state => state.date,
        time: state => state.time,
        allowToJoin: state => state.allowToJoin,
        lastUsedDate: state => state.lastUsedDate,
        callServerValue: state => state.callServerValue,
        callServerUser: state => state.callServerUser,
        callServerTableId: state => state.callServerTableId
    },
    mutations: {
        SET_MENUS(state, menus) {
            state.menus = menus
        },
        SET_CURRENT_CATEGORY(state, data){
            state.currentCategory = data
        },
        SET_CURRENT_TAB(state, data){
            state.currentTab = data
        },
        SET_DATE(state, data){
            state.date = data
        },
        SET_TIME(state, data){
            state.time = data
        },
        SET_ALLOW_TO_JOIN(state, data){
            state.allowToJoin = data
        },
        SET_LAST_USED_DATE(state, data){
            state.lastUsedDate = data
        },
        SET_CALL_SERVER_VALUE(state, data){
            state.callServerValue = data
        },
        SET_CALL_SERVER_USER(state, data){
            state.callServerUser = data
        },
        SET_CALL_SERVER_TABLE_ID(state, data){
            state.callServerTableId = data
        }
    },
    actions: {
        async setMenu({ commit }, locationId) {
            // set data and time from url
            let date = ''
            if(router.currentRoute.query.date){
                let dateString = router.currentRoute.query.date +' '+router.currentRoute.query.time
                commit('SET_DATE', router.currentRoute.query.date)
                commit('SET_TIME', router.currentRoute.query.time)
                moment.tz.setDefault('UTC')
                date = moment(dateString, 'YYYY-MM-DD H-m')
                if(!date.isValid()){
                    commit('SET_MENUS', [])
                    return
                }
            }else{
                commit('SET_DATE', '')
                commit('SET_TIME', '')
            }

            // reset store if last used app is more than 10 days
            if(this.getters['menu/lastUsedDate']){
                if(moment(this.getters['menu/lastUsedDate']).isAfter(moment().add(9, 'd'))){
                    console.log("local store is older than 10 days")
                    this.commit('cart/RESET')
                    this.commit('cartUser/RESET')
                    this.commit('payment/RESET')
                    commit('SET_LAST_USED_DATE', moment().format()) 
                }
            }
            commit('SET_LAST_USED_DATE', moment().format())

            const { data } = await MenuAPI.getMenus(locationId, date, (this.getters['cart/orderType']=='dinein'))
            if(this.getters['cart/orderType']=='dinein'){
                let menu = await MenuHelper.getValidMenuItemForDinein(data)
                if(menu){
                    commit('SET_MENUS', menu)
                }else{
                    commit('SET_MENUS', [])
                }
            }else{
                let menu = await MenuHelper.getValidMenuItemsForTakeout(data)
                if(menu){
                    commit('SET_MENUS', menu)
                }else{
                    commit('SET_MENUS', [])
                }
            }

            // set menu if 1st is enabled. currenly only show first menu
            // if(data.menus[0].status && moment().isBetween(data.menus[0].start_from, moment(data.menus[0].end_on).add(1, 'd'))){
            //     commit('SET_MENUS', data.menus)
            // }else{
            //     commit('SET_MENUS', [])
            // }
        }
    }
}