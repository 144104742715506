import { APP_URL, APP_API_TOKEN } from '../env'
import axios from '../_client'
import store from '../../store'

const userAxiosInstaceV1 = axios.create({
  baseURL: `${APP_URL}/api/v1`,
  headers: {
    'x-api-token': APP_API_TOKEN
  }
})
userAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})
export const UserAPI = {
  /**
     * 
     */
  register(data) {
    return userAxiosInstaceV1({
      method: 'post',
      url: `/register/phone`,
      data: data
    })
  },

  emailRegister(data){
    return userAxiosInstaceV1({
      method: 'post',
      url: `/register/local`,
      data: data
    })
  },

  socialRegister(data){
    return userAxiosInstaceV1({
      method: 'post',
      url: `/register/local`,
      data: data
    })
  },

  // resend for signup page
  resend(data){
    return userAxiosInstaceV1({
      method: 'post',
      url: `/contact_details/verify/resend`,
      data: data
    })
  },

  verify(verification_token, business_id){
    return userAxiosInstaceV1({
      method: 'get',
      url: `/contact_details/verify/${verification_token}?business_id=${business_id}&login=true&contact_details=true&address=true`
    })
  },

  login(data){
    return userAxiosInstaceV1({
      method: 'post',
      url: `/auth/login/phone`,
      data: data
    })
  },

  email(data){
    return userAxiosInstaceV1({
      method: 'post',
      url: `/auth/login/email`,
      data: data
    })
  },

  loginSocial(data){
    return userAxiosInstaceV1({
      method: 'post',
      url: `/userSocial/login?user=true&contact_details=true`,
      data: data
    })
  },
  
  verifyAuthToken(data){
    return userAxiosInstaceV1({
      method: 'post',
      url: `/auth/code/verify?user=true&contact_details=true&address=true`,
      data: data
    })
  },

  logout(){
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'post',
      url: `/auth/logout`,
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  },

  checkLoyalty(){
    let vg_businessId = store.getters['location/business_id']
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'get',
      url: `/business/${vg_businessId}/user/${vg_loginUser.user.id}/loyalty`,
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  },

  registerLoyalty(loyaltyData){
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'post',
      url: `/userLoyalty`,
      data: loyaltyData,
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  },

  caslEmail(caslData){
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'patch',
      url: `/users/profile`,
      data: caslData,
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  },

  getCasl(){
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'get',
      url: '/users/profile?contact_details=true',
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  },

  addAddress(data){
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'post',
      url: '/users/addresses',
      data: data,
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  },

  getAddress(){
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'get',
      url: '/users/addresses?page=1&limit=5',
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  },

  checkAuth(){
    let vg_loginUser = store.getters['login/user']
    return userAxiosInstaceV1({
      method: 'get',
      url: '/users/profile',
      headers: {
        'x-auth-token': vg_loginUser['x-auth-token']
      }
    })
  }
}


