import axios from '../_client'
const authAxiosInstaceV1 = axios.create({
  baseURL: `https://maps.googleapis.com/maps/api`,
})

authAxiosInstaceV1.interceptors.response.use(function (response) {
  if (response.data.redirect_to) {
    location.href = response.data.redirect_to
  }
  return response
}, function (error) {
  return Promise.reject(error)
})

export const GoogleAPI = {
    getAddressFromCoordinate(lat, lng){
        return authAxiosInstaceV1({
          method: 'get',
          url: `/geocode/json?latlng=${lat},${lng}&key=AIzaSyBJs2f0TNNui_OcRHgVRTIsJAvGQ0EB7oA`
        })
    }
}