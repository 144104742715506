<template>
  <div class="toolbar">
    <v-app-bar fixed elevation="1">
      <v-spacer></v-spacer>
      <v-toolbar-title>Recommended</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <loading-block v-if="loading" style="margin-top: 150px;" />
    <template v-else>
      <v-list id="list-content" v-if="allMenu && allMenu.length > 0" class="menu-list" two-line style="position: absolute !important; overflow: scroll !important; top: 1px !important; padding-top: 50px !important; bottom:85px !important;">
        <template v-for="(category, categoryIndex) in allMenu">
          <div :id="'tab-content-'+categoryIndex" :key="'tc'+categoryIndex">
          <template v-for="(item, menuIndex) in category.items">
            <div v-if="item.status" :key="categoryIndex+'m'+menuIndex" >
              <item :itemId="menuIndex===0?categoryIndex:''" :item="item"/>
              <v-divider></v-divider>
            </div>
          </template>
          </div>
        </template>
        <version />
      </v-list>
      <div v-else class="ml-5 mt-5" style="position: absolute !important; overflow: auto !important; top: 100px !important; bottom:85px !important;">No menu found.</div>
    </template>

    <v-footer class="footer" fixed color="white">
      <v-card class="flex" elevation="0">
        <v-card-text class="other-options" v-if="option">
          <v-btn class="mb-2" block color="primary" outlined @click.prevent="browseMenu">Browse Menu</v-btn>
          <!-- <v-btn class="mb-2" block color="primary" outlined @click.prevent="inviteFriend">Invite A Friend</v-btn> -->
          <!-- <v-btn class="mb-2" block color="primary" outlined @click.prevent="account">Account</v-btn> -->
          <!-- <v-btn class="mb-0" block color="primary" outlined @click.prevent="callServer">Call Server</v-btn> -->
        </v-card-text>
      
        <v-card-actions class="mt-5">
          <div class="footer-bar">
            <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
            <v-btn @click.prevent="moreOption" small fab class="center-btn">
              <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
              <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
            </v-btn>
            <v-spacer />
            <v-btn @click.prevent="skip" class="right-btn" dark>Skip</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-footer>

  </div>
</template>
<script>
import {Cart} from '@/helpers/cartHelper'
import {StringHelper} from '@/helpers/stringHelper'
// import {CartUserAPI} from '@/clients/cart-user'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import Version from '@/components/custom-components/version'
import Item from './item'
export default {
  components: {
    Item,
    Version
  },
  props: ['businessName', 'locationName'],
  data () {
    return {
      enableScroll: false,
      allCategoryScrollPos: [],
      lastScrollPos: 0,
      loading: false,
      tabs: [],
      //currentTab: 0,
      menu: [],
      option: false,
      allMenu: [],
      searchWord: ''
    }
  },
  async mounted () {
    this.loading = true
    this.vm_signupStatus(false)
    // add table id
    if(this.$router.currentRoute.query && this.$router.currentRoute.query.table_id){
      this.vm_tableId(this.$router.currentRoute.query.table_id)
    }else{
      this.vm_tableId(null)
    }

    // check if payed is true from previous
    if(this.vg_payed){
      this.vm_payed(false)
    }

    // get location
    await this.getLocation()

    await this.initalizeCart()

    this.loading = false

  },
  watch:{
    vg_payed(val){
        if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
          this.$router.push({name: 'receipt', query: {payer: false}})
        }
    }
  },
  computed: {
    currentTab: {
      get(){
        return this.vg_currentTab  
      },
      set(val){
        this.vm_currentTab(val)
      }
    },
    notify: {
        get(){
            return this.vg_notification;
        },
        set(value){
            this.vm_notification(value)
        }
    },
    ...mapState('menu', ['menus']),
    ...mapState('location', ['location']),
    ...mapGetters({
          vg_cart : 'cart/cart',
          vg_cartId: 'cart/cart_id',
          vg_location: 'location/location',
          vg_businessId: 'location/business_id',
          vg_businessName: 'location/businessName',
          vg_tableId: 'cart/tableId',
          vg_loginUser: 'login/user',
          vg_joinedCardId: 'cart/joinedCardId',
          vg_notification: 'cartUser/notification',
          vg_currentTab: 'menu/currentTab',
          vg_payed: 'payment/payed'
      })
  },
  methods: {
    async initalizeCart(){
      // @Cart - add categories in cart (initialize local cart)
      this.menus[0].categories.forEach((eachCategory) => {
        // this.vm_currentCategory(eachCategory)
        let cart = Cart.addCategory(eachCategory, [...this.vg_cart])
        if(cart){
          this.vm_cart(cart)
        }
      })
      
      this.allMenu = this.menus[0].categories
    },
    async getLocation(){
      try{
        if(!this.location || !this.location.slug || this.location.slug!==this.locationName){
          this.vm_setBusinessName(this.businessName)
          await this.va_setLocation([this.businessName, this.locationName])
          // empty cart on location change
          this.vm_resetCart()
          this.vm_resetCartUser()
        }
        
        // get menus
        await this.va_setMenu(this.location)
        this.getMenu()
      }catch(err){
        if(err.response.data && err.response.data.msg){
          this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
        }
      }
    },
    async getMenu(){
      if (this.menus && this.menus[0] && this.menus[0].categories) {
        // show only enabled category
        if(this.menus && this.menus[0] && this.menus[0].categories){
          this.tabs = this.menus[0].categories
          if (this.menus[0].categories[0] && this.menus[0].categories[0].items) {
            this.menu = this.menus[0].categories[0].items
          }
        }
      }
    },
    skip(){
      this.$router.push({name: 'close-bill'})
    },
    back(){
      this.$router.go(-1)
    },
    browseMenu(){
      let url = StringHelper.menuUrlGenerator(
          this.vg_businessName, 
          this.vg_location.slug,
          this.vg_tableId,
          this.vg_joinedCardId
      )
      this.$router.push(url)
    },
    inviteFriend(){
      this.$router.push({name: 'invite-friend'})
    },
    account(){
      this.$router.push({name: 'customer-detail'})
    },
    callServer(){

    },
    myTable(){
        this.$router.push({name: 'manage-table'})
    },
    moreOption(){
      this.option = !this.option
    },
    // for scroll down
    handleScroll(e) {
      if(this.enableScroll){
        let matchedCategoryResult = null
        if(e.srcElement.scrollTop>this.lastScrollPos){
          matchedCategoryResult = this.allCategoryScrollPos.findIndex(eachCategory => (eachCategory-100)>=(e.srcElement.scrollTop))
          if(matchedCategoryResult>0){
            this.currentTab = matchedCategoryResult-1
          }
        }
        this.lastScrollPos = e.srcElement.scrollTop
      }
    },
    getAllScrollPos(){
      for(let i=0; i<this.allMenu.length; i++){
        let scrollPos = document.getElementById('tab-'+i).offsetTop
        this.allCategoryScrollPos.push(scrollPos)

        // some random high number for scroll to work
        if(i===this.allMenu.length-1){
          scrollPos = 9000000
          this.allCategoryScrollPos.push(scrollPos)
        }
      }
    },
    scrollTo(hashNo){
      const self = this
      this.enableScroll = false
      let hash = 'tab-'+ hashNo
      document.getElementById(hash).scrollIntoView(true)
      document.querySelector('.v-list').scrollBy(0, -100);  // adjust for fixed header
      setTimeout(() => {
        // check if element has reach to top
        const elPos = document.getElementById(hash).offsetTop-100   // adjust for fixed header
        const scrollPos = document.querySelector('.v-list');
        
        if(scrollPos.scrollTop && Math.round(elPos)!==Math.round(scrollPos.scrollTop)){
          // test which tab can it reach to
          let testHash = Number(hashNo+'')
          let testElPos = document.getElementById('tab-'+testHash).offsetTop
          while(testElPos>scrollPos.scrollTop){
            testHash--
            testElPos = document.getElementById('tab-'+testHash).offsetTop
          }
          self.currentTab = testHash
        }
        self.enableScroll = true
      }, 300)
      
    },
    ...mapMutations({
      vm_cart: 'cart/SET_CART',
      vm_currentCategory: 'menu/SET_CURRENT_CATEGORY',
      vm_currentTab: 'menu/SET_CURRENT_TAB',
      vm_tableId: 'cart/SET_TABLE_ID',
      vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',
      vm_signupStatus: 'signup/SET_STATUS',
      vm_setBusinessName: 'location/SET_BUSINESS_NAME',
      vm_notification: 'cartUser/SET_NOTIFICATION',
      vm_payed: 'payment/SET_PAYED',
      vm_resetCart: 'cart/RESET',
      vm_resetCartUser: 'cartUser/RESET'
    }),
    ...mapActions({
      va_setMenu: 'menu/setMenu',
      va_setLocation: 'location/setLocation'
    })
  }
}
</script>
<style>
.toolbar .v-tabs .v-slide-group__prev {
  display: none !important;
}
.toolbar .v-tabs .v-tab--active {
  background: #000055 !important;
  border-radius: 30px;
  color: #fff;
  margin: 5px;
}
.toolbar .menu-list .v-list-item .v-list-item__subtitle {
  overflow: initial;
  white-space: initial;
}
.toolbar .menu-list .v-list-item__subtitle span {
  margin-right: 10px;
}
.toolbar .menu-list .v-list-item__subtitle .item-contains {
  background: #ff683c;
  border-radius: 5px;
  color: white;
  padding-left: 3px;
  padding-right: 3px;
}
.toolbar .menu-list .v-input__slot:before {
  border: none;
}
.search .v-input__slot{
  min-height:26px !important;
}
</style>
