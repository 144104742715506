<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-card v-if="camera">
                <v-card-title>
                    <v-spacer />
                    <span>Identify Table</span>
                    <v-spacer />
                    <v-btn fab dark x-small @click.prevent="closeQRCodeScanner">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        type="button"
                        color="primary"
                        block
                        @click.prevent="switchToTable"
                        >
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
            <v-form v-else ref="form" v-model="valid" @submit.prevent="setTableId">
                <v-card>
                    <v-card-title>
                        <v-spacer />
                        <span>Identify Table</span>
                        <v-spacer />
                        <v-btn fab dark x-small @click.prevent="closeIdentifyTable">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model.number="form.table_number"
                            :rules="rules.tableRules"
                            label="Table"
                            hint="Enter the Number on the table to continue"
                            required
                            outlined
                            maxlength="4"
                            @keypress="isNumber"
                            ></v-text-field>

                        <v-btn text color="primary" @click.prevent="scanQRCode">SCAN QR CODE</v-btn>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            type="submit"
                            color="primary"
                            block
                            >
                            Continue
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return {
            form: {
                table_number: ''
            },
            rules: {
                tableRules: [
                    v => !!v || 'Please use a valid table number'
                ]
            },
            valid: false,
            camera: false,
            error: ''
        }
    },
    watch: {
        vg_identifyTableStatus(val){
            if(val){
                this.form.table_number = ''
                if(this.$refs.form){
                    this.$refs.form.reset()
                }
                this.vm_abruptClose(false)
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_itemInCart(val){
            // getting triggered from close bill page on vg_itemInCart change
            if(val && this.$router.currentRoute.name=='identify-table'){
                this.$router.push({name: 'cart'})
            }
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_identifyTableStatus
            },
            set(val){
                this.vm_identifyTableStatus(val)
            }
        },
        ...mapGetters({
            vg_identifyTableStatus: 'identifyTable/status',
            vg_payed: 'payment/payed',
            vg_loginUser: 'login/user',
            vg_itemInCart: 'cartUser/itemInCart'
        })
    },
    methods: {
        isNumber(e) {
            e = (e) ? e : window.event;
            var charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        setTableId(){
            if(this.$refs.form.validate()){
                this.vm_tableId(this.form.table_number)
                this.vm_identifyTableStatus(false)
            }
        },
        closeQRCodeScanner(){
            this.camera = false
            this.vm_identifyTableStatus(false)
            this.vm_abruptClose(true)
        },
        closeIdentifyTable(){
            this.$refs.form.reset()
            this.vm_identifyTableStatus(false)
            this.vm_abruptClose(true)
        },
        scanQRCode(){
            this.camera = true
        },
        switchToTable(){
            this.camera = false
        },
        onDecode (urlVal) {
            try{
                let url = new URL(urlVal)
                let tableId = url.searchParams.get("table_id")
                this.vm_tableId(tableId)
                this.vm_identifyTableStatus(false)
            }catch(err){
                console.log(err)
            }
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                }
            }
        },
        ...mapMutations({
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_abruptClose: 'identifyTable/SET_ABRUPT_CLOSE',
            vm_identifyTableStatus: 'identifyTable/SET_STATUS'
        })
    }
}
</script>