<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Guest Has Unpaid Items</span>
                    <v-spacer />
                </v-card-title>
                <v-card-text>
                    <div>
                        Guests cannot be removed unless their items are paid for or transferred to someone else
                    </div>
                </v-card-text>
                <v-card-text>
                    <v-btn @click.prevent="cancel()" class="primary px-5 mb-5" block>Got it</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import { mapGetters, mapMutations } from 'vuex'

export default {
    data() {
        return {
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_cannotRemoveUserPop
            },
            set(val){
                this.vm_cannotRemoveUserPop(val)
            }
        },
        ...mapGetters({
            vg_cannotRemoveUserPop: 'cartUser/cannotRemoveUserPop',
        })
    },
    methods: {
        capitalizeFirstLetter(name){
            return StringHelper.capitalizeFirstLetter(name)
        },
        cancel(){
            this.vm_cannotRemoveUserPop(false)
        },
        ...mapMutations({
            vm_cannotRemoveUserPop: 'cartUser/SET_CANNOT_REMOVE_USER_POP'
        })
    }
}
</script>