<template>
    <v-dialog v-model="confirmDeliveryTakeout" persistent  >
        <v-card>
            <v-card-title>
                    <v-spacer />
                    <span>Choose order</span>
                    <v-spacer />
                    <v-btn fab dark x-small @click.prevent="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
            </v-card-title>
            <v-card-text>
                <div class="text-center" style="padding-top: 20px !important;">
                    <v-btn outlined large color="grey" @click.prevent="dineIn()">
                        <v-img contain src="@/assets/img/fork-knife.png" height="20" width="20" />
                        <span class="primary--text ml-2">DINE IN</span>
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: ['confirmDeliveryTakeout'],
    methods: {
        dineIn(){
            this.$emit('orderType', 'dinein')
        },
        takeOut(){
            this.$emit('orderType', 'pickup')
        },
        delivery(){
            this.$emit('orderType', 'delivery')
        },
        close(){
            this.$emit('cancel')
        }
    }
}
</script>