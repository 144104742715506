import store from '@/store'

export const StringHelper = {
    formatPhone(phoneNumber){
        if(!phoneNumber) return

        let cleaned = ('' + phoneNumber).replace(/\D/g, '')
        let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{2})()\d{2}$/)
        if (match) {
            return [match[2], '-**-*', match[5]].join('')
        } else {
            return
        }
    },
    formatPercentage(number){
        if(!number) return
        
        let x = number.replace(/\D/g, '').match(/(\d{0,2}|100)/)
        return x[0] ? x[0]+'%' : '';
    },
    stripPercentSign(percentageValue){
        if(!percentageValue) return

        return percentageValue.replace(/\D/g, '')
    },
    capitalize(name){
        if(!name) return
        
        return name.charAt(0).toUpperCase() + name.slice(1)
    },
    standardPhoneFormat(phoneNumber){
        if(!phoneNumber) return
        
        let x = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    flatPhoneFormat(phoneNumber){
        return phoneNumber.replace(/[^0-9.]/g, '');
    },
    censorPhone(phoneNumber){
        if(!phoneNumber) return

        let cleaned = ('' + phoneNumber).replace(/\D/g, '')
        let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{2})(\d{2})$/)
        if (match) {
            return ['+',match[1], '-', match[2], '-**-*', match[5]].join('')
        } else {
            return
        }
    },
    menuUrlGenerator(businessName='', locationName='', tableId='', joiningId=''){
        let vg_serverCart = store.getters['cart/serverCart']
        let url = `/${businessName}/${locationName}/menu`
        let query = {}
        if(tableId) {
            query.table_id = tableId
        }else if(vg_serverCart.table_id){
            query.table_id = vg_serverCart.table_id
        }
        if(joiningId) query.join_cart = joiningId
        if(store.getters['menu/date']) query.date = store.getters['menu/date']
        if(store.getters['menu/time']) query.time = store.getters['menu/time']
        if(store.getters['menu/allowToJoin']) query.allow_to_join = store.getters['menu/allowToJoin']
        const params = new URLSearchParams(query)
        const queryString = params.toString()
        return url+(queryString?'?'+queryString:'')
    },
    recommendUrlGenerator(businessName='', locationName='', tableId='', joiningId=''){
        let url = `/${businessName}/${locationName}/recommend`
        let query = ''
        let isFirstQuery = false
        if(tableId){
            isFirstQuery = true
            query = query+'?table_id='+tableId
        }
        if(joiningId){
            query = query+(isFirstQuery?'&':'?')
            query = query+'join_cart='+joiningId
        }
        return url+query
    },
    menuDetailUrlGenerator(businessName='', locationName='', itemId=''){
        let url = `/${businessName}/${locationName}/menu/${itemId}/item`
        return url
    },
    getIconName(name){
        let matches =  name.match(/\b(\w)/g);
        return matches.join('')
    },
    capitalizeFirstLetter(string){
        if(!string) return
        
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getUserNameOrPhone(userObject){
        if(!userObject.user) return
        let vg_loginWith = store.getters['login/loginWith']
        let loginWithUserObject = []
        
        let username = '';
        if(userObject.user.first_name!==null)
            username = userObject.user.first_name+' '
        if(userObject.user.last_name!==null)
            username += userObject.user.last_name
        if(username==='' && vg_loginWith)
            // get email or phone
            loginWithUserObject = userObject.user.contact_details.filter(each => each.contact_value==vg_loginWith)
            if(loginWithUserObject && loginWithUserObject.length>0 && loginWithUserObject[0].contact_value){
                if(isNaN(parseInt(loginWithUserObject[0].contact_value)))
                    username = loginWithUserObject[0].contact_value
                else
                    username = this.censorPhone(loginWithUserObject[0].contact_value)
            }
        return username
    },
    getNameOrPhone(userObject){
        if(userObject?.name===undefined) return
        
        let username = '';
        if(userObject.name!==null)
            username = userObject.name
        else
            username = this.censorPhone(userObject.phone)
        
        return username
    },
    getFirstNameOrPhone(userObject){
        if(!userObject.user) return
        let vg_loginWith = store.getters['login/loginWith']
        let loginWithUserObject = []
        
        let username = '';
        if(userObject.user.first_name!==null)
            username = this.capitalize(userObject.user.first_name)
        if(username==='' && vg_loginWith)
            // get email or phone
            loginWithUserObject = userObject.user.contact_details.filter(each => each.contact_value==vg_loginWith)
            if(loginWithUserObject && loginWithUserObject.length>0 && loginWithUserObject[0].contact_value){
                if(isNaN(parseInt(loginWithUserObject[0].contact_value)))
                    username = loginWithUserObject[0].contact_value
                else
                    username = this.censorPhone(loginWithUserObject[0].contact_value)
            }
        return username
    },
    getPhone(userObject){
        if(userObject.user && userObject.user.contact_details && userObject.user.contact_details.length>0){
            let obj = userObject.user.contact_details.filter(each => each.contact_type=='phone')
            if(obj.length>0){
                return obj[0].contact_value.slice(-10)
            }else{
                return null
            }
        }
        return null
    },
    getMyEmailOrPhone(type){
        let vg_loginUser = store.getters['login/user']
        let vg_loginWith = store.getters['login/loginWith']

        if(type=='email'){
            if(isNaN(parseInt(vg_loginWith))){
                // string
                return vg_loginWith
            }else{
                // phone so search from loginUser
                let filterEmails = vg_loginUser.user.contact_details.filter(each => each.contact_type=='email')
                if(filterEmails.length>0){
                    return filterEmails[0].contact_value
                }else{
                    return null
                }
            }
        }else if(type=='phone'){
            if(isNaN(parseInt(vg_loginWith))){
                // string so search from loginUser
                let filterPhones = vg_loginUser.user.contact_details.filter(each => each.contact_type=='phone')
                if(filterPhones.length>0){
                    return filterPhones[0].contact_value
                }else{
                    return null
                }
            }else{
                // phone
                return vg_loginWith
            }
        }

        return
    },
    isLoginWithIsEmail(){
        let vg_loginWith = store.getters['login/loginWith']
        if(!vg_loginWith) return

        return (isNaN(parseInt(vg_loginWith)))
    },
    isLoginWithIsPhone(){
        let vg_loginWith = store.getters['login/loginWith']
        if(!vg_loginWith) return

        return (!isNaN(parseInt(vg_loginWith)))
    },
    search(allMenus, searchTerm){
        // enabled menu only
        let menus = allMenus.filter(category => {return category.status})

        let result = []
        for(let i=0; i<menus.length; i++){
            if(menus[i] && menus[i].name){
                // search category name
                let isCategory = menus[i].name.search(new RegExp(searchTerm, "i"))>=0
                let itemResult = menus[i].items.filter(item => {
                    // search item name and description
                    let isItemName = item.name.search(new RegExp(searchTerm, "i"))>=0
                    let isDescription = item.short_description.search(new RegExp(searchTerm, "i"))>=0
                    let isTag = item.tags.filter(tag => {
                        return tag.name.search(new RegExp(searchTerm, "i"))>=0
                    })
                    return (isItemName||isDescription||isTag.length>0)
                })

                // show all category if word match category else show itemresult
                if(isCategory){
                    result.push(menus[i])
                }else if(itemResult.length>0){
                    result.push({id: menus[i].id, items: itemResult, name: menus[i].name})
                }
                
            }
        }
        return result
    }
}