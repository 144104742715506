/* eslint-disable indent */
export const AUTH_URL = process.env.VUE_APP_AUTH_URL
export const AUTH_TOKEN = process.env.VUE_APP_AUTH_TOKEN
export const LOCATION_URL = process.env.VUE_APP_LOCATION_URL
export const LOCATION_API_TOKEN = process.env.VUE_APP_LOCATION_API_TOKEN
export const APP_LOYALTY_URL = process.env.VUE_APP_LOYALTY_URL
export const APP_URL = process.env.VUE_APP_URL
export const APP_API_TOKEN = process.env.VUE_APP_API_TOKEN
export const APP_API_LOYALTY_TOKEN = process.env.VUE_APP_API_LOYALTY_TOKEN
export const APP_BUSINESS_ID = process.env.VUE_APP_BUSINESS_ID
export const APP_CART_URL = process.env.VUE_APP_CART_URL
export const APP_ORDER_URL = process.env.VUE_APP_ORDER_URL
export const APP_PAYMENT_URL= process.env.VUE_APP_PAYMENT_URL
export const APP_PAYMENT_API_TOKEN = process.env.VUE_APP_PAYMENT_API_TOKEN
export const APP_MONERIS_URL = process.env.VUE_APP_MONERIS_URL
export const PUBNUB_PUBLIC_KEY = process.env.VUE_APP_PUBLIC_KEY
export const PUBNUB_SUBSCRIBE_KEY = process.env.VUE_APP_SUBSCRIBE_KEY
export const STRIPE_SECRET_KEY = process.env.VUE_APP_STRIPE_SECRET_KEY
export const FISKA_TERMINAL_ID = process.env.VUE_APP_FISKA_TERMINAL_ID
export const FISKA_SECRET = process.env.VUE_APP_FISKA_SECRET
export const CLOVER_SDK = process.env.VUE_APP_CLOVER_SDK_URL
export const CLOVER_MERCHANT_ID = process.env.VUE_APP_CLOVER_MERCHANT_ID
export const FACEBOOK_API_ID = process.env.VUE_APP_FACEBOOK_API_ID