<template>
    <div>
        <v-dialog content-class="fiska-popup" v-model="dialog" persistent style="width:380px;">
            <v-btn class="close-btn" style="position:absolute; right:30px; margin-top: 5px; z-index:9999;" fab dark x-small @click.prevent="closeFiska">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <loading-block v-if="!iframeReady" class="pt-9" />
            <v-card v-else>
                <v-card-title>
                    <v-spacer />
                    <span>Add Payment Method</span>
                    <v-spacer />
                </v-card-title>
                <v-card-text class="px-0" style="overflow:hidden !important;" >
                    <iframe id="fiskaIframe" style="overflow:hidden;" :src="iframeUrl" @load="iframeLoaded" frameborder="0" width="100%" height="720px"></iframe>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import moment from 'moment'
import sha512 from 'js-sha512'
import { FISKA_TERMINAL_ID, FISKA_SECRET} from '@/clients/env'
import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return{
            iframeReady: false,
            iframeUrl: '',
            terminalId: '',
            secret: '',
            merchantRefId: '',
            datetime: '',
            hash: '',
        }
    },
    mounted(){
        this.load()
    },
    watch:{
        vg_fiskaPopup(val){
            if(val){
                this.load()
            }
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_fiskaPopup
            },
            set(val){
                this.vm_fiskaPopup(val)
            }
        },
        ...mapGetters({
            vg_fiskaPopup: 'payment/fiskaPopup'
        })
    },
    methods: {
        load(){
            this.iframeReady = false
            this.terminalId = FISKA_TERMINAL_ID,
            this.secret = FISKA_SECRET
            this.merchantRefId = Date.now()
            this.datetime = moment(Date.now()).format('DD-MM-YYYY:HH:mm:ss:SSS')
            let hashForm = `${this.terminalId}:${this.merchantRefId}:${this.datetime}:register:${this.secret}`
            this.hash = sha512(hashForm)
            this.iframeUrl = `https://testpayments.nuvei.com/merchant/securecardpage?ACTION=register&TERMINALID=1064A24&MERCHANTREF=${this.merchantRefId}&DATETIME=${this.datetime}&RECEIPTPAGEURL=https://gr8.uat.unoapp.io/fiska_reponse&HASH=${this.hash}&STOREDCREDENTIALUSE=UNSCHEDULED&INIFRAME=Y`
            this.iframeReady = true
        },
        closeFiska(){
            this.vm_fiskaPopup(false)
        },
        iframeLoaded(){
            this.iframeReady = true
        },
        ...mapMutations({
            vm_fiskaPopup: 'payment/SET_FISKA_POPUP'
        })
    }
}
</script>
<style>
@media screen and (max-width: 400px) {
    .fiska-popup{
        margin:0;
    }
    .fiska-popup .close-btn{
        right:15px !important;
    }
}
</style>