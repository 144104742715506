export const ArrayHelper = {
    // check whether checkbox is checked or unchecked
    // return id which is added or removed
    checkCheckboxGroup(newArr, oldArr){
        let addedId = newArr.filter(x => !oldArr.includes(x))
        let removedId = oldArr.filter(x => !newArr.includes(x))
        if(addedId.length>0){
            return {id: addedId[0], remark: 'added'}
        }else if(removedId.length>0){
            return {id: removedId[0], remark: 'removed'}
        }
    }
}