import { apolloClient } from '@/vue-apollo'
import { GET_MENUS, GET_MENUS_WITH_ORDER_TYPE, GET_MENUS_WITH_DATE, GET_MENUS_WITH_DATE_ORDER_TYPE } from '@/graphql/queries/menu'

export const MenuAPI = {
	getMenus(location, date = '', isDineIn) {
		if (date === '') {
			if (isDineIn) {
				return apolloClient.query({
					query: GET_MENUS,
					variables: { location_id: location.id, filter_by_hour: true, timezone: location.address.timezone_id },
					fetchPolicy: 'no-cache',
				})
			} else {
				return apolloClient.query({
					query: GET_MENUS_WITH_ORDER_TYPE,
					variables: { location_id: location.id, filter_by_hour: false, timezone: location.address.timezone_id, orderType: 'takeout' },
					fetchPolicy: 'no-cache',
				})
			}
		} else {
			if (isDineIn) {
				return apolloClient.query({
					query: GET_MENUS_WITH_DATE,
					variables: { location_id: location.id, filter_by_hour: true, timezone: location.address.timezone_id, date: date },
					fetchPolicy: 'no-cache',
				})
			} else {
				return apolloClient.query({
					query: GET_MENUS_WITH_DATE_ORDER_TYPE,
					variables: { location_id: location.id, filter_by_hour: false, timezone: location.address.timezone_id, date: date, orderType: 'takeout' },
					fetchPolicy: 'no-cache',
				})
			}
		}
	},
}
