<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Welcome!</span>
                    <v-spacer />
                    <v-btn fab dark x-small  @click.prevent="closeLoginIndex">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-2 px-3">
                    <v-img v-if="vg_business.branding && vg_business.branding.logo_url" :src="vg_business.branding.logo_url" contain max-height="50" />
                    <v-btn class="px-5 my-5" color="primary" elevation="0" block @click="signupIndex">First Time</v-btn>
                    <v-btn class="mb-5 primary--text body-2" block outlined @click="loginIndex">I already have an account </v-btn>
                    <v-btn v-if="!isMenuItemPage()" class="px-5 primary--text" @click="guest" block text>Continue as Guest</v-btn>
                </v-card-text>
                <v-card-text>
                    <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                    <span class="float-left pr-1 primary--text">POWERED BY :</span>
                    <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return{
            
        }
    },
    computed: {
        dialog: {
            get(){
                return this.vg_loginWelcomeStatus
            },
            set(val){
                this.vm_loginWelcomeStatus(val)
            }
        },
        ...mapGetters({
            vg_loginWelcomeStatus: 'login/status',
            vg_business: 'location/business'
        })
    },
    methods: {
        isMenuItemPage(){
            return this.$router.currentRoute.name=='menu-detail'
        },
        guest(){
            this.vm_loginWelcomeStatus(false)
        },
        closeLoginIndex(){
            this.vm_loginWelcomeStatus(false)
        },
        signupIndex(){
            this.vm_loginWelcomeStatus(false)
            this.vm_signupWelcomeStatus(true)
        },
        loginIndex(){
            this.vm_loginWelcomeStatus(false)
            this.vm_loginPop(true)
        },
        ...mapMutations({
            vm_loginWelcomeStatus: 'login/SET_STATUS',
            vm_loginPop : 'login/SET_LOGIN_POP',
            vm_signupWelcomeStatus: 'signup/SET_STATUS'
        })
    }
}
</script>