<template>
    <div>
        <loading-block v-if="loading" class="pt-9" />
        <div class="mt-5" v-else>
            <div style="position:absolute; z-index:99; color:#fff; left:0; right:0; top: 10px; margin:auto; width:300px;">
                <div class="title ml-2">{{vg_programName}}</div>
                <div class="body-2 font-weight-bold pt-1 ml-2" v-if="redeemPoints">You have {{redeemPoints.toLocaleString()}} points</div>
                <div class="body-2 text--lighten-2 pt-1 ml-2" v-if="vg_loyaltyData.settings">{{vg_loyaltyData.settings.points_for_dollar}} points = $1</div>
                <div style="margin-top:15px;">
                    <img style="margin: auto; position:absolute; left:0; right:0; max-width:280px;" v-if="vg_loyaltyData.branding" :src="vg_loyaltyData.branding.logo_url" contain height="100" />
                </div>
            </div>
            <div v-if="vg_loyaltyData.branding" class="mx-auto" :style="'min-width:290px; max-width:400px; height:250px; background-color:'+vg_loyaltyData.branding.secondary_color+'; border-radius: 15px;'" />
            <v-img v-else class="mx-auto" src="@/assets/img/loyalty-banner.png" style="min-width:290px; max-width:400px; height:220ppx" />
            <div class="text-center primary--text title my-5" style="cursor:pointer;" @click.prevent="faq">HOW TO EARN &amp; REDEEM POINTS</div>
            <div class="text-center font-weight-bold title my-5">Membership Transaction History</div>
            <loading-block v-if="loading" class="pt-9" />
            <div v-else style="overflow:auto; margin-bottom:100px;">
                <v-list two-line class="mx-5">
                    <div v-if="loyaltyHistory.results && loyaltyHistory.results.length>0">
                        <template v-for="(loyalty, index) in loyaltyHistory.results">
                            <v-list-item :key="index" class="mx-0 px-0">
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold" >{{ loyaltyHeading[loyalty.tx_type] ? loyaltyHeading[loyalty.tx_type] : 'Order#'+ loyalty.ref_id }}</v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-bold" v-if="loyalty.location_data">{{loyalty.location_data.name}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-1">
                                        <div class="float-left">Point used</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle >
                                        <v-btn elevation="0" class="float-left red lighten-5 red--text font-weight-bold" x-small>{{loyalty.points_redeemed}}</v-btn>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action class="ml-0">
                                    <v-btn v-if="loyalty.tx_type=='ecomm_order'" class="font-weight-bold px-0" style="height:18px;" elevation="0" text @click.prevent="viewDetail(loyalty.ref_id)">View Details</v-btn>
                                    <v-list-item-subtitle class="font-weight-bold"></v-list-item-subtitle>
                                    <v-list-item-subtitle class="mt-1">
                                        <div class="float-left">Point earned</div>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle >
                                        <v-btn elevation="0" class="float-right green lighten-5 green--text font-weight-bold" x-small>{{loyalty.points_earned}}</v-btn>
                                    </v-list-item-subtitle>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider :key="'d'+index"></v-divider>
                        </template>

                        <div v-if="loyaltyHistory.total_pages!==vg_loyaltyPage">
                            <loading-block v-if="loadingMore" class="my-2" />
                            <v-btn v-else block text class="my-2" @click.prevent="loadMore">More</v-btn>
                        </div>
                    </div>
                    <div v-else>No past membership found.</div>
                    <version />
                </v-list>
            </div>
        </div>
        <v-footer class="footer" fixed color="white" v-if="!loading">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || (vg_orderType!=='dinein') || !!vg_callServerValue">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                <div class="footer-bar">
                    <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                    <v-btn @click.prevent="moreOption" small fab class="center-btn">
                    <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                    <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn @click.prevent="faq" class="right-btn" dark>FAQ</v-btn>
                </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import {CartAPI} from '@/clients/cart'
import {UserAPI} from '@/clients/user'
import {LoyaltyAPI} from '@/clients/loyalty/index'
import {OrderAPI} from '@/clients/order'
import {LoyaltyTransactionAPI} from '@/clients/loyalty-transaction'
import {mapGetters, mapMutations} from 'vuex'
import Version from '@/components/custom-components/version'
export default {
    components: {
        Version
    },
    data(){
        return {
            loading: false,
            option: false,
            loadingMore: false,
            redeemPoints: null,
            loyaltyHistory: {},
            loyaltyHeading: {
                'ecomm_order': 'Place order',
                'welcome_bonus': 'Sign up points',
                'app_bonus': 'App actions',
                'store_checkin_bonus': 'store check-in',
                'point_adjustment': 'Admin points adjustments',
                'client_point_adjustment': 'Client points adjustments',
                'void': 'Void',
                'birthday_bonus': 'Birthday bonus',
                'anniversary_bonus': 'Anniversary bonus',
                'referral_bonus': 'Referral bonus',
                'referral_joining_bonus': 'Referral joining bonus',
            }
        }
    },
    async mounted(){
        this.loading = true
        this.vm_loyaltyPage(1)
        await this.loyaltyProgram()
        await this.loyaltyPoints()
        await this.getLoyaltyTransactions()
        this.vm_faqStatus(false)
        this.loading = false
    },
    watch:{
        async vg_tab(val){
            if(val===2){
                this.loading = true
                this.vm_loyaltyPage(1)
                await this.loyaltyProgram()
                await this.loyaltyPoints()
                await this.getLoyaltyTransactions()
                this.loading = false
            }
        }
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        ...mapGetters({
            vg_orderType: 'cart/orderType',
            vg_tab: 'account/tab',
            vg_tableId: 'cart/tableId',
            vg_loginUser: 'login/user',
            vg_location: 'location/location',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_businessId: 'location/business_id',
            vg_businessName: 'location/businessName',
            vg_notification: 'cartUser/notification',
            vg_callServerValue: 'menu/callServerValue',
            vg_loyaltyPage: 'loyalty/loyaltyPage',
            vg_programName: 'loyalty/programName',
            vg_loyaltyData: 'loyalty/loyaltyData',
            vg_itemInCart: 'cartUser/itemInCart'
        })
    },
    methods: {
        back(){
            this.navigateToMenu()
        },
        navigateToMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        moreOption(){
            this.option = !this.option
        },
        async callServer(){
            try{ 
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        myTable(){
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        async loyaltyProgram(){
            // loyalty
            try{
                const loyaltyData = await LoyaltyAPI.loyaltyService(this.vg_businessId, true)
                // get loyalty service - enable or disable
                if(loyaltyData && loyaltyData.data){
                    this.vm_loyaltyData(loyaltyData.data)
                }
            }catch(err){
                console.log(err)
            }
        },
        async loyaltyPoints(){
            try{
                let {data} = await UserAPI.checkLoyalty()
                if(data){
                    this.redeemPoints = data.points
                }
            }catch(err){
                console.log(err)
            }
        },
        async loadMore(){
            try{
                this.loadingMore = true
                this.vm_loyaltyPage((this.vg_loyaltyPage+1))
                let {data} = await LoyaltyTransactionAPI.loyaltyTransactions(this.vg_loyaltyPage)
                this.loyaltyHistory.results = this.loyaltyHistory.results.concat(data.results)
                this.loadingMore = false
            }catch(err){
                this.loadingMore = false
                console.log(err)
            }
        },
        async getLoyaltyTransactions(){
            try{
                let {data} = await LoyaltyTransactionAPI.loyaltyTransactions(1)
                this.loyaltyHistory = data
            }catch(err){
                if(err.response.status===404){
                    this.loyaltyHistory.results = []
                }else if(err.response.data.msg=='Looks like your not logged in. Please login and try again'){
                    this.reset()
                    this.snackbar({open: true, text:err.response.data.msg, color: 'red'})
                    this.$router.push({name: 'menu'})
                }else{
                    this.snackbar({open: true, text:err.response.data.msg, color: 'red'})
                }
            }
        },
        faq(){
            this.vm_faqStatus(true)
        },
        async viewDetail(orderId){
            if(!orderId) return

            try{
                this.spinner(true)
                let {data} = await OrderAPI.getOrderDetailByCartId(orderId)
                if(data.payload && data.payload.data && data.payload.data[0]){
                    this.vm_currentOrder(data.payload.data[0])
                    this.$router.push({name: 'past-order'})
                }
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        reset(){
            this.vm_setOrderType(null)
            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_deliveryReset()
            this.vm_resetPayment()
            this.vm_tableId('')
            this.vm_allowToJoin(false)
            this.vm_resetUser()
            this.vm_confirmDeliveryPickup(true)
        },
        ...mapMutations({
            vm_paymentToken: 'payment/SET_TOKEN',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_faqStatus: 'faq/SET_STATUS',
            vm_loyaltyPage: 'loyalty/SET_LOYALTY_PAGE',
            vm_loyaltyData: 'loyalty/SET_LOYALTY_DATA',
            vm_currentOrder: 'order/SET_CURRENT',
            vm_resetCart: 'cart/RESET',
            vm_resetUser: 'login/RESET_USER',
            vm_deliveryReset: 'delivery/RESET',
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_resetPayment: 'payment/RESET',
            vm_resetCartUser: 'cartUser/RESET',
            vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
        })
    }
} 
</script>