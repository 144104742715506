<template>
    <v-card class="my-5" @click.prevent="orderDetail(order)">
        <v-list-item three-line class="pr-0">
            <v-list-item-content class="pb-0">
                <div class="body-2 grey--text">{{formatDate(order.created_at)}}</div>
                <v-list-item-title>{{location.display_name}}</v-list-item-title>
                <div class="body-2 grey--text">{{getOrderType(order.order_receive_method)}}</div>
            </v-list-item-content>
            <v-list-item-avatar class="pb-0 ma-0 grey lighten-2" :style="{width:'100px', minWidth:'100px', minHeight:'75px', borderRadius: 0}">
                <div class="title">${{parseFloat(order.total).toFixed(2)}}</div>
            </v-list-item-avatar>
        </v-list-item>
        <v-divider />
        <template v-if="status">
            <v-list-item class="mt-5" two-line v-for="(item, ind) in order.order_items" :key="'item-'+ind">
                <v-list-item-content class="pt-0">
                    <div class="row"> 
                        <div class="col-4">
                            <v-img :src="item.image_url" contain max-width="100" />
                            <span style="font-size: 12px;">{{getCourse(item.course)}}</span>
                        </div>
                        <div class="col-8">
                            <v-list-item-title>{{item.name}}</v-list-item-title>
                            <v-list-item-action-text>{{getName(item.cart_item_modifiers)}}</v-list-item-action-text>
                            <v-list-item-subtitle class="black--text font-weight-bold pt-1">${{parseFloat(item.actual_price).toFixed(2)}}</v-list-item-subtitle>
                        </div>
                    </div>
                </v-list-item-content>
                <v-list-item-action-text class="pt-0"> 
                    <v-btn @click.prevent.stop="addOrViewOrder(item)" text class="ma-0 pa-0 primary--text" style="border-radius:0">REORDER</v-btn>
                </v-list-item-action-text>
            </v-list-item>
            <v-divider />
        </template>
        <v-list-item-action class="d-block ma-0 pa-2">
            <v-btn text block @click.prevent.stop="toggle">
                <span class="body-2 font-weight-bold" >{{status?'CLOSE':'View Order'}}</span>
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-list-item-action>
    </v-card>
</template>
<script>
import {DateHelper} from '@/helpers/dateHelper'
import { mapState, mapMutations } from 'vuex'
import { Cart } from '../../helpers/cartHelper'
import { ItemSearchAPI } from '@/clients/item'

export default {
    props: ['order'],
    data(){
        return{
            courses: ['Now', 'First Course', 'Second Course', 'Third Course', 'Takeout'],
            status: false
        }
    },
    computed: {
        ...mapState('location', ['location']),
    },
    methods: {
        getCourse(course){
            return this.courses[course]
        },
        getOrderType(orderType){
            if(orderType==='dinein'){
                return 'Dine in'
            }else if(orderType=='pickup'){
                return 'Take out'
            }else if(orderType=='delivery'){
                return 'Delivery'
            }
        },
        formatDate(date){
            if(!date) return
            return DateHelper.formatDate(date)
        },
        getName(orderItems){
            let names = ''
            orderItems.forEach(modifier => {
                if(modifier.cart_item_modifier_items && modifier.cart_item_modifier_items.length>0){
                    modifier.cart_item_modifier_items.forEach(modifierItem => {
                        names = names+', '+modifierItem.name;
                    })
                }
            })
            return names.substring(2)
        },
        menuItemSlug(item){
            return item.name.replace(/\s+/g, '-').toLowerCase()+'-'+item.item_id
        },
        toggle(){
            this.status = !this.status
        },
        orderDetail(order){
            this.vm_currentOrder(order)
            this.$router.push({name: 'past-order'})
        },
        async addOrViewOrder(order){
            let menuItemSlug = order.item_slug?order.item_slug:this.menuItemSlug(order)
            const { data } = await ItemSearchAPI.getItem(menuItemSlug)
            if(data.itemSearch){
                let item = Cart.pastOrderToMenuItem(order, data.itemSearch)
                console.log(item)
                this.vm_editItem(item)
            }
            this.$router.push({name: 'menu-detail', params: {slug: menuItemSlug}})
            
        },
        ...mapMutations({
            vm_currentOrder: 'order/SET_CURRENT',
            vm_item: 'item/SET_ITEM',
            vm_editItem: 'cart/SET_EDIT_ITEM',
        })
    }
}
</script>