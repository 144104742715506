<template>
    <div class="my-2 py-3 " style="background-color: white;">
        <div class="mx-5 font-weight-bold">Delivery </div>
        <div class="mx-5">To: {{formattedAddress}}</div>
        <div class="mx-5"><v-icon>mdi-phone</v-icon> {{phone}}</div>
        <div class="mx-5">
            <v-row>
                <v-col>
                    <div>{{text}}</div>
                </v-col>
                <v-col>
                    <div class="text-right">{{time}}</div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import {GoogleHelper} from '@/helpers/googleHelper'
import moment from 'moment'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            phone: '',
            text: '', 
            time: '',
            formattedAddress: '',
        }
    },
    mounted(){
        this.phone = StringHelper.standardPhoneFormat(this.vg_deliveryPhone)
        this.formattedAddress = GoogleHelper.getFormattedAddress(this.vg_deliveryAddress)
        if(this.vg_deliveryAt==='now'){
            this.text = 'Ready in about'
            this.time = moment().add('20', 'm').format('HH:mm')
        }else{
            this.text = this.readableDate()
            this.time = this.getPickupTime(this.vg_deliveryTime)
        }
    },
    computed: {
        ...mapGetters({
            vg_deliveryPhone: 'delivery/phone',
            vg_deliveryAddress: 'delivery/address',
            vg_location: 'location/location',
            vg_deliveryAt: 'delivery/deliveryAt',
            vg_deliveryDate: 'delivery/deliveryDate',
            vg_deliveryTime: 'delivery/deliveryTime'
        })
    },
    methods: {
        readableDate(){
            if(moment(this.vg_deliveryDate, 'YYYY-MM-DD').format('DD')===moment().format('DD'))
                return 'Today'
            else if(moment(this.vg_deliveryDate, 'YYYY-MM-DD').format('DD')===moment().add(1, 'd').format('DD'))
                return 'Tomorrow'
            else
                return 'Later'
        },
        getPickupTime(time){
            return moment(time, ["hh:mm a"]).format("HH:mm")
        },
    }
}
</script>