export default {
    namespaced: true,
    state: {
      enabled: false,
      status: false,
      removeId: null,
      programName: '',
      loyaltyData: {},
      loyaltyPage: 1
    },
    getters: {
        enabled: state => state.enabled,
        status: state => state.status,
        removeId: state => state.removeId,
        programName: state => state.programName,
        loyaltyData: state => state.loyaltyData,
        loyaltyPage: state => state.loyaltyPage
    },
    mutations: {
        SET_ENABLED(state, data){
            state.enabled  = data
        },
        SET_STATUS(state, data) {
            state.status = data
        },
        SET_REMOVE_ID(state, data){
            state.removeId  = data
        },
        SET_PROGRAM_NAME(state, data){
            state.programName = data
        },
        SET_LOYALTY_DATA(state, data){
            state.loyaltyData = data
        },
        SET_LOYALTY_PAGE(state, data){
            state.loyaltyPage = data
        }
    }
}