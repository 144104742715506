<template>
    <div class="mx-5 mb-5">
        <div :class="'footer-info-expand '+parentName" v-if="vg_frequentOrders.length>0">
            <v-list>
                <v-list-item-title class="font-weight-bold pb-2">Frequently Ordered</v-list-item-title>
                <v-divider></v-divider>
                <div :class="'mt-0 footer-info-list '+listName" v-for="(order, ind) in vg_frequentOrders" :key="ind">
                    <v-list-item @click.prevent="orderDetail(order)">
                        <v-list-item-icon class="mr-2 d-block" style="width:30px;">
                            <div class="font-weight-bold title">{{ind+1}}</div>
                            <div class="grey--text">x{{order.no_of_count}}</div>
                        </v-list-item-icon>
                        <v-list-item-content class="ma-0 pa-0">
                            <v-row>
                                <v-col cols="4">
                                    <v-img :src="order.image" min-width="30" max-width="90" max-height="80" contain />
                                </v-col>
                                <v-col cols="8"  style="align-self: center;">
                                    <div>{{order.name}}</div>
                                    <div class="font-weight-bold">${{(order.price).toFixed(2)}}</div>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                        <v-list-item-action class="ma-0 pa-0">
                            <v-btn text class="ma-0 pa-0 primary--text">REORDER</v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
            </v-list>
            <a @click.prevent="expand">
                <v-btn text class="font-weight-bold mb-2" block>
                    <span >{{parentName?'Close':'Open'}}</span>
                    <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
            </a>
        </div>
        <div v-else>
            <div class="font-weight-bold mb-2">Frequent Ordered</div>
            <v-divider></v-divider>
            <div class="mt-3 text-center">{{username}} has no past orders</div>
        </div>
    </div>
</template>
<script>
import {CartAPI} from '@/clients/cart'
import {Cart} from '@/helpers/cartHelper'
import { OrderAPI } from '../../clients/order'
import {StringHelper} from '@/helpers/stringHelper'
import {PromoHelper} from '@/helpers/PromoHelper'
import {LoyaltyHelper} from '@/helpers/LoyaltyHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
    data(){
        return{
            username: '',
            parentName: 'expand-info',
            listName: ''
        }
    },
    mounted(){
        this.username = StringHelper.getFirstNameOrPhone(this.vg_loginUser)
        
    },
    watch: {
        async vg_tableId(val, oldVal){
            if(val!=='' && !oldVal){
                this.addToCart()
            }
        },
    },
    computed: {
        ...mapGetters({
            vg_item: 'item/item',
            vg_menu: 'menu/menus',
            
            vg_cartId: 'cart/cart_id',
            
            vg_loginUser: 'login/user',
            vg_tableId: 'cart/tableId',
            vg_orderType: 'cart/orderType',
            vg_location: 'location/location',
            vg_server_cart: 'cart/serverCart',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_businessId: 'location/business_id',
            vg_frequentOrders: 'order/frequentOrders',
            
        })
    },
    methods: {
        async orderDetail(order){
            if(order.modifiers && order.modifiers.length>0){
                this.$router.push({name: 'menu-detail', params: {slug: order.slug}})
            }else{
                await this.va_setItem(order.slug)
                this.addToCart()
            }
        },
        async addToCart(){
            try{
                this.spinner(true)
                // check for cart id
                if(!this.vg_cartId && !this.vg_joinedCardId){
                    if(!await this.checkCart()){
                        this.spinner(false)
                        return
                    }
                }

                // interact with server 
                let vg_item = Cart.getDataItem(this.vg_item)
                let currentCategory = Cart.getCategoryFromItem(this.vg_menu[0].categories, this.vg_item)

                let itemParam = {
                    cart_id: this.vg_cartId?this.vg_cartId:this.vg_joinedCardId,
                    user_id: this.vg_loginUser.user.id,
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    item_sku: vg_item.item_sku,
                    name: vg_item.name,
                    slug: this.vg_item.slug,
                    category: currentCategory.name,
                    category_id: currentCategory.id,
                    item_id: this.vg_item.id,
                    item_order: this.vg_item.order,
                    item_qty: 1,
                    base_price: Number(this.vg_item.price),
                    actual_price: this.calculateItemPrice(vg_item),
                    cart_item_modifiers: Cart.clearNullQtyItem(this.vg_item).cart_item_modifiers,
                    added_by: 'user',
                    note: ''
                }
                if(this.vg_orderType=='dinein'){
                    itemParam.course = currentCategory.course
                }else if(this.vg_orderType=='delivery'){
                    itemParam.course = 0
                }else{
                    itemParam.course = 4
                }
                if(StringHelper.isLoginWithIsPhone()){
                    itemParam.user_phone = StringHelper.getMyEmailOrPhone('phone')
                }else{
                    itemParam.user_email = StringHelper.getMyEmailOrPhone('email')
                }

                // check for modifier and note in cart
                let {match, serverCartItemId, itemQty, cart_item_modifiers} = ServerCart.isItemWithMatchedModifierOnServerCardNotSend(this.vg_server_cart, itemParam, this.vg_loginUser.user.id)
                if(match){
                    itemParam.item_qty = itemParam.item_qty + itemQty
                    itemParam.cart_item_modifiers = cart_item_modifiers
                    await CartAPI.updateItem(serverCartItemId, itemParam)
                }else{
                    await CartAPI.addItem(itemParam)
                    await PromoHelper.removePromo()
                    await LoyaltyHelper.removeRedeem()
                }
                this.spinner(false)

                this.goToMenu()
            }catch(err){
                if(err.response.status===418){
                    try{
                        let {data} = await OrderAPI.lastOrder(this.vg_location.id)
                        if(data.payload && data.payload.data && data.payload.data.length>0 && this.vg_orderType!='dinein'){
                            this.spinner(false)
                            this.snackbar({open: true, text: 'There is ongoing cart', color: 'error'});
                            this.$router.push({name: 'order-status'})
                        }else{
                            this.snackbar({open: true, text: 'Current cart is already processed. Resetting order session.', color: 'error'});
                            this.vm_setOrderType(null)
                            this.vm_confirmDeliveryPickup(true)
                            this.vm_resetCart()
                            this.vm_resetCartUser()
                            this.vm_deliveryReset()
                            this.vm_resetPayment()
                            this.vm_tableId('')
                            this.spinner(false)
                            this.$router.push({name: 'menu'})
                        }
                    }catch(err){
                        this.spinner(false) 
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                        if(err.response.data.msg=='Looks like your not logged in. Please login and try again'){
                            this.reset()
                            this.$router.push({name: 'menu'})
                        }
                    }
                }else{
                    this.spinner(false)
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                }
            }
        },
        async checkCart(){
            try{
                // check for table id
                if(!this.vg_tableId && this.vg_orderType=='dinein'){
                    this.vm_identifyTableStatus(true)
                    return
                }

                // get active cart by location, table and user
                let activeCart
                if(this.vg_orderType=='dinein'){
                    let activeCartData = {
                        business_id: this.vg_businessId,
                        location_id: this.vg_location.id,
                        table_id: this.vg_tableId,
                        user_id: this.vg_loginUser.user.id,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser)
                    }
                    if(StringHelper.isLoginWithIsPhone()){
                        activeCartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        activeCartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }
                    
                    let {data} = await CartAPI.getActiveCartByTableUserLocation(activeCartData)
                    activeCart = data.payload
                }else{
                    let {data} = await CartAPI.getActivePickupOrDeliveryCartByLocation(this.vg_location.id, this.vg_loginUser.user.id)
                    activeCart = data.payload
                }

                if(activeCart && activeCart.id){
                    if(this.vg_joinedCardId){
                        this.vm_joinedCardId(activeCart.id)
                    }else{
                        this.vm_cartId(activeCart.id)
                    }
                    this.vm_resetPayerInfo()
                    return true
                }
            }catch(err){
                if(err.response.data.msg==='This table has ongoing order and you are not invited to this table yet.'){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.spinner(false)
                    this.vm_tableId('')
                    return false
                }else{
                    let cartData
                    if(this.vg_orderType=='dinein'){
                        cartData = {
                            location_id: this.vg_location.id,
                            table_id: this.vg_tableId,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                            added_by: 'user',
                            order_receive_method: this.vg_orderType,
                            business_id:this.vg_businessId
                        }
                    }else{
                        cartData = {
                            location_id: this.vg_location.id,
                            user_id: this.vg_loginUser.user.id,
                            user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                            added_by: 'user',
                            order_receive_method: this.vg_orderType,
                            business_id:this.vg_businessId
                        }
                    }
                    // add user_email or user_phone based on email or phone login
                    if(StringHelper.isLoginWithIsPhone()){
                        cartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        cartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }
                    try{
                        let {data} = await CartAPI.createCart(cartData)
                        let cart = data.payload
                        if(cart.id){
                            if(this.vg_joinedCardId){
                                this.vm_joinedCardId(cart.id)
                            }else{
                                this.vm_cartId(cart.id)
                            }
                            this.vm_resetPayerInfo()
                            return true
                        }
                        return false
                    }catch(err){
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                        this.spinner(false)
                        this.vm_tableId('')
                        return false
                    }
                }
            }
        },
        calculateItemPrice(item){
            return Cart.calculateItemPrice(item)
        },
        goToMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.$router.currentRoute.params.businessName, 
                this.$router.currentRoute.params.locationName, 
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        expand(){
            this.parentName = (this.listName)?'expand-info':''
            this.listName = (this.listName)?'':'info-list-hide'
        },
        reset(){
            this.vm_setOrderType(null)
            this.vm_resetCart()
            this.vm_resetCartUser()
            this.vm_deliveryReset()
            this.vm_resetPayment()
            this.vm_tableId('')
            this.vm_allowToJoin(false)
            this.vm_resetUser()
            this.vm_confirmDeliveryPickup(true)
        },
        ...mapMutations({
            vm_resetCart: 'cart/RESET',
            vm_cartId :'cart/SET_CART_ID',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_resetUser: 'login/RESET_USER',
            vm_resetPayment: 'payment/RESET',
            vm_resetCartUser: 'cartUser/RESET',
            vm_deliveryReset: 'delivery/RESET',
            vm_currentOrder: 'order/SET_CURRENT',
            vm_setOrderType: 'cart/SET_ORDER_TYPE',
            vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
            vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',
            vm_resetPayerInfo: 'payment/RESET_PAYER_INFO',
            vm_identifyTableStatus: 'identifyTable/SET_STATUS',
            vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
        }),
        ...mapActions({
            va_setItem: 'item/setItem'
        })
    }
}
</script>
<style scoped>
    .footer-info-expand { 
        max-height: 90px;
        overflow: hidden;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding:0 10px;
    }

    .footer-info-expand.expand-info { 
        max-height: 800px;
        transition: max-height 1s ease-in-out;
    }

    .expand-btn { 
        display: flex;
        width: 100%;
        height: 45px;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        text-transform: uppercase;
        cursor: pointer;
     }

    .info-expand-icon {
        width: 13px;
        height: 8px;
        fill: #ccc;
    }

    .info-list-hide { display: none; }

    .footer-info-list { 
        margin-top: 10px;
        text-align: left;
        font-size: 14px;
        line-height: 1.5;
    }
</style>
