<template>
    <v-container>
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>How was your <span class="primary--text">Experience?</span></v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-form ref="form" @submit.prevent="customerReview">
            <div>
                <v-card class="mx-5" elevation="0" style="margin-top: 30px;">
                    <v-card-text class="pb-0">
                        <div class="mx-auto text-center">
                            <v-img v-if="review.rating==1" style="margin-left:-40px" src="@/assets/img/1-star.png" width="120" height="55" /> 
                            <v-img v-if="review.rating==2" style="margin-left:-20px" src="@/assets/img/2-star.png" width="165" height="55" />
                            <v-img v-if="review.rating==3" style="margin-left:40px" src="@/assets/img/3-star.png" width="155" height="55" />
                            <v-img v-if="review.rating==4" style="margin-left:90px" src="@/assets/img/4-star.png" width="150" height="55" />
                            <v-img v-if="review.rating==5" style="margin-left:150px" src="@/assets/img/5-star.png" width="140" height="55" />
                            <v-rating v-model="review.rating" background-color="primary" color="yellow darken-2" large></v-rating>
                        </div>
                    </v-card-text>
                    <v-card-title class="mt-0 pt-0">
                        <div class="mx-auto text-center" style="word-break:break-word;">How Could We Improve?</div>
                    </v-card-title>
                    <v-card-text class="my-0 py-0 body-2">
                        <div class="mx-auto text-center">It will help us provide you with the best service possible in the future!</div>
                    </v-card-text>
                    <v-card-text>
                        <v-textarea v-model="review.comments" hide-details="" label="Input Comments (Optional)" outlined rows="5" row-height="15"></v-textarea>
                    </v-card-text>
                </v-card>
                <version />
            </div>
            <v-footer fixed color="white">
                <v-card class="flex" elevation="0">
                    <v-card-actions class="mt-5">
                        <v-btn type="submit" block class="default-btn primary" dark>Submit</v-btn>
                    </v-card-actions>
                    <v-card-actions>
                        <v-btn @click.prevent="back" block outlined class="primary--text">Skip</v-btn>
                    </v-card-actions>
                </v-card>
            </v-footer>
        </v-form>
    </v-container>
</template>

<script>
import {OrderAPI} from '@/clients/order'
import { StringHelper } from '@/helpers/stringHelper'
import Version from '@/components/custom-components/version'
import {mapGetters} from 'vuex'
export default {
    components: {
        Version
    },
    data(){
        return {
            option: false,
            review: {
                rating: 0,
                comments: '',
                reviewer_name: ''
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_loginUser: 'login/user',
            vg_orderId: 'payment/orderId',
            vg_location: 'location/location',
            vg_businessName: 'location/businessName',
        })
    },
    methods: {
        back(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug
            )
            this.$router.push(url)
        },
        async customerReview(){
            try{
                if(this.review.rating){
                    this.spinner(true)
                    this.review.reviewer_name = StringHelper.getUserNameOrPhone(this.vg_loginUser)
                    this.review.reviewer_id = this.vg_loginUser.user.id
                    if(this.vg_orderId){
                        await OrderAPI.review(this.vg_orderId, this.review);

                        this.$refs.form.reset()
                        this.snackbar({open: true, text: 'Review has been sent successfully', color: 'success'});
                        this.spinner(false)
                        this.back()
                    }
                }else{
                    this.snackbar({open: true, text: 'Please select rating', color: 'error'});
                }
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
            }
        }
    }
}
</script>
