<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span v-if="vg_deliveryAddress.city">Change Address</span>
                    <span v-else>Add Address</span>
                    <v-spacer />
                    <v-btn fab dark x-small  @click.prevent="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-2">

                    <v-row class="delivery-address my-3">
                        <v-col cols="10">
                            <gmap-autocomplete
                                :options="autocompleteOptions"
                                ref="googlePlace"
                                style="width:100%"
                                class="google-autocomplete"
                                large
                                placeholder="Enter Address"
                                @place_changed="setAddress"
                            ></gmap-autocomplete>
                        </v-col>
                        <v-col>
                            <a @click.prevent="getGPSLocation()"><v-icon>mdi-crosshairs-gps</v-icon></a>
                        </v-col>
                    </v-row>

                    <v-radio-group v-model="selectAddressId" style="max-height: 200px; overflow:auto;">
                        <v-radio v-for="address in savedAddress" :key='address.id' :label="address.formatted_address" :value="address.id"></v-radio>
                    </v-radio-group>

                    <v-btn v-if="vg_deliveryAddress.city" class="px-5 my-5 primary" block @click.prevent="setDelivery" :disabled="Object.keys(this.address).length==0">Change Address</v-btn>
                    <v-btn v-else class="px-5 my-5 primary" block @click.prevent="setDelivery" :disabled="Object.keys(this.address).length==0">Add Address</v-btn>
                    <v-btn class="px-5 mb-5" block text @click.prevent="setCancel">Cancel</v-btn>
                </v-card-text>
                <v-card-text>
                    <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                    <span class="float-left pr-1 primary--text">POWERED BY :</span>
                    <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {UserAPI} from '@/clients/user'
import {LocationAPI} from '@/clients/location'
import {GoogleAPI} from '@/clients/google'
import {GoogleHelper} from '@/helpers/googleHelper'
import {mapGetters, mapMutations} from 'vuex'

export default {
    data(){
        return{
            savedAddress: [],
            address: {},
            selectAddressId: null,
            autocompleteOptions: {
                componentRestrictions: {
                    country: [
                        'ca',
                    ],
                },
            },
            geo: {
                lat: '',
                lng: ''
            }
        }
    },
    watch: {
        async vg_changeDeliveryPopup(val){
            if(!val){
                this.address = {}
            }else{
                this.selectAddressId = null
                let savedAddress = await this.getAddress()
                if(savedAddress.results && savedAddress.results.length>0){
                    this.savedAddress = savedAddress.results
                }
            }
        },
        selectAddressId(val){
            if(val){
                let addressObj = this.savedAddress.find(each => each.id==val)
                this.address = addressObj
                this.geo = {lat: addressObj.latitude, lng: addressObj.longitude}
            }
        }
    },
    computed: {
        geoLocation: {
            get(){
                return this.vg_geoLocation
            },
            set(val){
                this.vm_geoLocation(val)
            }
        },
        dialog: {
            get(){
                return this.vg_changeDeliveryPopup
            },
            set(val){
                this.vm_changeDeliveryPopup(val)
            }
        },
        ...mapGetters({
            vg_deliveryAddress: 'delivery/address',
            vg_geoLocation: 'delivery/geoLocation',
            vg_location: 'location/location',
            vg_server_cart: 'cart/serverCart',
            vg_changeDeliveryPopup: 'delivery/changeDeliveryPop',
        })
    },
    methods: {
        async getAddress(){
            try{
                this.spinner(true)
                let {data} = await UserAPI.getAddress()
                this.spinner(false)
                return data
            }catch(err){
                this.spinner(false)
            }
        },
        setAddress(address){
            if(address){
                this.geo = {lat: address.geometry.location.lat(), lng: address.geometry.location.lng()}
                this.address = GoogleHelper.getAddress(address)
                this.address.label = 'default'
                this.address.address_type = 'home'
                this.address.verified = false
            }
        },
        getGPSLocation() {
            let self = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async function (position) {
                    self.geo = {lat: position.coords.latitude, lng: position.coords.longitude}
                    try{
                        self.spinner(true)
                        let result = await GoogleAPI.getAddressFromCoordinate(position.coords.latitude, position.coords.longitude)
                        if(result.data && result.data.results && result.data.results[0]){
                            self.$refs.googlePlace.$refs.input.value = result.data.results[0].formatted_address
                            self.address = GoogleHelper.getAddress(result.data.results[0])
                            self.address.label = 'default'
                            self.address.address_type = 'home'
                            self.address.verified = false
                        }
                        self.spinner(false)
                    }catch(err){
                        console.log(err)
                        self.spinner(false)
                    }
                });
            }
        },
        async isDeliveryAvailable(){
            try{
                this.spinner(true)
                let {data} = await LocationAPI.getDeliveryFee(this.vg_location.id, this.geo, this.vg_server_cart.subtotal)
                if(data.payload){
                    this.vm_deliveryFee(data.payload.delivery_fee)
                }
                this.spinner(false)
                return true
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                console.log(err)
            }
        },
        async setDelivery(){
            if(await this.isDeliveryAvailable()){
                this.vm_orderType('delivery')
                this.vm_address(this.address)
                this.vm_geoLocation(this.geo)
                this.vm_changeDeliveryPopup(false)
                if(this.selectAddressId){
                    this.vm_savePhonePop(true)
                }else{
                    this.vm_saveAddressPop(true)
                }
            }
            this.$refs.googlePlace.$refs.input.value = ''
        },
        setCancel(){
            this.$refs.googlePlace.$refs.input.value = ''
            this.vm_changeDeliveryPopup(false)
        },
        close(){
            this.$refs.googlePlace.$refs.input.value = ''
            this.vm_changeDeliveryPopup(false)
        },
        ...mapMutations({
            vm_deliveryFee: 'delivery/SET_DELIVERY_FEE',
            vm_address: 'delivery/SET_ADDRESS',
            vm_orderType: 'cart/SET_ORDER_TYPE',
            vm_changeDeliveryPopup: 'delivery/SET_CHANGE_DELIVERY_POP',
            vm_saveAddressPop: 'delivery/SET_SAVE_ADDRESS',
            vm_geoLocation: 'delivery/SET_GEO_LOCATION',
            vm_savePhonePop: 'delivery/SET_SAVE_PHONE_POP',
        })
    }
}
</script>

<style>
    .delivery-address{
        border: solid thin #ccc;
        border-radius: 5px;
    }
    .google-autocomplete:focus-visible{
        outline: none;
    }
</style>
