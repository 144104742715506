import {PaymentAPI} from '@/clients/payment'

export default {
    namespaced: true,
    state: {
      token: "",
      payments: [],
      selectedPayment: '',
      payed: false,
      cancelled: false,
      payer: '',
      orderId: '',
      orderCartId: '',
      selectedPaymentType: 'AllByOne',   // AllByOne, payForMyOrder, splitEvenly, selectItems
      disableMyItemBtn: false,
      disableWholeItemBtn: false,
      disableSplitEvenlyBtn: false,
      disablePayBtn: false,
      fiskaPopup: false,
      showFiskaAdress: false
    },
    getters: {
        token: state => state.token,
        payments: state => state.payments,
        selectedPayment: state => state.selectedPayment,
        cancelled: state => state.cancelled,
        payed: state => state.payed,
        payer: state => state.payer,
        orderId: state => state.orderId,
        orderCartId: state => state.orderCartId,
        selectedPaymentType: state => state.selectedPaymentType,
        disableMyItemBtn: state => state.disableMyItemBtn,
        disableWholeItemBtn: state => state.disableWholeItemBtn,
        disableSplitEvenlyBtn: state => state.disableSplitEvenlyBtn,
        disablePayBtn: state => state.disablePayBtn,
        fiskaPopup: state => state.fiskaPopup,
        showFiskaAdress: state => state.showFiskaAdress
    },
    mutations: {
        SET_TOKEN(state, data){
            state.token = data
        },
        SET_PAYMENTS(state, data) {
            state.payments = data
        },
        SET_SELECTED_PAYMENT(state, data){
            state.selectedPayment = data
        },
        SET_CANCELLED(state, data){
            state.cancelled = data
        },
        SET_PAYED(state, data){
            state.payed = data
        },
        SET_PAYER(state, data){
            state.payer = data
        },
        SET_ORDER_ID(state, data){
            state.orderId = data
        },
        SET_ORDER_CART_ID(state, data){
            state.orderCartId = data
        },
        SET_SELECTED_PAYMENT_TYPE(state, data){
            state.selectedPaymentType = data
        },
        SET_DISABLE_MY_ITEM_BTN(state, data){
            state.disableMyItemBtn  =data
        },
        SET_DISABLE_WHOLE_ITEM_BTN(state, data){
            state.disableWholeItemBtn = data
        },
        SET_DISABLE_SPLIT_EVENLY_BTN(state, data){
            state.disableSplitEvenlyBtn = data
        },
        SET_DISABLE_PAY_BTN(state, data){
            state.disablePayBtn = data
        },
        SET_FISKA_POPUP(state, data){
            state.fiskaPopup = data
        },
        SET_SHOW_FISKA_ADDRESS(state, data){
            state.showFiskaAdress = data
        },
        RESET(state){
            state.payments = []
            state.selectedPayment = ''
            state.token = ''
            state.selectedPaymentType = 'AllByOne'
            state.prevSelectedPaymentType = ''
            state.disableMyItemBtn = false
            state.disableWholeItemBtn = false
            state.disableSplitEvenlyBtn = false
            state.disablePayBtn = false
            state.fiskaPopup = false,
            state.showFiskaAdress = false
        },
        RESET_PAYER_INFO(state){
            state.cancelled = false
            state.payed = false
            state.payer = ''
            state.orderId = ''
            state.token = ''
        }
    },
    actions: {
        async payments({commit}, paymentData){
            try{
                const {data} = await PaymentAPI.getPayment(paymentData.businessId, paymentData.userId, paymentData.location);
                commit('SET_PAYMENTS', data.payload)
                return data.payload
            }catch(err){
                commit('SET_PAYMENTS', [])
                return []
            }
        }
    }
}
