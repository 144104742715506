export const GoogleHelper = {
    getAddress(googleObj){
        if(!googleObj || !googleObj.place_id) return


        let streetObj = googleObj.address_components.find(obj => obj.types.indexOf("street_number")>=0)
        let roadObj = googleObj.address_components.find(obj => obj.types.indexOf("route")>=0)
        let cityObj = googleObj.address_components.find(obj => obj.types.indexOf("administrative_area_level_2")>=0)
        let provinceObj = googleObj.address_components.find(obj => obj.types.indexOf("administrative_area_level_1")>=0)
        let countryObj = googleObj.address_components.find(obj => obj.types.indexOf("country")>=0)
        let postalObj =  googleObj.address_components.find(obj => obj.types.indexOf("postal_code")>=0)
        
        let addressObj = {
            address_line_1: '',
            address_line_2: '',
            city: '',
            province: '',
            country: '',
            postal_code: ''
        }
        if(streetObj && streetObj.short_name) addressObj.address_line_1 = streetObj.short_name
        if(roadObj && roadObj.short_name) addressObj.address_line_1 += ' '+roadObj.short_name
        if(cityObj && cityObj.short_name) addressObj.city = cityObj.short_name
        if(provinceObj && provinceObj.short_name) addressObj.province = provinceObj.short_name
        if(countryObj && countryObj.long_name) addressObj.country = countryObj.long_name
        if(postalObj && postalObj.short_name) addressObj.postal_code = postalObj.short_name

        return addressObj
    },

    getFormattedAddress(address){
        let formattedAddress = ''
        if(address.address_line_1){
            formattedAddress = address.address_line_1
        }
        if(address.address_line_2){
            formattedAddress += address.address_line_2
        }
        if(address.city){
            formattedAddress += (formattedAddress.length>0) ? ', '+address.city : address.city
        }
        if(address.province){
            formattedAddress += (formattedAddress.length>0) ? ' '+address.province : address.province
        }
        if(address.postal_code){
            formattedAddress += (formattedAddress.length>0) ? ' '+address.postal_code : address.postal_code
        }
        if(address.country){
            formattedAddress += (formattedAddress.length>0) ? ', '+address.country : address.country
        }

        return formattedAddress
    }
}