import store from '@/store'

export default {
  methods: {
    snackbar({ text = '', open = true, timeout = 3000, color = 'grey darken-2', x = null, y = 'top', icon = null }) {
      store.commit('Snackbar/snackbar', { text, open, timeout, color, x, y, icon })
    },
    spinner(state) {
      this.$root.spinners.main = state
    }
  }
}
