export default {
    namespaced: true,
    state: {
      status: false
    },
    getters: {
        status: state => state.status
    },
    mutations: {
        SET_STATUS(state, status) {
            state.status = status
        }
    }
}