<template>
    <div class="my-2 py-3 " style="background-color: white;">
        <v-row>
            <v-col cols="4">
                <div class="mx-5 font-weight-bold">Checkout</div>
            </v-col>
            <v-col class="text-right" cols="8">
                <a class="mx-5" @click.prevent="switchToPickup">SWITCH TO PICKUP</a>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <div class="mx-5 font-weight-bold">Delivery</div>
            </v-col>
            <v-col class="text-right" cols="8">
                <a class="mx-5" @click.prevent="changeAddress">CHANGE ADDRESS</a>
            </v-col>
        </v-row>
        <div class="mx-5">To: {{formattedAddress}}</div>
        <div class="mx-5"><v-icon>mdi-phone</v-icon> {{phone}}</div>
        <div v-if="deliveryAt==='now'" class="mx-5">
            <v-row>
                <v-col cols="8">
                    <div>On your address in about</div>
                </v-col>
                <v-col cols="4">
                    <div class="text-right font-weight-bold">20 - 30 min</div>
                </v-col>
            </v-row>
            <v-row class="pt-0 mt-0">
                <v-col>
                    <v-btn class="primary" block @click.prevent="toggleDelivery('now')" :disabled="!storeOpen">Now</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-if="vg_location.settings.delivery_orders && vg_location.settings.delivery_orders.use_timeslots" outlined block @click.prevent="toggleDelivery('future')">Later</v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else  class="mx-5">
            <v-row>
                <v-col>
                    <div>{{readableDay}}</div>
                </v-col>
                <v-col>
                    <div class="text-right">{{readableTime}}</div>
                </v-col>
            </v-row>
            <v-row  class="pt-0 mt-0">
                <v-col>
                    <v-btn outlined block @click.prevent="toggleDelivery('now')" :disabled="!storeOpen">Now</v-btn>
                </v-col>
                <v-col>
                    <v-btn class="primary" block @click.prevent="toggleDelivery('future')">Later</v-btn>
                </v-col>
            </v-row>
            <v-row  class="pt-0 mt-0">
                <v-col>
                    <v-select v-model="deliveryDate" style="border: solid thin #ccc" :items="dates" label="Today"
                        flat solo hide-details dense 
                    ></v-select>
                </v-col>
                <v-col >
                    <v-select v-model="deliveryTime" style="border: solid thin #ccc" :items="times" label="Time" no-data-text="Please select the date"
                        flat solo hide-details dense
                    ></v-select>
                </v-col>
            </v-row>
        </div>
        <div>
            <v-row>
                <v-col class="mx-5">
                    <v-text-field v-model="deliveryInstruction" outlined dense label="Delivery Instruction" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {CartAPI} from '@/clients/cart'
import {StringHelper} from '@/helpers/stringHelper'
import {GoogleHelper} from '@/helpers/googleHelper'
import {LocationAPI} from '@/clients/location'
import {mapMutations, mapGetters} from 'vuex'
import moment from 'moment'
import 'moment-timezone'
import _ from 'lodash'
export default {
    data(){
        return {
            phone: '',
            formattedAddress: '',
            locationPayload: [],
            dates: [],
            times: [],
            readableDay: '',
            readableTime: '',
            storeOpen: false
        }
    },
    async mounted(){
        this.phone = StringHelper.standardPhoneFormat(this.vg_deliveryPhone)
        this.formattedAddress = GoogleHelper.getFormattedAddress(this.vg_deliveryAddress)
        await this.getTimeSlot()
        this.storeOpen = this.isStoreOpenNow()

        if(this.storeOpen && !this.vg_deliveryAt){
            this.vm_deliveryAt('now')
        }else if(!this.storeOpen && (this.vg_deliveryAt=='now' || !this.vg_deliveryAt)){
            this.vm_deliveryAt('future')
        }
    },
    watch: {
        'vg_deliveryAddress.address_line_1'(){
            this.formattedAddress = GoogleHelper.getFormattedAddress(this.vg_deliveryAddress)
        },
        vg_deliveryDate(date){
            this.getDeliveryDate(date)
        },
        vg_deliveryTime(value){
            this.getDeliveryTime(value)
        }
    },
    computed: {
        deliveryAt: {
            get(){
                return this.vg_deliveryAt
            },
            set(val){
                this.vm_deliveryAt(val)
            }
        },
        deliveryDate: {
            get(){
                return this.vg_deliveryDate
            },
            set(val){
                this.vm_deliveryDate(val)
                this.readableDay = this.readableDate()
            }
        },
        deliveryTime: {
            get(){
                return this.vg_deliveryTime
            },
            set(val){
                this.vm_deliveryTime(val)
            }
        },
        deliveryInstruction: {
            get(){
                return this.vg_deliveryInstruction
            },
            set(val){
                this.vm_deliveryInstruction(val)
            }
        },
        ...mapGetters({
            vg_cartId: 'cart/cart_id',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_deliveryPhone: 'delivery/phone',
            vg_deliveryAddress: 'delivery/address',
            vg_location: 'location/location',
            vg_businessId: 'location/business_id',
            vg_deliveryAt: 'delivery/deliveryAt',
            vg_deliveryDate: 'delivery/deliveryDate',
            vg_deliveryTime: 'delivery/deliveryTime',
            vg_deliveryInstruction: 'delivery/note'
        })
    },
    methods: {
        async getTimeSlot(){
            try{
                let {data} = await LocationAPI.fetchDeliveryTimeSlots(this.vg_businessId, this.vg_location.slug)
                if(data.payload){
                    this.locationPayload = data.payload

                    data.payload.forEach(eachDate => {
                        let dateOnly = eachDate.split(' ').shift()
                        // load dates
                        if(this.dates.findIndex((each) => {return each.value===dateOnly})<0) 
                            this.dates.push({text: moment(dateOnly, 'YYYY-MM-DD').format('MMM. DD'), value: dateOnly})
                        
                    })
                    // load times if selected
                    if(this.vg_deliveryDate){
                        this.getDeliveryDate(this.vg_deliveryDate)
                    }

                    // select default readable time
                    this.getDeliveryTime(this.vg_deliveryTime)
                }

            }catch(err){
                console.log(err)
            }
        },
        isStoreOpenNow(){
            let timezone = this.vg_location.address.timezone_id?this.vg_location.address.timezone_id:'America/toronto'
            moment.tz.setDefault(timezone)
            let todayIndex = this.vg_location.hours_of_operation.findIndex(each => each.is_today)
            let todayStoreHours = this.vg_location.hours_of_operation[todayIndex]
            let yesterdayStoreHours = this.vg_location.hours_of_operation[todayIndex>0?todayIndex-1:6]
            let todayDate = moment().format('YYYY-MM-DD')
            let tomorrowDate = moment().add(1, 'day').format('YYYY-MM-DD')
            let openTime = null
            let closeTime = null
            let storeOpen = false

            // check with yesterday open time and today close time
            if(yesterdayStoreHours.open_time>yesterdayStoreHours.close_time){
                openTime = todayDate+' 00:00:00'
                closeTime = todayDate+' '+yesterdayStoreHours.close_time
                storeOpen = moment().isBetween(openTime, closeTime) && todayStoreHours.is_open
                if(storeOpen){
                    return true
                }
            }

            if(todayStoreHours.open_time<=todayStoreHours.close_time){
                openTime = todayDate+' '+todayStoreHours.open_time
                closeTime = todayDate+' '+todayStoreHours.close_time
            }else{
                openTime = todayDate+' '+todayStoreHours.open_time
                closeTime = tomorrowDate+' 23:59:59'
            }
            if(moment().isBetween(openTime, closeTime) && todayStoreHours.is_open){
                return true
            }else{
                return false
            }
        },
        readableDate(){
            if(moment(this.vg_deliveryDate, 'YYYY-MM-DD').format('DD')===moment().format('DD'))
                return 'Today'
            else if(moment(this.vg_deliveryDate, 'YYYY-MM-DD').format('DD')===moment().add(1, 'd').format('DD'))
                return 'Tomorrow'
            else
                return 'Later'
        },
        getDeliveryDate(date){
            this.times = []
            this.locationPayload.forEach(eachDate => {
                let dateArray = eachDate.split(' ')
                let timeOnly = dateArray[1]+' '+dateArray[2]
                if(dateArray[0]===date){
                    this.times.push({text: moment(timeOnly, 'H:mm a').format('HH:mm'), value: timeOnly})
                }
            })
            _.sortBy(this.times, ['text'])

            this.readableDay = this.readableDate()
        },
        getDeliveryTime(time){
            let timeIndex = _.findIndex(this.times, ['value', time])
            if(this.times[timeIndex])
                this.readableTime = this.times[timeIndex].text
        },
        toggleDelivery(status){
            this.vm_deliveryAt(status)
        },
        async switchToPickup(){
            try{
                let data = {
                    order_receive_method: 'pickup'
                }
                await CartAPI.switchReceiveMethod(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, data)
                this.vm_orderType('pickup')
            }catch(err){
                console.log(err)
            }
        },
        changeAddress(){
            this.vm_changeDeliveryPopup(true)
        },
        ...mapMutations({
            vm_orderType: 'cart/SET_ORDER_TYPE',
            vm_deliveryAt: 'delivery/SET_DELIVERY_AT',
            vm_deliveryDate: 'delivery/SET_DELIVERY_DATE',
            vm_deliveryTime: 'delivery/SET_DELIVERY_TIME',
            vm_deliveryInstruction: 'delivery/SET_NOTE',
            vm_changeDeliveryPopup: 'delivery/SET_CHANGE_DELIVERY_POP',
        })
    }
}
</script>