<template>
    <v-container>
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Cart</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <loading-block v-if="loading" class="pt-9" />

        <!-- Cart Processing -->
        <div v-else-if="isCartProcessing()" class="mx-2 py-5" style="border-radius: 10px; margin-bottom:100px; background-color:#fafafa;">
            <div class="mx-5 pb-3" >
                there is an order in progress, please check
            </div>
            <div class="text-center">
                <v-btn class="primary mx-5" @click.prevent="orderStatus">Order Status</v-btn>
            </div>
        </div>

        <!-- Server Cart -->
        <div v-else class="pt-3" style="margin-bottom:100px;">
            <div style="background-color:#fafafa;">
                <template v-if="vg_loginUser.user && vg_loginUser.user.id && (vg_cartId || vg_joinedCardId) && !isLocalData">
                    <!-- Server Cart Pending -->
                    <template v-if="isServerCartPending()">
                        <v-card class="mx-2 mb-2" elevation="0" color="#E5EAF0">
                            <v-card-title class="ml-5">
                                <img class="mr-2" style="vertical-align: middle" src="@/assets/img/shopping_cart_24px.png" width="24" height="24" />
                                <span style="opacity:1; color: black;">Pending Order</span>
                                <v-spacer />
                            </v-card-title>
                            <v-card-text>
                                    <!-- Start Pending Order -->
                                    <template v-for="(userItem, userindex) in sortServerCart(vg_server_cart, true)">
                                        <div :key="'pend-'+userindex" class="title text-center black--text pb-2">{{getUserNameOrPhone(userindex, vg_server_cart.cart_users)}}</div>
                                        <template v-for="(item, itemindex) in userItem">
                                            <v-list v-if="!item.sent_to_kitchen" three-line class="py-0"  color="#E5EAF0" :key="'pend-'+userindex+'item'+itemindex">
                                                <v-list-item style="min-height: 60px !important;" class="px-0 my-0">
                                                    <v-list-item-icon class="mr-2 mb-0 py-0">
                                                        <v-badge class="mt-0" :content="getCourse(item.course).abbr" :color="getCourse(item.course).color"/>
                                                    </v-list-item-icon>
                                                    <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                                        <div>{{item.item_qty}}x</div>
                                                    </v-list-item-icon>
                                                    <v-list-item-content class="my-0 py-0" style="align-self:start" :style="isMyItem(item)?'cursor:pointer':''" @click.prevent="editItem(item)">
                                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                                        <v-list-item-content class="pt-0 mt-0">
                                                            <!-- Modifier items -->
                                                            <template v-for="(item_modifier, modifierIndex) in item.cart_item_modifiers">
                                                                <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'a'+modifierIndex">{{item_modifier.name}}</div>
                                                                <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                                    <div v-if="modifier_item.qty>0" class="text--secondary" :key="'aa'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} {{(modifier_item.qty>1)?'('+modifier_item.qty+'x)':''}}</div>
                                                                </template>
                                                            </template>
                                                            <!-- Note -->
                                                            <template v-if="item.note">
                                                                <div class="text--secondary">Item note:</div>
                                                                <div class="text--secondary" style="overflow-wrap:anywhere;">{{item.note}}</div>
                                                            </template>
                                                        </v-list-item-content>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="my-0 py-0" style="align-self: flex-start;">
                                                        <v-list-item-action-text >
                                                            <span class="pr-3 body-2" style="color:black;">${{calculateServerPrice(item)}}</span>
                                                            <!-- <v-icon small>mdi-dots-vertical</v-icon> -->
                                                            <v-icon :disabled="userNotAcceptedToTable() || !isMyItem(item)" @click.prevent="removeServerItem(item)" small color="black">mdi-close</v-icon>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list>
                                        </template>
                                        <!-- Subtotal -->
                                        <v-list class="py-0" color="#E5EAF0" :key="'pen-s-'+userindex">
                                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                                <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                    <v-list-item-title class="my-0 py-0">Subtotal</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                    <v-list-item-action-text style="color:black;">
                                                        <span class="pr-5 mr-2 body-2" style="color:black;">${{userItemSubtotal(userItem)}}</span>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider class="my-3" :key="'pen-d-'+userindex" />
                                        </v-list>
                                    </template>
                                    <!-- Total -->
                                    <!-- Dinein -->
                                    <v-list class="py-0" color="#E5EAF0" v-if="vg_orderType=='dinein'">
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title">Total</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-5 mr-2 title" style="color:black;">${{calculatePendingTotal(vg_server_cart)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <!-- Pickup -->
                                    <v-list class="py-0" color="#E5EAF0" v-else>
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0">Tax</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-5 mr-2" style="color:black;">${{calculatePickupTax(vg_server_cart)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title">Total</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-5 mr-2 title" style="color:black;">${{calculatePickupTotal(vg_server_cart)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <!-- End Pending Order -->
                            </v-card-text>
                        </v-card>
                        <div  class="mx-5 mt-5 font-weight-bold"> Order Notes </div>
                        <v-textarea v-model="cartNote" class="pt-0 mx-5" rows="3" placeholder="Leave a Note for the order." counter maxlength="200" ></v-textarea>
                    </template>

                    <!-- Server Cart - Emtpy -->
                    <template v-else>
                        <v-card class="mx-2" elevation="0" color="#E5EAF0">
                            <v-card-text>
                                <template>
                                    <div class="text-center">Your order is empty</div>
                                    <v-btn class="mt-2" block color="primary" outlined @click.prevent="addItem">Add Item</v-btn>
                                </template>
                            </v-card-text>
                        </v-card>
                    </template>
                    
                    <!-- Server Cart Submited -->
                    <template v-if="isServerCartSubmitted() && vg_orderType=='dinein'">
                        <v-card class="mb-5 mx-2 saw-tooth" elevation="0">
                            <v-card-title>
                                <img class="mr-2" style="vertical-align: middle"  src="@/assets/img/IconRight.png" width="48" height="48" />
                                <span style="opacity:1; color: black;">Submitted Order</span>
                                <v-spacer />
                            </v-card-title>
                            <v-card-text>
                                <template>
                                    <!-- Start Submitting Order -->
                                    <template v-for="(userItem, userindex) in sortServerCart(vg_server_cart, false)">
                                        <div :key="'sub-'+userindex" class="title text-center black--text pb-2" v-if="vg_orderType=='dinein'">{{getUserNameOrPhone(userindex, vg_server_cart.cart_users)}}</div>
                                        <template v-for="(item, itemindex) in userItem">
                                            <v-list v-if="item.sent_to_kitchen" three-line class="py-0" :key="'sub-'+userindex+'item'+itemindex">
                                                <v-list-item style="min-height: 60px !important;" class="px-0 my-0">
                                                    <v-list-item-icon class="mr-2 mb-0 py-0">
                                                        <v-badge class="mt-0" :content="getCourse(item.course).abbr" :color="getCourse(item.course).color"/>
                                                    </v-list-item-icon>
                                                    <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                                        <div>{{item.item_qty}}x</div>
                                                    </v-list-item-icon>
                                                    <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                        <v-list-item-title>{{item.name}}</v-list-item-title>
                                                        <v-list-item-content class="pt-0 mt-0">
                                                            <!-- Modifier items -->
                                                            <template v-for="(item_modifier, modifierIndex) in item.cart_item_modifiers">
                                                                <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'sub-a'+modifierIndex">{{item_modifier.name}}</div>
                                                                <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                                    <div v-if="modifier_item.qty>0" class="text--secondary" :key="'sub-aa'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} {{(modifier_item.qty>1)?'('+modifier_item.qty+'x)':''}}</div>
                                                                </template>
                                                            </template>
                                                            <!-- Note -->
                                                            <template v-if="item.note">
                                                                <div class="text--secondary">Item note:</div>
                                                                <div class="text--secondary" style="overflow-wrap:anywhere;">{{item.note}}</div>
                                                            </template>
                                                        </v-list-item-content>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="my-0 py-0" style="align-self: flex-start;">
                                                        <v-list-item-action-text >
                                                            <span :class="(paidOrPrice(item)=='Paid')?'pr-3 body-2 green--text':'pr-3 body-2'" style="color:black;">{{paidOrPrice(item)}}</span>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </v-list>
                                        </template>
                                        <!-- Subtotal -->
                                        <v-list v-if="isMultipleUserOnTable()" class="py-0" :key="'sub'+userindex">
                                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                                <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                    <v-list-item-title class="my-0 py-0">Subtotal</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                    <v-list-item-action-text style="color:black;">
                                                        <span class="pr-3 body-2" style="color:black;">${{userItemSubtotal(userItem)}}</span>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider class="my-3" :key="'sub-d'+userindex" />
                                        </v-list>
                                    </template>
                                    <v-list class="py-0" v-if="tipsPaidAmt">
                                        <!-- Subtotal -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title">Subtotal</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 title" style="color:black;">${{parseFloat(vg_server_cart.subtotal).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- Tax -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 body-1">Tax</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 body-1" style="color:black;">${{parseFloat(vg_server_cart.tax).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- Tips -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 body-1">Paid Tips</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 body-1" style="color:black;">${{parseFloat(tipsPaidAmt).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- Paid -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title">Total Paid</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 title" style="color:black;">${{parseFloat(vg_server_cart.paid).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- Total Due if promo -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-if="promoCodes.length>0">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title" >Total Due</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text :style="(promoCodes.length>0)?'text-decoration:line-through':''">
                                                    <span class="pr-1 mr-2 title" style="color:black;">${{(parseFloat(vg_server_cart.total)-parseFloat(vg_server_cart.paid)+parseFloat(vg_server_cart.promocode)).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- if Loyalty, loyalty list -->
                                        <template v-if="vg_server_cart.loyalty_redeem_amount>0">
                                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-for="(loyalty, ind) in vg_server_cart.cart_loyalty" :key="'loyal'+ind">
                                                <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                    <v-list-item-title class="my-0 py-0 body-1">Loyalty: {{loyalty.points_to_redeem}}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                    <v-list-item-action-text style="color:black;">
                                                        <span class="pr-1 mr-2 body-1" style="color:green;">-${{parseFloat(loyalty.redeem_amount).toFixed(2)}}</span>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                        <!-- if Promo, promo list -->
                                        <template v-if="vg_server_cart.promocode>0">
                                            <template v-for="(promo, ind) in vg_server_cart.cart_promocodes">
                                                <v-list-item v-if="promo.applied_by_user=='user'" class="px-0 my-0" style="min-height: 20px !important;" :key="ind">
                                                    <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                                    <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                                    <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                        <v-list-item-title class="my-0 py-0 body-1">PROMO: {{promo.code}}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                        <v-list-item-action-text style="color:black;">
                                                            <span class="pr-1 mr-2 body-1" style="color:green;">-${{parseFloat(promo.applied_value).toFixed(2)}}</span>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </template>
                                        <!-- Total Due -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title" >Total Due</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text >
                                                    <span class="pr-1 mr-2 title" style="color:black;">${{(parseFloat(vg_server_cart.total)-parseFloat(vg_server_cart.paid)).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <v-list class="py-0" v-else>
                                        <!-- Subtotal -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title">Subtotal</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 title" style="color:black;">${{parseFloat(subtotal).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- if Loyalty, loyalty list -->
                                        <template v-if="vg_server_cart.loyalty_redeem_amount>0">
                                            <v-list-item class="px-0 my-0" style="min-height: 20px !important;" v-for="(loyalty, ind) in vg_server_cart.cart_loyalty" :key="'loyal'+ind">
                                                <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                    <v-list-item-title class="my-0 py-0 body-2">Point Used: {{loyalty.points_to_redeem}}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                    <v-list-item-action-text style="color:black;">
                                                        <span class="pr-1 mr-2 body-2" style="color:green;">-${{parseFloat(loyalty.redeem_amount).toFixed(2)}}</span>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </template>
                                        <!-- Promo -->
                                        <template v-if="vg_server_cart.promocode>0">
                                            <template v-for="(promo, ind) in vg_server_cart.cart_promocodes">
                                                <v-list-item v-if="promo.applied_by_user=='user'" class="px-0 my-0" style="min-height: 20px !important;" :key="ind">
                                                    <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                                    <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                                    <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                        <v-list-item-title class="my-0 py-0 body-2">PROMO: {{promo.code}}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                        <v-list-item-action-text style="color:black;">
                                                            <span class="pr-1 mr-2 body-2" style="color:green;">-${{parseFloat(promo.applied_value).toFixed(2)}}</span>
                                                        </v-list-item-action-text>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </template>
                                        <!-- Tax -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 body-2">Tax</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 body-2" style="color:black;">${{parseFloat(tax).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- Tips -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 body-2">Tip(s)</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 body-2" style="color:black;">${{parseFloat(tipsAmt).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <!-- Total -->
                                        <v-list-item class="px-0 my-0" style="min-height: 20px !important;">
                                            <v-list-item-icon class="mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-icon class="mt-0 mr-2 mb-0 py-0"></v-list-item-icon>
                                            <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                <v-list-item-title class="my-0 py-0 title">Total</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action class="ml-0 my-0 py-0"  style="align-self: flex-start;">
                                                <v-list-item-action-text style="color:black;">
                                                    <span class="pr-1 mr-2 title" style="color:black;">${{(parseFloat(total)).toFixed(2)}}</span>
                                                </v-list-item-action-text>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <!-- End Submitting Order -->
                                </template>
                            </v-card-text>
                        </v-card>
                    </template>
                </template>

                <!-- User not logged in - Cart -->
                <template v-else-if="isLocalData">
                    <v-card class="mx-2 mb-2" elevation="0" color="#E5EAF0">
                        <v-card-title class="ml-5">
                            <img class="mr-2" style="vertical-align: middle" src="@/assets/img/shopping_cart_24px.png" width="24" height="24" />
                            <span style="opacity:1; color: black;">Pending Order</span>
                            <v-spacer />
                        </v-card-title>
                        <v-card-text>
                                <template v-for="(category,index) in vg_cart" >
                                    <template v-for="(item, itemindex) in category.items">
                                        <v-list three-line class="py-0"  color="#E5EAF0" :key="'cat'+index+'item'+itemindex">
                                            <v-list-item style="min-height: 60px !important;" class="px-0 my-0">
                                                <v-list-item-icon class="mr-2 mb-0 py-0">
                                                    <v-badge class="mt-0" :content="getCourse(item.course).abbr" :color="getCourse(item.course).color"/>
                                                </v-list-item-icon>
                                                <v-list-item-icon class="mt-0 mr-2 mb-0 py-0">
                                                    <div>{{item.qty}}x</div>
                                                </v-list-item-icon>
                                                <v-list-item-content class="my-0 py-0" style="align-self:start">
                                                    <v-list-item-title>{{item.name}}</v-list-item-title>
                                                    <v-list-item-content class="pt-0 mt-0">
                                                        <!-- Modifier items -->
                                                        <template v-for="(item_modifier, modifierIndex) in item.cart_item_modifiers">
                                                            <div class="text--secondary" v-if="item_modifier.cart_item_modifier_items.length>0" :key="'ab'+modifierIndex">{{item_modifier.name}}</div>
                                                            <template v-for="(modifier_item, modifierItemIndex) in item_modifier.cart_item_modifier_items">
                                                                <div v-if="modifier_item.qty>0" class="text--secondary" :key="'ab'+modifierIndex+modifierItemIndex">- {{modifier_item.name}} ({{modifier_item.qty}}x)</div>
                                                            </template>
                                                        </template>
                                                        <!-- Note -->
                                                        <template v-if="item.note">
                                                            <div class="text--secondary">Item note:</div>
                                                            <div class="text--secondary">{{item.note}}</div>
                                                        </template>
                                                    </v-list-item-content>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0 py-0" style="align-self: flex-start;">
                                                    <v-list-item-action-text >
                                                        <span class="pr-3 body-2" style="color:black;">${{calculatePrice(item)}}</span>
                                                        <v-icon @click.prevent="removeItem(category, item)" small color="black">mdi-close</v-icon>
                                                    </v-list-item-action-text>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </template>
                                </template>
                        </v-card-text>
                    </v-card>
                </template>

                <!-- User Not logged in - Empty Cart -->
                <template v-else>
                    <v-card class="mx-2" elevation="0" color="#E5EAF0">
                        <v-card-text>
                            <div class="text-center">Your order is empty</div>
                            <v-btn class="mt-2" block color="primary" outlined @click.prevent="addItem">Add Item</v-btn>
                        </v-card-text>
                    </v-card>
                </template>
            </div>
            <version />
        </div>

        <v-footer class="footer" fixed color="white">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="browseMenu">Browse Menu</v-btn>
                <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || vg_orderType!=='dinein' || !!vg_callServerValue">Call Server</v-btn>

                </v-card-text>
                <v-card-actions class="mt-5">
                    <div class="footer-bar">
                        <v-btn @click.prevent="browseMenu" class="left-btn" dark>Back</v-btn>
                        <v-btn @click.prevent="moreOption" small fab class="center-btn">
                            <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                            <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                        </v-btn>
                        <v-spacer />
                        <v-btn @click.prevent="sendOrder" :disabled="(noItemInCart() || userNotAcceptedToTable())" class="right-btn" dark v-if="vg_orderType!=='dinein' && (isServerCartPending() || isLocalData)">Continue</v-btn>
                        <v-btn @click.prevent="sendOrder" :disabled="(noItemInCart() || userNotAcceptedToTable())" class="right-btn" dark v-else-if="vg_orderType=='dinein' && (isServerCartPending() || isLocalData)">Send Order</v-btn>
                        <v-btn @click.prevent="checkout" :disabled="(!isServerCartSubmitted() || vg_orderPickupDelivery || userNotAcceptedToTable())" class="right-btn" dark v-else>Checkout</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </v-container>
</template>
<script>
import {StringHelper} from '@/helpers/stringHelper'
import {CartUser} from '@/helpers/cartUserHelper'
import {PromoHelper} from '@/helpers/PromoHelper'
import {LoyaltyHelper} from '@/helpers/LoyaltyHelper'
import {Cart} from '@/helpers/cartHelper'
import {KitchenCart} from '@/helpers/kitchenCartHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {CartAPI} from '@/clients/cart'
import {CartUserAPI} from '@/clients/cart-user'
import {UserAPI} from '@/clients/user'
import {LocationAPI} from '@/clients/location'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Version from '@/components/custom-components/version'
export default {
    components: {
        Version
    },
    data(){
        return {
            loading: false,
            option: false,
            isLocalData: true,             // check if local data is available
            courses: [
                {abbr: 'N', text: "Now", color: 'orange darken-3'}, 
                {abbr: '1', text: "First Course", color: 'blue darken-3'}, 
                {abbr: '2', text: "Second Course", color: 'purple darken-3'}, 
                {abbr: '3', text: "Third Course", color: 'green darken-3'}, 
                {abbr: 'T', text: "Takeout", color: 'yellow darken-3'}
            ],

            // dom
            subtotal: 0,
            promoCodes: [],
            promoAmt: 0,
            redeemAmt: 0,
            tax: 0,
            tipsAmt: 0,
            tipsPaidAmt: 0,
            total: 0
        }
    },
    watch: {
        async vg_tableId(val, oldVal){
            if(val!=='' && !oldVal){
                await this.getServerCart()
                await this.va_cartUsers(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
                if(this.userNotAcceptedToTable()){
                    this.spinner(false)
                }else{
                    this.spinner(false)
                }
            }
        },
        async vg_loginUser(obj){
            if(obj.msg){
                // sent request for table joiner - allow_to_join won't have requested
                if(this.vg_joinedCardId){
                    await this.requestToJoinCart()
                }
                this.sendOrder()
            }
        },
        vg_cancelled(val){
            if(val){
                this.$router.push({name: 'menu'})
            }
        },
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
        vg_orderStatus(val){
            if(val==='completed'){
                this.$router.push({name: 'receipt', query: {payer: true}})
            }
        },
        vg_selectedPaymentType(val){
            if(val==='selectItems'){
                this.$router.push({name: 'select-item-to-pay'})
            }
        },
        'vg_server_cart.paid': {
            handler: async function(){
                await this.calculateSubtotal()
                await this.getPromoCode()
                await this.getLoyalty()
                this.calculateTax()
                this.calculateTips()
                this.calculateTotal()
            }
        },
        'vg_server_cart.loyalty_redeem_amount': {
            handler: async function(){
                await this.calculateSubtotal()
                await this.getPromoCode()
                await this.getLoyalty()
                this.calculateTax()
                this.calculateTips()
                this.calculateTotal()
            }
        },
        'vg_server_cart.promocode': {
            handler: async function(){
                await this.calculateSubtotal()
                await this.getPromoCode()
                await this.getLoyalty()
                this.calculateTax()
                this.calculateTips()
                this.calculateTotal()
            }
        },
        'vg_server_cart.total': {
             handler: async function(){
                await this.calculateSubtotal()
                await this.getPromoCode()
                await this.getLoyalty()
                this.calculateTax()
                this.calculateTips()
                this.calculateTotal()
            }
        },
        'vg_server_cart.cart_default_tip': {
            handler: async function(){
                this.calculateTips()
                this.calculateTotal()
            }
        },
        vg_savePhonePop(val){
            if(!val && this.vg_deliveryPhone){
                this.sendOrder(false)
            }
        }
    },
    async mounted(){
        this.loading = true
        await this.isLocalDataExists()
        if(!this.isLocalData){
            await this.getServerCart()
        }
        if(this.vg_itemInCart){
            this.vm_itemInCart(false)
            this.vm_notification(false)
        }
        
        if((this.vg_cartId || this.vg_joinedCardId)){
            await this.va_cartUsers(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
        }
        await this.calculateSubtotal()
        await this.getPromoCode()
        await this.getLoyalty()
        this.calculateTax()
        this.calculateTips()
        this.calculateTotal()
        this.loading = false
        this.spinner(false)
    },
    computed: {
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        cartNote: {
            get(){
                return this.vg_cart_note
            },
            set(val){
                this.vm_cartNote(val)
            }
        },
        ...mapGetters({
            vg_cart : 'cart/cart',
            vg_cart_note: 'cart/cartNote',
            vg_loginUser: 'login/user',
            vg_location: 'location/location',
            vg_businessName: 'location/businessName',
            vg_businessId: 'location/business_id',
            vg_tableId: 'cart/tableId',
            vg_cartId: 'cart/cart_id',
            vg_orderType: 'cart/orderType',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_server_cart: 'cart/serverCart',
            vg_kitchen_data: 'cart/kitchenData',
            vg_notification: 'cartUser/notification',
            vg_cancelled: 'payment/cancelled',
            vg_payed: 'payment/payed',
            vg_payer: 'payment/payer',
            vg_orderStatus: 'order/orderStatus',
            vg_orderPickupDelivery: 'order/orderPickupDelivery',
            vg_checkoutLocked: 'cart/lockCheckout',
            vg_selectedTips: 'cart/selectedTips',
            vg_tips: 'cart/tipsData',
            vg_selectedPaymentType: 'payment/selectedPaymentType',
            vg_itemInCart: 'cartUser/itemInCart',
            vg_cartUsers: 'cartUser/cartUsers',
            vg_callServerValue: 'menu/callServerValue',
            vg_deliveryPhone: 'delivery/phone',
            vg_deliveryAddress : 'delivery/address',
            vg_deliveryFee: 'delivery/deliveryFee',
            vg_geoLocation: 'delivery/geoLocation',
            vg_savePhonePop: 'delivery/savePhonePop',
        })
    },
    methods: {
        isCartProcessing(){
            return this.vg_orderPickupDelivery && (this.vg_server_cart.status==='pending' || this.vg_server_cart.status==='processed' || this.vg_server_cart.status==='ready') && this.vg_orderType!=='dinein'
        },
        orderStatus(){
            this.$router.push({name: 'order-status'})
        },
        trackRemove(item){
            this.$gtag.event('remove_from_cart',{
              "items": [
                {
                  "id": item.id,
                  "name": item.name,
                  "list_name": item.category+' menu',
                  "brand": "",
                  "category": item.category,
                  "variant": "",
                  "list_position": 1,
                  "quantity": item.item_qty,
                  "price": this.calculateServerPrice(item).toString()
                }
              ]
            })
        },
        menuItemSlug(item){
            return item.name.replace(/\s+/g, '-').toLowerCase()+'-'+item.item_id
        },
        editItem(item){
            if(this.isMyItem(item)){
                this.vm_editItem(item)
                if(this.vg_location.slug && this.vg_businessName){
                    let url = StringHelper.menuDetailUrlGenerator(
                        this.vg_businessName, 
                        this.vg_location.slug,
                        this.menuItemSlug(item)
                    )
                    this.$router.push(url)
                }
            }
        },
        browseMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        getCourse(course){
            if(this.courses[course]){
                return this.courses[course]
            }
        },
        async sendOrder(phonePop=true){
            if(!this.isLoggedIn()) return
            if(!this.isTableIdExists()) return
            if(!await this.isAddressAdded()) return
            if(phonePop){
                if(!this.isPhoneAdded()) return
            }

            let cartExists = true
            // don't allow to get or create cart for table joiner
            if(!this.vg_server_cart.id && !this.vg_joinedCardId){
                if(!await this.getOrCreateCart()){
                    cartExists = false
                }
            }

            if(cartExists){
                if((await this.isLocalDataExists() || ServerCart.isSomeServerCartUnsent(this.vg_server_cart))){
                    await this.addUpdateItem(this.vg_joinedCardId?this.vg_joinedCardId:this.vg_cartId)
                }else{
                    this.snackbar({open: true, text: 'No item in card to proceed', color: 'error'});
                }
            }
            this.spinner(false)
            
        },
        noItemInCart(){
            // disable only if there is no item in server cart to send to kitchen and local is empty
            // disable on true || true
            return !(ServerCart.isSomeServerCartUnsent(this.vg_server_cart) || !Cart.isCartEmpty(this.vg_cart));
        },
        userNotAcceptedToTable(){
            return !CartUser.isUserActive(this.vg_loginUser, this.vg_cartUsers)
        },
        isMyItem(item){
            if(item.user_id && this.vg_loginUser.user && this.vg_loginUser.user.id){
                return item.user_id==this.vg_loginUser.user.id
            }
            return false
            
        },
        addItem(){
            this.browseMenu()
        },
        isCartEmpty(){
            return Cart.isCartEmpty(this.vg_cart)
        },
        myTable(){
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        moreOption(){
            this.option = !this.option
        },
        removeItem(category, item){
            let cart = Cart.removeItem(category, {...item}, [...this.vg_cart])
            this.vm_cart(cart)
        },
        calculatePrice(item){
            return Cart.calculatePrice(item)
        },
        calculateItemPrice(item){
            return Cart.calculateItemPrice(item)
        },
        calculateServerPrice(item){
            return ServerCart.calculatePrice(item)
        },
        paidOrPrice(item){
            let itemPrice = this.calculateServerPrice(item)
            if(itemPrice==item.amount_paid && parseInt(itemPrice)!==0){
                return 'Paid'
            }else if(this.vg_selectedPaymentType=='splitEvenly'){
                return ServerCart.calculateUnpaidItemPrice(item)
            }else{
                return '$'+itemPrice
            }
        },
        async getPromoCode(){
            this.promoAmt = await PromoHelper.getAllPromoAmt()
        },
        async getLoyalty(){
            this.redeemAmt  = await LoyaltyHelper.getAllRedeemAmt()
        },
        calculateTax(){
            this.tax = ServerCart.calculateUnpaidTax(this.subtotal-this.promoAmt-this.redeemAmt)
        },
        async calculateSubtotal(){
            this.subtotal = ServerCart.calculateUnpaidSubtotal()
        },
        calculateTips(){
            // calculate tips from items sent to kitchen and get paid tips
            if(this.vg_server_cart.cart_default_tip && this.vg_server_cart.cart_default_tip){
                this.tipsAmt = ServerCart.calculateTips(this.subtotal-this.promoAmt-this.redeemAmt, this.vg_server_cart.cart_default_tip.value)
            }else if(this.vg_selectedTips.type==='flat'){
                this.tipsAmt = parseFloat(this.tipsAmt?this.tipsAmt:this.vg_selectedTips.amount) || 0.00
            }else{
                this.tipsAmt = ServerCart.calculateTips(this.subtotal-this.promoAmt-this.redeemAmt, this.vg_selectedTips.value)
            }
            this.tipsPaidAmt = this.vg_server_cart.tip
        },
        calculateTotal(){
            this.total = (parseFloat(this.subtotal)-parseFloat(this.promoAmt)-parseFloat(this.redeemAmt)+parseFloat(this.tax)+parseFloat(this.tipsAmt)).toFixed(2)
        },
        inviteFriend(){
            this.$router.push({name: 'invite-friend'})
        },
        account(){
            this.$router.push({name: 'customer-detail'})
        },
        checkout(){
            // show lock if locked and selected all by one
            if(this.vg_checkoutLocked && this.vg_selectedPaymentType=='AllByOne'){
                this.vm_lockCheckPop(true)
                return
            }

            if(this.isServerCartPending()){
                this.$router.push({name: 'item-cart'})
            }else{
                this.$router.push({name: 'close-bill'})
            }
        },
        async callServer(){
            try{
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        async isLocalDataExists(){
            this.isLocalData =  !Cart.isCartEmpty(this.vg_cart)
            return this.isLocalData
        },
        // show server cart empty if all item are send to kitchen
        isServerCartEmpty(){
            if(this.vg_server_cart.id){
                return ServerCart.isServerCartEmpty(this.vg_server_cart)
            }
        },
        isServerCartPending(){
            if(this.vg_server_cart.id){
                return ServerCart.isSomeServerCartUnsent(this.vg_server_cart)
            }
        },
        isServerCartSubmitted(){
            if(this.vg_server_cart.id){
                return ServerCart.isSomeServerCartSent(this.vg_server_cart)
            }
        },
        async getServerCart(){
            try{
                if(this.vg_loginUser.user && this.vg_loginUser.user.id){
                    if(!this.vg_cartId && !this.vg_joinedCardId){
                        if(!this.isTableIdExists()) return

                        if(!await this.getOrCreateCart()){
                            return
                        }
                    }
                    let {data} = await CartAPI.getCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
                    if(this.vg_orderType!=data.payload.order_receive_method){
                        this.switchService(this.vg_orderType)
                    }else{
                        this.vm_orderType(data.payload.order_receive_method)
                        this.vm_tableId(data.payload.table_id)
                        this.vm_server_cart(data.payload)
                    }
                }
            }catch(err){
                console.log(err)
            }
        },
        async switchService(orderType){
            try{
                let data = {
                order_receive_method: orderType
                }
                await CartAPI.switchReceiveMethod(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, data)
            }catch(err){
                console.log(err)
            }
        },
        async removeServerItem(item){
            try{
                this.spinner(true)
                this.trackRemove(item)
                await CartAPI.deleteItem(item.id, {user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser), user_id: this.vg_loginUser.user.id})
                if(this.vg_orderType!=='dinein'){
                    await PromoHelper.removePromo()
                    await LoyaltyHelper.removeRedeem()
                }
                await this.getServerCart()
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }

        },
        async addCartNote(){
            if(this.vg_cart_note){
                let cartData = {
                    table_id: this.vg_tableId,
                    note: this.vg_cart_note?this.vg_cart_note:''
                }
                let {data} = await CartAPI.updateCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, cartData)
                this.vm_server_cart(data.payload)
            }
        },
        // server cart data
        // isPending true for send_to_kitchen=false
        sortServerCart(serverCart, isPending){
            return ServerCart.sortCartItem(serverCart, isPending)
        },
        getUserNameOrPhone(index, users){
            let selectedUserObj = users.filter((user)=>{
                return user.user_id===index
            })
            if(selectedUserObj && selectedUserObj[0])
                return StringHelper.capitalize(selectedUserObj[0].name)
        },
        userItemSubtotal(userItems){
            if(this.vg_selectedPaymentType=='splitEvenly'){
                return ServerCart.calculateUserItemUnpaidTotal(userItems)
            }else{
                return ServerCart.calculateUserItemsTotal(userItems)
            }
        },
        calculatePendingTotal(serverCart){
            return ServerCart.calculatePendingTotal(serverCart)
        },
        calculateSubmittedTotal(serverCart){
            return ServerCart.calculateSubmittedTotal(serverCart)
        },
        calculatePickupTax(serverCart){
            if(this.vg_location.settings && this.vg_location.settings.tax_settings && this.vg_location.settings.tax_settings.tax){
                return ServerCart.calculatePickupTax(serverCart, this.vg_location.settings.tax_settings.tax)
            }else{
                return ServerCart.calculatePickupTax(serverCart, 0)
            }
        },
        calculatePickupTotal(serverCart){
            if(this.vg_location.settings && this.vg_location.settings.tax_settings && this.vg_location.settings.tax_settings.tax){
                return ServerCart.calculatePickupTotal(serverCart, this.vg_location.settings.tax_settings.tax)
            }else{
                return ServerCart.calculatePickupTotal(serverCart, 0)
            }
        },
        async requestToJoinCart(){
            try{
                let data = {
                cart_id: this.vg_joinedCardId,
                user_id: this.vg_loginUser.user.id,
                name: StringHelper.getUserNameOrPhone(this.vg_loginUser)
                }
                await CartUserAPI.inviteUserToJoinCart(data)

                // broadcast to user_requested_to_join
                this.$pnPublish({
                    channel: this.vg_joinedCardId,
                    message: {
                        event: 'user_requested_to_join'
                    }
                });
            }catch(err){
                console.log(err.response.data)
            }
        },
        isLoggedIn(){
            // check for login
            if(!this.vg_loginUser || !this.vg_loginUser.user || !this.vg_loginUser.user.id){
                this.vm_signupStatus(true)
                return false
            }
            return true
        },
        isTableIdExists(){
            // check for table id
            if(!this.vg_tableId && this.vg_orderType=='dinein'){
                this.vm_identifyTableStatus(true)
                return false
            }
            return true
        },
        async isAddressAdded(){
            if(this.vg_orderType=='delivery'){
                // check address
                if(!this.vg_deliveryAddress.city){
                    this.vm_changeDeliveryPopup(true)
                    // await this.getAddress()
                }
                // check delivery fee
                if(!await this.isDeliveryAvailable()){
                    return false
                }

                // check for lat lng
                if(!this.vg_geoLocation.lat || !this.vg_geoLocation.lng){
                    this.vm_changeDeliveryPopup(true)
                }

                // check address and delivery fee
                if(!!this.vg_deliveryAddress.city && (!!this.vg_deliveryFee || this.vg_deliveryFee===0) && !!this.vg_geoLocation.lat && !!this.vg_geoLocation.lng){
                    return true
                }else if(this.vg_orderType!=='delivery' ){
                    return true
                }else{
                    return false
                }
            }else{
                return true
            }
        },
        async getAddress(){
            try{
                this.spinner(true)
                let {data} = await UserAPI.getAddress()
                if(data.results && data.results[0]){
                    // set address
                    let addressData = {
                        address_line_1: data.results[0].address_line_1,
                        address_line_2: data.results[0].address_line_2,
                        address_type: 'home',
                        city: data.results[0].city,
                        country: data.results[0].country,
                        label: data.results[0].label,
                        postal_code: data.results[0].postal_code,
                        province: data.results[0].province,
                        verified:false
                    }
                    this.vm_address(addressData)

                    let geoData = {
                        lat: data.results[0].latitude,
                        lng: data.results[0].longitude
                    }
                    this.vm_geoLocation(geoData)
                }else{
                    this.vm_changeDeliveryPopup(true)
                }
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                this.vm_changeDeliveryPopup(true)
                
            }
        },
        async isDeliveryAvailable(){
            try{
                if(this.vg_geoLocation.lat && this.vg_geoLocation.lng){
                    this.spinner(true)
                    let {data} = await LocationAPI.getDeliveryFee(this.vg_location.id, this.vg_geoLocation, this.vg_server_cart.subtotal)
                    if(data.payload){
                        this.vm_deliveryFee(data.payload.delivery_fee)
                    }
                    this.spinner(false)
                    return true
                }
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                if(err.response.data.msg=='We do not support delivery for this location.'){
                    this.vm_changeDeliveryPopup(true)
                }
                console.log(err)
                return false
            }
        },
        isPhoneAdded(){
            if(this.vg_orderType!=='dinein' && this.vg_loginUser.user){
                if(!this.vg_deliveryPhone){
                    let phoneNumber = StringHelper.getPhone(this.vg_loginUser)
                    this.vm_deliveryPhone(phoneNumber)
                }
                this.vm_savePhonePop(true)
                return false
            }
            return true
        },
        // get active cart or create
        // return boolean - true | false (if card id exist)
        async getOrCreateCart(){
            try{
                this.spinner(true)
                // get active cart by location, table and user
                let activeCart
                if(this.vg_orderType=='dinein'){
                    let activeCartData = {
                        business_id: this.vg_businessId,
                        location_id: this.vg_location.id,
                        table_id: this.vg_tableId,
                        user_id: this.vg_loginUser.user.id,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser)

                    }
                    if(StringHelper.isLoginWithIsPhone()){
                        activeCartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        activeCartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }
                    let {data} = await CartAPI.getActiveCartByTableUserLocation(activeCartData)
                    activeCart = data.payload
                }else if(this.vg_orderType=='delivery' || this.vg_orderType=='pickup'){
                    let {data} = await CartAPI.getActivePickupOrDeliveryCartByLocation(this.vg_location.id, this.vg_loginUser.user.id)
                    activeCart = data.payload
                }
                if(activeCart && activeCart.id){
                    if(this.vg_joinedCardId){
                        this.vm_joinedCardId(activeCart.id)
                    }else{
                        this.vm_cartId(activeCart.id)
                    }
                    this.vm_server_cart(activeCart)
                    return true
                }
                this.spinner(false)
            }catch(err){
                if(err.response.data.msg==='This table has ongoing order and you are not invited to this table yet.'){
                    this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                    this.spinner(false)
                    this.vm_tableId('')
                    return false
                }else{
                    let cartData = {
                        location_id: this.vg_location.id,
                        user_id: this.vg_loginUser.user.id,
                        user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                        added_by: 'user',
                        order_receive_method: this.vg_orderType,
                        business_id:this.vg_businessId
                    }
                    if(StringHelper.isLoginWithIsPhone()){
                        cartData.user_phone = StringHelper.getMyEmailOrPhone('phone')
                    }else{
                        cartData.user_email = StringHelper.getMyEmailOrPhone('email')
                    }

                    // if dine in add table id
                    if(this.vg_orderType=='dinein'){
                        cartData.table_id = this.vg_tableId
                    }
                    // if contain note add note
                    if(this.vg_cart_note.trim()){
                        cartData.note = this.vg_cart_note
                    }

                    try{
                        let {data} = await CartAPI.createCart(cartData)
                        let cart = data.payload
                        if(cart.id){
                            if(this.vg_joinedCardId){
                                this.vm_joinedCardId(cart.id)
                            }else{
                                this.vm_cartId(cart.id)
                            }
                            this.vm_server_cart(cart)
                            return true
                        }
                        return false
                    }catch(err){
                        this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
                        this.spinner(false)
                        this.vm_tableId('')
                        return false
                    }
                }
            }
        }, 

        async getCardDetail(activeCartId){
            try {
                // get cart
                let {data} = await CartAPI.getCart(activeCartId)
                let cart = data.payload
                this.vm_server_cart(cart)
            }catch(err){
                console.log(err)
            }
        },
        
        async addUpdateItem(cartId){
            try{
                for(const ind in this.vg_cart){
                    for (const itemInd in this.vg_cart[ind].items){
                        // add item which is not send to kitchen
                        if(!KitchenCart.itemExists(this.vg_cart[ind].items[itemInd], this.vg_kitchen_data)){
                            let eachItem = this.vg_cart[ind].items[itemInd]
                            let eachCategory = this.vg_cart[ind]

                            let itemParam = {
                                cart_id: cartId,
                                user_id: this.vg_loginUser.user.id,
                                user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                                item_sku: eachItem.item_sku,
                                name: eachItem.name,
                                category: eachCategory.name,
                                category_id: eachCategory.id,
                                item_id: eachItem.id,
                                item_order: eachItem.item_order,
                                item_qty: eachItem.qty,
                                base_price: Number(eachItem.price),
                                actual_price: this.calculateItemPrice(eachItem),
                                cart_item_modifiers: eachItem.cart_item_modifiers,
                                added_by: 'user',
                                course: eachItem.course
                            }
                            if(eachItem.note.trim())
                                itemParam.note = eachItem.note
                            
                            let {data} = await CartAPI.addItem(itemParam)
                            this.vm_server_cart(data.payload)
                        }
                    }
                    // empty items from local data
                    this.vg_cart[ind].items = []
                }

                // add note and change status to completed is different
                if(this.vg_cart_note){
                    let cartData = {
                        table_id: this.vg_tableId,
                        note: this.vg_cart_note
                    }
                    let {data} = await CartAPI.updateCart(cartId, cartData)
                    this.vm_server_cart(data.payload)
                }

                this.spinner(false)
                if(this.vg_orderType=='dinein')
                    this.$router.push({name: 'order'})
                else
                    this.$router.push({name: 'close-bill'})

                //this.vm_resetCart();
            }catch(err){
                let errorMsg;
                if(err.response.data && err.response.data.msg){
                    errorMsg = err.response.data.msg
                }else{
                    errorMsg = 'Something went wrong'
                }
                this.spinner(false)
                this.snackbar({open: true, text: errorMsg, color: 'error'});
            }
        },
        isMultipleUserOnTable(){
            this.multipleUserOnTable = CartUser.isMultipleUserOnTable(this.vg_cartUsers)
        },
        ...mapMutations({
            vm_cart: 'cart/SET_CART',
            vm_cartId :'cart/SET_CART_ID',
            vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',
            vm_signupStatus: 'signup/SET_STATUS',
            vm_cartNote: 'cart/SET_CART_NOTE',
            vm_server_cart: 'cart/SET_SERVER_CART',
            vm_identifyTableStatus: 'identifyTable/SET_STATUS',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_editItem: 'cart/SET_EDIT_ITEM',
            vm_orderType: 'cart/SET_ORDER_TYPE',
            vm_tableId: 'cart/SET_TABLE_ID',
            vm_lockCheckPop: 'cart/SET_LOCK_CHECKOUT_POP',
            vm_itemInCart: 'cartUser/SET_ITEM_IN_CART',
            vm_savePhonePop: 'delivery/SET_SAVE_PHONE_POP',
            vm_address: 'delivery/SET_ADDRESS',
            vm_geoLocation: 'delivery/SET_GEO_LOCATION',
            vm_deliveryFee: 'delivery/SET_DELIVERY_FEE',
            vm_deliveryPhone: 'delivery/SET_PHONE',
            vm_changeDeliveryPopup: 'delivery/SET_CHANGE_DELIVERY_POP',
        }),
        ...mapActions({
            va_cartUsers: 'cartUser/cartUsers'
        })
    }
}
</script>