import store from '@/store'
import { LocationAPI } from '@/clients/location/index'
import { LoyaltyAPI } from '@/clients/loyalty/index'
import {AuthAPI} from '@/clients/auth'
export default {
    namespaced: true,
    state: {
        business_id: '',
        businessName: "",
        business: {},
        location: {}
    },
    getters: {
        business_id: state => state.business_id,
        businessName: state => state.businessName,
        business: state => state.business,
        location: state => state.location
    },
    mutations: {
        SET_BUSINESS_ID(state, data){
            state.business_id = data
        },
        SET_BUSINESS_NAME(state, data){
            state.businessName = data
        },
        SET_BUSINESS(state, data){
            state.business = data
        },
        SET_LOCATION(state, data) {
            state.location = data
        },
        RESET_LOCATION_BUSINESS(state){
            state.location= {}
            state.businessName = ""
            state.business_id = ""
        }
    },
    actions: {
        async setLocation( {commit}, params) {
            const {data: {payload}} = await AuthAPI.getBusinessId(params[0])
            
            // reset if location changed
            // reset cart if location changed
            if(this.state.location.location.slug!=params[1]){
                this.commit('cart/RESET')
            }
            // reset all if business change
            if(this.state.location.business_id !== payload.id){
                this.commit('cartUser/RESET')
                this.commit('payment/RESET_PAYER_INFO')
                this.commit('login/RESET_USER')
            }
            commit('SET_BUSINESS_ID', payload.id)
            commit('SET_BUSINESS', payload)
            const { data } = await LocationAPI.fetchBySlug(payload.id, params[1])
            commit('SET_LOCATION', data.payload)

            // loyalty
            try{
                const loyaltyData = await LoyaltyAPI.loyaltyService(payload.id)
                // get loyalty service - enable or disable
                if(loyaltyData && loyaltyData.data){
                    store.commit('loyalty/SET_ENABLED', loyaltyData.data.is_active)
                    store.commit('loyalty/SET_PROGRAM_NAME', loyaltyData.data.name)
                    store.commit('loyalty/SET_LOYALTY_DATA', loyaltyData.data)
                }
            }catch(err){
                store.commit('loyalty/SET_ENABLED', false)
            }
        },
        async setLocationAndBusinessOnly( {commit}, params) {
            const {data: {payload}} = await AuthAPI.getBusinessId(params[0])
            
            // reset if location changed
            // reset cart if location changed
            if(this.state.location.location.slug!=params[1]){
                this.commit('cart/RESET')
            }
            // reset all if business change
            if(this.state.location.business_id !== payload.id){
                this.commit('cartUser/RESET')
                this.commit('payment/RESET_PAYER_INFO')
                this.commit('login/RESET_USER')
            }
            commit('SET_BUSINESS_ID', payload.id)
            commit('SET_BUSINESS', payload)
            const { data } = await LocationAPI.fetchBySlug(payload.id, params[1])
            commit('SET_LOCATION', data.payload)
        },
        async setLocationOnly({commit}, {locationName='', businessId= ''}){
            const { data } = await LocationAPI.fetchBySlug(businessId, locationName)
            commit('SET_LOCATION', data.payload)
        }
    }
}