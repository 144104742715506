<template>
    <v-container class="account toolbar">
        <v-toolbar elevation="0">
            <v-spacer></v-spacer>
            <v-toolbar-title>Account</v-toolbar-title>
            <v-spacer></v-spacer>

            <template v-slot:extension>
                <v-tabs hide-slider v-model="tab">
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab v-for="item in items" :key="item">
                        {{ item }}
                    </v-tab>
                </v-tabs>
            </template>
        </v-toolbar>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <order-history />
            </v-tab-item>
            <v-tab-item>
                <payment />
            </v-tab-item>
            <v-tab-item v-if="vg_loyaltyEnabled">
                <loyalty />
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import OrderHistory from '@/views/account/order-history'
import Payment from '@/views/account/payment'
import Loyalty from '@/views/account/loyalty'
import { mapGetters, mapMutations } from 'vuex'
export default {
    components: {
        OrderHistory,
        Payment,
        Loyalty
    },
    data(){
        return {
            items: []
        }
    },
    mounted(){
        this.items = ["Order History", "Payment"]
        if(this.vg_loyaltyEnabled){
            this.items.push("Membership")
        }
    },
    watch: {
        vg_payed(val){
            if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
                this.$router.push({name: 'receipt', query: {payer: false}})
            }
        },
    },
    computed: {
        tab: {
            get(){
                return this.vg_tab;
            },
            set(value){
                this.vm_Tab(value)
            }
        },
        ...mapGetters({
            vg_tab: 'account/tab',
            vg_payed: 'payment/payed',
            vg_payer: 'payment/payer',
            vg_loginUser: 'login/user',
            vg_loyaltyEnabled: 'loyalty/enabled'
        })
    },
    methods: {
        ...mapMutations({
            vm_Tab: 'account/SET_TAB'
        })
    }
}
</script>

<style>
.account .v-tabs-bar__content{
    justify-content: center;
}
</style>
