<template>
    <div>
        <loading-block v-if="loading" class="pt-9" />
        <div v-else>
            <template v-if="payments.length>0">
                <div class="mt-5" style="margin-bottom:100px; overflow:auto;">
                    <div class="title text-center">Payment methods</div>
                    <v-row class="payment mx-5" v-for="(payment, ind) in payments" :key="ind">
                        <v-col cols="8">
                            <v-img :src="getCardType(payment)" width="30" height="22" style="display: inline-block; float:left;" />
                            <span class="pl-1" style="display: inline-block; float:left;">&bull;&bull;&bull;&bull;&nbsp;{{payment.last_four}}</span>
                        </v-col>
                        <v-col  cols="4">
                            <div style="float:right;">
                                <v-icon color="yellow darken-2" v-if="payment.is_default">mdi-star</v-icon>
                                <v-icon style="cursor:pointer;" @click.prevent="removePayment(payment.id)">mdi-trash-can-outline</v-icon>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-else>
                <div class="mt-5 title text-center">Add a payment method.</div>
                <v-row class="text-center mx-5 mt-5">
                    <v-col>Save your card to pay with one tap!</v-col>
                </v-row>
            </template>
        </div>

        <v-footer class="footer" fixed color="white" v-if="!loading">
            <v-card class="flex" elevation="0">
                <v-card-text class="other-options" v-if="option">
                    <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="!vg_tableId || !vg_loginUser.user || (vg_orderType!=='dinein') || !!vg_callServerValue">Call Server</v-btn>
                </v-card-text>
            
                <v-card-actions class="mt-5">
                <div class="footer-bar">
                    <v-btn @click.prevent="back" class="left-btn" dark>Back</v-btn>
                    <v-btn @click.prevent="moreOption" small fab class="center-btn">
                    <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                    <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn @click.prevent="addPayment" class="right-btn" dark>Add Payment</v-btn>
                </div>
                </v-card-actions>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
import {StringHelper} from '@/helpers/stringHelper'
import {CartAPI} from '@/clients/cart'
import {PaymentAPI} from '@/clients/payment'
import {mapGetters, mapMutations} from 'vuex'
export default {
    data(){
        return {
            loading: false,
            option: false,
            // payments: []
        }
    },
    async mounted(){
        this.loading = true
        await this.getPaymentToken()
        await this.getPayments()
        this.loading = false
    },
    watch:{
        async vg_fiskaPopup(val){
            if(!val){
                await this.getPayments()
            }
        },
        async vg_tab(val){
            if(val===1){
                this.loading = true
                await this.getPaymentToken()
                await this.getPayments()
                this.loading = false
            }
        }
    },
    computed: {
        payments:{
            get(){
                return this.vg_payments
            },
            set(val){
                this.vm_payments(val)
            }
        },
        notify: {
            get(){
                return this.vg_notification;
            },
            set(value){
                this.vm_notification(value)
            }
        },
        ...mapGetters({
            vg_payments: 'payment/payments',
            vg_orderType: 'cart/orderType',
            vg_tab: 'account/tab',
            vg_tableId: 'cart/tableId',
            vg_loginUser: 'login/user',
            vg_location: 'location/location',
            vg_joinedCardId: 'cart/joinedCardId',
            vg_businessId: 'location/business_id',
            vg_businessName: 'location/businessName',
            vg_notification: 'cartUser/notification',
            vg_callServerValue: 'menu/callServerValue',
            vg_fiskaPopup: 'payment/fiskaPopup',
            vg_itemInCart: 'cartUser/itemInCart'
        })
    },
    methods: {
        back(){
            this.navigateToMenu()
        },
        navigateToMenu(){
            let url = StringHelper.menuUrlGenerator(
                this.vg_businessName, 
                this.vg_location.slug,
                this.vg_tableId,
                this.vg_joinedCardId
            )
            this.$router.push(url)
        },
        moreOption(){
            this.option = !this.option
        },
        async callServer(){
            try{ 
                this.spinner(true)
                let data = {
                    user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
                    location_id: this.vg_location.id,
                    business_id: this.vg_businessId
                }
                let response = await CartAPI.callServer(this.vg_tableId, data)
                this.snackbar({open: true, text: response.data.msg, color: 'success'});
                this.option = false
                this.spinner(false)
            }catch(err){
                this.spinner(false)
                console.log(err)
            }
        },
        myTable(){
            if(this.vg_itemInCart){
                this.$router.push({name: 'cart'})
            }else{
                this.$router.push({name: 'manage-table'})
            }
        },
        addPayment(){
            if(this.vg_location.settings && this.vg_location.settings.payment_processor_details && this.vg_location.settings.payment_processor_details.provider_name==='fiska_payment'){
                this.vm_fiskaPopup(true)
            }else{
                this.$router.push({name: 'add-payment'})
            }
        },
        getCardType(payment){
            if(payment.card_brand=='AMEX'){
                return require('@/assets/img/amex.png');
            }else if(payment.card_brand=='MC'){
                return require('@/assets/img/mastercard.png');
            }else if(payment.card_brand=='DISC'){
                return require('@/assets/img/discover.png');
            }else{
                return require('@/assets/img/visa.png');
            }
        },
        async getPaymentToken(){
            // get token - todo (remove this - not used anymore)
            try{
                await PaymentAPI.getApiToken(this.vg_businessId)
                this.vm_paymentToken(true)
            }catch(err){
                // disable card on token error
                this.vm_paymentToken('')
            }
        },
        async getPayments(){
            try{
                let {data} = await PaymentAPI.getPayment(this.vg_businessId, this.vg_loginUser.user.id, this.vg_location)
                this.payments = data.payload
            }catch(err){
                this.payments = []
            }
        },
        async removePayment(paymentId){
            try{
                if(confirm("Are you sure you want to delete this card?")){
                    this.spinner(true)
                    let deleteData = {
                        business_uuid: this.vg_businessId,
                        location_id: this.vg_location.id
                    }
                    await PaymentAPI.deletePayment(paymentId, deleteData)
                    await this.getPayments()
                    this.snackbar({open: true, text: "Credit card removed successfully", color: 'success'});
                    this.spinner(false)
                }
            }catch(err){
                this.spinner(false)
                this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
            }
        },
        ...mapMutations({
            vm_paymentToken: 'payment/SET_TOKEN',
            vm_notification: 'cartUser/SET_NOTIFICATION',
            vm_fiskaPopup: 'payment/SET_FISKA_POPUP',
            vm_payments: 'payment/SET_PAYMENTS'
        })
    }
}
</script>

<style>
.payment{
    border: solid thin #005;
    border-radius: 5px;
    margin: 10px;
}
</style>