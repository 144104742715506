<template>
  <div class="toolbar">
    <confirm-delivery-takeout v-if="confirmDeliveryPickup" @changeCart="switchServiceFromPopup"></confirm-delivery-takeout>
    <div v-else>
      <v-app-bar fixed elevation="1">
        <v-toolbar-title>{{location.display_name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="pr-3">
          <sign-in-sign-out />
        </div>
        <v-btn small icon v-if="!search" @click.prevent="showSearch">
          <v-img class="pr-5 mr-5" contain src="@/assets/img/search_24px.png" width="24" height="24" />
        </v-btn>

        <template v-slot:extension>
          <loading-block v-if="loading" class="pt-9" />
          <v-tabs v-show="!search" v-else hide-slider v-model="currentTab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="(tab, tabIndex) in tabs"  :key="tabIndex" @click.prevent="scrollTo(tabIndex)">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <div v-show="search" class="search" style="width:100%">
            <v-spacer/>
            <div style="margin:auto; min-width:180px; max-width:520px;">
              <v-row>
                <v-col cols="8" class="pa-0">
                  <v-text-field class="ml-5 pa-0" style="background: white;" outlined hide-details="" v-model="searchWord" />
                </v-col>
                <v-col cols="4" class="pa-0">
                  <v-btn @click.prevent="cancelSearch" text>Cancel</v-btn>
                </v-col>
              </v-row>
            </div>
            <v-spacer/>
          </div>
        </template>
      </v-app-bar>

      <loading-block v-if="loading" style="margin-top: 150px;" />
      <template v-else>
        <v-list id="list-content" v-if="allMenu && allMenu.length > 0" class="menu-list mb-5 pb-5" two-line style="position: absolute; overflow: scroll; top: 110px; bottom:85px; left:0; right:0;">
          <v-container>
            <template v-for="(category, categoryIndex) in allMenu">
              <div :id="'tab-'+categoryIndex" :key="'a'+categoryIndex"  v-intersect="handleIntersect" :class="(categoryIndex==0)?'py-1':'py-1 my-4'"></div>
              <div class="title text-center" :key="'c'+categoryIndex" >{{category.name}}</div>
              <div :id="'tab-content-'+categoryIndex" :key="'tc'+categoryIndex">
                <template v-for="(item, menuIndex) in category.items">
                  <div v-if="item.status" :key="categoryIndex+'m'+menuIndex" >
                    <item :itemId="menuIndex===0?categoryIndex:''" :item="item"/>
                    <v-divider></v-divider>
                  </div>
                </template>
              </div>
            </template>
            <version />
          </v-container>
        </v-list>
        <div v-else-if="search" class="ml-5 mt-5" style="position: absolute !important; overflow: auto !important; top: 100px !important; bottom:85px !important;">No items related to "{{searchWord}}" were found.</div>
        <div v-else class="ml-5 mt-5" style="position: absolute !important; overflow: auto !important; top: 100px !important; bottom:85px !important;">No menu found.</div>
      </template>

      <v-footer class="footer" fixed color="white">
        <v-card class="flex" elevation="0">
          <v-card-text class="other-options" v-if="option">
            <v-btn class="mb-2" block color="primary" outlined @click.prevent="inviteFriend" :disabled="(loading || !vg_loginUser.user || isSplitEvenlyPaid() || userRequestedToJoinTable() || vg_orderType!=='dinein')">Invite A Friend</v-btn>
            <v-btn class="mb-2" block color="primary" outlined @click.prevent="myTable" v-if="vg_loginUser.user && !!vg_tableId" :disabled="loading">My Table #{{vg_tableId}}</v-btn>
            <v-btn class="mb-2" block color="primary" outlined @click.prevent="account" :disabled="loading || !vg_loginUser.user">Account</v-btn>
            <v-btn class="mb-2" block color="primary" outlined @click.prevent="callServer" :disabled="(loading || !vg_tableId || !vg_loginUser.user || vg_orderType!=='dinein' || !!vg_callServerValue || userRequestedToJoinTable())">Call Server</v-btn>
            <v-btn class="mb-2" v-if="vg_orderType!='dinein'" block color="primary" outlined @click.prevent="switchService('dinein')" :disabled="loading || isServerCartSubmitted() || vg_orderPickupDelivery || multipleUserOnTable || (!vg_userJoined && !!vg_joinedCardId)">{{'Switch To Dine in'}}</v-btn>
            <v-btn class="mb-2" v-if="vg_orderType!='pickup'" block color="primary" outlined @click.prevent="switchService('pickup')" :disabled="loading || isServerCartSubmitted() || vg_orderPickupDelivery || multipleUserOnTable || (!vg_userJoined && !!vg_joinedCardId)">{{'Switch To Takeout'}}</v-btn>
            <v-btn class="mb-0" v-if="vg_orderType!='delivery'" block color="primary" outlined @click.prevent="switchService('delivery')" :disabled="loading || isServerCartSubmitted() || vg_orderPickupDelivery || multipleUserOnTable || (!vg_userJoined && !!vg_joinedCardId)">{{'Switch To Delivery'}}</v-btn>
          </v-card-text>
        
          <v-card-actions class="mt-5">
            <div class="footer-bar">
              <v-btn @click.prevent="checkout" class="left-btn" :disabled="(!isAllowedForCheckout() || vg_orderPickupDelivery || userRequestedToJoinTable())" dark>Checkout</v-btn>
              <v-btn @click.prevent="moreOption" small fab class="center-btn">
                <v-img v-if="notify" src="@/assets/img/notifications_active.png" width="20" height="20" contain @click.prevent="myTable"/>
                <v-icon v-else v-text="option?'mdi-chevron-down':'mdi-chevron-up'"></v-icon>
              </v-btn>
              <v-spacer />
              <v-btn @click.prevent="cart" class="right-btn" dark :disabled="(vg_loginUser.user && userRequestedToJoinTable())">Cart {{countItem()>0?('('+countItem()+')'):''}}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-footer>
    </div>
  </div>
</template>
<script>
import {Cart} from '@/helpers/cartHelper'
import {CartUser} from '@/helpers/cartUserHelper'
import {StringHelper} from '@/helpers/stringHelper'
import {ServerCart} from '@/helpers/serverCartHelper'
import {CartUserAPI} from '@/clients/cart-user'
import {UserAPI} from '@/clients/user'
import {CartAPI} from '@/clients/cart'
import {LocationAPI} from '@/clients/location'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
// import moment from 'moment'
import Item from './item'
import ConfirmDeliveryTakeout from './confirm-delivery-takeout'
import SignInSignOut from './signin-signout'
import Version from '@/components/custom-components/version'
export default {
  components: {
    Item,
    ConfirmDeliveryTakeout,
    SignInSignOut,
    Version
  },
  props: ['businessName', 'locationName'],
  data () {
    return {
      enableScroll: false,
      previousY: 0,
      scrollStatus: null,
      loading: false,
      tabs: [],
      menu: [],
      option: false,
      allMenu: [],
      search: false,
      searchWord: '',
      switchTo: '',
      prevOrderType: '',
      multipleUserOnTable: false
    }
  },
  async mounted () {
    this.loading = true
    this.vm_signupStatus(false)
    this.vm_confirmDeliveryPickup(false)
    // add table id
    if(this.$router.currentRoute.query && this.$router.currentRoute.query.table_id){
      // if table id and store table id is different
      if(!!this.vg_tableId && this.$router.currentRoute.query.table_id!=this.vg_tableId){
        this.resetCart()
      }
      this.vm_setOrderType('dinein')
      this.vm_tableId(this.$router.currentRoute.query.table_id)
      this.vm_confirmDeliveryPickup(false)
    }else{
      this.vm_tableId(null)
      this.vm_callServerTableId(null)
    }

    if(this.$router.currentRoute.query.allow_to_join){
      this.vm_allowToJoin(true)
    }else if(this.$router.currentRoute.query.allow_to_join){
      this.vm_allowToJoin(false)
    }

    if(this.$router.currentRoute.query.receive_method && !this.$router.currentRoute.query.table_id){
      this.vm_setOrderType(this.$router.currentRoute.query.receive_method==='dinein'?'dinein':'pickup')
    }else if(this.$router.currentRoute.query.join_cart && !this.$router.currentRoute.query.table_id){
      // have join id but not table id in url
      this.vm_setOrderType('pickup')
    }

    // get location
    await this.getLocation()
    await this.isTableOwnerOrTableJoiner()
    if(this.vg_loginUser.user && (this.vg_cartId || this.vg_joinedCardId)){
      await this.va_cartUsers(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
      this.isMultipleUserOnTable()
    }else if(!this.vg_loginUser.user && !!this.$route.query.table_id){
      this.vm_loginStatus(true)
    }

    await Promise.all([this.initalizeCart(), this.switchDineInOrTakeoutIfNeeded()])

    this.loading = false
    if(!this.vg_confirmDeliveryPickup){
      this.initScroll()
    }

    if(!this.vg_orderType){
      this.vm_confirmDeliveryPickup(true)
    }

  },
  watch:{
    vg_confirmDeliveryPickup(val){
      if(!val){
        this.initScroll()
      }
    },

    vg_orderType(val){
      if(!val){
        this.vm_confirmDeliveryPickup(true)
      }
    },

    searchWord(val){
      this.allMenu = StringHelper.search(this.menus[0].categories, val)
    },
    async vg_loginUser(loginUser){
      if(loginUser.user && loginUser.user.id){
        this.loading = true
        await this.getLocation()
        await this.initalizeCart()
        // check for join card id - login with joining cart id
        await this.isTableOwnerOrTableJoiner()
        if(this.vg_joinedCardId){
          // subscribe to only pusblish event for new_user_request
          this.$pnSubscribe({
            channels: [this.vg_joinedCardId]
          })

          // subscribe to user id for check cart after 40 min
          console.log(`pubnub start on id ${this.vg_loginUser.user.id}`)
          this.$pnSubscribe({
            channels: [this.vg_loginUser.user.id]
          })

          // check for cart
          if(!this.vg_server_cart || !this.vg_server_cart.id){
            this.getCartForJoiningUser()
          }
        }
        this.loading = false
      }
    },
    currentTab(index){
      this.vm_currentCategory(this.menus[0].categories[index])
    },
    vg_payed(val){
        if(val && this.vg_payer && this.vg_payer!==StringHelper.getUserNameOrPhone(this.vg_loginUser)){
          this.$router.push({name: 'receipt', query: {payer: false}})
        }
    },
    vg_itemInCart(val){
      if(val){
        this.$router.push({name: 'cart'})
      }
    },
    vg_orderStatus(val){
      if(val==='completed'){
          this.$router.push({name: 'receipt', query: {payer: true}})
      }
    },
    async vg_tableId(val, oldVal){
      if(val!==''){
        if(!oldVal){
          await this.switchService(this.switchTo)
        }else if(oldVal && val!=oldVal){
          // reload on table transfer
          // this.goToMenu()
        }
      }else if(oldVal!='' && this.vg_loginUser.user){
        // close bill from server without sending item to kitchen - user join incognito & close cart from server
        if(this.$router.currentRoute.query.table_id && this.$router.currentRoute.query.table_id!=''){
          // this.goToMenu()
        }
      }
    },
    // vg_joinedCardId(val){
    //   if(!val){
    //     this.$router.push({name: 'menu'})
    //   }
    // },
    vg_selectedPaymentType(val){
      if(val==='selectItems'){
          this.$router.push({name: 'select-item-to-pay'})
      }
    }
  },
  computed: {
    currentTab: {
      get(){
        return this.vg_currentTab  
      },
      set(val){
        this.vm_currentTab(val)
      }
    },
    notify: {
        get(){
            return this.vg_notification;
        },
        set(value){
            this.vm_notification(value)
        }
    },
    confirmDeliveryPickup: {
      get(){
        return this.vg_confirmDeliveryPickup
      },
      set(value){
        this.vm_confirmDeliveryPickup(value)
      }
    },
    ...mapState('menu', ['menus']),
    ...mapState('location', ['location']),
    ...mapGetters({
          vg_cart : 'cart/cart',
          vg_cartId: 'cart/cart_id',
          vg_cartUsers: 'cartUser/cartUsers',
          vg_location: 'location/location',
          vg_businessId: 'location/business_id',
          vg_tableId: 'cart/tableId',
          vg_loginUser: 'login/user',
          vg_joinedCardId: 'cart/joinedCardId',
          vg_server_cart: 'cart/serverCart',
          vg_kitchen_cart: 'cart/kitchenData',
          vg_notification: 'cartUser/notification',
          vg_userJoined: 'cart/userJoined',
          vg_currentTab: 'menu/currentTab',
          vg_payed: 'payment/payed',
          vg_payer: 'payment/payer',
          vg_orderType: 'cart/orderType',
          vg_confirmDeliveryPickup: 'cart/confirm_delivery_pickup',
          vg_orderStatus: 'order/orderStatus',
          vg_orderPickupDelivery: 'order/orderPickupDelivery',
          vg_checkoutLocked: 'cart/lockCheckout',
          vg_businessName: 'location/businessName',
          vg_itemInCart: 'cartUser/itemInCart',
          vg_selectedPaymentType: 'payment/selectedPaymentType',
          vg_callServerValue: 'menu/callServerValue',
          vg_deliveryFee: 'delivery/deliveryFee',
          vg_deliveryPhone: 'delivery/phone',
          vg_deliveryAddress : 'delivery/address',
          vg_geoLocation: 'delivery/geoLocation',
      })
  },
  methods: {
    goToMenu(){
    // reload when table transfer
    let url = StringHelper.menuUrlGenerator(
        this.$router.currentRoute.params.businessName, 
        this.$router.currentRoute.params.locationName, 
        this.vg_tableId,
        this.vg_joinedCardId
    )
    this.$router.push(url)
    },
    countItem(){
      if(!this.vg_orderPickupDelivery){
        if(this.vg_loginUser && this.vg_loginUser.user && this.vg_loginUser.user.id){
          return ServerCart.countUnsentCartItemQty(this.vg_server_cart)
        }else{
          return Cart.countCartItemQty(this.vg_cart)
        }
      }
    },
    showSearch(){
      this.search = true
    },
    cancelSearch(){
      this.searchWord = ''
      this.search = false
    },
    getUsernameOrPhone() {
      return StringHelper.getUserNameOrPhone(this.vg_loginUser)
    },
    async initalizeCart(){
      // @Cart - add categories in cart (initialize local cart)
      if(this.menus[0] && this.menus[0].categories){
        this.menus[0].categories.forEach((eachCategory) => {
          // this.vm_currentCategory(eachCategory)
          let cart = Cart.addCategory(eachCategory, [...this.vg_cart])
          if(cart){
            this.vm_cart(cart)
          }
        })
        
        // load menu
        this.allMenu = this.menus[0].categories
      }else{
        this.allMenu = []
      }
    },
    async getLocation(){
      try{
        this.vm_setBusinessName(this.businessName)
        await this.va_setLocation([this.businessName, this.locationName])

        // will check token if logged in
        if(await this.validateToken()){
          // get menus
          await this.va_setMenu(this.location)
          this.getMenu()
        }else{
          this.logOut()
        }
      }catch(err){
        if(err.response && err.response.data && err.response.data.msg){
          this.vm_resetLocationBusiness()
          this.$router.push({name: 'not-found'})
        }
      }
    },
    async getMenu(){
      // show only enabled category
      if(this.menus && this.menus[0] && this.menus[0].categories){
        this.tabs = this.menus[0].categories
        if (this.menus[0].categories[0] && this.menus[0].categories[0].items) {
          this.menu = this.menus[0].categories[0].items
        }
      }
    },
    async validateToken(){
      if(this.vg_loginUser.user){
        try{
          await UserAPI.checkAuth()
          return true
        }catch(err){
          this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
          return false
        }
      }else{
        return true
      }
    },
    async isTableOwnerOrTableJoiner(){
      // login and join card if contain query string joining_cart
      // get joining cart from table_id and allow_to_join
      // send request if userId is not null

      if(this.$router.currentRoute.query.join_cart){
        // get from url
        this.vm_joinedCardId(this.$router.currentRoute.query.join_cart)
        this.joinCart(this.$router.currentRoute.query.join_cart)
      } else{
        // get from api
        if(this.$router.currentRoute.query.table_id){
          let JoiningCartIdAndTAbleOwnerUserId = await this.getJoiningCartIdAndTableOwnerUserIdFromGetActiveCartByTableUserLocationApi()
          let cartId = (JoiningCartIdAndTAbleOwnerUserId && JoiningCartIdAndTAbleOwnerUserId.joiningCartId)?JoiningCartIdAndTAbleOwnerUserId.joiningCartId:null
          let tableOwnerUserId = (JoiningCartIdAndTAbleOwnerUserId && JoiningCartIdAndTAbleOwnerUserId.userId)?JoiningCartIdAndTAbleOwnerUserId.userId:null
          if(tableOwnerUserId==null || (this.vg_loginUser.user && this.vg_loginUser.user.id==tableOwnerUserId)){
            this.vm_cartId(cartId)
            this.vm_tableId(this.$router.currentRoute.query.table_id)
          }else{
            this.joinCart(cartId)
          }
        }
      }
    },
    isMultipleUserOnTable(){
      this.multipleUserOnTable = CartUser.isMultipleUserOnTable(this.vg_cartUsers)
    },
    async getJoiningCartIdAndTableOwnerUserIdFromGetActiveCartByTableUserLocationApi(){
      // get joining cart id if query string contain allow_to_join
      if(this.$router.currentRoute.query.table_id){
        try{
          // get data cart id and user id from get active cart by table user location
          let tableData = {
            business_id: this.vg_businessId,
            location_id: this.vg_location.id,
            table_id: this.$router.currentRoute.query.table_id,
          }
          if(this.vg_loginUser.user){
            tableData.user_id = this.vg_loginUser.user.id
            tableData.user_name = StringHelper.getUserNameOrPhone(this.vg_loginUser)
            if(StringHelper.isLoginWithIsPhone()){
                tableData.user_phone = StringHelper.getMyEmailOrPhone('phone')
            }else{
                tableData.user_email = StringHelper.getMyEmailOrPhone('email')
            }
          }
          let {data} = await CartAPI.getActiveCartByTableUserLocation(tableData)

          if(data.payload && data.payload.id){
            return {joiningCartId: data.payload.id, userId: data.payload.user_id}
          }else{
            return {joiningCartId: null, userId: null}
          }
        }catch(err){
          this.snackbar({open: true, text: err.response.data.msg, color: 'error'})
        }
      }
    },
    async allowToJoin(){
      if(this.$router.currentRoute.query.table_id){
        this.vm_tableId(this.$router.currentRoute.query.table_id)
        this.vm_setOrderType('dinein')
      }else{
        this.vm_setOrderType('pickup')
      }
      this.vm_allowToJoin(true)
      await this.initalizeCart()
      this.vm_confirmDeliveryPickup(false)
    },
    async joinCart(joiningCartId){
      if(joiningCartId){
        // popup login
        if(!this.vg_loginUser || !this.vg_loginUser.user || !this.vg_loginUser.user.id){
            // initializeCart if query string is allow_to_join
            if(this.$router.currentRoute.query.allow_to_join){
              // remove login popup
              await this.allowToJoin()
              this.loading = false
              return
            }else{
              this.vm_allowToJoin(false)
              this.vm_signupStatus(true)
              return
            }
        }else{
          this.vm_joinedCardId(joiningCartId)
          await this.sendInvitation()

          // subscribe to only pusblish event for new_user_request
          this.$pnSubscribe({
            channels: [this.vg_joinedCardId]
          })
        }
      }else{
        // sometime get route of other page on fast changing page
        if(this.$router.currentRoute.name==='menu'){
          this.vm_joinedCardId(null)
        }
      }
    },
    async sendInvitation(){
      try{
        // if user is hasnot joined the cart
        if(!CartUser.isUserActive(this.vg_loginUser, this.vg_cartUsers)){
          // empty cart id and will have joining cart id
          this.vm_cartId(null)
          this.vm_resetPayment()
          
          let data = {
            cart_id: this.vg_joinedCardId,
            user_id: this.vg_loginUser.user.id,
            name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
          }
          if(StringHelper.isLoginWithIsPhone()){
              data.phone = StringHelper.getMyEmailOrPhone('phone')
          }else{
              data.email = StringHelper.getMyEmailOrPhone('email')
          }

          await CartUserAPI.inviteUserToJoinCart(data)

          // broadcast to user_requested_to_join
          this.$pnPublish({
            channel: this.vg_joinedCardId,
            message: {
              event: 'user_requested_to_join'
            }
          });
        }
      }catch(err){
        console.log(err.response.data)
      }
    },
    cart(){
      this.$router.push({name: 'cart'})
    },
    async checkout(){
      // show lock if locked and selected all by one
      if(this.vg_checkoutLocked && this.vg_selectedPaymentType=='AllByOne'){
        this.vm_lockCheckPop(true)
        return
      }

      // check for delivery Fee
      if(this.vg_orderType=='delivery' && (!this.vg_deliveryAddress.city || this.vg_deliveryFee==null)){
        this.vm_changeDeliveryPopup(true)
        return
      }else if(this.vg_orderType=='delivery' && !await this.isDeliveryAvailable()){
        return
      }

      // check for phone number
      if((this.vg_orderType=='delivery' || this.vg_orderType=='pickup') && !this.vg_deliveryPhone){
        let phoneNumber = StringHelper.getPhone(this.vg_loginUser)
        this.vm_deliveryPhone(phoneNumber)
        if(!phoneNumber){
            this.vm_savePhonePop(true)
            return 
        }
      }

      if(this.vg_orderType=='dinein' && this.isServerCartPending()){
          this.$router.push({name: 'item-cart'})
      }else{
        this.$router.push({name: 'close-bill'})
      }
    },
    isServerCartPending(){
        if(this.vg_server_cart.id){
            return ServerCart.isSomeServerCartUnsent(this.vg_server_cart)
        }
    },
    inviteFriend(){
      this.$router.push({name: 'invite-friend'})
    },
    account(){
      this.$router.push({name: 'customer-detail'})
    },
    isAllowedForCheckout(){
      if(this.vg_server_cart.id){
        if(this.vg_orderType=='dinein'){
          return ServerCart.isSomeServerCartSent(this.vg_server_cart)
        }else{
          return !ServerCart.isServerCartEmpty(this.vg_server_cart)
        }
      }else{
        return false
      }
    },
    isServerCartSubmitted(){
        if(this.vg_server_cart.id){
            return ServerCart.isSomeServerCartSent(this.vg_server_cart)
        }else{
          return false
        }
    },
    isSplitEvenlyPaid(){
      if(this.vg_server_cart){
        return (this.vg_server_cart.paid>0 && this.vg_selectedPaymentType=='splitEvenly')
      }
    },
    userRequestedToJoinTable(){
      return CartUser.isUserNotAccepted(this.vg_loginUser, this.vg_cartUsers)
    },
    async callServer(){
      try{
        this.spinner(true)
        let data = {
          user_name: StringHelper.getUserNameOrPhone(this.vg_loginUser),
          location_id: this.vg_location.id,
          business_id: this.vg_businessId
        }
        let response = await CartAPI.callServer(this.vg_tableId, data)
        this.snackbar({open: true, text: response.data.msg, color: 'success'});
        this.option = false
        this.spinner(false)
      }catch(err){
        this.spinner(false)
        console.log(err)
      }
    },
    myTable(){
      if(this.vg_itemInCart){
        this.$router.push({name: 'cart'})
      }else{
        this.$router.push({name: 'manage-table'})
      }
    },
    moreOption(){
      this.option = !this.option
    },
    async getCartForJoiningUser(){
      if(this.vg_joinedCardId){
        if(this.vg_orderType=='dinein'){
          // get cart for already joined user

          await this.va_cartUsers(this.vg_joinedCardId)
          
          if(CartUser.isUserActive(this.vg_loginUser, this.vg_cartUsers)){
            let activeCartData = {
                table_id: this.vg_tableId
            }
            let {data} = await CartAPI.updateCart(this.vg_joinedCardId ,activeCartData)
            this.vm_server_cart(data.payload)
          }
        }
      }
    },
    switchDineInOrTakeoutIfNeeded(){
      // receive method not dinein, takeout, delivery or null just random text
      if(this.$router.currentRoute.query.receive_method!=='dinein' && this.$router.currentRoute.query.receive_method!=='takeout' && this.$router.currentRoute.query.receive_method!=='delivery' && this.$router.currentRoute.query.receive_method!=null){
        this.vm_setOrderType(null)
        this.vm_confirmDeliveryPickup(true)
      // receive_method=dinein but current takeout, receive_method=takeout but current dinein, receive_method=dinein or takeout in incognito mode
      }else if((this.$router.currentRoute.query.receive_method==='dinein' && this.vg_orderType!=='dinein') || (this.$router.currentRoute.query.receive_method==='takeout' && this.vg_orderType!=='takeout') || (this.$router.currentRoute.query.receive_method==='delivery' && this.vg_orderType!=='delivery') || (this.$router.currentRoute.query.receive_method!=null && this.vg_orderType!=='dinein')){
        this.vm_confirmDeliveryPickup(false)
        this.vm_orderDeliveryPickup(false)
        if(this.$router.currentRoute.query.table_id && parseInt(this.$router.currentRoute.query.table_id)) this.vm_tableId(this.$router.currentRoute.query.table_id) 
        this.switchService(this.$router.currentRoute.query.receive_method=='takeout'?'pickup':this.$router.currentRoute.query.receive_method)
      }else if(this.vg_orderType===null){
        // user arrive first time
        if(this.vg_location.settings && this.vg_location.settings.pickup_orders && this.vg_location.settings.pickup_orders.is_active){
          this.vm_confirmDeliveryPickup(true)
        }else{
          this.vm_setOrderType('dinein')
          this.vm_confirmDeliveryPickup(false)
          this.vm_orderDeliveryPickup(false)
        }
      }
    },
    async switchServiceFromPopup(switchTo){
      await this.switchService(switchTo)
    },
    async switchService(switchTo, revert=false){
      try{
        if(!switchTo) return
        this.switchTo = switchTo
        this.prevOrderType = ''+this.vg_orderType

        // card id is available
        if(this.vg_cartId || this.vg_joinedCardId){          
          // process switching only if showPopupIfTableIdNotExist is false
          if(!await this.showPopupIfTableIdNotExist(switchTo)){
            // switch to dine or takeout
            let data = {
              // api uses dinein or pickup(takeout)
              order_receive_method: switchTo
            }
            await CartAPI.switchReceiveMethod(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId, data)
            if(!revert){
              await this.updateCartWithIdForDineinAndGetData(switchTo)
            }else{
              // remove table id if reverting to takeout
              if(switchTo==='takeout' || switchTo==='delivery'){
                  this.vm_tableId(null)
                }
            }
          }
        }else{
          // for switch order type when not logged in
          this.vm_setOrderType(switchTo)
          if(this.switchTo=='pickup' || this.switchTo=='delivery'){
            this.vm_tableId(null)
          }

          this.option = false
          let message = ""
          if(switchTo=='pickup'){
            message = "take out"
          }else if(switchTo=='delivery'){
            message = "delivery"
          }else{
            message = "dine in"
          }
          this.snackbar({open: true, text: `Switched to ${message} order`, color: 'success'});
        }
        this.va_setMenu(this.vg_location)
      }catch(err){
        if(!revert){
            // if already switch but table id already exist
            await this.updateCartWithIdForDineinAndGetData(switchTo)
         }
      }
    },
    async updateCartWithIdForDineinAndGetData(switchTo){
        try{

            // get active cart by location, table and user
            let activeCart
            if(switchTo==='dinein'){
                let activeCartData = {
                    location_id: this.vg_location.id,
                    table_id: this.vg_tableId
                }
                let {data} = await CartAPI.updateCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId ,activeCartData)
                activeCart = data.payload
            }else if(switchTo==='pickup'){
                let {data} = await CartAPI.getActivePickupOrDeliveryCartByLocation(this.vg_location.id, this.vg_loginUser.user.id)
                activeCart = data.payload

                this.vm_tableId(null)
            }else if(switchTo=='delivery'){
              let {data} = await CartAPI.getActivePickupOrDeliveryCartByLocation(this.vg_location.id, this.vg_loginUser.user.id)
                activeCart = data.payload

                this.vm_tableId(null)
            }

            if(activeCart && activeCart.id){
                // this.vm_cartId(activeCart.id)
                this.vm_resetPayerInfo()

                // on success switch service
                this.option = false
                this.vm_setOrderType(switchTo)
                this.vm_confirmDeliveryPickup(false)
                this.vm_orderDeliveryPickup(false)
                return true
            }
        }catch(err){
          if(err.response.data.msg==='This table has ongoing order. Please choose another table'){
              this.switchService(this.prevOrderType, true)
              this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
              this.spinner(false)
              this.vm_tableId('')
              return false
          }else{
            // revert
            this.switchService(this.prevOrderType, true)
          }
        }
    },
    async showPopupIfTableIdNotExist(switchTo){
      // check for table id for switch to dinin
      if(switchTo==='dinein'){
        if(!this.vg_tableId){
          this.vm_identifyTableStatus(true)
          return true
        }else{
          // check if table id is already active
          try{
            await CartAPI.getCartIdByTableNumber(this.vg_location.id, this.vg_tableId)
            this.snackbar({open: true, text: 'This table has ongoing order. Please choose another table', color: 'error'});
            this.vm_tableId('')
            return true
          }catch(err){
            return false
          }
        }
      }else{
       return false
      }
    },
    initScroll(){
      // enable interact only after all element loaded  
      setTimeout(()=> {
        this.enableScroll = true
        this.scrollTo(this.vg_currentTab)

        let self = this
        document.querySelector('.v-list').addEventListener('scroll', function(e){
          let currentY = e.target.scrollTop
          if (currentY > this.previousY){
            self.scrollStatus = 'down'
          } else {
            self.scrollStatus = 'up'
          }
          this.previousY = currentY <= 0 ? 0 : currentY
        })
      },900)
    },
    // for scroll
    handleIntersect(entries) {
      if(this.enableScroll){
        if(entries[0].target.id && entries[0].boundingClientRect.bottom<=450){
          this.currentTab = Number(entries[0].target.id.split('-')[1])
        }else if(entries[0].target.id && entries[0].boundingClientRect.bottom>=550 && this.scrollStatus=='up'){
          let tabNum = Number(entries[0].target.id.split('-')[1])
          let tempTab = (tabNum>0)?(tabNum-1):0
          let tabContentNum = document.querySelector('#tab-content-'+tempTab).childNodes.length
          if(tabContentNum>5){
            this.currentTab = (tabNum>0)?(tabNum-1):0
          }
        }
      }
    },
    scrollTo(hashNo){
      const self = this
      this.enableScroll = false
      let hash = 'tab-'+ hashNo
      document.getElementById(hash).scrollIntoView(true)
      document.querySelector('.v-list').scrollBy(0, 0); 
      setTimeout(() => {
        // check if element has reach to top
        const elPos = document.getElementById(hash).offsetTop
        const scrollPos = document.querySelector('.v-list');
        
        if(scrollPos.scrollTop && Math.round(elPos)!==Math.round(scrollPos.scrollTop)){
          // test which tab can it reach to
          let testHash = Number(hashNo+'')
          let testElPos = document.getElementById('tab-'+testHash).offsetTop
          while(testElPos>scrollPos.scrollTop){
            testHash--
            testElPos = document.getElementById('tab-'+testHash).offsetTop
          }
          self.currentTab = testHash
        }
        self.enableScroll = true
      }, 300)
      
    },
    async getServerCart(){
      try{
        if(this.vg_loginUser.user && this.vg_loginUser.user.id){
          if(this.vg_cartId || this.vg_joinedCardId){
            let {data} = await CartAPI.getCart(this.vg_cartId?this.vg_cartId:this.vg_joinedCardId)
            this.vm_server_cart(data.payload)
          }
        }
      }catch(err){
          console.log(err)
      }
    },

    async isDeliveryAvailable(){
        try{
            if(this.vg_geoLocation.lat && this.vg_geoLocation.lng){
                this.spinner(true)
                let {data} = await LocationAPI.getDeliveryFee(this.vg_location.id, this.vg_geoLocation, this.vg_server_cart.subtotal)
                if(data.payload){
                    this.vm_deliveryFee(data.payload.delivery_fee)
                }
                this.spinner(false)
                return true
            }
        }catch(err){
            this.spinner(false)
            this.snackbar({open: true, text: err.response.data.msg, color: 'error'});
            return false
        }
    },

    resetCart(){
      if(this.$router.currentRoute.query.receive_method!=='dinein' && this.$router.currentRoute.query.receive_method!=='takeout' && !this.$router.currentRoute.query.join_cart && (!(this.$router.currentRoute.query.table_id && this.$router.currentRoute.query.allow_to_join))){
        this.vm_confirmDeliveryPickup(true)
      }
      this.vm_resetCart()
      this.vm_resetCartUser()
      this.vm_resetPayment()
    },
    logOut(){
      this.vm_setOrderType(null)
      this.vm_resetCart()
      this.vm_resetCartUser()
      this.vm_deliveryReset()
      this.vm_resetPayment()
      this.vm_tableId('')
      this.vm_allowToJoin(false)
      this.vm_resetUser()
      this.vm_confirmDeliveryPickup(true)
    },
    ...mapMutations({
      vm_cart: 'cart/SET_CART',
      vm_cartId :'cart/SET_CART_ID',
      vm_currentCategory: 'menu/SET_CURRENT_CATEGORY',
      vm_currentTab: 'menu/SET_CURRENT_TAB',
      vm_tableId: 'cart/SET_TABLE_ID',
      vm_joinedCardId: 'cart/SET_JOINED_CARD_ID',
      vm_signupStatus: 'signup/SET_STATUS',
      vm_loginStatus: 'login/SET_STATUS',
      vm_setBusinessName: 'location/SET_BUSINESS_NAME',
      vm_notification: 'cartUser/SET_NOTIFICATION',
      vm_payed: 'payment/SET_PAYED',
      vm_resetPayerInfo: 'payment/RESET_PAYER_INFO',
      vm_resetPayment: 'payment/RESET',
      vm_deliveryReset: 'delivery/RESET',
      vm_resetCart: 'cart/RESET',
      vm_resetUser: 'login/RESET_USER',
      vm_setOrderType: 'cart/SET_ORDER_TYPE',
      vm_confirmDeliveryPickup: 'cart/SET_CONFIRM_DELIVERY_PICKUP',
      vm_server_cart: 'cart/SET_SERVER_CART',
      vm_resetCartUser: 'cartUser/RESET',
      vm_resetLocationBusiness: 'location/RESET_LOCATION_BUSINESS',
      vm_orderDeliveryPickup: 'order/SET_ORDER_PICKUP_DELIVERY',
      vm_identifyTableStatus: 'identifyTable/SET_STATUS',
      vm_allowToJoin: 'menu/SET_ALLOW_TO_JOIN',
      vm_lockCheckPop: 'cart/SET_LOCK_CHECKOUT_POP',
      vm_callServerTableId: 'menu/SET_CALL_SERVER_TABLE_ID',
      vm_changeDeliveryPopup: 'delivery/SET_CHANGE_DELIVERY_POP',
      vm_deliveryPhone: 'delivery/SET_PHONE',
      vm_savePhonePop: 'delivery/SET_SAVE_PHONE_POP',
      vm_deliveryFee: 'delivery/SET_DELIVERY_FEE',
    }),
    ...mapActions({
      va_setMenu: 'menu/setMenu',
      va_cartUsers: 'cartUser/cartUsers',
      va_setLocation: 'location/setLocation'
      
    })
  }
}
</script>
<style>
.toolbar .v-tabs .v-slide-group__prev {
  display: none !important;
}
.toolbar .v-tabs .v-tab--active {
  background: #000055 !important;
  border-radius: 30px;
  color: #fff;
  margin: 5px;
}
.toolbar .menu-list .v-list-item .v-list-item__subtitle {
  overflow: initial;
  white-space: initial;
}
.toolbar .menu-list .v-list-item__subtitle span {
  margin-right: 10px;
}
.toolbar .menu-list .v-list-item__subtitle .tag-text{
  float: left;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  background-color: #fff;
}
.toolbar .menu-list .v-list-item__subtitle .tag{
  float: left;
  background: #fff;
  border-radius: 50%;
}
.toolbar .menu-list .v-list-item__subtitle .contains.tag-text{
  border: solid thin #F44336;
}
.toolbar .menu-list .v-list-item__subtitle .may_contain.tag-text{
  border: solid thin #008;
} 
/* .toolbar .menu-list .v-list-item__subtitle .item-contains {
  background: #ff683c;
  border-radius: 5px;
  color: white;
  padding-left: 3px;
  padding-right: 3px;
}
.toolbar .menu-list .v-list-item__subtitle .item-may-contains {
  background: #000055;
  border-radius: 5px;
  color: white;
  padding-left: 3px;
  padding-right: 3px;
} */
.toolbar .menu-list .v-input__slot:before {
  border: none;
}
.search .v-input__slot{
  min-height:26px !important;
}
</style>