<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400">
            <v-card>
                <v-card-title>
                    <v-spacer />
                    <span>Do you want delivery?</span>
                    <v-spacer />
                    <v-btn fab dark x-small  @click.prevent="close">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-2">
                    <div>We deliver! Try your address bellow to see if you are within our bounds! Otherwise, try our Pick Up option.</div>

                    <v-row class="delivery-address my-3">
                        <v-col cols="10">
                            <gmap-autocomplete
                                :options="autocompleteOptions"
                                ref="googlePlace"
                                style="width:100%"
                                class="google-autocomplete"
                                large
                                placeholder="Enter Address"
                                @place_changed="setAddress"
                            ></gmap-autocomplete>
                        </v-col>
                        <v-col>
                            <a @click.prevent="getGPSLocation()"><v-icon>mdi-crosshairs-gps</v-icon></a>
                        </v-col>
                    </v-row>

                    <v-btn class="px-5 mb-5 primary" block @click.prevent="setDelivery" :disabled="Object.keys(this.address).length==0">See Delivery Menu</v-btn>
                    <v-btn class="px-5 mb-5 primary--text" block text @click.prevent="setPickup">I want pick up</v-btn>
                </v-card-text>
                <v-card-text>
                    <div class="mx-auto pb-5" style="width:125px; font-size:10px;">
                    <span class="float-left pr-1 primary--text">POWERED BY :</span>
                    <v-img class="float-left" src="@/assets/img/logo-small.png" contain width="35" height="25" style="top:-5px"/>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {GoogleAPI} from '@/clients/google'
import {GoogleHelper} from '@/helpers/googleHelper'
import {mapGetters, mapMutations} from 'vuex'

export default {
    data(){
        return{
            address: {},
            autocompleteOptions: {
                componentRestrictions: {
                    country: [
                        'ca',
                    ],
                },
            },
            geo: {
                lat: '',
                lng: ''
            }
        }
    },
    computed: {
        geoLocation: {
            get(){
                return this.vg_geoLocation
            },
            set(val){
                this.vm_geoLocation(val)
            }
        },
        dialog: {
            get(){
                return this.vg_deliveryPopup
            },
            set(val){
                this.vm_deliveryPopup(val)
            }
        },
        ...mapGetters({
            vg_geoLocation: 'delivery/geoLocation',
            vg_deliveryPopup: 'delivery/popup',
        })
    },
    methods: {
        setAddress(address){
            if(address){
                this.geo = {lat: address.geometry.location.lat(), lng: address.geometry.location.lng()}
                this.address = GoogleHelper.getAddress(address)
                this.address.label = 'default'
                this.address.address_type = 'home'
                this.address.verified = false
            }
        },
        getGPSLocation() {
            let self = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async function (position) {
                    self.geo = {lat: position.coords.latitude, lng: position.coords.longitude}
                    try{
                        self.spinner(true)
                        let result = await GoogleAPI.getAddressFromCoordinate(position.coords.latitude, position.coords.longitude)
                        if(result.data && result.data.results && result.data.results[0]){
                            self.$refs.googlePlace.$refs.input.value = result.data.results[0].formatted_address
                            self.address = GoogleHelper.getAddress(result.data.results[0])
                            self.address.label = 'default'
                            self.address.address_type = 'home'
                            self.address.verified = false
                        }
                        self.spinner(false)
                    }catch(err){
                        console.log(err)
                        self.spinner(false)
                    }
                });
            }
        },
        async setDelivery(){
            this.vm_orderType('delivery')
            this.vm_address(this.address)
            this.vm_geoLocation(this.geo)
            this.vm_saveAddressPop(true)
            this.vm_deliveryPopup(false)
            this.$refs.googlePlace.$refs.input.value = ''
        },
        setPickup(){
            this.vm_orderType('pickup')
            this.vm_deliveryPopup(false)
            this.$refs.googlePlace.$refs.input.value = ''
        },
        close(){
            this.$refs.googlePlace.$refs.input.value = ''
            this.vm_deliveryPopup(false)
        },
        ...mapMutations({
            vm_address: 'delivery/SET_ADDRESS',
            vm_orderType: 'cart/SET_ORDER_TYPE',
            vm_deliveryPopup: 'delivery/SET_POPUP',
            vm_saveAddressPop: 'delivery/SET_SAVE_ADDRESS',
            vm_geoLocation: 'delivery/SET_GEO_LOCATION'
        })
    }
}
</script>

<style>
    .delivery-address{
        border: solid thin #ccc;
        border-radius: 5px;
    }
    .google-autocomplete:focus-visible{
        outline: none;
    }
</style>
